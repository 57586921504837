import {
  DriverMap,
  MapMarker,
  MapPolygon,
  MapPolyline,
  PassengerMap,
} from 'models';
import React from 'react';
import { noop } from 'utils';

type MapContentProps = {
  journeyId: string;
  elements?: {
    markers: Array<MapMarker>;
    polygons: Array<MapPolygon>;
    polylines: Array<MapPolyline>;
  };
  driverMap: DriverMap;
  passengersMap: PassengerMap;
  setDriverMap: (value: DriverMap) => void;
  setPassengersMap: (value: PassengerMap) => void;
};

export default React.createContext<MapContentProps>({
  journeyId: '',
  elements: undefined,
  passengersMap: {},
  driverMap: {} as DriverMap,
  setDriverMap: noop,
  setPassengersMap: noop,
});

import { appUri } from 'config';
import { Route } from 'models';
import Users from './Users.page';
import UsersFeedback from './pages/UsersFeedback.component';

const usersRoutes: Route[] = [
  {
    handle: 'users',
    component: Users,
    title: 'users',
    exact: true,
    path: appUri.USERS,
  },
  {
    handle: 'users-feedback',
    component: UsersFeedback,
    title: 'users-feedback',
    exact: true,
    path: appUri.USERS_FEEDBACK,
  },
];


export default usersRoutes;

import { appUri } from 'config';
import { Route } from 'models';
import { PrivateRoute } from 'modules';
import ServiceAccounts from './ServiceAccounts.component';
import ServiceAccountsKeys from './ServiceAccountsKeys.component';

const serviceAccountsRoutes: Route[] = [
  {
    handle: 'Service accounts keys',
    path: appUri.SETTINGS_SERVICE_ACCOUNTS_KEYS,
    exact: true,
    component: () => <PrivateRoute component={ServiceAccountsKeys} />,
  },
  {
    handle: 'Service accounts',
    path: appUri.SETTINGS_SERVICE_ACCOUNTS_KEYS_KEY(),
    exact: false,
    component: () => <PrivateRoute component={ServiceAccounts} />,
  },
];

export default serviceAccountsRoutes;

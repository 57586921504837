import { PaginatedResponse } from 'models';
import { Reward } from 'models/Reward';
import httpClient from '../httpClient';

// TODO: uncoment this when pagination and sorting is done on BE side
// const getRewards = ({
//   per_page,
//   page,
//   search,
//   sort_by,
//   sort_direction,
// }: GetRewardsProps) =>
//   httpClient.get<PaginatedResponse<Reward, 'vouchers'>>('/vouchers', {
//     params: {
//       per_page,
//       page,
//       search,
//       sort: `${sort_by}:${sort_direction}`,
//     },
//   });

const getRewards = () =>
  httpClient.get<PaginatedResponse<Reward, 'vouchers'>>('/vouchers');

const createReward = (name: string, file: File[], communities: string[]) => {
  const formData = new FormData();

  formData.append('name', name);

  file?.forEach((file: File) => {
    formData.append('file[]', file);
  });

  communities?.forEach((community) => {
    formData.append('communities[]', community);
  });

  return httpClient.post('/vouchers', formData);
};

const updateReward = (
  voucherID: number,
  name: string,
  file: File[],
  communities: string[]
) => {
  const formData = new FormData();

  formData.append('name', name);

  if (file && file.length > 0) {
    file?.forEach((file: File) => {
      formData.append('file[]', file);
    });
  }

  communities.forEach((communitie) => {
    formData.append('communities[]', communitie);
  });

  return httpClient.put(`vouchers/${voucherID}`, formData);
};

const updateRewardPerCommunity = (
  organisationID: number,
  rewards: number[]
) => {
  const formData = new FormData();

  rewards?.forEach((reward) => {
    formData.append('vouchers[]', `${reward}`);
  });

  return httpClient.post(`organisations/${organisationID}/vouchers`, formData);
};

export default {
  getRewards,
  createReward,
  updateReward,
  updateRewardPerCommunity,
};

import { useContext, useEffect } from 'react';
import { GoogleMapsContext, getColor } from '@faxi/web-component-library';
import { MapPolyline as MapPolylineModel } from 'models';

type MapPolylineProps = MapPolylineModel & {
  onPolylineAdd(Segment: google.maps.Polyline): void;
};

const MapPolyline: React.FC<MapPolylineProps> = (props) => {
  const { from, to, onPolylineAdd } = props;

  const { map } = useContext(GoogleMapsContext);

  useEffect(() => {
    if (!map) return;

    onPolylineAdd(
      new window.google.maps.Polyline({
        path: [
          new window.google.maps.LatLng(+from.lat, +from.lng),
          new window.google.maps.LatLng(+to.lat, +to.lng),
        ],
        map,
        strokeColor: getColor('--PRIMARY_1_1'),
        strokeWeight: 3,
        icons: [
          {
            icon: { path: google.maps.SymbolPath.CIRCLE },
            offset: '0',
          },
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
            },
            offset: '100%',
          },
        ],
      })
    );
  }, [from.lat, from.lng, map, onPolylineAdd, to.lat, to.lng]);

  return null;
};

export default MapPolyline;

import { theme } from '@faxi/web-component-library';
import { phablet, flex } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const DashboardPageStyled = styled.div`
  ${flex('column', 'center', 'center')};
  text-align: center;

  ${phablet(
    css`
      margin: unset;
      padding: ${theme.sizes.SIZE_16};
    `
  )};

  .dashboard-page {
    &__heading {
      color: var(--PRIMARY_1_1);
      width: ${theme.sizes.SIZE_472};

      ${phablet(
        css`
          width: unset;
        `
      )};
    }

    &__text {
      margin: ${theme.sizes.SIZE_24} 0 ${theme.sizes.SIZE_40} 0;
      font-size: ${theme.fontSizes.FONT_14};
      color: var(--SHADE_1_2);
      width: ${theme.sizes.SIZE_424};

      ${phablet(
        css`
          width: unset;
        `
      )};
    }

    &__graphics {
      width: ${theme.sizes.SIZE_296};
      height: ${theme.sizes.SIZE_288};

      ${phablet(
        css`
          width: unset;
        `
      )};
    }
  }
`;

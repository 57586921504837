import { SelectOption } from '@faxi/web-component-library';
import { Log } from 'models/Log';

const EXCLUDED_COLUMNS = ['created_at', 'name', 'id'] as Array<keyof Log>;

const SEARCH_PARAMS = {
  email: 'email',
  user_id: 'user_id',
  date: 'date',
} as const;

const LOGS_SEARCH_OPTIONS = [
  { label: 'User ID', value: 'user_id' },
  { label: 'User email', value: 'email' },
  { label: 'Day', value: 'date' },
] as Array<SelectOption<string>>;

const TRANSLATION_KEYS = {
  email: 'Email',
  device_time: 'Date/Time',
  user_id: 'UID',
  type: 'Type',
} as Record<Partial<keyof Log>, string>;

const PARAMS_PLACEHOLDERS = {
  email: 'Search by email',
  user_id: 'Search by user ID',
  date: 'Search by day',
} as Record<LogsSearchParam, string>;

export type LogsSearchParam = typeof SEARCH_PARAMS[keyof typeof SEARCH_PARAMS];

export {
  SEARCH_PARAMS,
  LOGS_SEARCH_OPTIONS,
  TRANSLATION_KEYS,
  PARAMS_PLACEHOLDERS,
  EXCLUDED_COLUMNS,
};

import { Modal as ModalComponent, theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const Modal = styled(ModalComponent)`
  .create-reward-modal {
    &__hide {
      display: none;
    }

    &__desc {
      font-size: ${theme.fontSizes.FONT_14};
      font-weight: 400;
      line-height: ${theme.sizes.SIZE_20};
      color: var(--SECONDARY_1_1);
      margin: ${theme.sizes.SIZE_24} 0 ${theme.sizes.SIZE_16};
    }
  }
`;

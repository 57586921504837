import styled, { css } from 'styled-components';
import { Heading, theme } from '@faxi/web-component-library';
import { phablet } from '@faxi/web-css-utilities';

export const TableHeader = styled(Heading)`
  font-size: ${theme.fontSizes.FONT_34};
  font-weight: 600;
  line-height: 44px;
  color: var(--PRIMARY_1_1);
  margin-bottom: ${theme.sizes.SIZE_32};
`;

export const TraxTable = styled.div`
  padding: ${theme.sizes.SIZE_48} ${theme.sizes.SIZE_200};
  overflow: auto;

  .wcl-table-container {
    overflow: auto;
    height: auto;

    .wcl-table__heading-row {
      position: sticky;
      top: 0;
      background-color: var(--BACKGROUND_1_1);
      z-index: 1;
    }
  }

  ${phablet(css`
    padding: ${theme.sizes.SIZE_20};
  `)};

  .trax-table {
    &__back-button {
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__empty-placeholder {
      color: var(--SHADE_1_3);
    }
  }

  .wcl-table {
    &__data {
      white-space: break-spaces;
      word-break: break-word;

      > span:first-child {
        word-break: normal;
      }
    }
  }
`;

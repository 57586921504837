import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import authBus, { AUTH_BUS_EVENTS } from 'modules/authBus';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import refreshTokenService from 'modules/refreshTokenService';
import { history } from '../Router';
import { appUri } from 'config';
import { snackBarErrorMessage } from 'utils';

//routes from which will be redirected to dashboard on 404 error code
const errorRouteFallback = ['journeys', 'journey-list'];

const origin = window.location.origin.includes('localhost')
  ? 'https://app-dev.kinto-join.it'
  : window.location.origin.replace('superadmin', 'app');

const httpClient = axios.create({
  baseURL: `${origin}/sa/api/`,
});

const setToken = (config: AxiosRequestConfig) => {
  const token = storageService.getItem(STORAGE_KEYS.TOKEN);
  if (token) (config.headers as any).Authorization = `Bearer ${token}`;
};

const refresh = async (config: AxiosRequestConfig) => {
  return await new Promise<void>((resolve, reject) => {
    function onRefreshTokenSuccess() {
      setToken(config);
      removeListeners();
      resolve();
    }

    function onRefreshTokenFail() {
      reject(new Error('Could not refresh token'));
      removeListeners();
    }

    const removeSuccessListener = authBus.addEventListener(
      AUTH_BUS_EVENTS.REFRESH_TOKEN_SUCCESS,
      onRefreshTokenSuccess
    );

    const removeFailListener = authBus.addEventListener(
      AUTH_BUS_EVENTS.REFRESH_TOKEN_FAIL,
      onRefreshTokenFail
    );

    const removeListeners = () => {
      removeFailListener();
      removeSuccessListener();
    };

    setTimeout(() => {
      reject(new Error('Could not refresh token'));
      removeListeners();
      refreshTokenService.refreshingAtm = false;
    }, refreshTokenService.checkRefreshTreshold);
  });
};

httpClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    setToken(config);

    if (refreshTokenService.refreshingAtm && !(config as any).isRefresh) {
      await refresh(config);
    }

    return config;
  },
  function (err: AxiosError) {
    Promise.reject(err);
  }
);

httpClient.interceptors.response.use(
  (res: AxiosResponse) => {
    if (res?.data.error && ![1001, 1003].includes(res?.data.errc)) {
      return Promise.reject(res?.data.error);
    }

    return res;
  },
  (error: Error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(undefined, async (error) => {
  const { response, config } = error;
  const apiStatusCode = response?.data?.data?.code;

  if (
    response &&
    ([401].includes(response.status) || [1036, 9007].includes(apiStatusCode))
  ) {
    if (config.isRefresh) {
      refreshTokenService.triedRefresh = true;
      return;
    }

    if (refreshTokenService.refreshingAtm) {
      await refresh(config);
      return httpClient(config);
    }

    try {
      await refreshTokenService.refresh();
      return httpClient(config);
    } catch (err) {
      (error as any).preventDefault = true;
      authBus.broadcastEvent(AUTH_BUS_EVENTS.LOGGED_OUT, true);
    }
  }

  if (
    response &&
    [404, 403].includes(response.status) &&
    ![9005].includes(response.data.data.errc)
  ) {
    if (
      errorRouteFallback.find((route) =>
        history.location.pathname.includes(route)
      )
    ) {
      history.push(appUri.DASHBOARD);
      snackBarErrorMessage('Invalid parameters');
    }
  }

  return Promise.reject(error);
});

export default httpClient;

import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Chart,
  SelectOption,
  MiniReportTotal,
  ReportContainer,
  Switch,
  useUtilities,
  PlaceholderChart,
  Heading,
  useQueryParams,
  DatePicker,
  MiniReportTotalProps,
  getColor,
  Link,
  useDatePickerParams,
} from '@faxi/web-component-library';
import classNames from 'classnames';

import { apiAnalytics } from 'modules';
import { formatChartData } from 'utils';
import { Analytics } from 'models';
import {
  DATE_PICKER_ACTIONS_LABELS,
  DATE_PICKER_LABELS,
  appUri,
  reportsConfig,
} from 'config';
import JourneyStatusChart from './components/JourneyStatusChart';
import { INameExtended } from 'components/Icon';
import CommunitySearch from 'pages/Users/components/CommuitySearch';

import * as Styled from './Analytics.page.styles';

export type UserPreferences = {
  dateFormat: string;
  unit: string;
};

const AnalyticsPage: FC = (): JSX.Element => {
  const {
    params: { community_id, community_name },
    setQueryParam,
    removeQueryParams,
  } = useQueryParams<{
    community_id: string;
    community_name: string;
  }>();

  const { showOverlay, hideOverlay } = useUtilities();

  const { dateRange, setDateRange } = useDatePickerParams(
    reportsConfig.range_start
  );

  const [analytics, setAnalytics] = useState<Analytics>();
  const analyticsHasError = useRef<boolean>(false);

  const [selectedCommunity, setSelectedCommunity] = useState<SelectOption>({
    id: community_id,
    value: community_id,
    label: community_name,
  } as SelectOption);

  const [showTestUsers, setShowTestUsers] = useState(false);

  const [loadingAnalytics, setLoadingAnalytics] = useState(false);

  const noxTotal = useMemo(
    () =>
      analytics
        ? +Number.parseFloat(
            `${
              Object.values(analytics.emission_stats).slice(-1)[0]?.[
                'nox_cumulative'
              ]
            }` || '0'
          ).toFixed(2)
        : 0,
    [analytics]
  );

  const noxTotalTest = useMemo(
    () =>
      analytics
        ? +Number.parseFloat(
            `${
              Object.values(analytics.emission_stats).slice(-1)[0]?.[
                'nox_cumulative-test'
              ]
            }` || '0'
          ).toFixed(2)
        : 0,
    [analytics]
  );

  const co2Total = useMemo(
    () =>
      analytics
        ? +Number.parseFloat(
            `${
              Object.values(analytics.emission_stats).slice(-1)[0]?.[
                'co2_cumulative'
              ]
            }` || '0'
          ).toFixed(2)
        : 0,
    [analytics]
  );

  const co2TotalTest = useMemo(
    () =>
      analytics
        ? +Number.parseFloat(
            `${
              Object.values(analytics.emission_stats).slice(-1)[0]?.[
                'co2_cumulative-test'
              ]
            }` || '0'
          ).toFixed(2)
        : 0,
    [analytics]
  );

  const miniReports = useMemo(
    () =>
      analytics &&
      ([
        {
          className: 'analytics__graph--total-communities',
          text: 'Total communities',
          iconName: 'regular-users',
          total: `${analytics.total_communities}`,
          totalTest: analytics.total_communities_test
            ? `${analytics.total_communities_test}`
            : '0',
        },
        {
          className: 'analytics__graph--total-users',
          text: 'Total Users',
          iconName: 'user',
          total: `${analytics.total_users}`,
          totalTest: analytics.total_users_test
            ? `${analytics.total_users_test}`
            : '0',
        },
        {
          className: 'analytics__graph--carparks',
          text: 'Total number of carparks',
          iconName: 'cars',
          total: `${analytics.carparks}`,
          totalTest: analytics.carparks_test
            ? `${analytics.carparks_test}`
            : '0',
        },
        {
          className: 'analytics__graph--carparks-spaces',
          text: 'Total number of parking spaces',
          iconName: 'circle-parking',
          total: `${analytics.carpark_spaces}`,
          totalTest: analytics.carpark_spaces_test
            ? `${analytics.carpark_spaces_test}`
            : '0',
        },
        {
          className: 'analytics__graph--num-of-shifts',
          text: 'Total number of shifts that are created',
          iconName: 'clock',
          total: `${analytics.shifts}`,
          totalTest: analytics.shifts_test ? `${analytics.shifts_test}` : '0',
        },
        {
          className: 'analytics__graph--num-of-users-shifts',
          text: 'Total number of users that have shifts assigned',
          iconName: 'user-clock',
          total: `${analytics.shift_users}`,
          totalTest: analytics.shift_users_test
            ? `${analytics.shift_users_test}`
            : '0',
        },
      ] as Array<MiniReportTotalProps<INameExtended>>),
    [analytics]
  );

  const seriesData = useCallback(
    (propName: keyof Analytics, isTest = false, deepPropName?: string) => {
      if (
        !analytics ||
        (isTest && !analytics[`${propName}_test` as keyof Analytics])
      )
        return {};

      return formatChartData(
        isTest
          ? (analytics[`${propName}_test` as keyof Analytics] as Record<
              string,
              number
            >)
          : (analytics[propName] as Record<string, number>),
        deepPropName
      );
    },
    [analytics]
  );

  const renderChartOrPlaceholder = useCallback(
    (data: Record<string, number>[], chart: ReactNode) => {
      if (
        analyticsHasError.current ||
        data.find((d) => !Object.keys(d).length)
      ) {
        return (
          <PlaceholderChart label="Unable to return data. Please try again later." />
        );
      }

      return chart;
    },
    []
  );

  const getAnalytics = useCallback(async () => {
    if (dateRange) {
      try {
        setLoadingAnalytics(true);
        showOverlay('.analytics-page', 'fixed');
        const { data } = await apiAnalytics.getAnalytics(
          dateRange?.from.format('YYYY-MM-DD'),
          dateRange?.to.format('YYYY-MM-DD'),
          showTestUsers ? 1 : undefined,
          selectedCommunity?.value
        );

        if (data) {
          setAnalytics(data);
        }
      } catch (e) {
        console.error(e);
        analyticsHasError.current = true;
      } finally {
        setLoadingAnalytics(false);
        analyticsHasError.current = false;
        hideOverlay('.analytics-page');
      }
    }
  }, [
    dateRange,
    showOverlay,
    showTestUsers,
    selectedCommunity?.value,
    hideOverlay,
  ]);

  // GET ANALYTICS
  useEffect(() => {
    getAnalytics();
  }, [dateRange, selectedCommunity, getAnalytics]);

  return (
    <Styled.AnalyticsPageStyled className="analytics-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="analytics__header"
      >
        Analytics
      </Heading>
      <div className="analytics__filters">
        <div className="analytics__filters__data">
          <CommunitySearch
            className="analytics__filters__data__select"
            selectedCommunity={selectedCommunity}
            onSelect={(opt) => {
              setSelectedCommunity(opt);
              setQueryParam('community_id', opt.value);
              setQueryParam('community_name', opt.label);
            }}
            onClear={() => {
              setSelectedCommunity({} as SelectOption);
              removeQueryParams(['community_id', 'community_name']);
            }}
            useQueryParams={false}
            initialSearch={selectedCommunity.label}
          />
          <Link
            className="analytics__filters__data__link"
            to={appUri.ANALYTICS_EKG}
          >
            Community EKG
          </Link>
        </div>
        <div className="analytics__filters__show-test-data">
          <span>Show test data</span>
          <Switch
            value={showTestUsers}
            onChange={() => setShowTestUsers(!showTestUsers)}
          />
        </div>
      </div>

      {analytics && (
        <div className="analytics__totals">
          {miniReports?.map((report) => (
            <MiniReportTotal
              key={report.iconName}
              {...report}
              totalTest={report.totalTest}
              hasTest={showTestUsers}
              loading={loadingAnalytics}
            />
          ))}
        </div>
      )}

      <DatePicker
        className="analytics__date-picker"
        value={dateRange}
        buttonsLabels={DATE_PICKER_LABELS}
        startingRangeDate={reportsConfig.range_start}
        formButtonsLabels={DATE_PICKER_ACTIONS_LABELS}
        onChange={setDateRange}
      />

      {analytics && (
        <div
          className={classNames('analytics__graphs', {
            'analytics__graphs--selected-community': selectedCommunity?.value,
          })}
        >
          {/* REGISTERED COMMUNITIES */}
          {!selectedCommunity?.value && (
            <ReportContainer
              title="Registered communities"
              info="The graph shows the total number of newly registered communities in a market within a selected date range."
              className="analytics__graph analytics__graph--registered-communities"
              totalsTooltipContent="Total for the chosen period"
              totals={[
                {
                  number: analytics.registered_communities_total,
                  testNumber: analytics.registered_communities_total_test || 0,
                  hasTestData: showTestUsers,
                },
              ]}
            >
              {renderChartOrPlaceholder(
                [seriesData('registered_communities')].concat(
                  showTestUsers
                    ? [seriesData('registered_communities', true)]
                    : []
                ),
                <Chart
                  series={[
                    {
                      name: 'Registered communities',
                      data: seriesData('registered_communities'),
                    },
                  ].concat(
                    showTestUsers && analytics.registered_communities_test
                      ? [
                          {
                            name: 'Registered communities (test)',
                            data: seriesData('registered_communities', true),
                          },
                        ]
                      : []
                  )}
                />
              )}
            </ReportContainer>
          )}

          {/* ACTIVE COMMUNITIES */}
          {!selectedCommunity?.value && (
            <ReportContainer
              title="Active communities"
              info="The graph shows the number of active communities within a selected date range. An active community is a community with at least one active user."
              className="analytics__graph analytics__graph--active-communities"
              totalsTooltipContent="Total for the chosen period"
              totals={[
                {
                  number: analytics.active_communities_total,
                  testNumber: analytics.active_communities_total_test || 0,
                  hasTestData: showTestUsers,
                },
              ]}
            >
              {renderChartOrPlaceholder(
                [seriesData('active_communities')].concat(
                  showTestUsers ? [seriesData('active_communities', true)] : []
                ),
                <Chart
                  series={[
                    {
                      name: 'Active communities',
                      data: seriesData('active_communities'),
                    },
                  ].concat(
                    showTestUsers && analytics.active_communities_test
                      ? [
                          {
                            name: 'Active communities (test)',
                            data: seriesData('active_communities', true),
                          },
                        ]
                      : []
                  )}
                />
              )}
            </ReportContainer>
          )}

          {/* REGISTERED USERS */}
          <ReportContainer
            title="Registered users"
            info="The graph shows the total number of newly registered users across communities within a selected date range."
            className="analytics__graph analytics__graph--registered-users"
            totalsTooltipContent="Total for the chosen period"
            totals={[
              {
                number: analytics.registered_users_total,
                testNumber: analytics.registered_users_test_total || 0,
                hasTestData: showTestUsers,
              },
            ]}
          >
            {renderChartOrPlaceholder(
              [seriesData('registered_users')].concat(
                showTestUsers ? [seriesData('registered_users', true)] : []
              ),
              <Chart
                series={[
                  {
                    name: 'Registered users',
                    data: seriesData('registered_users'),
                  },
                ].concat(
                  showTestUsers && analytics.registered_users_test
                    ? [
                        {
                          name: 'Registered users (test)',
                          data: seriesData('registered_users', true),
                        },
                      ]
                    : []
                )}
              />
            )}
          </ReportContainer>

          {/* ACTIVE USERS */}
          <ReportContainer
            title="Active users"
            info="An active user is a user who has started at least one journey in a day."
            className="analytics__graph analytics__graph--active-users"
            totalsTooltipContent="Total for the chosen period"
            totals={[
              {
                number: analytics.active_users_total,
                testNumber: analytics.active_users_total_test || 0,
                hasTestData: showTestUsers,
              },
            ]}
          >
            {renderChartOrPlaceholder(
              [seriesData('active_users')].concat(
                showTestUsers ? [seriesData('active_users', true)] : []
              ),
              <Chart
                series={[
                  {
                    name: 'Active users',
                    data: seriesData('active_users'),
                  },
                ].concat(
                  showTestUsers && analytics.active_users_test
                    ? [
                        {
                          name: 'Active users (test)',
                          data: seriesData('active_users', true),
                        },
                      ]
                    : []
                )}
              />
            )}
          </ReportContainer>

          {/* NOX */}
          <ReportContainer
            title="NOₓ"
            info="The graph shows the total amount of NOx saved by using sustainable travel methods, for a chosen period."
            className="analytics__graph analytics__graph--nox"
            totalsTooltipContent="Total for the chosen period"
            totals={[
              {
                number: noxTotal,
                testNumber: noxTotalTest || 0,
                hasTestData: showTestUsers,
                unit: 'g',
              },
            ]}
          >
            {renderChartOrPlaceholder(
              [seriesData('emission_stats', false, 'nox')].concat(
                showTestUsers
                  ? [seriesData('emission_stats', false, 'nox-test')]
                  : []
              ),
              <Chart
                series={[
                  {
                    name: 'NOₓ (g)',
                    data: seriesData('emission_stats', false, 'nox'),
                  },
                ].concat(
                  showTestUsers
                    ? [
                        {
                          name: 'NOₓ (g) (test)',
                          data: seriesData('emission_stats', false, 'nox-test'),
                        },
                      ]
                    : []
                )}
              />
            )}
          </ReportContainer>

          {/* CO2 */}
          <ReportContainer
            title="CO₂"
            info="The graph shows the total amount of CO₂ saved by using sustainable travel methods, for a chosen period."
            className="analytics__graph analytics__graph--co2"
            totalsTooltipContent="Total for the chosen period"
            totals={[
              {
                number: co2Total,
                testNumber: co2TotalTest || 0,
                hasTestData: showTestUsers,
                unit: 'kg',
              },
            ]}
          >
            {renderChartOrPlaceholder(
              [seriesData('emission_stats', false, 'co2')].concat(
                showTestUsers
                  ? [seriesData('emission_stats', false, 'co2-test')]
                  : []
              ),
              <Chart
                series={[
                  {
                    name: 'CO₂ (kg)',
                    data: seriesData('emission_stats', false, 'co2'),
                  },
                ].concat(
                  showTestUsers
                    ? [
                        {
                          name: 'CO₂ (kg) (test)',
                          data: seriesData('emission_stats', false, 'co2-test'),
                        },
                      ]
                    : []
                )}
              />
            )}
          </ReportContainer>

          {/* NUMBER OF VERIFIED JOURNEYS */}
          <ReportContainer
            title="Number of verified journeys"
            info="The graph shows the total number of verified journeys across communities, including carpooling, walking and cycling journeys within a selected date range."
            className="analytics__graph analytics__graph--number-journeys"
            // TODO: multiple tooltips
            totalsTooltipContent="Total all/carpooling/walking/cycling for the chosen period"
            totals={[
              {
                number:
                  analytics.carpooling_journeys_total +
                  analytics.walking_journeys_total +
                  analytics.cycling_journeys_total,
                testNumber:
                  (analytics.carpooling_journeys_total_test || 0) +
                  (analytics.walking_journeys_total_test || 0) +
                  (analytics.cycling_journeys_total_test || 0),
                hasTestData: showTestUsers,
              },
              {
                number: analytics.carpooling_journeys_total,
                testNumber: analytics.carpooling_journeys_total_test || 0,
                hasTestData: showTestUsers,
              },
              {
                number: analytics.walking_journeys_total,
                testNumber: analytics.walking_journeys_total_test || 0,
                hasTestData: showTestUsers,
              },
              {
                number: analytics.cycling_journeys_total,
                testNumber: analytics.cycling_journeys_total_test || 0,
                hasTestData: showTestUsers,
              },
            ]}
          >
            {renderChartOrPlaceholder(
              [
                seriesData('carpooling_journeys'),
                seriesData('walking_journeys'),
                seriesData('cycling_journeys'),
              ].concat(
                showTestUsers
                  ? [
                      seriesData('carpooling_journeys', true),
                      seriesData('walking_journeys', true),
                      seriesData('cycling_journeys', true),
                    ]
                  : []
              ),
              <Chart
                series={[
                  {
                    name: 'Carpooling',
                    data: seriesData('carpooling_journeys'),
                    color: getColor('--ACCENT_1_1'),
                    backgroundColor: 'transparent',
                  },
                  {
                    name: 'Walking',
                    data: seriesData('walking_journeys'),
                    color: '#F6BB7A',
                    backgroundColor: 'transparent',
                  },
                  {
                    name: 'Cycling',
                    data: seriesData('cycling_journeys'),
                    color: getColor('--PRIMARY_2_1'),
                    backgroundColor: 'transparent',
                  },
                ].concat(
                  showTestUsers && analytics.carpooling_journeys_test
                    ? [
                        {
                          name: 'Carpooling (test)',
                          data: seriesData('carpooling_journeys', true),
                          color: getColor('--NOTIFICATIONS_1_1'),
                          backgroundColor: 'transparent',
                        },
                      ]
                    : [],
                  analytics.walking_journeys_test
                    ? [
                        {
                          name: 'Walking (test)',
                          data: seriesData('walking_journeys', true),
                          color: getColor('--SECONDARY_2_1'),
                          backgroundColor: 'transparent',
                        },
                      ]
                    : [],
                  analytics.cycling_journeys_test
                    ? [
                        {
                          name: 'Cycling (test)',
                          data: seriesData('cycling_journeys', true),
                          color: getColor('--ALERT_ERROR_1_1'),
                          backgroundColor: 'transparent',
                        },
                      ]
                    : []
                )}
              />
            )}
          </ReportContainer>

          {/*Gamification campaigns*/}
          <ReportContainer
            title="Gamification campaigns"
            info="The graph shows the total amount of gamification campaigns created, for a chosen period."
            className="analytics__graph analytics__graph--campaigns"
            totalsTooltipContent="Total for the chosen period"
            totals={[{ number: analytics.campaigns_total }]}
          >
            {renderChartOrPlaceholder(
              [seriesData('campaigns')],
              <Chart
                series={[
                  {
                    name: 'Campaigns',
                    data: seriesData('campaigns'),
                  },
                ]}
              />
            )}
          </ReportContainer>

          {/*Earned rewards*/}
          <ReportContainer
            title="Earned Rewards"
            info="The graph shows the total amount of earned rewards, for a chosen period."
            className="analytics__graph analytics__graph--rewards"
            totalsTooltipContent="Total for the chosen period"
            totals={[{ number: analytics.rewards_total }]}
          >
            {renderChartOrPlaceholder(
              [seriesData('rewards')],
              <Chart
                series={[
                  {
                    name: 'Earned rewards',
                    data: seriesData('rewards'),
                  },
                ]}
              />
            )}
          </ReportContainer>

          {/* NUMBER OF BOOKED JOURNEYS */}
          <ReportContainer
            title="Number of booked journeys"
            info="Number of participants is number of all users related to specific booking including the driver (all statuses)."
            className="analytics__graph analytics__graph--number-booked-journeys"
            // TODO: multiple tooltips
            totalsTooltipContent="Total bookings/participants for the chosen period"
            totals={[
              {
                number: analytics.booked_journeys.total_bookings,
              },
              {
                number: analytics.booked_journeys.total_participants,
              },
            ]}
          >
            {renderChartOrPlaceholder(
              [
                seriesData('booked_journeys', false, 'participants'),
                seriesData('booked_journeys', false, 'total_bookings'),
              ],
              <Chart
                series={[
                  {
                    name: 'Participants',
                    data: seriesData('booked_journeys', false, 'participants'),
                  },
                  {
                    name: 'Total bookings',
                    data: seriesData(
                      'booked_journeys',
                      false,
                      'total_bookings'
                    ),
                  },
                ]}
              />
            )}
          </ReportContainer>

          <JourneyStatusChart
            className={classNames(
              'analytics__graph',
              'analytics__graph--journey-statuses'
            )}
            range={dateRange}
            showTestUsers={showTestUsers}
            communityId={selectedCommunity?.value}
          />
        </div>
      )}
    </Styled.AnalyticsPageStyled>
  );
};

export default AnalyticsPage;

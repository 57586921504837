import { theme } from '@faxi/web-component-library';
import { flex, fontSize, laptop } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const CreateContainer = styled.div`
  background-color: var(--BACKGROUND_2_1);
  padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_176};
  ${flex('column')};
  flex-grow: 1;
  overflow: auto;

  ${laptop(
    css`
      padding: ${theme.sizes.SIZE_20};
    `
  )}

  > h1 {
    color: var(--SECONDARY_1_1);
    ${fontSize(theme.fontSizes.FONT_24, '34px')};
    font-weight: 400;
  }

  .kinto-create-form {
    &__fieldset {
      max-width: ${theme.sizes.SIZE_1064};

      &--plan {
        .input__container {
          max-width: ${theme.sizes.SIZE_504};
        }

        .textarea__container {
          width: 100%;
          max-width: ${theme.sizes.SIZE_504};
        }
      }

      &--activation {
        .kinto-select-field,
        .calendar-container {
          max-width: ${theme.sizes.SIZE_374};
        }
      }

      &--price {
        > div {
          ${flex('row')};
          gap: ${theme.sizes.SIZE_32};

          ${laptop(css`
            flex-direction: column;
          `)};

          .input__container {
            width: ${theme.sizes.SIZE_256};
          }
        }
      }

      &--community {
        .input__container {
          max-width: ${theme.sizes.SIZE_200};
        }

        .kinto-create-form__fieldset__radio-group {
          ${flex('row')};
          gap: ${theme.sizes.SIZE_24};

          .wcl-radio-group__radio-button {
            min-height: ${theme.sizes.SIZE_56};
          }

          &__input-container {
            ${flex('column', 'center', 'center')};
            min-height: ${theme.sizes.SIZE_56};
            margin-top: auto;
          }
        }
      }

      &--features {
        .wcl-checkbox-group__checkboxes {
          gap: ${theme.sizes.SIZE_16};
        }
      }
    }

    &__actions {
      margin-right: auto;
    }
  }

  > form {
    ${flex('column')};
    gap: ${theme.sizes.SIZE_16};

    > fieldset {
      ${flex('column')};
      gap: ${theme.sizes.SIZE_16};
      background-color: var(--BACKGROUND_1_1);
      border-radius: ${theme.sizes.SIZE_8};
      padding: ${theme.sizes.SIZE_16};

      > legend,
      .wcl-checkbox-group__legend {
        ${flex('row', 'flex-start', 'center')};
        gap: ${theme.sizes.SIZE_8};
        float: left;
        margin-bottom: 0;
        ${fontSize(theme.fontSizes.FONT_18, '25px')};
        color: var(--SHADE_1_1);

        &__required {
          font-size: ${theme.sizes.SIZE_12};
          color: var(--SHADE_1_2);

          &--disabled {
            color: var(--SHADE_1_4);
          }
        }
      }
    }
  }
`;

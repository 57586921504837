import React from 'react';
import { Textarea, TextareaProps } from '@faxi/web-component-library';
import { useFieldError, FieldProps } from '@faxi/web-form';

type ValueOnChange = (event: string) => void;

export type TextareaFieldProps = FieldProps<string, ValueOnChange> &
  TextareaProps;

const TextareaField: React.FC<TextareaFieldProps> = (
  props: TextareaFieldProps
): JSX.Element => {
  const { dirty, error, touched, className, ...rest } = props;

  const showError = useFieldError(props);

  return (
    <Textarea
      className={className}
      errorText={String(error)}
      error={showError}
      {...rest}
    />
  );
};

export default TextareaField;

import { appUri } from 'config';
import { Route } from 'models';
import Plans from './Plans.page';
import CreatePlan from './pages/CreatePlan';

const plansRoutes: Route[] = [
  {
    component: Plans,
    handle: 'Plans',
    title: 'Plans',
    exact: true,
    path: appUri.PLANS,
  },

  {
    component: CreatePlan,
    handle: 'Create a plan',
    title: 'Create a plan',
    exact: true,
    path: appUri.PLANS_CREATE,
  },
];

export default plansRoutes;

import { theme } from '@faxi/web-component-library';
import {
  flex,
  flexGap,
  fontSize,
  phablet,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const VerificationSwitch = styled.div`
  ${flex('row', 'center', 'center')};
  flex-wrap: wrap;
  border-bottom: 1px solid var(--SHADE_1_7);
  padding-bottom: ${theme.sizes.SIZE_16};

  .kinto-verification-switch {
    &__name {
      flex: 0 0 40%;
    }

    &__type {
      flex: 0 0 20%;

      ${phablet(css`
        flex: 0 0 40%;
      `)};
    }

    &__field,
    &__status {
      margin-left: auto;
    }

    &__user-photo {
      ${size(theme.sizes.SIZE_32)};
    }
  }
`;

export const VerificationColum = styled.div`
  ${flex('row', 'flex-start', 'center')};
  ${flexGap(theme.sizes.SIZE_8, 'row')};
  ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
  word-break: break-word;
  font-weight: 400;
  color: var(--SHADE_1_1);
  padding: ${theme.sizes.SIZE_12} 14px;

  .profile-img {
    ${size(theme.sizes.SIZE_24)};
  }
`;

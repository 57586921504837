import React from 'react';
import ReactDOM from 'react-dom';

import * as Styled from './MapInfoWindow.styles';

type MapInfoWindowProps = {
  data: Record<string, any>;
};

const MapInfoWindow: React.FC<MapInfoWindowProps> = (props) => {
  const { data } = props;

  return (
    <Styled.MapInfoWindow className="kinto-map-info-window">
      {Object.entries(data).map(([key, value]) => (
        <li key={key}>
          {/* VALUE IS UNDER BACKTICKS SO IT SHOWS STRING OF CONTENT `false` */}
          {key}: <b>{`${value}`}</b>
        </li>
      ))}
    </Styled.MapInfoWindow>
  );
};

const MAP_ID = 'kinto-map';
const MAP_INFO_WINDOW_ID = 'kinto-map-info-window';

export default {
  show: (data: any) => {
    const mapInfoWindow = document.createElement('div');

    mapInfoWindow.classList.add('kinto-map-info-window-container');
    mapInfoWindow.id = MAP_INFO_WINDOW_ID;

    const kintoMap = document.getElementById(MAP_ID);

    kintoMap?.appendChild(mapInfoWindow);

    ReactDOM.render(<MapInfoWindow data={data} />, mapInfoWindow);
  },
  hide: () => {
    const mapInfoWindow = document.getElementById(MAP_INFO_WINDOW_ID);

    const kintoMap = document.getElementById(MAP_ID);

    if (mapInfoWindow && kintoMap) {
      kintoMap.removeChild(mapInfoWindow);
    }
  },
};

import { User } from 'models';
import { Admin } from 'models/Admin';
import { AuthBody } from 'models/Auth';
import storageService, { STORAGE_KEYS } from './storageService';

const credentialService = {
  get token(): string {
    return storageService.getItem<string>(STORAGE_KEYS.TOKEN);
  },

  get user(): Admin {
    return storageService.getItem<any>(STORAGE_KEYS.USER);
  },

  get userId(): string {
    return storageService.getItem<string>(STORAGE_KEYS.USER_ID);
  },

  get platformId(): string {
    return (
      storageService.getItem(STORAGE_KEYS.USER) as User
    ).platform.id.toString();
  },

  set token(token: string) {
    storageService.setItem(STORAGE_KEYS.TOKEN, token);
  },

  set refreshToken(refreshToken: string) {
    storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
  },

  saveUser(user: Admin) {
    storageService.setItem(STORAGE_KEYS.USER, user);
  },

  saveAuthBody(authBody: AuthBody): void {
    storageService.setItem(STORAGE_KEYS.USER_ID, authBody.userId);
    storageService.setItem(STORAGE_KEYS.TOKEN, authBody.token);
    storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, authBody.refreshToken);
  },

  removeAuthBody(): void {
    storageService.removeItem(STORAGE_KEYS.USER_ID);
    storageService.removeItem(STORAGE_KEYS.TOKEN);
    storageService.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  },
};

export default credentialService;

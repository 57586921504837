import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ModalProps,
  Table,
  StatusElement,
  StatusElementStatus,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { apiUsers } from 'modules';
import { AuthContext } from 'store';
import { useCallbackAsync } from 'hooks';
import { TablePageLayout } from 'components';
import { snackBarSuccessMessage } from 'utils';
import { UserOrganisation, UserStatus, USER_STATUS, User } from 'models/User';
import UserPermissionModal from '../UserPermissionModal';

import * as Styled from './UserCommunitiesModal.styles';
import { NoData } from 'Global.styles';

type UserCommunitiesModalProps = {
  className?: string;
  communityId?: string;
  onPermissionChange?: () => void;
  user?: Pick<User, 'id' | 'firstname' | 'lastname'>;
} & ModalProps;

const mapOrganisations = (orgs: Array<UserOrganisation>) =>
  orgs.map((org) => mapOrganisation(org));

const mapOrganisation = ({ id, name, status, type }: UserOrganisation) => ({
  id,
  name,
  type,
  statusType: status as UserStatus,
  status: (
    <StatusElement small status={ELEMENT_STATUS[status as UserStatus]}>
      {USER_STATUS_TITLE[status as UserStatus]}
    </StatusElement>
  ),
});

const USER_STATUS_TITLE = {
  Y: 'Approved',
  N: 'Pending',
  R: 'Rejected',
  D: 'Deactivated',
  XY: 'Community Deactivated',
} as Record<UserStatus, string>;

const ELEMENT_STATUS = {
  Y: 'approved',
  N: 'pending',
  R: 'rejected',
  D: 'canceled',
  XY: 'canceled',
} as Record<UserStatus, StatusElementStatus>;

const TRANSLATION_KEYS = {
  id: 'Id',
  name: 'Community',
  status: 'Status',
  type: 'Role',
} as Record<keyof UserOrganisation, string>;

const CommunitiesModal: FC<UserCommunitiesModalProps> = (props) => {
  const { className, communityId, user, onPermissionChange, ...rest } = props;

  const { admin } = useContext(AuthContext);

  const activeBtnRef = useRef<HTMLButtonElement>();

  const [userPermission, setUserPermission] = useState<boolean>(false);
  const [currentCommunity, setCurrentCommunity] = useState<UserOrganisation>();
  const [organisations, setOrganisations] = useState<UserOrganisation[]>([]);

  const username = useMemo(
    () => `${user?.firstname} ${user?.lastname}`,
    [user]
  );

  const [loadCommunities, loadingCommunities] = useCallbackAsync({
    showSpinner: false,
    callback: async (userId: string) => {
      const {
        data: { data },
      } = await apiUsers.getUserCommunties(userId, communityId);
      setOrganisations(mapOrganisations(data.organisaitons));
    },
  });

  const [updateUserStatus, loadingUserStatus] = useCallbackAsync({
    showSpinner: false,
    callback: async (organisation: UserOrganisation, status: UserStatus) => {
      await apiUsers.updateUserStatus(user!.id, `${organisation.id}`, status);

      snackBarSuccessMessage('User status is successfully updated');

      setOrganisations((oldOrganisations) =>
        oldOrganisations.map((org) =>
          org.id === organisation.id
            ? mapOrganisation({ ...organisation, status })
            : org
        )
      );
    },
  });

  const canEdit = useMemo(
    () => !!admin?.permissions.find((p) => p.name === 'users_edit'),
    [admin]
  );

  const handleApplyPermissions = useCallback(
    (communityId: number, role: 'admin' | 'regular') => {
      onPermissionChange?.();

      setOrganisations((communities) =>
        communities.map((community) =>
          community.id === communityId
            ? { ...community, type: role }
            : community
        )
      );
    },
    [onPermissionChange]
  );

  useEffect(() => {
    if (!user) return;

    loadCommunities(user.id);
  }, [loadCommunities, user]);

  const tableActions = useMemo(
    () => (org: UserOrganisation) =>
      org.statusType === USER_STATUS.PENDING ? (
        <TablePageLayout.TableActions
          actions={[
            {
              name: 'Approve',
              icon: 'check',
              onClick: (el) => {
                activeBtnRef.current = el as HTMLButtonElement;
                updateUserStatus(org, USER_STATUS.APPROVED);
              },
            },
            {
              name: 'Reject',
              variant: 'delete-ghost',
              icon: 'xmark',
              onClick: (el) => {
                activeBtnRef.current = el as HTMLButtonElement;
                updateUserStatus(org, USER_STATUS.REJECTED);
              },
            },
          ]}
        />
      ) : org.statusType === USER_STATUS.APPROVED ? (
        <TablePageLayout.TableActions
          actions={[
            {
              icon: 'user-gear',
              name: 'User permissions',
              onClick: (el) => {
                activeBtnRef.current = el as HTMLButtonElement;
                setUserPermission(true);
                setCurrentCommunity(org);
              },
            },
          ]}
        />
      ) : (
        <p className="user-communities-modal__no-actions">No actions</p>
      ),
    [updateUserStatus]
  );

  return (
    <Styled.Modal
      className={classNames('user-communities-modal', className)}
      loading={loadingCommunities || loadingUserStatus}
      title={`Communities (${username || '-'}) `}
      {...rest}
    >
      {organisations && (
        <Table<UserOrganisation>
          tableId="user-communities-table"
          expandable={canEdit}
          disableSort
          tableActions={tableActions}
          className="user-communities-modal__table"
          excludeColumns={['id', 'statusType']}
          translationKeys={TRANSLATION_KEYS}
          tableData={organisations}
        />
      )}

      {!organisations.length && !loadingCommunities && (
        <NoData>No communities.</NoData>
      )}

      {userPermission && currentCommunity && user && (
        <UserPermissionModal
          userId={user.id}
          username={username}
          community={currentCommunity}
          triggerRef={activeBtnRef.current}
          onApplyPermission={handleApplyPermissions}
          onClose={() => setUserPermission(false)}
        />
      )}
    </Styled.Modal>
  );
};

export default memo(CommunitiesModal);

import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const CarParkContainer = styled.div`
  .kinto-carpark {
    &__table {
      margin-top: ${theme.sizes.SIZE_32};
    }
  }
`;

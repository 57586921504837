import credentialService from 'services/credentialService';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import authBus, { AUTH_BUS_EVENTS } from './authBus';
import apiAuth from './router/api/apiAuth';

export type RefreshTokenService = {
  refreshingAtm: boolean;
  triedRefresh: boolean;
  checkRefreshTreshold: number;
  refresh: () => void;
  reinit: () => void;
};

export default {
  refreshingAtm: false,
  checkRefreshTreshold: 5000,
  triedRefresh: false,
  async refresh() {
    this.refreshingAtm = true;

    const refreshToken = storageService.getItem<string>(
      STORAGE_KEYS.REFRESH_TOKEN
    );

    const {
      data: {
        data: { token: newToken, refresh_token: newRefreshToken },
      },
    } = await apiAuth.refreshToken(refreshToken);

    credentialService.token = newToken;
    credentialService.refreshToken = newRefreshToken;
    authBus.broadcastEvent(AUTH_BUS_EVENTS.REFRESH_TOKEN_SUCCESS);

    this.refreshingAtm = false;
  },
  reinit() {
    this.triedRefresh = false;
    this.refreshingAtm = false;
  },
} as RefreshTokenService;

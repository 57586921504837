import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { uniqBy, debounce } from 'lodash';
import {
  useUtilities,
  useEffectSkipFirst,
  useLatestRef,
} from '@faxi/web-component-library';
import { PaginatedResponse } from 'models';
import useSearch from './useSearch';
import { AxiosResponse } from 'axios';

type InfiniteProps<
  T extends Record<string, any>,
  ItemsKeys extends string = 'users'
> = {
  deps?: any[];
  perPage: number;
  condition?: boolean;
  skipFirst?: boolean;
  defaultItems?: T[];
  itemsKey: string;
  searchUrlName?: string;
  spinnerParent?: string;
  initialSearch?: string;
  useQueryParams?: boolean;
  apiRequest: (
    page: string,
    search: string,
    per_page: string
  ) => Promise<AxiosResponse<PaginatedResponse<any, ItemsKeys>>>;
  onItemsLoad?: (currentPage: number, items: Array<T>) => void;
  mappingFunction?: (items: Array<any>) => Array<any>;
};

const useInfinitePagination = <
  T extends Record<string, any>,
  ItemsKey extends string
>({
  deps = [],
  perPage,
  condition = true,
  skipFirst = false,
  defaultItems = [],
  spinnerParent,
  initialSearch,
  itemsKey = 'users',
  searchUrlName = 'search',
  useQueryParams = true,
  apiRequest,
  onItemsLoad,
  mappingFunction,
}: InfiniteProps<T, ItemsKey>) => {
  const { showOverlay, hideOverlay } = useUtilities();

  const [items, setItems] = useState<Array<any>>(defaultItems);
  const [totalPages, setTotalPages] = useState(1);

  const [searchUrl, setSearchUrl] = useSearch(searchUrlName, initialSearch);
  const [searchState, setSearchState] = useState(initialSearch || '');

  const [loading, setLoading] = useState(true);

  const search = useMemo(
    () => (useQueryParams ? searchUrl : searchState),
    [searchUrl, searchState, useQueryParams]
  );

  const currentPage = useRef(1);
  const first = useRef(true);
  const resetDataFlag = useRef(false);
  const skipFirstRef = useRef(skipFirst);
  const lastSearchString = useRef(search);
  const searchStringChanged = useRef(false);
  const loadRef = useRef(false);
  const total = useRef<number>();

  const [canContinuePagination, setCanContinuePagination] = useState(
    currentPage.current < totalPages
  );

  const mappingFunctionRef = useLatestRef(mappingFunction);
  const apiRequestRef = useLatestRef(apiRequest);

  const concatNewItemsUnique = useCallback(
    (newItems: any[], at: 'start' | 'end' = 'end') =>
      setItems((old: T[]) =>
        uniqBy(
          (at === 'start' ? newItems : [])
            .concat(old)
            .concat(at === 'end' ? newItems : []),
          'id'
        )
      ),
    []
  );

  const addItemRemoveOld = useCallback(
    (newItem: Object & { id: any }, at: 'start' | 'end' = 'end') => {
      setItems((old) =>
        (at === 'start' ? [newItem] : [])
          .concat(old.filter((i) => i.id !== newItem.id))
          .concat(at === 'end' ? [newItem] : [])
      );
    },
    []
  );

  const updateItem = useCallback(
    (newItem: Object & { id: any }) => {
      const index = items.findIndex((i) => i.id === newItem.id);

      if (index > -1) {
        const newItems = [...items];
        const item = { ...items[index], ...newItem };

        newItems[index] = item;

        setItems(newItems);
      }
    },
    [items]
  );

  const getFiles = useCallback(
    async (page: number, search = '') => {
      try {
        setLoading(true);
        spinnerParent && page === 1 && showOverlay(spinnerParent);

        const {
          data: {
            data,
            meta: { last_page: totalPages, total: totalItems },
          },
        } = await apiRequestRef.current(`${page}`, search, `${perPage}`);

        let finalItems: Array<T> = [];

        if (data[itemsKey as ItemsKey]) {
          if (mappingFunctionRef.current) {
            finalItems = mappingFunctionRef.current(data[itemsKey as ItemsKey]);
          } else finalItems = data[itemsKey as ItemsKey];
        }

        if (resetDataFlag.current) {
          setItems(finalItems);
          resetDataFlag.current = false;
        } else if (lastSearchString.current !== search) {
          lastSearchString.current = search;
          setItems(finalItems);
        } else {
          concatNewItemsUnique(finalItems);
        }

        total.current = totalItems;
        setTotalPages(totalPages);

        loadRef.current = false;

        onItemsLoad?.(page, finalItems);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        spinnerParent && hideOverlay(spinnerParent);
      }
    },
    [
      perPage,
      spinnerParent,
      apiRequestRef,
      itemsKey,
      mappingFunctionRef,
      onItemsLoad,
      concatNewItemsUnique,
      showOverlay,
      hideOverlay,
    ]
  );

  const debounceGetFiles = useMemo(() => debounce(getFiles, 250), [getFiles]);

  const onContainerScrolled = useCallback(() => {
    if (loading) return;

    if (currentPage.current < totalPages) {
      currentPage.current += 1;
      getFiles(currentPage.current, search);
    }
  }, [loading, totalPages, getFiles, search]);

  const onSearchChange = useCallback(
    (value: string) => {
      currentPage.current = 1;

      setTotalPages(1);

      useQueryParams ? setSearchUrl(value) : setSearchState(value);

      debounceGetFiles(currentPage.current, value);
    },
    [debounceGetFiles, setSearchUrl, useQueryParams]
  );

  const reset = useCallback(() => {
    resetDataFlag.current = true;
    currentPage.current = 1;

    getFiles(currentPage.current);
  }, [getFiles]);

  const handleContainerScroll = useCallback(() => {
    if (canContinuePagination) onContainerScrolled();
  }, [onContainerScrolled, canContinuePagination]);

  useEffect(() => {
    if (skipFirstRef.current && first.current) {
      first.current = false;
      return;
    }

    if (
      loadRef.current ||
      (resetDataFlag.current && currentPage.current > 1) ||
      !condition
    )
      return;
    if (searchStringChanged.current) loadRef.current = true;

    getFiles(currentPage.current, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFiles, condition]);

  useEffectSkipFirst(() => {
    reset();
  }, [...deps]);

  useEffectSkipFirst(() => {
    setCanContinuePagination(currentPage.current < totalPages);
  }, [perPage, totalPages, items]);

  return {
    items,
    total: total.current,
    loading,
    search,
    canContinuePagination,
    setItems,
    reset,
    updateItem,
    setLoading,
    onSearchChange,
    addItemRemoveOld,
    concatNewItemsUnique,
    handleContainerScroll,
  };
};

export default useInfinitePagination;

import { FC, useMemo, useState } from 'react';
import { CheckboxGroup, CheckboxGroupProps } from '@faxi/web-component-library';
import { FieldProps } from '@faxi/web-form';

export type CheckboxGroupFieldProps = FieldProps<string[]> & CheckboxGroupProps;

const CheckboxGroupField: FC<CheckboxGroupFieldProps> = (
  props: CheckboxGroupFieldProps
): JSX.Element => {
  const { error, dirty, touched: pTouched, ...rest } = props;

  const [touched, setTouched] = useState(false);

  const showError = useMemo(() => error && touched, [error, touched]);

  return (
    <CheckboxGroup
      {...rest}
      onBlur={() => setTouched(true)}
      error={showError}
      errorText={`${error}`}
    />
  );
};

export default CheckboxGroupField;

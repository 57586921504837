import { FC } from 'react';
import {
  Select,
  SelectOption,
  useEffectOnceWhen,
} from '@faxi/web-component-library';
import { apiCommunities } from 'modules';
import { isNonEmptyString } from 'utils';
import { useInfinitePagination } from 'hooks';

const COMMUNITIES_SEARCH_PER_PAGE = 50;

export type SearchedCommunity = {
  id: number;
  name: string;
};

type CommunitySearchProps = {
  className?: string;
  selectedCommunity?: SelectOption;
  onClear: () => void;
  onSelect: (value: SelectOption<string>) => void;
  useQueryParams?: boolean;
  initialSearch?: string;
};

const CommunitySearch: FC<CommunitySearchProps> = (props) => {
  const {
    className,
    selectedCommunity,
    onSelect,
    onClear,
    useQueryParams = true,
    initialSearch,
  } = props;

  const {
    loading,
    search,
    items: options,
    setItems: setOptions,
    onSearchChange,
    handleContainerScroll,
  } = useInfinitePagination<SearchedCommunity, 'organisations'>({
    itemsKey: 'organisations',
    searchUrlName: 'community_search',
    useQueryParams: useQueryParams,
    initialSearch: initialSearch,
    perPage: COMMUNITIES_SEARCH_PER_PAGE,
    mappingFunction: (
      communities: Array<SearchedCommunity>
    ): SelectOption<string>[] =>
      communities
        .map(({ id, name }) => ({
          id: `${id}`,
          label: name,
          value: `${id}`,
        }))
        .filter((comm) => isNonEmptyString(comm.label)),
    apiRequest: (page: string, search: string, perPage: string) =>
      apiCommunities.getSearchCommunities(page, search, perPage),
  });

  useEffectOnceWhen(() => {
    if (!selectedCommunity?.value || !selectedCommunity.id) return;

    const qParamOption = {
      id: selectedCommunity?.id,
      value: selectedCommunity?.value,
      label: selectedCommunity?.label,
    } as SelectOption;

    const alreadyExists = options.find(
      (opt) => `${opt.id}` === qParamOption?.value
    );

    setOptions((old) => [
      ...((!alreadyExists ? [qParamOption] : []) as SelectOption<string>[]),
      ...old,
    ]);
  }, options.length > 0);

  return (
    <Select
      className={className}
      placeholder="Select community"
      hasClearAction
      clearTitle="Clear selection"
      async
      searchable
      closeOptionsOnClear
      initialSearch={search}
      repositionDropdown={false}
      loading={loading}
      options={options}
      value={selectedCommunity?.value}
      onClear={() => {
        onClear();
        onSearchChange('');
      }}
      onChange={(value) => {
        // on select option set search value
        onSearchChange(value.label);
        onSelect(value);
      }}
      onSearchChange={onSearchChange}
      onContainerScroll={handleContainerScroll}
    />
  );
};

export default CommunitySearch;

import { phablet } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';
import styled, { css } from 'styled-components';
import { flex, marginChildren } from '@faxi/web-css-utilities';

export const Container = styled.div`
  background-color: var(--BACKGROUND_1_1);

  ${phablet(css`
    right: unset;
    left: ${theme.sizes.SIZE_16};
  `)};

  @media (max-width: 480px) {
    width: 100%;
    left: 0;
  }

  .calendar--mobile {
    min-width: unset;
    width: 100%;
    margin: 0px;
  }

  .date-range-selector {
    &__buttons {
      ${flex('column', 'center', 'center')};
      ${marginChildren(`0 0 ${theme.sizes.SIZE_8} 0`)};

      width: 100%;

      &__button {
        cursor: pointer;
        width: ${theme.sizes.SIZE_376};
        font-size: ${theme.fontSizes.FONT_14};
        padding: ${`${theme.sizes.SIZE_4} ${theme.sizes.SIZE_20}`};
        font-weight: 500;
        background-color: var(--BACKGROUND_2_1);
        border: ${theme.sizes.SIZE_1} solid var(--BACKGROUND_2_1);
        color: var(--SECONDARY_1_1);
        border-radius: 8px;

        &:hover {
          background-color: var(--SUCCESS_1_1);
        }

        @media (max-width: 480px) {
          width: 100%;
          height: ${theme.sizes.SIZE_40};
        }

        &--active {
          background-color: var(--SUCCESS_1_1);
          border-color: var(--PRIMARY_1_3);
        }
      }

      ${phablet(
        css`
          width: 100%;
        `
      )};
    }

    &__form {
      margin-top: ${theme.sizes.SIZE_10};

      &__fields {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: 320px) {
          flex-direction: column;
        }

        &__field {
          .input__container__placeholder {
            transition: unset;
          }

          @media (max-width: 320px) {
            width: unset;

            &:first-of-type {
              margin-bottom: ${theme.sizes.SIZE_16};
            }
          }
        }

        &__delimiter {
          margin: auto ${theme.sizes.SIZE_10};
          font-weight: 500;
          color: var(--SECONDARY_1_1);

          @media (max-width: 320px) {
            display: none;
          }
        }
      }

      &__actions {
        width: 100%;
        justify-content: flex-end;

        @media (max-width: 320px) {
          margin-top: ${theme.sizes.SIZE_24};
        }
      }
    }
  }
`;

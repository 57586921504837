import React from 'react';

type CreateCommunityContextProps = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

export default React.createContext<CreateCommunityContextProps>({
  step: 1,
  setStep: () => {},
});

import { appUri, RoutesRenderer } from 'config';
import { PrivateRoute } from 'modules';

import DriverTraxTable from 'components/tables/DriverTraxTable';
import PassengerTraxTable from 'components/tables/PassengerTraxTable';
import { Map } from 'pages';
import Journeys from '.';
import { Route } from 'models';
import MapDataProvider from 'pages/Map/providers/MapData';
import Journey from './pages/components/Journey';

const mapRoutes: Route[] = [
  {
    component: Map,
    handle: 'Journey map',
    title: 'Journey map',
    exact: true,
    path: appUri.JOURNEY_MAP(),
  },
  {
    component: DriverTraxTable,
    handle: 'Driver Trax',
    title: 'Driver Trax',
    exact: true,
    path: appUri.JOURNEYS_DRIVER_TRAXES(),
  },
  {
    component: PassengerTraxTable,
    handle: 'Passenger Trax',
    title: 'Passenger Trax',
    exact: true,
    path: appUri.JOURNEYS_PASSENGER_TRAXES(),
  },
];

const journeysRoutes: Route[] = [
  {
    component: Journeys,
    handle: 'Journeys',
    title: 'Journeys',
    exact: true,
    path: appUri.JOURNEYS,
  },
  {
    component: Journey,
    handle: 'Journey',
    title: 'Journey',
    exact: true,
    path: appUri.JOURNEY(),
  },
  {
    component: (props: any) => (
      <PrivateRoute
        component={
          <MapDataProvider>
            <RoutesRenderer {...props} />
          </MapDataProvider>
        }
      />
    ),
    handle: 'Journey map',
    title: 'Journey map',
    exact: false,
    path: appUri.JOURNEY_MAP(),
    routes: mapRoutes,
  },
];

export default journeysRoutes;

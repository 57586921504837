import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form, FormRef } from '@faxi/web-form';
import { Icon, useCallbackRef, ModalRef } from '@faxi/web-component-library';

import {
  mapPremiumFeatures,
  filterFeaturesObject,
  PREMIUM_FEATURES_TITLES,
  PREMIUM_FEATURES_WARNINGS,
} from './utils';
import { apiCommunities } from 'modules';
import { snackBarSuccessMessage } from 'utils';
import { useFormButtons, useCallbackAsync } from 'hooks';
import {
  PremiumFeature,
  PremiumFeaturesNames,
  ExtendedPremiumFeature,
  PremiumFeaturesModalProps,
  PremiumFeaturesModalFormType,
} from 'models';
import { PREMIUM_FEATURES_ICONS } from 'pages/Communities/Communities.page';
import PremiumFeatureTab from './components/PremiumFeatureTab';

import { FormActions } from 'Global.styles';
import * as Styled from './PremiumFeaturesModal.styles';

const mapFeatureTabs = (
  features: PremiumFeature[],
  featureType: PremiumFeature['type']
) =>
  features
    .filter(({ type }) => type === featureType)
    .map(
      ({ name, children, ...rest }) =>
        ({
          ...rest,
          name,
          icon: PREMIUM_FEATURES_ICONS[name],
          title: PREMIUM_FEATURES_TITLES[name],
          warning: PREMIUM_FEATURES_WARNINGS[name],
          ...(children && {
            subPremiumFeatures: children.map(({ name, ...rest }) => ({
              ...rest,
              name,
              icon: PREMIUM_FEATURES_ICONS[name],
              title: PREMIUM_FEATURES_TITLES[name],
            })),
          }),
        } as ExtendedPremiumFeature)
    );

const PremiumFeaturesModal: FC<PremiumFeaturesModalProps> = (props) => {
  const { onSubmit, community, ...rest } = props;

  const modalRef = useRef<ModalRef>(null);

  const [form, formRef] = useCallbackRef<FormRef>();
  const [FormButtons] = useFormButtons('Save changes', 'Cancel');

  const [transportModes, setTransportModes] = useState<
    ExtendedPremiumFeature[]
  >([]);
  const [tabs, setTabs] = useState<ExtendedPremiumFeature[]>([]);
  const [features, setFeatures] = useState<ExtendedPremiumFeature[]>([]);

  const [clientAdminTabs, setClientAdminTabs] = useState<
    ExtendedPremiumFeature[]
  >([]);

  const initialData = useMemo<Record<PremiumFeaturesNames, '0' | '1'>>(
    () => mapPremiumFeatures(community?.premium_features || []),
    [community]
  );

  const featuresNamesArray = useMemo(
    () => transportModes.map((f) => f.name),
    [transportModes]
  );

  const transportModeSectionDisabled = useMemo(
    () =>
      !form
        ? false
        : featuresNamesArray.length === 0
        ? false
        : Object.entries(form.fields)
            .filter(([key]) =>
              featuresNamesArray.includes(key as PremiumFeaturesNames)
            )
            .every(([, field]) => field.value === '0'),
    [featuresNamesArray, form]
  );

  const [handleOnSubmit, loadingSubmit] = useCallbackAsync({
    showSpinner: false,
    callback: async (values: PremiumFeaturesModalFormType) => {
      if (!community) return;

      const features = filterFeaturesObject(values).map((item) => item.at(0));

      const {
        data: { premium_features },
      } = await apiCommunities.updatePremiumFeatures(
        community.id,
        features as Array<PremiumFeaturesNames>
      );

      snackBarSuccessMessage('Successfully updated premium features!');
      onSubmit?.(community.id, premium_features);
      modalRef.current?.close();
    },
  });

  const [getPremiumFeatures, loadingPremiumFeatures] = useCallbackAsync({
    showSpinner: false,
    callback: async () => {
      try {
        const { data: features } = await apiCommunities.getPremiumFeatures();

        setTransportModes(mapFeatureTabs(features, 'transport_mode'));
        setTabs(mapFeatureTabs(features, 'mobile_tab'));
        setFeatures(mapFeatureTabs(features, 'other'));
        setClientAdminTabs(mapFeatureTabs(features, 'client_admin'));
      } catch (e) {
        console.error(e);
      }
    },
  });

  const formWrapper = useCallback(
    ({ children }: any) => (
      <Form
        ref={formRef}
        onSubmit={handleOnSubmit}
        initialData={initialData}
        strictValidation={false}
        children={children}
        className="kinto-modal__form"
      />
    ),
    [initialData, formRef, handleOnSubmit]
  );

  useEffect(() => {
    getPremiumFeatures();
  }, [getPremiumFeatures]);

  return (
    <Styled.PremiumFeaturesModal
      loading={loadingSubmit || loadingPremiumFeatures}
      title="Premium features"
      className="kinto-premium-features-modal"
      childrenWrapper={formWrapper}
      ref={modalRef}
      footer={
        <FormActions className="kinto-modal__actions">
          <FormButtons.Submit
            disabled={
              !form?.isFormChanged() ||
              !form?.syncFormValid ||
              transportModeSectionDisabled
            }
          />
          <FormButtons.Cancel onClick={() => modalRef.current?.close()} />
        </FormActions>
      }
      {...rest}
    >
      <h3 className="kinto-modal__subtitle">Modes of transport</h3>
      <PremiumFeatureTab form={form} features={transportModes} />
      {transportModeSectionDisabled && (
        <div className="kinto-modal__error-message">
          <Icon name="ban" />
          <p className="error-text">
            One mode of transport needs to be enabled
          </p>
        </div>
      )}

      <h3 className="kinto-modal__subtitle">Menu pages on web admin app </h3>
      <PremiumFeatureTab form={form} features={clientAdminTabs} />

      <h3 className="kinto-modal__subtitle">
        Navigation tabs on the mobile app
        <br />
        <span className="kinto-modal__subtitle__info">
          (Hiding a tab will also hide all related features and data on other
          pages)
        </span>
      </h3>
      <PremiumFeatureTab form={form} features={tabs} />
      <h3 className="kinto-modal__subtitle">Other features</h3>
      <PremiumFeatureTab
        form={form}
        features={features}
        initialData={initialData}
      />
    </Styled.PremiumFeaturesModal>
  );
};

export default memo(PremiumFeaturesModal);

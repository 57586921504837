/* eslint-disable react-hooks/exhaustive-deps */
import {
  useUtilities,
  SortDirections,
  SortOption,
  useEffectSkipFirst,
  useQueryParams,
} from '@faxi/web-component-library';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AxiosResponse } from 'axios';
import { PaginatedResponse } from 'models/PaginatedData';
import { debounce } from 'lodash';
import { isNonEmptyString } from 'utils';
import { useLocation } from 'react-router';
import useSearch from './useSearch';

export type ApiRequestArgs<T, ParamType> = {
  per_page?: number;
  currentPage?: number;
  searchString?: string;
  sort_by?: keyof T;
  sort_direction?: SortDirections;
  params?: ParamType;
};

export type TablePaginationProps<
  T extends Record<string, any>,
  ItemsKeys extends string = 'users',
  ParamsObj extends Record<string, any> = SortOption<T>
> = {
  data?: Array<T>;
  params?: ParamsObj;
  deps?: Array<any>;
  count?: number;
  itemsKey?: string;
  customErrorMessage?: string;
  spinnerParentClass?: string;
  hasSortParams?: boolean;
  initialParams?: Record<string, any>;
  apiRequest: (
    args: ApiRequestArgs<T, ParamsObj>
  ) => Promise<AxiosResponse<PaginatedResponse<any, ItemsKeys>>>;
  mappingFunction?: (values: Array<any>) => Array<T>;
};

export default function useTablePagination<
  T extends Record<string, any>,
  ItemsKey extends string
>(args: TablePaginationProps<T, ItemsKey>) {
  const {
    apiRequest,
    mappingFunction,
    count: pCount = 10,
    data: pData,
    params: pParams,
    deps = [],
    itemsKey = 'users',
    customErrorMessage,
    spinnerParentClass,
    hasSortParams = true,
    initialParams = hasSortParams
      ? { sortBy: 'id', sortDirection: 'desc' }
      : {},
  } = args;

  const { showOverlay, hideOverlay } = useUtilities();

  const {
    params: { page, sortBy, sortDirection },
    setQueryParam,
    setMultipleQueryParams,
  } = useQueryParams<{
    page: string;
    sortBy: string;
    sortDirection: SortDirections;
  }>();

  const { state } = useLocation();

  const loadRef = useRef<boolean>(false);
  const skipSearch = useRef<boolean>(false);
  const skipRequest = useRef<boolean>(false);

  const searchStringChanged = useRef<boolean>(false);
  const previousValidSearch = useRef<string>('');

  const [search, setSearch] = useSearch('search');

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [requestError, setRequestError] = useState(false);
  const [count, setCount] = useState(pCount);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState<Array<T>>(pData || []);
  const [params, setParams] = useState(pParams || ({} as SortOption<T>));
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [revalidate, setRevalidate] = useState<boolean>(false);

  const [activeColumnSort, setActiveColumnSort] = useState<SortOption<T>>({
    sortBy: sortBy || initialParams?.sortBy,
    sortDirection: sortDirection || initialParams?.sortDirection,
  });

  const fetchRequestReady = useMemo(() => {
    if (deps.length > 0) return deps?.every((d) => d !== undefined);
    else return true;
  }, [deps]);

  const revalidateData = useCallback(() => {
    //update data with api call on revalidate
    setRevalidate((old) => !old);
  }, []);

  const loadData = useCallback(async () => {
    try {
      if (!apiRequest) return;
      setLoading(true);
      spinnerParentClass && showOverlay(spinnerParentClass, 'fixed');

      const { data: requestData } = await apiRequest({
        per_page: count,
        currentPage: Number(page || 1),
        searchString: debouncedSearch,
        sort_by: activeColumnSort.sortBy,
        sort_direction: activeColumnSort.sortDirection,
        params: params || undefined,
      });

      const {
        data,
        meta: { total },
      } = requestData;

      let tableData: Array<T> = [];

      if (data[itemsKey as ItemsKey]) {
        if (mappingFunction) {
          tableData = mappingFunction(data[itemsKey as ItemsKey]);
        } else tableData = data[itemsKey as ItemsKey];

        setData(tableData as Array<T>);
        setTotalCount(total);
        setTotalPages(Math.ceil(total / count));
      } else {
        setData([]);
        setTotalPages(1);
        setQueryParam('page', 1, true);
        setErrorMsg(customErrorMessage || 'Request Failed');
      }

      loadRef.current = false;
    } catch (e) {
      console.error(e);
      setRequestError(true);
    } finally {
      setLoading(false);
      previousValidSearch.current = debouncedSearch;
      spinnerParentClass && hideOverlay(spinnerParentClass);
    }
  }, [
    count,
    page,
    debouncedSearch,
    params,
    itemsKey,
    customErrorMessage,
    activeColumnSort.sortBy,
    activeColumnSort.sortDirection,
    apiRequest,
    mappingFunction,
    showOverlay,
    hideOverlay,
  ]);

  const onSearchChange = useCallback(
    debounce((value: string) => {
      if (!isNonEmptyString(value)) {
        setDebouncedSearch('');
        return;
      }

      setQueryParam('page', 1, true);
      setDebouncedSearch(value);
    }, 250),
    [setDebouncedSearch]
  );

  const setTableSearch = useCallback(
    (value: string, error?: boolean) => {
      // error exists, skip for sure
      // search is the same as the previously valid search, no data -> fetch, else skip

      if (error && value !== '') skipSearch.current = true;
      else skipSearch.current = false;

      setSearch(value);
    },
    [data, debouncedSearch, page]
  );

  useEffect(() => {
    if (!(state as any)?.navigationLink) return;

    if (!search && debouncedSearch) {
      skipRequest.current = true;
    }
  }, [search, debouncedSearch]);

  useEffectSkipFirst(() => {
    searchStringChanged.current = true;
    onSearchChange(search);
  }, [onSearchChange, search]);

  useEffect(() => {
    if (!fetchRequestReady || loadRef.current) return;
    if (!searchStringChanged.current) loadRef.current = true;

    if (skipSearch.current) return;

    if (skipRequest.current) {
      skipRequest.current = false;
      return;
    }

    loadData();
    searchStringChanged.current = false;
  }, [
    count,
    params,
    page,
    debouncedSearch,
    fetchRequestReady,
    activeColumnSort.sortBy,
    activeColumnSort.sortDirection,
    revalidate,
    ...deps,
  ]);

  useEffectSkipFirst(() => {
    if (!initialParams) return;

    setMultipleQueryParams(
      {
        ...initialParams,
        ...(hasSortParams
          ? {
              sortBy: activeColumnSort.sortBy as string,
              sortDirection: activeColumnSort.sortDirection,
            }
          : {}),
      },
      true
    );
  }, [activeColumnSort]);

  return {
    totalPages,
    requestError,
    errorMsg,
    data,
    count,
    loading,
    params,
    totalCount,
    currentPage: Number(page || 1),
    search,
    debouncedSearch,
    activeColumnSort,
    loadData,
    setData,
    setCount,
    setParams,
    setTotalPages,
    setCurrentPage: (page: number) => setQueryParam('page', page, true),
    setSearch: setTableSearch,
    setActiveColumnSort,
    revalidateData,
  };
}

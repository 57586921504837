import { useMemo } from 'react';
import { Redirect } from 'react-router';
import { appUri } from 'config';
import credentialService from 'services/credentialService';

const OnlyPublicRoute: React.FC<{
  component: React.FC<any> | JSX.Element;
}> = (props) => {
  const { component: Component, ...rest } = props;

  const isAuthenticated = useMemo(() => credentialService.token, []);

  return isAuthenticated ? (
    <Redirect to={appUri.DASHBOARD} />
  ) : typeof Component === 'object' ? (
    Component
  ) : (
    <Component {...rest} />
  );
};

export default OnlyPublicRoute;

import { useCallback, useEffect, useState } from 'react';
import { useUtilities } from '@faxi/web-component-library';
import { orderBy, uniqBy } from 'lodash';
import { useParams } from 'react-router';
import {
  DriverMap,
  MapMarker,
  MapMarkerType,
  MapPolygon,
  MapPolyline,
  PassengerMap,
  Trax,
} from 'models';
import { ParticipantMap } from 'models/Map';
import { apiJourneys } from 'modules';
import MapContext from './MapData.context';

export type Coordinates = { lat: number; lng: number };

export type MapProviderProps = {
  children?: React.ReactNode;
};

const MapDataProvider: React.FC<MapProviderProps> = (props) => {
  const { children } = props;

  const { showOverlay, hideOverlay } = useUtilities();

  const [elements, setElements] = useState<{
    markers: MapMarker[];
    polygons: MapPolygon[];
    polylines: MapPolyline[];
  }>();

  const [driverMap, setDriverMap] = useState<DriverMap>({} as DriverMap);
  const [passengersMap, setPassengersMap] = useState<PassengerMap>(
    {} as PassengerMap
  );

  const { journeyId } = useParams<{ journeyId: string }>();

  const mapTrax = useCallback(
    (trax: (Trax & { type: MapMarkerType })[]): MapMarker[] => {
      return uniqBy(
        trax
          // TODO: THIS SORT IS POSSIBLY UNNECESSARY
          .sort(
            (t1, t2) => new Date(t1.ts).getTime() - new Date(t2.ts).getTime()
          )
          .map((t, index) => {
            if (!t.pin_arr) {
              return t;
            }

            let upper = index;
            let lower = index;

            // GET CLOSEST UPPER POINT WHICH ISN'T "BLE"
            while (trax[upper].pin_arr && upper < trax.length - 1) {
              ++upper;
            }

            // GET CLOSEST LOWER POINT WHICH ISN'T "BLE"
            while (trax[lower].pin_arr && lower > 0) {
              --lower;
            }

            if (upper === trax.length - 1) {
              upper = lower;
            }

            if (lower === 0) {
              lower = upper;
            }

            const lat =
              (parseFloat(trax[upper].lat) + parseFloat(trax[lower].lat)) / 2;

            const lng =
              (parseFloat(trax[upper].lng) + parseFloat(trax[lower].lng)) / 2;

            return {
              ...t,
              lat: lat.toFixed(6),
              lng: lng.toFixed(6),
              ble: true,
            };
          })
          .filter((el) => !!el.lat),
        (el) => `${el.lat}_${el.lng}`
      );
    },
    []
  );

  useEffect(() => {
    const initMarkerCluster = async () => {
      try {
        showOverlay('.kinto-map-page-container');

        const {
          data: {
            data: { data },
          },
        } = await apiJourneys.getJourneyInspect(journeyId);

        const driver = data.participants.find(
          (participant: ParticipantMap) =>
            participant.participant_type !== 'passenger'
        );

        const participants = orderBy(
          data.participants.filter(
            (participant: ParticipantMap) =>
              participant.participant_type === 'passenger'
          ),
          'user_id',
          'asc'
        );

        if (driver) {
          const {
            trax,
            segments,
            boxes,
            start_point,
            user_id,
            participant_type,
            end_point,
          } = driver;

          setDriverMap({
            trax: trax?.data,
            segments: segments,
            boxes: boxes,
            start_lat: `${start_point.coordinates[1]}`,
            start_lng: `${start_point.coordinates[0]}`,
            end_lat: `${end_point.coordinates[1]}`,
            end_lng: `${end_point.coordinates[0]}`,
            id_driver: user_id,
            participant_type: participant_type,
          });
        }

        const passengers = participants.reduce(
          (acc, participant) => ({
            ...acc,
            [`${participant.user_id}`]: {
              trax: participant?.trax?.data,
              start_lat: `${participant?.start_point.coordinates[1]}`,
              start_lng: `${participant?.start_point.coordinates[0]}`,
            },
          }),
          {}
        );

        setPassengersMap(passengers);

        setElements({
          markers: (
            [
              // DRIVER START LOCATION
              {
                lat: `${data.start_point.coordinates[1]}`,
                lng: `${data.start_point.coordinates[0]}`,
                type: 'driver-start',
                userId: driver?.user_id,
              },
              // DRIVER END LOCATION
              {
                lat: `${data.end_point.coordinates[1]}`,
                lng: `${data.end_point.coordinates[0]}`,
                type: 'driver-end',
              },
            ].concat(
              // PASSENGER PICK UP LOCATIONS
              participants.map(
                (participant: ParticipantMap, index: number) => ({
                  lat: `${participant.start_point.coordinates[1]}`,
                  lng: `${participant.start_point.coordinates[0]}`,
                  type: 'passenger-pick-up',
                  userId: participant.user_id,
                  passengerIndex: index + 1,
                })
              )
            ) as MapMarker[]
          )
            .concat(
              // PASSENGER DROP OFF LOCATIONS
              participants.map(
                (participant: ParticipantMap, index: number) => ({
                  lat: `${participant.end_point.coordinates[1]}`,
                  lng: `${participant.end_point.coordinates[0]}`,
                  type: 'passenger-drop-off',
                  userId: participant.user_id,
                  passengerIndex: index + 1,
                })
              ) as MapMarker[]
            )
            .concat(
              // DRIVER TRAX
              driver?.trax
                ? mapTrax(
                    driver?.trax.data
                      .filter((el) => !!el.lat)
                      .map(({ ...rest }) => ({ ...rest, type: 'driver-trax' }))
                  )
                : []
            )
            .concat(
              // PASSENGERS TRAX
              mapTrax(
                participants
                  .map((participant: ParticipantMap, index: number) =>
                    participant?.trax
                      ? participant.trax.data.map((t) => ({
                          ...t,
                          userId: participant.user_id,
                          index,
                          type: 'passenger-trax',
                        }))
                      : []
                  )
                  .flat() as Array<Trax & { type: MapMarkerType }>
              )
            ),
          polygons: driver?.boxes || [],
          polylines: driver?.segments || [],
        });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.kinto-map-page-container');
      }
    };

    initMarkerCluster();
  }, [hideOverlay, journeyId, mapTrax, showOverlay]);

  return (
    <MapContext.Provider
      value={{
        journeyId,
        elements,
        driverMap,
        passengersMap,
        setDriverMap,
        setPassengersMap,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export default MapDataProvider;

import { ReactNode, useCallback, useState } from 'react';
import {
  Button,
  DatePicker,
  Heading,
  Icon,
  Input,
  StatusElement,
  StatusElementStatus,
  Table,
  getColor,
  useQueryParams,
  useDatePickerParams,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import {
  COLUMNS_SETTINGS_LABELS,
  DATE_PICKER_ACTIONS_LABELS,
  DATE_PICKER_LABELS,
  dateFormat,
  reportsConfig,
} from 'config';
import { TablePageLayout } from 'components';
import ActivityDetailsModal from './components/ActivityDetailsModal';
import { NoData } from 'Global.styles';
import { useColumnSettings, useTablePagination } from 'hooks';
import { apiLogs } from 'modules';
import { TRANSLATION_KEYS, EXCLUDED_COLUMNS } from './utils';
import { ActivityLog } from 'models/Log';

export type ActivityTableType = {
  id: number;
  property: string;
  new_value: string;
  old_value: string;
  user_name: string;
  date: string;
  event: ReactNode;
} & Pick<ActivityLog, 'properties'>;

const EVENT_STATUS = {
  created: 'approved',
  updated: 'pending',
  deleted: 'rejected',
} as Record<ActivityLog['event'], StatusElementStatus>;

const ChangeLogs: React.FC = () => {
  const { setQueryParam } = useQueryParams<{
    page: string;
  }>();

  const { dateRange, setDateRange } = useDatePickerParams(
    reportsConfig.range_start
  );

  const [activityDetailsOpen, setActivityDetailsOpen] =
    useState<boolean>(false);

  const [currentDetails, setCurrentDetails] = useState<ActivityTableType>();

  const {
    activeColumnSort,
    count,
    totalCount,
    totalPages,
    currentPage,
    search,
    data: tableData,
    setCount,
    setSearch,
    setCurrentPage,
    setActiveColumnSort,
  } = useTablePagination<ActivityTableType, 'activitylogs'>({
    hasSortParams: false,
    itemsKey: 'activitylogs',
    spinnerParentClass: '.kinto-page',
    deps: [dateRange],
    mappingFunction: (data: Array<ActivityLog>) => {
      return data.map(
        (d) =>
          ({
            id: d.id,
            property: d.type,
            user_name: `${d.user.firstname} ${d.user.lastname}`,
            date: dayjs(d.created_at).format(dateFormat),
            event: (
              <StatusElement status={EVENT_STATUS[d.event]}>
                {d.event}
              </StatusElement>
            ),
            properties: d.properties,
          } as ActivityTableType)
      );
    },
    apiRequest: ({ per_page, currentPage, searchString }) => {
      return apiLogs.getChangeLogs({
        per_page,
        page: currentPage,
        searchString,
        dateRange,
      });
    },
  });

  const handleChangeSearchInput = useCallback(
    (value: string, error?: boolean) => {
      setSearch(value, error);
      setQueryParam('page', 1);
    },
    [setQueryParam, setSearch]
  );

  const {
    columnBtnRef,
    columnSettingsOpen,
    closeColumnSettings,
    ColumnSettingsButton,
  } = useColumnSettings();

  return (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__header"
      >
        Change logs
      </Heading>
      <div className="kinto-page__actions">
        <Input
          value={search}
          placeholder="Search here..."
          prefixIcon={<Icon name="search" />}
          {...(search && {
            suffixIcon: (
              <Button
                variant="ghost"
                aria-label="Delete input search value"
                onClick={() => setSearch('')}
                icon={<Icon name="xmark" />}
              />
            ),
          })}
          onChange={handleChangeSearchInput}
        />
        <DatePicker
          className="analytics__date-picker"
          value={dateRange}
          buttonsLabels={DATE_PICKER_LABELS}
          startingRangeDate={reportsConfig.range_start}
          formButtonsLabels={DATE_PICKER_ACTIONS_LABELS}
          onChange={setDateRange}
        />

        {tableData.length !== 0 && <ColumnSettingsButton />}
      </div>
      {tableData.length === 0 ? (
        <NoData className="kinto-no-data">
          Sorry, there are no results that match your search.
        </NoData>
      ) : (
        <Table<ActivityTableType>
          cacheColumns
          disableSort
          rowsHaveAction
          tableId="changes-logs-table"
          rowsActionHeader="Actions"
          rowsActionButtonLabel="Details"
          expandable={false}
          tableData={tableData}
          initialSort={activeColumnSort}
          goToPageInputProps={{ placeholder: 'Go to page' }}
          excludeColumns={EXCLUDED_COLUMNS}
          columnSettingsOpen={columnSettingsOpen}
          columnsModalLabels={COLUMNS_SETTINGS_LABELS}
          columnsBtnElement={columnBtnRef.current as HTMLElement}
          paginationData={{
            currentPage,
            totalCount,
            totalPages,
            limit: count,
          }}
          translationKeys={TRANSLATION_KEYS}
          onPageChange={setCurrentPage}
          onLimitChange={(limit) => {
            setCurrentPage(1);
            setCount(+limit.value);
          }}
          onColumnSortClicked={(sort) => {
            setActiveColumnSort(sort);
            setCurrentPage(1);
          }}
          onColumnsModalClose={closeColumnSettings}
          onEditAction={(data) => {
            setActivityDetailsOpen(true);
            setCurrentDetails(data);
          }}
        />
      )}
      {activityDetailsOpen && currentDetails && (
        <ActivityDetailsModal
          {...currentDetails}
          onClose={() => setActivityDetailsOpen(false)}
        />
      )}
    </TablePageLayout.PageLayoutContainer>
  );
};

export default ChangeLogs;

import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Container = styled.div`
  .upload-csv {
    &__reward_name {
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__files {
      max-height: ${theme.sizes.SIZE_304};
      overflow: auto;
    }

    &__file-uploaded {
      ${flex('row', 'space-between', 'baseline')}
      background-color: var(--ALERT_SUCCESS_1_7);
      color: var(--ALERT_SUCCESS_DARK_1_1);
      padding: 0 ${theme.sizes.SIZE_12};
      margin-bottom: ${theme.sizes.SIZE_8};

      &:last-child {
        margin-bottom: ${theme.sizes.SIZE_32};
      }
    }

    &__initial-file-uploaded {
      ${flex('row', 'space-between', 'baseline')}
      font-weight: 600;
      padding: ${theme.sizes.SIZE_12};
      background-color: var(--PRIMARY_1_7);
      margin-bottom: ${theme.sizes.SIZE_8};

      &:last-child {
        margin-bottom: ${theme.sizes.SIZE_32};
      }
    }
  }
`;

import { FC, useMemo } from 'react';
import { useHistory } from 'react-router';
import {
  SelectOption,
  useCallbackRef,
  useFormButtons,
} from '@faxi/web-component-library';
import { Form, FormField, FormRef, validators } from '@faxi/web-form';
import classNames from 'classnames';
import { useCallbackAsync, useInfinitePagination } from 'hooks';
import { apiActivations, apiCommunities } from 'modules';
import { appUri } from 'config';
import { CalendarField, CreatePlanActivation, SelectField } from 'components';
import dayjs from 'dayjs';
import { SearchedCommunity } from 'pages/Users/components/CommuitySearch/CommunitySearch.component';
import { isNonEmptyString, snackBarSuccessMessage } from 'utils';
import { composeValidators } from 'validation/validators/specific';
import { FormActions } from 'Global.styles';

const CreateActivation: FC = () => {
  const history = useHistory();

  const [FormButtons] = useFormButtons({
    submitLabel: 'Create',
    cancelLabel: 'Cancel',
  });

  const [form, formRef] = useCallbackRef<FormRef>();

  const validations = useMemo(
    () => ({
      organisation_id: composeValidators(
        validators.general.required('This field is required')
      ),
      plan_id: composeValidators(
        validators.general.required('This field is required')
      ),
      billing_period: composeValidators(
        validators.general.required('This field is required')
      ),
      activation_date: composeValidators(
        validators.general.required('This field is required')
      ),
    }),
    []
  );

  const {
    loading: loadingCommunityOptions,
    items: communityOptions,
    onSearchChange: onCommunitySearchChange,
    handleContainerScroll: handleCommunityContainerScroll,
  } = useInfinitePagination<SearchedCommunity, 'organisations'>({
    spinnerParent: '.kinto-my-bookings',
    itemsKey: 'organisations',
    perPage: 10,
    useQueryParams: false,
    mappingFunction: (
      communities: Array<SearchedCommunity>
    ): SelectOption<string>[] =>
      communities
        .map(({ id, name }) => ({
          id: `${id}`,
          label: name,
          value: `${id}`,
        }))
        .filter(({ label }) => isNonEmptyString(label)),
    apiRequest: (page: string, search: string, perPage: string) =>
      apiCommunities.getSearchCommunities(page, search, perPage),
  });

  const {
    loading: loadingPlanOptions,
    items: plansOptions,
    onSearchChange: onPlanSearchChange,
    handleContainerScroll: handlePlanContainerScroll,
  } = useInfinitePagination<SearchedCommunity, 'plans'>({
    spinnerParent: '.kinto-my-bookings',
    itemsKey: 'plans',
    perPage: 10,
    useQueryParams: false,
    mappingFunction: (
      communities: Array<SearchedCommunity>
    ): SelectOption<string>[] =>
      communities
        .map(({ id, name }) => ({
          id: `${id}`,
          label: name,
          value: `${id}`,
        }))
        .filter(({ label }) => isNonEmptyString(label)),
    apiRequest: (page: string, search: string, perPage: string) =>
      apiActivations.getPlansDropdown(page, search, perPage),
  });

  const [handleOnSubmit, loading] = useCallbackAsync({
    spinnerParent: '.kinto-create-plan',
    callback: async (values) => {
      try {
        const { data } = await apiActivations.createActivation({
          ...values,
          activation_date: dayjs(values.activation_date, 'DD-MM-YYYY').format(
            'YYYY-MM-DD'
          ),
        });

        if (data) {
          snackBarSuccessMessage('Activation successfully created');
          history.push(appUri.ACTIVATIONS);
        }
      } catch (e) {
        console.error(e);
      }
    },
  });

  return (
    <CreatePlanActivation.CreateContainer
      className={classNames('kinto-create-form')}
    >
      <h1>New activation</h1>

      <Form onSubmit={handleOnSubmit} ref={formRef}>
        <fieldset
          className={classNames(
            'kinto-create-form__fieldset',
            'kinto-create-form__fieldset--activation'
          )}
        >
          <FormField
            name="organisation_id"
            component={SelectField}
            placeholder="Select community"
            hasClearAction
            clearTitle="Clear selection"
            async
            renderAsPortal
            searchable
            closeOptionsOnClear
            repositionDropdown={false}
            loading={loadingCommunityOptions}
            options={communityOptions}
            onClear={() => {
              onCommunitySearchChange('');
            }}
            onSearchChange={onCommunitySearchChange}
            onContainerScroll={handleCommunityContainerScroll}
            required
            requiredLabel="Required"
            validate={validations.organisation_id}
          />
          <FormField
            name="plan_id"
            component={SelectField}
            placeholder="Plan"
            hasClearAction
            clearTitle="Clear selection"
            async
            renderAsPortal
            searchable
            closeOptionsOnClear
            repositionDropdown={false}
            loading={loadingPlanOptions}
            options={plansOptions}
            onClear={() => {
              onPlanSearchChange('');
            }}
            onSearchChange={onPlanSearchChange}
            onContainerScroll={handlePlanContainerScroll}
            required
            requiredLabel="Required"
            validate={validations.plan_id}
          />

          <FormField
            name="billing_period"
            placeholder="Biling period"
            component={SelectField}
            renderAsPortal
            options={[
              { label: 'Monthly', value: 'monthly' },
              { label: 'Quarterly', value: 'quarterly' },
              { label: 'Yearly', value: 'yearly' },
            ]}
            required
            requiredLabel="Required"
            validate={validations.billing_period}
          />
          <FormField
            name="activation_date"
            component={CalendarField}
            renderAsPortal
            placeholder="Activation date"
            leftLimitDate={dayjs()}
            rightLimitDate={dayjs().add(20, 'years')}
            required
            requiredLabel="Required"
            validate={validations.activation_date}
          />
        </fieldset>

        <FormActions className="kinto-create-form__actions">
          <FormButtons.Submit
            disabled={!form?.isFormChanged() || !form?.syncFormValid || loading}
          />
          <FormButtons.Cancel
            onClick={() => history.push(appUri.ACTIVATIONS)}
          />
        </FormActions>
      </Form>
    </CreatePlanActivation.CreateContainer>
  );
};

export default CreateActivation;

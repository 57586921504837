import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, getColor, Heading, Table } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import { dateFormat } from 'config';
import { useCallbackAsync } from 'hooks';
import { apiRewards } from 'modules';
import { Reward } from 'models';
import { Icon, TablePageLayout } from 'components';
import CreateRewardModal from './components/CreateRewardModal';
import EditRewardModal from './components/EditRewardModal';

const RewardsPage: FC = (): JSX.Element => {
  const [showCreateRewardModal, setShowCreateRewardModal] = useState(false);
  const [showEditRewardModal, setShowEditRewardModal] = useState(false);

  const selectedReward = useRef<Reward>();

  const [rewards, setRewards] = useState<Reward[]>([]);

  const translationKeys = useMemo(
    () =>
      ({
        name: 'Reward name',
        number_of_codes: 'Number of codes',
        created_at: 'Date created',
      } as Record<Partial<keyof Reward>, string>),
    []
  );

  // TODO: uncoment this when pagination and sorting is done on BE side
  // const mapUserData = useCallback(
  //   (rewards: Reward[]) =>
  //     rewards.map(
  //       ({ id, name, number_of_codes, created_at }: Reward) =>
  //         ({
  //           id,
  //           name,
  //           number_of_codes,
  //           created_at,
  //         } as Reward)
  //     ),
  //   []
  // );

  // const {
  //   data,
  //   count,
  //   totalCount,
  //   requestError,
  //   totalPages,
  //   currentPage,
  //   search,
  //   activeColumnSort,
  //   setCount,
  //   setCurrentPage,
  //   setSearch,
  //   revalidateData,
  //   setActiveColumnSort,
  // } = useTablePagination<Reward, 'vouchers'>({
  //   itemsKey: 'vouchers',
  //   spinnerParentClass: '.kinto-page',
  //   mappingFunction: (values: Array<Reward>) => mapUserData(values),
  //   initialParams: { sortBy: 'created_at', sortDirection: 'desc' },
  //   apiRequest: ({
  //     per_page,
  //     currentPage,
  //     searchString,
  //     sort_by,
  //     sort_direction,
  //   }) =>
  //     apiRewards.getRewards({
  //       per_page,
  //       page: currentPage,
  //       search: searchString,
  //       sort_by,
  //       sort_direction,
  //     }),
  // });

  const [getRewards] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.kinto-page',
    callback: async () => {
      try {
        const {
          data: {
            data: { vouchers },
          },
        } = await apiRewards.getRewards();

        setRewards(
          vouchers.map((voucher) => ({
            ...voucher,
            created_at: dayjs(voucher.created_at).format(dateFormat),
          }))
        );
      } catch (e) {
        console.error(e);
      }
    },
  });

  useEffect(() => {
    getRewards();
  }, [getRewards]);

  return (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__header"
      >
        Rewards
      </Heading>

      <div className="kinto-page__actions">
        <Button
          icon={<Icon name="plus" />}
          iconPosition="right"
          onClick={() => {
            setShowCreateRewardModal(true);
          }}
        >
          Create a Reward
        </Button>
      </div>

      {rewards.length !== 0 ? (
        <Table
          tableId="rewards-page"
          tableData={rewards}
          translationKeys={translationKeys}
          excludeColumns={['id', 'communities', 'updated_at', 'platform_id']}
          disableSort={true}
          rowsHaveAction={true}
          rowsActionButtonIcon="pen"
          onEditAction={(data) => {
            selectedReward.current = data;
            setShowEditRewardModal(true);
          }}
        />
      ) : (
        <div className="kinto-page__empty-state">No rewards created yet</div>
      )}

      {showCreateRewardModal && (
        <CreateRewardModal
          onClose={() => {
            setShowCreateRewardModal(false);
          }}
          onCreate={(reward) => {
            setRewards((old) => [reward, ...old]);
          }}
        />
      )}

      {showEditRewardModal && (
        <EditRewardModal
          reward={selectedReward.current!}
          onClose={() => {
            setShowEditRewardModal(false);
          }}
          onUpdate={(updatedReward) => {
            setRewards((old) =>
              old.map((reward) =>
                reward.id === updatedReward.id ? updatedReward : reward
              )
            );
          }}
        />
      )}
    </TablePageLayout.PageLayoutContainer>
  );
};

export default RewardsPage;

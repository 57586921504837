import { FC } from 'react';
import classNames from 'classnames';

import * as Styled from './PreviewInput.styles';

type PreviewInputProps = {
  className?: string;
  title?: string;
  content?: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const PreviewInput: FC<PreviewInputProps> = (props) => {
  const { className, title, content } = props;

  return (
    <Styled.PreviewInput className={classNames('preview-input', className)}>
      <span className="preview-input--title">{title}</span>
      <span className="preview-input--content">{content}</span>
    </Styled.PreviewInput>
  );
};

export default PreviewInput;

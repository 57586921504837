import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { Admin } from 'models/Admin';
import { appUri } from 'config';
import authBus, { AUTH_BUS_EVENTS } from 'modules/authBus';
import refreshTokenService from 'modules/refreshTokenService';
import credentialService from 'services/credentialService';
import apiUsers from 'modules/router/api/apiUsers';
import AuthContext from './Auth.context';
import { AxiosError } from 'axios';
import { snackBarErrorMessage } from 'utils';
import { useUtilities } from '@faxi/web-component-library';

const AuthProvider: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const { showOverlay, hideOverlay } = useUtilities();

  const history = useHistory();

  const location = useLocation();

  const [admin, setAdmin] = useState<Admin>(credentialService.user);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!credentialService.token
  );
  const [globalLoading, setGlobalLoading] = useState<boolean>(false);

  const adminFullName = useMemo(
    () => [admin?.firstname, admin?.lastname].join(' ').trim(),
    [admin]
  );

  const simpleLogout = useCallback(() => {
    localStorage.clear();
    refreshTokenService.reinit();
    history.push(appUri.LOGIN);
    setIsAuthenticated(false);
  }, [history]);

  const globalLogout = useCallback(
    (showToast: boolean) => {
      if (showToast) snackBarErrorMessage('Your session has expired');
      // TODO: re-consider this redirection-based logout :)

      if (refreshTokenService.triedRefresh) {
        simpleLogout();
        return;
      }

      history.replace(appUri.LOGGING_OUT);
    },
    [history, simpleLogout]
  );

  useEffect(() => {
    const loadCurrentUser = async () => {
      try {
        setGlobalLoading(true);
        showOverlay('body');

        const { data } = await apiUsers.getAdmin(credentialService.userId);
        credentialService.saveUser(data[0]);

        setAdmin(data[0]);
      } catch (e) {
        snackBarErrorMessage(e as AxiosError);
      } finally {
        setGlobalLoading(false);
        hideOverlay('body');
      }
    };

    if (isAuthenticated) loadCurrentUser();
  }, [hideOverlay, isAuthenticated, showOverlay]);

  useEffect(() => {
    const eventLogout = (showToast: boolean) => {
      globalLogout(showToast);
    };

    authBus.addEventListener(AUTH_BUS_EVENTS.LOGGED_OUT, eventLogout);

    return () => {
      authBus.removeEventListener(AUTH_BUS_EVENTS.LOGGED_OUT, eventLogout);
    };
  }, [globalLogout]);

  // TODO: this is to avoid sidebar stale when 2 tabs are open and user logs out in one, storage is cleared but the `isAuthenticated` state is still true
  // consider implementing a smarter logic here
  useEffect(() => {
    if (location.pathname.includes('login')) {
      setIsAuthenticated(false);
    }
  }, [location.pathname]);

  // TODO: this fixed initial render where it seemed unauthenticated, credential service is a bit late
  useEffect(() => {
    setTimeout(() => {
      setIsAuthenticated(!!credentialService.token);
    }, 0);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        admin,
        adminFullName,
        globalLoading,
        isAuthenticated,
        simpleLogout,
        setAdmin,
        globalLogout,
        setIsAuthenticated,
        setGlobalLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import { useCallback } from 'react';
import { useQueryParams } from '@faxi/web-component-library';

export default function useSearch(searchUrlName: string, initialValue = '') {
  const {
    params: { [`${searchUrlName}`]: search = initialValue },
    setQueryParam,
  } = useQueryParams();

  const setSearch = useCallback(
    (value: string) => setQueryParam(searchUrlName, value || null),
    [setQueryParam, searchUrlName]
  );

  return [search, setSearch] as const;
}

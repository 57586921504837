const NUMBERS_ONLY = /^[1-9][0-9]{0,2}(,?[0-9]{3})*(\.[0-9]+)?$/i;

const VALID: any = undefined;

export const numbersOnly = (errorMessage: string) => (value: any) => {
  return NUMBERS_ONLY.test(value) ? VALID : errorMessage;
};

export const maxLength =
  (errorMessage: string, maxLength: number) => (value: any) => {
    const hasLength = value && typeof value.length === 'number';
    return hasLength && value.length <= maxLength ? VALID : errorMessage;
  };

export const minLength =
  (errorMessage: string, minLength: number) => (value: any) => {
    const hasLength = value && typeof value.length === 'number';
    return hasLength && value.length >= minLength ? VALID : errorMessage;
  };

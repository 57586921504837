import { theme, Input } from '@faxi/web-component-library';
import { flex, flexGap, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const InputContainer = styled(Input)`
  .input__label {
    ${flex('row', 'flex-start', 'center')};
    ${flexGap(theme.sizes.SIZE_4, 'row')};
  }

  .input-field {
    &__tooltip {
      ${flex('row', 'center', 'center')};
      ${size(theme.sizes.SIZE_24)};

      color: var(--PRIMARY_1_1);
      margin-left: ${theme.sizes.SIZE_4};
    }
  }
`;

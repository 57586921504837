import { SortDirections } from '@faxi/web-component-library';
import { Plan, PaginatedResponse, Price } from 'models';
import httpClient from '../httpClient';

const getAll = ({
  page,
  per_page,
  sort_by,
  sort_direction,
}: {
  page?: number;
  per_page?: number;
  sort_by?: keyof Plan;
  sort_direction?: SortDirections;
}) =>
  httpClient.get<PaginatedResponse<Plan, 'plans'>>('/plans', {
    params: {
      page,
      per_page,
      ...((sort_by || sort_direction) && {
        sort: `${sort_by}:${sort_direction}`,
      }),
    },
  });

const create = ({
  name,
  description,
  published,
  premium_features,
  prices,
  users_limit,
}: {
  name: string;
  description: string;
  published: boolean;
  premium_features: number[];
  prices: Pick<Price, 'type' | 'price' | 'currency'>[];
  users_limit?: number;
}) =>
  httpClient.post('/plans', {
    name,
    description,
    published,
    premium_features,
    prices,
    users_limit,
  });

const remove = ({ id }: { id: number }) => httpClient.delete(`/plans/${id}`);

export default {
  getAll,
  create,
  remove,
};

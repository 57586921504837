import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const LegendContainer = styled.div`
  ${flex('column', 'flex-start', 'flex-start')};
  padding: ${theme.sizes.SIZE_24};
  border-radius: ${theme.sizes.SIZE_8};

  > :not(:last-child) {
    margin-bottom: ${theme.sizes.SIZE_16};
  }

  .map-legend {
    &__pin-info {
      display: flex;
      flex-direction: center;
      align-items: center;

      &__pin {
        ${size(theme.sizes.SIZE_16)};

        margin-right: ${theme.sizes.SIZE_12};
      }

      &__title {
        ${fontSize(theme.fontSizes.FONT_14, '18px')};

        margin: 0;
        font-weight: 400;
        color: var(--SHADE_1_2);
      }
    }
  }
`;

import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const Container = styled.div`
  &.calendar-container {
    section {
      padding: 0;
    }
  }

  .calendar-container__input {
    .input__container__placeholder {
      transition: unset;
    }
  }

  .wcl-calendar {
    border: unset;
    width: ${theme.sizes.SIZE_320};
  }
`;

import { useCallback, useMemo } from 'react';
import {
  Breadcrumbs,
  Button,
  Heading,
  useUtilities,
  SelectOption,
  SortOption,
  Table,
  getColor,
} from '@faxi/web-component-library';
import { utils, write } from 'xlsx';

import { TablePageLayout } from 'components';
import { UserFeedback } from 'models/User';
import { apiUsers } from 'modules';
import { appUri, dateFormat } from 'config';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useTablePagination } from 'hooks';
import { stringToArrayBuffer } from '../utils';

const FEEDBACK_DATE_FORMAT = 'DD.MM.YYYY, HH:mm';

const UsersFeedback = () => {
  const { showOverlay, hideOverlay } = useUtilities();

  const translationKeys = useMemo(
    () =>
      ({
        id: 'ID',
        feedback: 'Feedback',
        deleted_at: 'Feedback date & time',
      } as Record<Partial<keyof UserFeedback>, string>),
    []
  );

  const mapUserFeedback = useCallback(
    (users: UserFeedback[]) =>
      users.map(
        ({ id, feedback, deleted_at }: UserFeedback) =>
          ({
            id,
            feedback,
            deleted_at: dayjs(deleted_at)
              .utc(true)
              .format(FEEDBACK_DATE_FORMAT),
          } as UserFeedback)
      ),
    []
  );

  const {
    data,
    count,
    totalCount,
    totalPages,
    currentPage,
    activeColumnSort,
    setCount,
    setCurrentPage,
    setActiveColumnSort,
  } = useTablePagination<UserFeedback, 'users'>({
    itemsKey: 'users',
    spinnerParentClass: '.kinto-page',
    mappingFunction: (values: Array<UserFeedback>) => mapUserFeedback(values),
    initialParams: { sortBy: 'deleted_at', sortDirection: 'asc' },
    apiRequest: ({ per_page, currentPage, sort_by, sort_direction }) => {
      return apiUsers.getUsersFeedback({
        per_page,
        page: currentPage,
        sort_by,
        sort_direction,
      });
    },
  });

  const handleOnColumnSort = useCallback(
    (sortOptions: SortOption<UserFeedback>) => {
      setActiveColumnSort(sortOptions);
      setCurrentPage(1);
    },
    [setActiveColumnSort, setCurrentPage]
  );

  const generateXLSX = useCallback(async () => {
    if (!data) {
      return;
    }

    showOverlay('body', 'fixed');

    const {
      data: { data: feedbacks },
    } = await apiUsers.getUsersFeedback({
      per_page: totalCount,
      sort_by: activeColumnSort.sortBy,
      sort_direction: activeColumnSort.sortDirection,
    });

    const book = utils.book_new();

    const sheetName = 'Users Feedback';

    book.Props = {
      Title: sheetName,
      Author: 'Kinto',
      CreatedDate: new Date(),
    };

    book.SheetNames.push(sheetName);

    const xlsxData = [];

    xlsxData.push(
      Object.keys(data[0]).map((el) => (translationKeys as any)?.[el])
    );

    feedbacks.users.forEach(({ id, feedback, deleted_at }) =>
      xlsxData.push([
        id,
        feedback,
        dayjs(deleted_at).format(FEEDBACK_DATE_FORMAT),
      ])
    );

    const sheet = utils.aoa_to_sheet(xlsxData);
    const wscols = [{ wch: 10 }, { wch: 30 }, { wch: 25 }];

    sheet['!cols'] = wscols;

    book.Sheets[sheetName] = sheet;

    const bookout = write(book, { bookType: 'xlsx', type: 'binary' });

    saveAs(
      new Blob([stringToArrayBuffer(bookout)], {
        type: 'application/octet-stream',
      }),
      `users_feedback_${dayjs().format(dateFormat)}.xlsx`
    );

    hideOverlay('body');
  }, [
    data,
    showOverlay,
    totalCount,
    activeColumnSort,
    hideOverlay,
    translationKeys,
  ]);

  return (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Breadcrumbs
        crumbs={[
          { text: 'Users', href: appUri.USERS },
          { text: 'User feedback', href: appUri.USERS_FEEDBACK },
        ]}
        className="kinto-users-feedback__breadcrumbs"
      />

      <div className="kinto-users-feedback__title">
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="kinto-page__header"
        >
          User Feedback
        </Heading>

        <Button onClick={generateXLSX} disabled={data.length === 0}>
          Export to XLSX
        </Button>
      </div>

      <Table<UserFeedback>
        tableId="users-feedback-table"
        className="kinto-users-feedback__table"
        breakAtMaxWidth={1200}
        tableData={data}
        translationKeys={translationKeys}
        initialSort={activeColumnSort}
        excludeColumns={['created_at']}
        excludeSortColumns={['id', 'feedback']}
        paginationData={{
          currentPage,
          limit: count,
          totalCount,
          totalPages,
        }}
        goToPageInputProps={{ placeholder: 'Go to page' }}
        onPageChange={setCurrentPage}
        onLimitChange={(data: SelectOption) => {
          setCurrentPage(1);
          setCount(+data.value);
        }}
        onColumnSortClicked={handleOnColumnSort}
      />
    </TablePageLayout.PageLayoutContainer>
  );
};

export default UsersFeedback;

import { FC } from 'react';
import {
  GoogleAutocomplete,
  GoogleAutocompleteProps,
} from '@faxi/web-component-library';
import { useFieldError, FieldProps } from '@faxi/web-form';

export type AddressPlace = {
  lat: number;
  lng: number;
  formatted_address?: string;
  address?: string;
  country?: string;
  locality?: string;
  administrative_area_level_1?: string;
  postal_code?: string;
  city?: string;
};

export type GoogleAutocompletePropsValue = AddressPlace & { placeId?: string };

export type GoogleAutocompleteFieldProps = FieldProps<
  GoogleAutocompletePropsValue,
  any
> &
  GoogleAutocompleteProps;

const GoogleAutocompleteField: FC<GoogleAutocompleteFieldProps> = (
  props: GoogleAutocompleteFieldProps
): JSX.Element => {
  const { dirty, touched, error, ...rest } = props;

  const showError = useFieldError(props);

  return (
    <GoogleAutocomplete errorText={String(error)} error={showError} {...rest} />
  );
};

export default GoogleAutocompleteField;

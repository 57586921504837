import { useCallback, useContext, useEffect } from 'react';
import { GoogleMapsContext } from '@faxi/web-component-library';
import { uniqueId } from 'lodash';

import MarkersContext, {
  OVERLAY_TYPE,
} from 'pages/Map/providers/Markers/Markers.context';
import MapDataContext from 'pages/Map/providers/MapData/MapData.context';
import MapMarker from '../MapMarker';
import { MapMarker as MapMarkerType } from 'models';
import MapPolygon from '../MapPolygon';
import MapPolyline from '../MapPolyline';
import { getMarkersBounds } from 'pages/Map/utils/getMarkerBounds';

export type ExtendMarkerType = google.maps.Marker & MapMarkerType;
export type ExtendPolygonType = google.maps.Polygon & MapMarkerType;
export type ExtendPolylineType = google.maps.Polyline & MapMarkerType;

const MapMarkers: React.FC = () => {
  const { elements } = useContext(MapDataContext);
  const { addPin } = useContext(MarkersContext);

  const { map } = useContext(GoogleMapsContext);

  const onPolygonAdd = useCallback(
    (polygon: ExtendPolygonType) => {
      addPin(polygon, OVERLAY_TYPE.POLYGON);
    },
    [addPin]
  );

  const onPolylineAdd = useCallback(
    (polyline: ExtendPolylineType) => {
      addPin(polyline, OVERLAY_TYPE.POLYLINE);
    },
    [addPin]
  );

  const fitMapToMarkers = useCallback(() => {
    if (!map || !elements?.markers.length) return;

    const routeStart = elements.markers.find(
      (marker) => marker.type === 'driver-start'
    )!;
    const routeEnd = elements.markers.find(
      (marker) => marker.type === 'driver-end'
    )!;

    const bounds = getMarkersBounds([
      new google.maps.Marker({
        position: { lat: +routeStart.lat, lng: +routeStart.lng },
      }),
      new google.maps.Marker({
        position: { lat: +routeEnd.lat, lng: +routeEnd.lng },
      }),
    ]);

    // TODO:
    // COMMENT: NOT SURE IF THIS PADDING IS NEEDED, WITH TEST DATA IT WAS
    map.fitBounds(bounds, { top: 80, left: 0 });
  }, [map, elements]);

  useEffect(() => {
    fitMapToMarkers();
  }, [fitMapToMarkers]);

  return (
    <>
      {elements?.markers.map(
        ({ lat, lng, type, index, userId, passengerIndex, ...rest }) => (
          <MapMarker
            // TODO: remove uniqueId
            userId={userId}
            key={`${lat}_${lng}_${uniqueId()}`}
            lat={lat}
            lng={lng}
            type={type}
            index={index}
            passengerIndex={passengerIndex}
            {...rest}
          />
        )
      )}
      {elements?.polygons.map(({ A, B, C, D, ok }) => (
        <MapPolygon
          key={`${A.lat}_${A.lng}_${B.lat}_${B.lng}_${C.lat}_${C.lng}_${
            D.lat
          }_${D.lng}_${uniqueId()}}`}
          A={A}
          B={B}
          C={C}
          D={D}
          ok={ok}
          onPolygonAdd={onPolygonAdd}
        />
      ))}
      {elements?.polylines.map(({ from, to, head, ok }) => (
        <MapPolyline
          ok={ok}
          key={`${from.lat}_${from.lng}_${to.lat}_${to.lng}_${uniqueId()}`}
          from={from}
          to={to}
          head={head}
          onPolylineAdd={onPolylineAdd}
        />
      ))}
    </>
  );
};

export default MapMarkers;

import { DatePickerValue, SortDirections } from '@faxi/web-component-library';
import { PaginatedResponse } from 'models';
import { ActivityLog, Log } from 'models/Log';
import httpClient from '../httpClient';

const getLogs = async ({
  page,
  per_page,
  searchString,
  sort_by,
  sort_direction,
}: {
  per_page?: number;
  page?: number;
  searchString?: { param: string; value: string };
  sort_by?: keyof Log;
  sort_direction?: SortDirections;
}) =>
  httpClient.get<PaginatedResponse<Log, 'logs'>>('mobilelogs', {
    params: {
      per_page,
      page,
      [`${searchString?.param}`]: searchString?.value,
      sort: (sort_by || sort_direction) && `${sort_by}:${sort_direction}`,
    },
  });

const downloadLogs = async (lid: number) =>
  httpClient.get<Blob>(`mobilelogs/${lid}/download`, {
    responseType: 'blob',
  });

const getChangeLogs = async ({
  page,
  per_page,
  searchString,
  dateRange,
}: {
  page?: number;
  per_page?: number;
  searchString?: string;
  dateRange?: DatePickerValue;
}) =>
  httpClient.get<PaginatedResponse<ActivityLog, 'activitylogs'>>(
    'activitylogs',
    {
      params: {
        per_page,
        page,
        ...(searchString && { search: searchString }),
        ...(dateRange && {
          startdate: dateRange.from?.format('YYYY-MM-DD'),
          enddate: dateRange.to?.format('YYYY-MM-DD'),
        }),
      },
    }
  );

export default { getLogs, getChangeLogs, downloadLogs };

import classNames from 'classnames';
import * as Styled from './Pin.styles';

type PassengerPinProps = {
  type?: 'driver' | 'passenger';
  index?: number;
  className?: string;
  alt?: string;
};

const Pin: React.FC<PassengerPinProps> = (props: PassengerPinProps) => {
  const { index, className, type = 'passenger', alt } = props;

  return (
    <Styled.PassengerPin className={className}>
      <img
        src={
          type === 'driver'
            ? '/assets/svg/driver-trax-map-pin.svg'
            : '/assets/svg/BLE-empty.svg'
        }
        alt={alt}
        className={classNames(
          'passenger-pin__img',
          `passenger-pin__img--${type}`
        )}
      />
      {type === 'passenger' && index && (
        <span className="passenger-pin__index">{index}</span>
      )}
    </Styled.PassengerPin>
  );
};

export default Pin;

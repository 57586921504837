import {
  Expander,
  Icon,
  Tabs,
  useCallbackRef,
} from '@faxi/web-component-library';
import { FormField, FormRef } from '@faxi/web-form';
import classNames from 'classnames';
import { ExtendedPremiumFeature, PremiumFeaturesNames } from 'models';
import React, { useCallback, useEffect, useState } from 'react';
import { PREMIUM_FEATURE_TABS_OPTIONS } from '../../utils';

import * as Styled from './PremiumFeatureTab.styles';

type PremiumFeatureTabType = {
  features: ExtendedPremiumFeature[];
  form: FormRef;
  initialData?: Record<PremiumFeaturesNames, '0' | '1'>;
};
const PremiumFeatureTab = (props: PremiumFeatureTabType) => {
  const { features, form, initialData } = props;

  const [showWarning, setShowWarning] = useState<string[]>([]);
  const [warningElement, warningElementRef] = useCallbackRef<HTMLDivElement>();

  const renderFeature = useCallback(
    ({
      id,
      title,
      icon,
      name,
      onChange,
      className,
    }: ExtendedPremiumFeature & {
      onChange?: ((value: React.ReactText) => void) | undefined;
    }) => {
      return (
        <div
          key={id}
          className={classNames(
            'feature',
            className,
            `feature--${title?.toLowerCase()}`
          )}
        >
          <label className="feature__title">
            {icon ? (
              <Icon name={icon} className="feature__title__icon" />
            ) : (
              <div className="feature__title__icon">•</div>
            )}
            <span>{title}</span>
          </label>

          <div className="feature__tab-field">
            <FormField
              name={name}
              tabs={PREMIUM_FEATURE_TABS_OPTIONS}
              component={Tabs}
              onChange={(value) => {
                onChange?.(value);
              }}
            />
          </div>
        </div>
      );
    },
    []
  );

  const valueDifferFromInitial = useCallback(
    (name: PremiumFeaturesNames) => {
      return (
        (initialData?.[`${name}`] as string) !==
        (form?.fields?.[`${name}`]?.value as string)
      );
    },
    [form?.fields, initialData]
  );

  useEffect(() => {
    warningElement?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [warningElement]);

  return (
    <Styled.PremiumFeatureTab>
      {features.map(
        ({
          id,
          name,
          icon,
          title,
          subPremiumFeatures,
          children,
          type,
          warning,
        }) => (
          <React.Fragment key={id}>
            {Number(subPremiumFeatures?.length) > 0 ? (
              <Expander
                key={id}
                bodyAs="div"
                icon={<Icon name="chevron-down" />}
                header={renderFeature({
                  id,
                  title,
                  icon,
                  name,
                  onChange: (value) => {
                    subPremiumFeatures?.forEach(({ name }) => {
                      form.updateValueField(name, '0');
                    });

                    setShowWarning((old) =>
                      value === '0'
                        ? [...old, name]
                        : old.filter((n) => n !== name)
                    );
                  },
                  type,
                  children,
                  className: classNames({
                    'feature--warning': warning && showWarning,
                  }),
                })}
                body={subPremiumFeatures?.map(
                  ({ id, name: subName, title, icon, type, children }) =>
                    renderFeature({
                      id,
                      title,
                      icon,
                      name: subName,
                      onChange: (value) => {
                        if (value === '1') {
                          form.updateValueField(name, '1');

                          setShowWarning((old) =>
                            old.filter((n) => n !== name)
                          );
                        }
                      },
                      type,
                      children,
                    })
                )}
              />
            ) : (
              renderFeature({
                id,
                title,
                icon,
                name,
                type,
                children,
                onChange: (value) => {
                  setShowWarning((old) =>
                    value === '0'
                      ? [...old, name]
                      : old.filter((n) => n !== name)
                  );
                },
              })
            )}
            {warning &&
              showWarning.includes(name) &&
              valueDifferFromInitial(name) && (
                <div className="feature__warning-msg" ref={warningElementRef}>
                  <Icon name="triangle-exclamation" />
                  {warning}
                </div>
              )}
          </React.Fragment>
        )
      )}
    </Styled.PremiumFeatureTab>
  );
};

export default PremiumFeatureTab;

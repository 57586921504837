import { SortDirections } from '@faxi/web-component-library';
import { PaginatedResponse, Activation, APIResponse } from 'models';
import { ActivationBillingPeriod, ActivationStatus } from 'models/Activation';
import httpClient from '../httpClient';

// sorting: status, billing_period, activation_date, organisation_name, plan_name
const getActivations = ({
  page,
  per_page,
  sort_by,
  sort_direction,
}: {
  page?: number;
  per_page?: number;
  sort_by?: keyof Activation;
  sort_direction?: SortDirections;
}) =>
  httpClient.get<PaginatedResponse<Activation, 'subscriptions'>>(
    '/subscriptions',
    {
      params: {
        page,
        per_page,
        ...((sort_by || sort_direction) && {
          sort: `${sort_by}:${sort_direction}`,
        }),
      },
    }
  );

const createActivation = ({
  organisation_id,
  plan_id,
  billing_period,
  activation_date,
}: {
  organisation_id: number;
  plan_id: number;
  billing_period: ActivationBillingPeriod;
  activation_date: string;
}) =>
  httpClient.post<APIResponse<Activation>>('/subscriptions', {
    organisation_id,
    plan_id,
    billing_period,
    activation_date,
  });

const getPlansDropdown = (page: string, search?: string, per_page?: string) =>
  httpClient.get<PaginatedResponse<{ id: number; name: string }, 'plans'>>(
    'plans/dropdown',
    {
      params: {
        page,
        search,
        per_page,
      },
    }
  );

const deleteActivation = (id: number) =>
  httpClient.delete(`subscriptions/${id}`);

const updateActivation = (id: number, status: ActivationStatus) =>
  httpClient.put(`subscriptions/${id}`, { status });

export default {
  getActivations,
  createActivation,
  getPlansDropdown,
  deleteActivation,
  updateActivation,
};

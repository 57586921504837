import { useCallback, useRef } from 'react';
import MapContext, {
  OVERLAY_TYPE,
  PossibleOverlayType,
} from './Markers.context';
import {
  ExtendMarkerType,
  ExtendPolygonType,
  ExtendPolylineType,
} from 'pages/Map/components/MapElements/MapElements.component';

type MarkersProviderProps = {
  children?: React.ReactNode;
};

const MarkersProvider: React.FC<MarkersProviderProps> = (
  props: MarkersProviderProps
) => {
  const { children } = props;

  const mapMarkers = useRef<Array<ExtendMarkerType>>([]);
  const mapPolygons = useRef<Array<google.maps.Polygon>>([]);
  const mapPolylines = useRef<Array<google.maps.Polyline>>([]);

  const addPin = useCallback(
    (
      marker: ExtendMarkerType | ExtendPolygonType | ExtendPolylineType,
      type?: PossibleOverlayType
    ) => {
      switch (type) {
        case OVERLAY_TYPE.MARKER:
          mapMarkers.current.push(marker as ExtendMarkerType);
          break;
        case OVERLAY_TYPE.POLYGON:
          mapPolygons.current.push(marker as ExtendPolygonType);
          break;
        case OVERLAY_TYPE.POLYLINE:
          mapPolylines.current.push(marker as ExtendPolylineType);
          break;
      }
    },
    []
  );

  return (
    <MapContext.Provider
      value={{
        mapMarkers: mapMarkers.current,
        mapPolygons: mapPolygons.current,
        addPin,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export default MarkersProvider;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import {
  Breadcrumbs,
  Heading,
  Link,
  getColor,
  Button,
  Tooltip,
} from '@faxi/web-component-library';

import {
  Device,
  Event,
  Journey,
  JourneyDetails,
  Participant,
  PassengerTable,
  Traxes,
} from 'models';
import {
  deviceTranslationKeys,
  eventTranslationKeys,
  journeyTranslationKeys,
  passengerTranslationKeys,
  traxTranslationKeys,
} from './translationKeys';

import { Icon, Pin, TablePageLayout } from 'components';
import TableData from './components/TableData';
import EventTableLegend from './components/EventTableLegend';
import ExcludePassengerModal from './components/ExcludePassengerModal';
import { apiJourneys } from 'modules';
import { appUri } from 'config';
import { dateInUTCFormat } from 'utils';
import { storageService } from 'services';
import { STORAGE_KEYS } from 'services/storageService';
import { orderBy, uniqBy, uniqueId } from 'lodash';
import dayjs from 'dayjs';

import * as Styled from './Journey.page.styles';

type ParticipantId = { user_id: string; index: number };

const JourneyPage: React.FC = () => {
  const journeyExcludeColumns = ['status_history', 'user', 'type'] as Array<
    keyof Journey
  >;

  const passengerExcludeColumns = ['uncertain'] as Array<keyof Participant>;

  const { communityId, journeyId } = useParams<{
    communityId: string;
    journeyId: string;
  }>();

  const [events, setEvents] = useState<Array<Event>>([]);
  const [journeys, setJourneys] = useState<Array<JourneyDetails>>([]);
  const [passengers, setPassengers] = useState<Array<PassengerTable>>([]);
  const [traxes, setTraxes] = useState<Array<Traxes>>([]);
  const [devices, setDevices] = useState<Array<Device>>([]);
  const [loading, setLoading] = useState(false);
  const [participantsIds, setParticipantsIds] = useState<ParticipantId[]>();
  const [selectedRowsFromLegend, setSelectedRowsFromLegend] =
    useState<number>();

  const [excludePassenger, setExcludePassenger] = useState<
    (PassengerTable & { jid: string }) | undefined
  >();

  const communityName = useCallback(
    () => storageService.getItem(STORAGE_KEYS.SELECTED_COMMUNITY),
    []
  );

  const mapJourneys = useCallback(
    (journeys: Array<JourneyDetails>) =>
      journeys.map(
        ({
          id,
          user_id,
          email,
          vehicle_registration,
          created_at,
          start_time,
          end_time,
          direction,
          estimated_distance,
          participants_count,
          start_point,
          end_point,
          points,
        }) =>
          ({
            pin: <Pin type="driver" />,
            id: id,
            user_id: user_id,
            email: email,
            vehicle_registration: vehicle_registration,
            created_at: dateInUTCFormat(created_at),
            start_time: dateInUTCFormat(start_time),
            end_time: dateInUTCFormat(end_time),
            direction: direction === 'AB' ? 'Home To Work' : 'Work To Home',
            estimated_distance:
              estimated_distance !== undefined
                ? (+estimated_distance / 1000).toFixed(2) + 'km'
                : '-',
            participants_count: participants_count || '-',
            coordinates:
              direction === 'AB'
                ? `(${start_point.coordinates[1]},${start_point.coordinates[0]})`
                : `(${end_point.coordinates[1]},${end_point.coordinates[0]})`,
            points: points || '-',
          } as unknown as JourneyDetails)
      ),
    []
  );

  const mapPassengers = useCallback<
    (passengers: Array<Participant>) => PassengerTable[]
  >(
    (passengers: Array<Participant>) =>
      orderBy(
        passengers.map(
          (
            {
              id,
              user_id,
              email,
              username,
              estimated_distance,
              created_at,
              pocs_time,
              pocs_type,
              start_point,
              end_point,
              points,
              uncertain,
            },
            index
          ) =>
            ({
              pin: <Pin index={++index} />,
              id: (
                <div className="sa-journey__passenger-list__table__tooltip">
                  <span>{id}</span>
                  {uncertain && (
                    <Tooltip
                      placement="top"
                      content="This passenger is excluded from stats and reports"
                    >
                      <span className="sa-journey__passenger-list__table__tooltip">
                        <Icon name="ban" />
                      </span>
                    </Tooltip>
                  )}
                </div>
              ),
              passenger_id: user_id,
              email: email,
              screenName: username,
              estimated_distance: estimated_distance
                ? (+estimated_distance / 1000).toFixed(2) + 'km'
                : '-',
              created_at: dateInUTCFormat(created_at),
              pocs_time: dateInUTCFormat(pocs_time),
              pocs_type: pocs_type || '-',
              start_point: `${start_point.coordinates[1]}, ${start_point.coordinates[0]}`,
              end_point: `${end_point.coordinates[1]}, ${end_point.coordinates[0]}`,
              points: points || '-',
              uncertain,
            } as unknown as PassengerTable)
        ),
        'passenger_id',
        'asc'
      ),
    []
  );

  const mapTraxes = useCallback(
    (traxes: Array<Traxes>) =>
      traxes.map(
        ({ id, user_id, count, created_at }, index) =>
          ({
            pin: (
              <Pin index={index} type={index === 0 ? 'driver' : 'passenger'} />
            ),
            id: id,
            user_id: user_id,
            count: count || '-',
            created_at: dateInUTCFormat(created_at),
          } as unknown as Traxes)
      ),
    []
  );

  const mapEvents = useCallback(
    (events: Array<Event>, participantsIds: ParticipantId[]) =>
      events.map((event) => {
        const participant = participantsIds.find(
          ({ user_id }) => +user_id === event.user_id
        );

        return {
          pin: participant && (
            <Pin
              index={participant?.index}
              type={participant.index ? 'passenger' : 'driver'}
              className={
                participant.index
                  ? `sa-journey__events-list__passenger-pin pin-${participant?.index}`
                  : 'sa-journey__events-list__driver-pin pin-0'
              }
            />
          ),
          id: event.id,
          created_at: dateInUTCFormat(event.created_at),
          user_id: event.user_id,
          organisation_id: event.organisation_id,
          journey_id: event.journey_id,
          transaction_id: event.transaction_id || '-',
          text: event.text,
        } as Event;
      }),
    []
  );

  const mapDevices = useCallback(
    (devices: Array<Device>, participantsIds: ParticipantId[]) =>
      devices.map(
        ({
          device_id,
          created_at,
          user_id,
          type,
          reg_id,
          app_release_number,
          app_build_number,
        }) => {
          const participant = participantsIds.find(
            ({ user_id: userID }) => +userID === user_id
          );

          return {
            pin:
              participant &&
              (participant.index ? (
                <Pin index={participant.index} />
              ) : (
                <img
                  src="/assets/svg/driver-trax-map-pin.svg"
                  alt=""
                  className="sa-journey__events-list__driver-pin pin-0"
                />
              )),
            device_id: device_id,
            created_at: dateInUTCFormat(created_at),
            user_id: user_id,
            type: type,
            reg_id: reg_id,
            app_release_number: app_release_number,
            app_build_number: app_build_number,
            id: +uniqueId(`${user_id}`),
          } as Device;
        }
      ),
    []
  );

  const loadJourneyDetails = useCallback(async () => {
    try {
      setLoading(true);

      const {
        data: {
          data: { devices, events, journey, participants },
        },
      } = await apiJourneys.getJourneyById(journeyId);

      const participantsWithoutDriver = orderBy(
        participants.filter(
          (participant: Participant) => participant.type === 'passenger'
        ),
        'user_id',
        'asc'
      );

      const driver = participants.find(
        (participant: Participant) => participant.type !== 'passenger'
      ) as Participant;

      const participantsIds = uniqBy(participantsWithoutDriver, 'user_id').map(
        ({ user_id }, index) => ({ user_id, index: index + 1 })
      );

      setParticipantsIds(participantsIds);

      const traxes = [
        driver.trax,
        ...participantsWithoutDriver.map((participant) => participant.trax),
      ];

      setTraxes(mapTraxes(traxes));
      setEvents(
        mapEvents(events, [
          { user_id: driver?.user_id || '', index: 0 },
          ...participantsIds,
        ])
      );
      setJourneys(
        mapJourneys([
          {
            ...journey,
            // we wont to show in journey details table data from driver email and vrn
            email: driver?.email || '-',
            vehicle_registration: driver?.user_vrm || '-',
            estimated_distance: driver?.estimated_distance,
            points: driver?.points,
          },
        ])
      );
      setPassengers(mapPassengers(participantsWithoutDriver));

      setDevices(
        mapDevices(devices, [
          { user_id: driver?.user_id || '', index: 0 },
          ...participantsIds,
        ])
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [journeyId, mapTraxes, mapEvents, mapJourneys, mapPassengers, mapDevices]);

  // color eventList table rows by clicking on legend items
  // rows are divided by user_id
  const handleSelectPassengerFromEventListLegend = useCallback(
    (num: number) => {
      const elementList = document.getElementsByClassName(
        'sa-journey__events-list'
      )?.[0];

      const trows = elementList.getElementsByClassName('wcl-table__row');

      for (const row of trows) {
        if (
          row.contains(
            (row as HTMLElement).getElementsByClassName(`pin-${num}`)?.[0]
          )
        ) {
          if (num !== selectedRowsFromLegend) {
            (row as HTMLElement).style.backgroundColor =
              getColor('--ACCENT_1_3');
            setSelectedRowsFromLegend(num);
          } else {
            (row as HTMLElement).style.backgroundColor =
              getColor('--BACKGROUND_1_1');
            setSelectedRowsFromLegend(undefined);
          }
        } else {
          (row as HTMLElement).style.backgroundColor =
            getColor('--BACKGROUND_1_1');
        }
      }
    },
    [selectedRowsFromLegend]
  );

  useEffect(() => {
    if (!journeyId) return;
    loadJourneyDetails();
  }, [journeyId, loadJourneyDetails]);

  const passengerTableActions = useMemo(
    () => (passenger: PassengerTable) => {
      return (
        <TablePageLayout.ActionsContainer>
          <Tooltip
            content="Participant who didn’t verify the journey can’t be excluded"
            disabled={passenger.pocs_type !== '-'}
          >
            <div>
              {passenger.uncertain ? (
                <div className="sa-journey__passenger-list__excluded-label">
                  Excluded from the journey
                </div>
              ) : (
                <Button
                  type="button"
                  variant="delete-ghost"
                  icon={<Icon name="ban" />}
                  onClick={() => {
                    setExcludePassenger({ ...passenger, jid: journeyId });
                  }}
                  disabled={passenger.pocs_type === '-'}
                >
                  Exclude
                </Button>
              )}
            </div>
          </Tooltip>
        </TablePageLayout.ActionsContainer>
      );
    },
    [journeyId]
  );

  // count of passenger that are verified and are not excluded
  const notExcludedPassengers = useMemo(
    () =>
      passengers.filter(
        (passenger) => passenger.pocs_type !== '-' && !passenger.uncertain
      ).length,
    [passengers]
  );

  const onExcludePassenger = useCallback((id: number, reason: string) => {
    setPassengers((old) =>
      old.map((pass) => {
        if (pass.id === id) {
          return {
            ...pass,
            uncertain: { time: dayjs().toString(), reason },
            id: (
              <div className="sa-journey__passenger-list__table__tooltip">
                <span>{id}</span>
                <Tooltip
                  placement="top"
                  content="This passenger is excluded from stats and reports"
                >
                  <span className="sa-journey__passenger-list__table__tooltip">
                    <Icon name="ban" />
                  </span>
                </Tooltip>
              </div>
            ),
          } as unknown as PassengerTable;
        } else return pass;
      })
    );
  }, []);

  return (
    <Styled.JourneyContainer className="sa-journey">
      <Breadcrumbs
        className="sa-journey__breadcrumbs"
        crumbs={
          communityId
            ? [
                { text: 'Communities', href: appUri.COMMUNITIES },
                {
                  text: `Journeys ${communityName()}`,
                  href: appUri.COMMUNITIES_JOURNEYS(communityId),
                },
                { text: `Journey ${journeyId}`, href: '' },
              ]
            : [
                {
                  text: 'Journeys',
                  href: appUri.JOURNEYS,
                },
                {
                  text: `Journey ${journeyId}`,
                  href: '',
                },
              ]
        }
      />
      <div className="sa-journey__actions">
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="sa-journey__journey-header"
        >
          {`Journey ${journeyId}`}
        </Heading>
        <Link
          to={
            communityId
              ? appUri.COMMUNITIES_JOURNEY_MAP(communityId, journeyId)
              : appUri.JOURNEY_MAP(`${journeyId}`)
          }
          icon={<Icon name="map-location-dot" />}
        >
          Map View
        </Link>
      </div>

      <TableData<JourneyDetails>
        tableId="journey-list-table"
        className="sa-journey__journey-list"
        label="Journey Details"
        loading={loading}
        data={journeys}
        translationKeys={journeyTranslationKeys}
        excludeColumns={journeyExcludeColumns}
        emptyPlaceholder="There is no journey data."
        breakAtMaxWidth={1600}
      />
      <TableData<PassengerTable>
        tableId="passenger-list-table"
        className="sa-journey__passenger-list"
        loading={loading}
        label="Passenger List"
        data={passengers}
        translationKeys={passengerTranslationKeys}
        excludeColumns={passengerExcludeColumns}
        emptyPlaceholder="There is no passenger data."
        breakAtMaxWidth={1600}
        tableActions={passengerTableActions}
        expandable
      />
      <TableData<Traxes>
        tableId="traxes-list-table"
        className="sa-journey__traxes-list"
        loading={loading}
        label="Trax List"
        data={traxes}
        translationKeys={traxTranslationKeys}
        emptyPlaceholder="There is no trax data."
        breakAtMaxWidth={1000}
      />
      <TableData<Event>
        tableId="events-list-table"
        className="sa-journey__events-list"
        loading={loading}
        label="Event List"
        data={events}
        translationKeys={eventTranslationKeys}
        emptyPlaceholder="There is no event data."
        breakAtMaxWidth={1200}
        legend={
          <EventTableLegend
            numberOfPassengers={participantsIds?.length}
            onSelectPassenger={handleSelectPassengerFromEventListLegend}
          />
        }
      />
      <TableData<Device>
        tableId="device-list-table"
        className="sa-journey__device-list"
        loading={loading}
        label="Device list"
        data={devices}
        translationKeys={deviceTranslationKeys}
        emptyPlaceholder="There is no device data."
        breakAtMaxWidth={1400}
        excludeColumns={['id']}
      />

      {excludePassenger && (
        <ExcludePassengerModal
          passenger={excludePassenger}
          lastPassenger={notExcludedPassengers === 1}
          onClose={() => {
            setExcludePassenger(undefined);
          }}
          onExclude={onExcludePassenger}
        />
      )}
    </Styled.JourneyContainer>
  );
};

export default JourneyPage;

import { flex, position, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';
import { phablet } from '@faxi/web-css-utilities';
import { elevate_s, theme } from '@faxi/web-component-library';

export const Map = styled.div`
  position: relative;

  > div.kinto-map {
    ${size('100%')};
    ${position('absolute', '0')};
  }
`;

export const MapPage = styled.div`
  display: flex;
  height: 100%;

  ${phablet(css`
    flex-direction: column;
  `)}

  ${Map} {
    height: 100%;
    flex: 1 1 100%;
  }
`;

export const MapPageContainer = styled.div`
  ${size('100%')};
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: ${theme.sizes.SIZE_384} 1fr;
  grid-template-areas: 'breadcrumbs breadcrumbs' 'aside map';
  overflow: auto;

  .kinto-map-page-container {
    &__breadcrumbs {
      padding: ${`${theme.sizes.SIZE_64} ${theme.sizes.SIZE_200}
        ${theme.sizes.SIZE_24}`};
      ${elevate_s};
      z-index: 1;
      grid-area: breadcrumbs;
    }

    &__aside {
      grid-area: aside;
      background-color: var(--BACKGROUND_2_1);
    }
  }

  .map-cluster-pin {
    font-family: ToyotaType;
    color: var(--BACKGROUND_1_1);

    img {
      ${size('100%')};
    }

    > div {
      ${flex('row', 'center', 'center')};

      font-weight: 500;
      height: 100%;

      > span {
        margin-bottom: ${theme.sizes.SIZE_2};
      }
    }
  }
`;

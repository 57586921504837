import { flex, marginChildren } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  ${flex('column', 'center', 'center')};
  width: 100%;
  height: 100%;

  ${marginChildren(`0 0 ${theme.sizes.SIZE_10} 0`)};
`;

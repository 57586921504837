import { validators } from '@faxi/web-form';
import dayjs from 'dayjs';
import specific from 'validation/validators/specific';

const maxLength = (length: number) =>
  validators.general.maxLength(
    length,
    `Must contain maximum ${length} characters`
  );

const maxValue = (maxValue: number) =>
  specific.maxNumber(`Maximum value for this field is ${maxValue}`, maxValue);

const minValue = (minValue: number) =>
  specific.minNumber(`Minimum value for this field is ${minValue}`, minValue);

const required = validators.general.required('This field is required');
const decimalsAllowed = specific.decimalsAllowed('Please input only numbers');
const negativeDecimalsAllowed = specific.negativeDecimalsAllowed(
  'Please input only numbers'
);
const JSONValidation = specific.validateJSON('Please enter valid JSON format');
const numberValidation = specific.integerOnly('Please input only numbers');

const allowOnlyFutureDate =
  (
    msg: string,
    format = 'DD.MM.YYYY',
    measurement: dayjs.UnitType = 'days',
    canBeEqual = false
  ) =>
  (value: string) =>
    canBeEqual
      ? dayjs(value, format).diff(dayjs(), measurement) <= 0
        ? msg
        : undefined
      : dayjs(value, format).diff(dayjs(), measurement) < 0
      ? msg
      : undefined;

export {
  maxLength,
  maxValue,
  minValue,
  required,
  decimalsAllowed,
  negativeDecimalsAllowed,
  JSONValidation,
  numberValidation,
  allowOnlyFutureDate,
};

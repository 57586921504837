import { FC, ReactNode, useState } from 'react';
import CreateCommunityProviderContext from './CreateCommunityProvider.context';

type CreateCommunityProviderProps = {
  children: ReactNode;
};

const CreateCommunityProvider: FC<CreateCommunityProviderProps> = (props) => {
  const { children } = props;

  const [step, setStep] = useState(1);

  return (
    <CreateCommunityProviderContext.Provider value={{ step, setStep }}>
      {children}
    </CreateCommunityProviderContext.Provider>
  );
};

export default CreateCommunityProvider;

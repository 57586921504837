import { AxiosResponse } from 'axios';
import { Analytics, APIResponse } from 'models';
import { JourneyStatusAnalytics } from 'models/Analytics';
import httpClient from '../httpClient';

const getAnalytics = (
  startdate: string,
  enddate: string,
  include_test_users?: number,
  communityId?: string
): Promise<AxiosResponse<Analytics>> =>
  httpClient.get('/analytics', {
    params: {
      startdate,
      enddate,
      include_test_users,
      community: communityId,
    },
  });

const getJourneyAnalytics = (
  startdate: string,
  enddate: string,
  include_test_users?: number,
  communityId?: string,
  mode?: string
) =>
  httpClient.get<APIResponse<JourneyStatusAnalytics>>('/journeys/bystatus', {
    params: {
      startdate,
      enddate,
      include_test_users,
      community: communityId,
      ...(mode && { mode }),
    },
  });

export default { getAnalytics, getJourneyAnalytics };

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Icon,
  Tooltip,
  useCallbackRef,
  useFormButtons,
} from '@faxi/web-component-library';
import {
  Form,
  FormField,
  FormRef,
  useFormRefValues,
  validators,
} from '@faxi/web-form';
import classNames from 'classnames';

import { credentialService } from 'services';
import { PremiumFeature, Price } from 'models';
import { useCallbackAsync } from 'hooks';
import { apiCommunities, apiPlans, apiPlatform } from 'modules';
import { appUri } from 'config';
import { snackBarSuccessMessage } from 'utils';

import { PREMIUM_FEATURES_ICONS } from 'pages/Communities/Communities.page';
import { PREMIUM_FEATURES_TITLES } from 'pages/Communities/components/PremiumFeaturesModal/utils';
import {
  CreatePlanActivation,
  TextareaField,
  InputField,
  CheckboxGroupField,
  RadioGroupField,
  SwitchField,
} from 'components';
import specific from 'validation/validators/specific';

import { FormActions } from 'Global.styles';

const CreatePlan: FC = () => {
  const {
    platform: { id: platformId },
  } = credentialService.user;

  const [form, formRef] = useCallbackRef<FormRef>();

  const history = useHistory();

  const [premiumFeatures, setPremiumFeatures] = useState<PremiumFeature[]>([]);

  const [currency, setCurrency] = useState('');

  const per_community = useFormRefValues(form, 'per_community')?.per_community;
  const per_user = useFormRefValues(form, 'per_user')?.per_user;
  const community_members = useFormRefValues(
    form,
    'community_members'
  )?.community_members;

  const priceValidation =
    (message: string, name: string) => (value: any, fields: any) => {
      const valid = value || fields[name]?.value;
      return valid ? undefined : message;
    };

  const limitValidation = useCallback(
    (message: string) => (value: any, fields: any) => {
      return fields['community_members'].value === 'unlimited' || value
        ? undefined
        : message;
    },
    []
  );

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required('This field is required'),
        validators.general.maxLength(32, 'Maximum length is 32'),
      ],
      description: [
        validators.general.required('This field is required'),
        validators.general.maxLength(255, 'Maximum length is 255'),
      ],
      per_community: [
        priceValidation('At least one price is required', 'per_user'),
        specific.minNumber('Minimum value for this field is 0', 0),
        specific.maxNumber(
          'Maximum value for this field is 99999999',
          99999999
        ),
      ],
      per_user: [
        priceValidation('At least one price is required', 'per_community'),
        specific.minNumber('Minimum value for this field is 0', 0),
        specific.maxNumber(
          'Maximum value for this field is 99999999',
          99999999
        ),
      ],
      users_limit: [
        limitValidation('Limit is required'),
        specific.minNumber('Minimum value for this field is 1', 1),
        specific.maxNumber(
          'Maximum value for this field is 99999999',
          99999999
        ),
      ],
      premium_features: [
        validators.general.requiredArray(
          'At least one premium feature is required'
        ),
      ],
    }),
    [limitValidation]
  );

  const [FormButtons] = useFormButtons({
    submitLabel: 'Create',
    cancelLabel: 'Cancel',
  });

  const [getPlansData] = useCallbackAsync({
    callback: async () => {
      const resolvedPromises = await Promise.all([
        apiCommunities.getPremiumFeatures(),
        apiPlatform.getPlatformbyId(`${platformId}`),
      ]);

      const {
        '0': { data: features },
        '1': {
          data: { data: platforms },
        },
      } = resolvedPromises;

      setPremiumFeatures(features);

      setCurrency(platforms[0].platform_currency);
    },
    spinnerParent: '.kinto-create-plan',
  });

  const premiumFeaturesCheckboxes = useMemo(
    () =>
      premiumFeatures.map(({ name, id }) => ({
        label: PREMIUM_FEATURES_TITLES[name],
        value: id,
        icon: PREMIUM_FEATURES_ICONS[name],
      })),
    [premiumFeatures]
  );

  const initialData = useMemo(
    () => ({
      published: true,
      community_members: 'unlimited',
    }),
    []
  );

  const [handleOnSubmit, loading] = useCallbackAsync({
    spinnerParent: '.kinto-create-plan',
    callback: async (values) => {
      const {
        name,
        description,
        published,
        premium_features,
        per_community,
        per_user,
        users_limit,
        community_members,
      } = values;

      await apiPlans.create({
        name,
        description,
        published,
        premium_features,
        prices: [
          ...(per_community
            ? [{ price: +per_community, type: 'per_community', currency }]
            : []),
          ...(per_user
            ? [{ price: +per_user, type: 'per_user', currency }]
            : []),
        ] as Pick<Price, 'price' | 'type' | 'currency'>[],
        users_limit: community_members === 'custom' ? users_limit : null,
      });

      snackBarSuccessMessage(`Successfully created plan ${name}`);

      history.push(appUri.PLANS);
    },
  });

  useEffect(() => {
    getPlansData();
  }, [getPlansData]);

  return (
    <CreatePlanActivation.CreateContainer className="kinto-create-form">
      <h1>Create a plan</h1>

      <Form onSubmit={handleOnSubmit} initialData={initialData} ref={formRef}>
        <fieldset
          className={classNames(
            'kinto-create-form__fieldset',
            'kinto-create-form__fieldset--plan'
          )}
        >
          <legend>General</legend>
          <FormField
            component={InputField}
            name="name"
            placeholder="Plan name"
            required
            requiredLabel="Required"
            validate={validations.name}
          />

          <FormField
            component={TextareaField}
            name="description"
            placeholder="Description"
            required
            requiredLabel="Required"
            noresize
            validate={validations.description}
          />
        </fieldset>

        <fieldset
          className={classNames(
            'kinto-create-form__fieldset',
            'kinto-create-form__fieldset--price'
          )}
        >
          <legend>
            <span>Price</span>
            <Tooltip
              placement="top"
              content="By adding both prices users can see and choose one of two options. 
            Price per community or price per user"
            >
              <span>
                <Icon name="circle-info" />
              </span>
            </Tooltip>
          </legend>

          <div>
            <FormField
              component={InputField}
              name="per_community"
              placeholder={`Price per community (${currency})`}
              type="number"
              required={!(per_community || per_user)}
              requiredLabel="Conditionally required"
              validate={validations.per_community}
            />

            <FormField
              component={InputField}
              name="per_user"
              placeholder={`Price per user (${currency})`}
              type="number"
              required={!(per_community || per_user)}
              requiredLabel="Conditionally required"
              validate={validations.per_user}
            />
          </div>
        </fieldset>

        <fieldset
          className={classNames(
            'kinto-create-form__fieldset',
            'kinto-create-form__fieldset--community'
          )}
        >
          <legend>Community</legend>

          <div className="kinto-create-form__fieldset__radio-group">
            <FormField
              component={RadioGroupField}
              name="community_members"
              orientation="column"
              options={[
                {
                  value: 'unlimited',
                  label: 'Unlimited members',
                },
                {
                  value: 'custom',
                  label: 'Custom limit',
                },
              ]}
            />

            <div className="kinto-create-form__fieldset__radio-group__input-container">
              <FormField
                component={InputField}
                name="users_limit"
                placeholder="Max. no. members"
                validate={validations.users_limit}
                disabled={community_members === 'unlimited'}
                required={community_members === 'custom'}
                requiredLabel="Conditionally required"
              />
            </div>
          </div>
        </fieldset>

        <FormField
          className={classNames(
            'kinto-create-form__fieldset',
            'kinto-create-form__fieldset--features'
          )}
          legend="Features"
          component={CheckboxGroupField}
          checkboxes={premiumFeaturesCheckboxes}
          name="premium_features"
          variant="chip"
          required
          requiredLabel="Required"
          validate={validations.premium_features}
        />

        <fieldset className="kinto-create-form__fieldset">
          <legend>Visibility</legend>
          <FormField component={SwitchField} name="published" label="Publish" />
        </fieldset>

        <FormActions className="kinto-create-form__actions">
          <FormButtons.Submit
            disabled={!form?.isFormChanged() || !form?.syncFormValid || loading}
          />
          <FormButtons.Cancel onClick={() => history.push(appUri.PLANS)} />
        </FormActions>
      </Form>
    </CreatePlanActivation.CreateContainer>
  );
};

export default CreatePlan;

import { Modal, theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize, phablet } from '@faxi/web-css-utilities';
import { size } from 'lodash';
import styled, { css } from 'styled-components';

export const CommunitySettingsModal = styled(Modal)`
  .community-setting-modal {
    &__journey-distance {
      ${flex('column')}

      &__fields {
        display: grid;
        grid-template-columns: 30% 1fr;
        row-gap: ${theme.sizes.SIZE_20};
        margin-top: ${theme.sizes.SIZE_4};
      }

      &__values {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

        display: flex;
        color: var(--SHADE_1_2);

        > div {
          ${flex('row', 'flex-start', 'center')}
          flex: 0 1 30%;

          i {
            margin-left: ${theme.sizes.SIZE_8};
            margin-top: ${theme.sizes.SIZE_2};
          }
        }
      }

      &__labels {
        color: var(--SHADE_1_1);
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
        font-weight: 600;
      }

      .wcl-radio-group {
        gap: 0;
      }

      .wcl-radio-group__radio-button {
        flex: 0 1 30%;
        justify-content: flex-start;
      }

      &__th {
        color: var(--SHADE_1_2);
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
      }
    }

    &__subtitle {
      ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};
      font-weight: 400;
      margin: 0 0 ${theme.sizes.SIZE_24};
      color: var(--SECONDARY_1_1);
      margin-bottom: ${theme.sizes.SIZE_16};
      margin-top: ${theme.sizes.SIZE_24};
      display: inline-block;
    }

    &__form {
      ${flex('column')};
      ${flexGap(theme.sizes.SIZE_24, 'column')};
      ${size('100%')};

      &__subtitle {
        ${fontSize(theme.sizes.SIZE_16, theme.sizes.SIZE_24)};

        font-weight: 500;
        margin: 0 0 ${theme.sizes.SIZE_24};
        color: var(--SECONDARY_1_1);
      }

      &__fields {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${theme.sizes.SIZE_32};

        ${phablet(css`
          ${flex('column')};
          ${flexGap(theme.sizes.SIZE_12, 'column')};

          gap: unset;
          width: 100%;
        `)}
      }
    }

    &__chip {
      display: inline-block;
      white-space: nowrap;
      font-size: ${theme.fontSizes.FONT_11};
      padding: ${theme.sizes.SIZE_8};
      margin-bottom: ${theme.sizes.SIZE_8};
      background-color: var(--BACKGROUND_2_1);
      border-radius: ${theme.sizes.SIZE_8};
    }
  }

  &.community-setting-modal {
    .wcl-modal {
      width: ${theme.sizes.SIZE_816};

      &__main {
        padding-bottom: ${theme.sizes.SIZE_24};
      }

      .kinto-modal__actions {
        margin-top: 0;
      }
    }
  }
`;

import styled, { createGlobalStyle, css } from 'styled-components';
import { elevate_xxl, theme } from '@faxi/web-component-library';
import {
  flex,
  marginChildren,
  position,
  size,
  fontSize,
  phablet,
} from '@faxi/web-css-utilities';

export const GlobalStyle = createGlobalStyle`
  .wcl-sidebar {
    grid-area: sidebar;

    &__top-container {
      ${flex('column', 'center')};
      width: 100%;
      height: ${theme.sizes.SIZE_56};
      padding: 0 ${theme.sizes.SIZE_12};
      margin: ${theme.sizes.SIZE_4} 0 ${theme.sizes.SIZE_20};

      .wcl-dropdown {
        width: fit-content;

        &__trigger {
          color: var(--PRIMARY_1_1);
    
          & > * .wcl-icon {
            transform: rotate(0deg);
            transition: transform 250ms ease-in-out;
          }

          &--open > * .wcl-icon {
            transform: rotate(180deg);
            transition: transform 250ms ease-in-out;
          }
        }
      }

      &__dropdown {
        padding: ${theme.sizes.SIZE_12} 0;

        &__items {
          ${flex('column')};
        }
      }
    }

    &__bottom-container {
      padding: 0 ${theme.sizes.SIZE_12};
      margin: ${theme.sizes.SIZE_4} 0 ${theme.sizes.SIZE_20};
      margin-right: auto;

      ${phablet(css`
        width: 100%;
      `)};

      hr {
        margin-bottom: ${theme.sizes.SIZE_12};
        background-color: var(--PRIMARY_2_1);
        border-color: var(--PRIMARY_2_1);
      }
    }
  }

  * {
    box-sizing: border-box;
    font-family: ToyotaType;
  }

  /* Move arrow to left side of tooltip contaner */
  .activate-per-community__tooltip {
    .tippy-svg-arrow {
      left: ${theme.sizes.SIZE_20} !important;
      transform: translateX(-50%) !important;
    }
  }

  html, body {
    margin: 0;
    text-size-adjust: none;
    height: 100%;
  }

  .root {
    height: 100%;
    display: grid;

    > &:nth-child(2) {
      grid-area: main;
    }

    &--is-authenticated {
      grid-template-areas: 'sidebar main';
      grid-template-columns: auto 1fr;

      ${phablet(css`
        grid-template-areas: 'sidebar' 'main';
        grid-template-columns: unset;
        grid-template-rows: auto 1fr;
      `)};
    }

    &--extra-element {
      grid-template-areas: 'sidebar view-only-banner' 'sidebar main';
      grid-template-rows: ${theme.sizes.SIZE_40} 1fr;

      ${phablet(css`
        grid-template-areas: 'sidebar' 'view-only-banner' 'main';
        grid-template-columns: unset;
        grid-template-rows: auto auto 1fr;
      `)};
    }
  }

  .community-ekg-page {
    background-color: var(--BACKGROUND_2_1);
    min-height: 100%;

    &__charts-container {
      ${flex('column')};
      gap: ${theme.sizes.SIZE_16};

      &__chart {
        border-radius: ${theme.sizes.SIZE_8};
        padding: ${theme.sizes.SIZE_32};
        background-color: var(--BACKGROUND_1_1);

        &__name {
          margin-bottom: ${theme.sizes.SIZE_16};
          color: var(--SECONDARY_1_1);
          font-weight: 600;
          font-size: ${theme.fontSizes.FONT_18};

          > span {
            color: var(--SHADE_1_2);
            font-weight: 400;
          }
        }

        .wcl-chart-container {
          height: ${theme.sizes.SIZE_184} !important;
        }
      }
    }
  }

  // TODO: not even sure this should be here
  .wcl-overlay[class], .wcl-dropdown__content[class]{
    z-index: 5;
  }

  #snack-bar-container{
    z-index: 6;
  }

  a {
    text-decoration: none;
  }

  .overlay {
    ${size('inherit', '100%')};
    background-color: var(--BACKGROUND_1_2);
    ${position('absolute', '0')}

    &.position-fixed {
      ${position('fixed', '0')}
    }
  }

  .position-start {
    > * {
      justify-content: start;
      padding-top: ${theme.sizes.SIZE_48}
    }
  }

  .position-end {
    > * {
      justify-content: end;
      padding-bottom: ${theme.sizes.SIZE_48}
    }
  }

  .position-relative {
    position:relative;
  }

  .wcl-chart-container {
    margin-top: auto;
  }

  .kinto-map-info-window-container {
    padding: ${theme.sizes.SIZE_16};
    position: fixed;
    bottom: ${theme.sizes.SIZE_56};
    right: ${theme.sizes.SIZE_64};
    z-index: 2;
    min-width: ${theme.sizes.SIZE_456};
    max-width: calc(50% - 64px);
    ${elevate_xxl};
    background-color: var(--BACKGROUND_1_1);
    border-radius: ${theme.sizes.SIZE_8};
  }

  .gm-ui-hover-effect {
    display: none !important;
  }

  .gm-style .gm-style-iw-t::after {
    height: ${theme.sizes.SIZE_12} !important;
    width: ${theme.sizes.SIZE_12} !important;
  }

  .gm-style-iw {
    font-weight: 400 !important;
    font-size: ${theme.sizes.SIZE_16} !important;
    color: var(--PRIMARY_1_1);
    padding: ${theme.sizes.SIZE_12} !important;
  }

  .gm-style-iw-d {
    overflow: unset !important;
  }

  .wcl-modal {
    width: ${theme.sizes.SIZE_712};

    > form {
      ${flex('column')};
      overflow: auto;
    }
  }

  .prompt-modal{
    &.deactivate-reason-modal{
      .prompt-modal__content{
        margin: 0;
        padding: 0;
      }
    }
  }
 
  form {
    .form {
      &__fields {
        ${marginChildren(`0 0 ${theme.sizes.SIZE_32} 0`)};

        .textarea {
          max-width: ${theme.sizes.SIZE_632};

          ${phablet(
            css`
              max-width: unset;
            `
          )};
        }

        .input,
        .password-field,
        .dropdown-field {
          max-width: ${theme.sizes.SIZE_240};

          ${phablet(
            css`
              max-width: unset;
            `
          )};
        }

        .input__container__placeholder {
          font-family: ToyotaTypeSubscript;
        }
      }
    }

    .edit-form-buttons {
      margin-top: ${theme.sizes.SIZE_32};
      justify-content: flex-start;
    }
  }

  .kinto-modal {
    &__user-banner {
      ${flex('row')};

      padding: ${theme.sizes.SIZE_16};
      background-color: var(--BACKGROUND_2_1);
      border-radius: ${theme.sizes.SIZE_8};

      &__image {
        ${size(theme.sizes.SIZE_24)};
        margin-right: ${theme.sizes.SIZE_8};
      }

      &__name {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
        color: var(--SHADE_1_1);
      }
    }

    &__actions{
      margin: 0;
    }

    &__permissions {
      margin-top: ${theme.sizes.SIZE_32};
      margin-bottom: unset;
      list-style-type: none;
      padding: unset;

      &__permission {
        ${flex('row', 'space-between', 'center')};
        padding: ${theme.sizes.SIZE_12} 0;
        border-bottom: ${theme.sizes.SIZE_1} solid var(--SHADE_1_7);

        &__name {
          width: 100%;
        }
      }
    }

    &__fields {
      ${marginChildren(`0 0 ${theme.sizes.SIZE_24} 0`)};
    }

    &__grid-fields {
      display: grid;
      grid-template-rows: repeat(6, auto);
      grid-template-columns: repeat(6, auto);
      gap: ${theme.sizes.SIZE_32};

      &--2-rows {
        grid-template-rows: repeat(2, auto);
      }

      &__radius,
      &__type,
      &__action-type,
      &__lat,
      &__lng {
        grid-column: span 2;
      }

      &__numocs, 
      &__premium, 
      &__eofp, 
      &__pocs  {
        grid-column: span 3;

        &--without-json {
          grid-column: 1 /4;
        }
      }

      &__name,
      &__json {
        grid-column: span 6;

        .textarea__container {
          width: 100%;
        }
      }
    }

    &__subtitle {
      ${fontSize(theme.fontSizes.FONT_16, '23px')};
      font-weight: 600;
      margin: ${theme.sizes.SIZE_16} 0;
      color: var(--SHADE_1_1);

      &__info {
        color: var(--SHADE_1_3);
        ${fontSize(theme.fontSizes.FONT_12, '17px')};
        font-weight: 400;
      }
    }

    &__error-message {
      ${flex('row', 'flex-start', 'center')};
      width: 100%;
      padding: ${theme.sizes.SIZE_16};
      border-radius: ${theme.sizes.SIZE_8};
      color: var(--ALERT_ERROR_1_1);
      background-color: var(--ALERT_ERROR_1_8);

      > .error-text {
        ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

        margin: 0;
        font-weight: 400;
        margin-left: ${theme.sizes.SIZE_12};
      }
    }
  }

  fieldset {
    border: unset;
    padding: unset;
    margin: unset;
    margin-inline: unset;
    padding-inline: unset;

    legend {
      ${fontSize(theme.fontSizes.FONT_16, '23px')};
      color: var(--PRIMARY_1_1);
      font-weight: 400;
      margin-bottom: ${theme.sizes.SIZE_16};
    }
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: .3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeOutUp {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
  }
`;

export const Separator = styled.div`
  min-height: ${theme.sizes.SIZE_1};
  background-color: var(--SHADE_1_8);
  width: 100%;
`;

export const FormActions = styled.div`
  ${flex('row', 'center', 'center')};
  ${marginChildren(`0 ${theme.sizes.SIZE_56} 0 0`)};

  ${(props: { marginTop?: string }) => {
    return `margin-top: ${props.marginTop || theme.sizes.SIZE_48};`;
  }};

  ${phablet(css`
    button {
      width: 100%;
    }
  `)};

  @media (max-width: 320px) {
    margin-top: ${theme.sizes.SIZE_40};
    margin: 0 auto;
    flex-direction: column;

    > :first-child {
      margin-right: 0;
    }

    > :last-child {
      margin-top: ${theme.sizes.SIZE_32};
    }
  }
`;

export const NoData = styled.h4`
  ${fontSize(theme.sizes.SIZE_16, theme.sizes.SIZE_48)};

  margin: 0;
  color: var(--SHADE_1_2);
`;

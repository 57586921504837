import React, { useCallback, useMemo } from 'react';
import {
  Button,
  Heading,
  SortOption,
  StatusElement,
  StatusElementStatus,
  Table,
  getColor,
  useQueryParams,
  useUtilities,
} from '@faxi/web-component-library';
import { Icon, TablePageLayout } from 'components';
import { useHistory } from 'react-router';
import { appUri, COLUMNS_SETTINGS_LABELS } from 'config';
import {
  Activation,
  ActivationBillingPeriod,
  ActivationStatus,
  TableActivation,
} from 'models';
import { useColumnSettings, useTablePagination } from 'hooks';
import { snackBarSuccessMessage } from 'utils';
import { apiActivations } from 'modules';
import { TableAction } from 'components/_layouts/PageLayout.styles';

const MAP_ACTIVATION_STATUS = {
  active: 'active',
  deactivated: 'canceled',
  pending: 'pending',
} as Record<ActivationStatus, StatusElementStatus>;

const MAP_ACTIVATION_STATUS_TRANSLATIONS = {
  active: 'Active',
  deactivated: 'Deactivated',
  pending: 'Pending',
} as Record<ActivationStatus, string>;

const MAP_ACTIVATION_BILLING_PERIOD = {
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  yearly: 'Yearly',
} as Record<ActivationBillingPeriod, string>;

const Activations = () => {
  const history = useHistory();

  const { showOverlay, hideOverlay, prompts } = useUtilities();

  const { setQueryParam } = useQueryParams<{
    page: string;
  }>();

  const translationKeys = useMemo(
    () =>
      ({
        organisation_name: 'Community/client',
        plan_name: 'Plan',
        billing_period: 'Billing period',
        activation_date: 'Activation date',
        status: 'Status',
      } as Record<Partial<keyof TableActivation>, string>),
    []
  );

  const {
    columnBtnRef,
    columnSettingsOpen,
    closeColumnSettings,
    ColumnSettingsButton,
  } = useColumnSettings();

  const mapActivations = useCallback(
    (activations: Activation[]) =>
      activations.map(
        ({ id, organisation, plan, billing_period, activation_date, status }) =>
          ({
            id,
            organisation_name: organisation.name,
            plan_name: plan.name,
            billing_period: MAP_ACTIVATION_BILLING_PERIOD[billing_period],
            activation_date,
            status: (
              <StatusElement status={MAP_ACTIVATION_STATUS[status]}>
                {MAP_ACTIVATION_STATUS_TRANSLATIONS[status]}
              </StatusElement>
            ),
            status_state: status,
          } as TableActivation)
      ),
    []
  );

  const {
    data,
    count,
    loading,
    totalCount,
    totalPages,
    currentPage,
    activeColumnSort,
    setCount,
    setCurrentPage,
    setActiveColumnSort,
    setData: setTableData,
  } = useTablePagination<TableActivation, 'subscriptions'>({
    itemsKey: 'subscriptions',
    spinnerParentClass: '.kinto-page',
    initialParams: {
      sortBy: 'organisation_name',
      sortDirection: 'desc',
    },
    mappingFunction: (values: Activation[]) => mapActivations(values),
    apiRequest: ({ per_page, currentPage, sort_by, sort_direction }) =>
      apiActivations.getActivations({
        page: currentPage,
        per_page,
        sort_by: sort_by as keyof Activation,
        sort_direction,
      }),
  });

  const handleOnColumnSort = useCallback(
    (sortOptions: SortOption<TableActivation>) => {
      setActiveColumnSort(sortOptions);
      setCurrentPage(1);
    },
    [setActiveColumnSort, setCurrentPage]
  );

  const handleDeleteActivation = useCallback(
    async ({ id }: Pick<Activation, 'id'>) => {
      showOverlay('.wcl-table');

      try {
        const { data } = await apiActivations.deleteActivation(id);

        if (data) {
          setTableData((old) => old.filter(({ id: pId }) => pId !== id));
          snackBarSuccessMessage(`Activation successfully deleted`);
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.wcl-table');
      }
    },
    [hideOverlay, setTableData, showOverlay]
  );

  const handleDeactivateActivation = useCallback(
    async ({ id }: Pick<Activation, 'id'>) => {
      showOverlay('.wcl-table');

      try {
        const { data } = await apiActivations.updateActivation(
          id,
          'deactivated'
        );

        if (data) {
          setTableData((old) =>
            old.map((activation) =>
              activation.id === id
                ? ({
                    ...activation,
                    status: (
                      <StatusElement status={'canceled'}>
                        Deactivated
                      </StatusElement>
                    ),
                    status_state: 'deactivated',
                  } as TableActivation)
                : activation
            )
          );
          snackBarSuccessMessage(`Activation successfully deleted`);
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.wcl-table');
      }
    },
    [hideOverlay, setTableData, showOverlay]
  );

  const tableActions = useMemo(
    () =>
      ({
        id,
        organisation_name,
        status_state,
      }: Pick<TableActivation, 'id' | 'organisation_name' | 'status_state'>) =>
        (
          <TablePageLayout.TableActions
            actions={
              [
                {
                  name: 'Delete',
                  icon: 'trash-can',
                  variant: 'delete-ghost',
                  onClick: async (btnElement?: HTMLButtonElement) => {
                    if (
                      await prompts.delete({
                        submitBtnText: 'Delete',
                        cancelBtnText: 'Cancel',
                        title: `Do you really want to delete the activation for ${organisation_name}?`,
                      })
                    ) {
                      handleDeleteActivation({ id });
                    }
                    btnElement?.focus();
                  },
                },
              ].concat(
                status_state === 'active'
                  ? [
                      {
                        name: 'Deactivate',
                        icon: 'ban',
                        variant: 'delete-ghost',
                        onClick: async (btnElement?: HTMLButtonElement) => {
                          if (
                            await prompts.deactivate({
                              submitBtnText: 'Deactivate',
                              cancelBtnText: 'Cancel',
                              title: `Do you really want to deactivate the activation for ${organisation_name}?`,
                              btnIcon: 'ban',
                            })
                          ) {
                            handleDeactivateActivation({ id });
                          }
                          btnElement?.focus();
                        },
                      },
                    ]
                  : []
              ) as TableAction[]
            }
          />
        ),

    [handleDeactivateActivation, handleDeleteActivation, prompts]
  );

  return (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__header"
      >
        Activations
      </Heading>

      <div className="kinto-page__actions">
        <Button
          variant="outline"
          icon={<Icon name="plus" />}
          onClick={() => history.push(appUri.ACTIVATIONS_CREATE)}
        >
          New activation
        </Button>
        {data.length !== 0 && <ColumnSettingsButton />}
      </div>

      {data.length === 0 ? (
        <div className="kinto-page__empty-state">
          No activations created yet
        </div>
      ) : (
        <Table<TableActivation>
          cacheColumns
          tableData={data}
          tableId="activations-table"
          translationKeys={translationKeys}
          columnSettingsOpen={columnSettingsOpen}
          columnsBtnElement={columnBtnRef.current!}
          excludeColumns={['id', 'status_state']}
          expandable
          tableActions={tableActions}
          columnsModalLabels={COLUMNS_SETTINGS_LABELS}
          initialSort={activeColumnSort}
          className="kinto-plans__table"
          paginationData={{
            currentPage,
            limit: count,
            totalCount,
            totalPages,
          }}
          goToPageInputProps={{ placeholder: 'Go to page' }}
          perPagePlaceholder="Per page"
          onPageChange={(value) => {
            setCurrentPage(value);
            setQueryParam('page', value);
          }}
          onLimitChange={(data) => {
            setCurrentPage(1);
            setCount(+data.value);
          }}
          onColumnsModalClose={closeColumnSettings}
          onColumnSortClicked={handleOnColumnSort}
          {...(!loading && {
            noDataPlaceholder: (
              <span className="">No activations created yet</span>
            ),
          })}
        />
      )}
    </TablePageLayout.PageLayoutContainer>
  );
};

export default Activations;

import { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import { useFieldError, FieldProps } from '@faxi/web-form';
import { Input, InputProps } from '@faxi/web-component-library';
import classNames from 'classnames';
import uniqid from 'uniqid';

import Icon, { INameExtended } from '../../Icon';

import * as Styled from './PasswordField.styles';

type ValueOnChange = (event: ChangeEvent<HTMLInputElement>) => void;

export type PasswordFieldProps = FieldProps<string, ValueOnChange> & {
  error?: string;
  className?: string;
  icon?: INameExtended;
} & InputProps;

const PasswordField: FC<PasswordFieldProps> = (
  props: PasswordFieldProps
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, label, dirty, touched, className, icon, id, ...rest } = props;

  const [inputType, setInputType] = useState('password');

  const [capsLockActive, setCapsLockActive] = useState(false);

  const showError = useFieldError(props);

  const actualId = useMemo(() => id || uniqid(), [id]);

  const capsLockChangedOnce = useRef(false);

  return (
    <Styled.Container className={classNames('kinto-password-field', className)}>
      <Input
        id={actualId}
        label={label}
        type={inputType}
        onKeyUp={(e) => {
          if (
            e.key === 'CapsLock' ||
            (!e.getModifierState('CapsLock') && capsLockActive)
          ) {
            capsLockChangedOnce.current = true;
            setCapsLockActive(false);
          }
        }}
        onKeyDown={(e) => {
          if (
            e.key === 'CapsLock' ||
            (e.getModifierState('CapsLock') && !capsLockActive)
          ) {
            setCapsLockActive(true);
          }
        }}
        errorText={String(error)}
        error={showError}
        suffixIcon={
          <Icon
            className="kinto-password-field__eye"
            name={inputType === 'password' ? 'eye-slash' : 'eye'}
            onClick={() => {
              setInputType(inputType === 'password' ? 'text' : 'password');
            }}
          />
        }
        {...rest}
      />

      <div
        className={classNames('kinto-password-field__caps-lock-active', {
          'kinto-password-field__caps-lock-active--exists':
            capsLockChangedOnce.current,
          'kinto-password-field__caps-lock-active--visible': capsLockActive,
        })}
      >
        Caps lock is on
      </div>
    </Styled.Container>
  );
};

export default PasswordField;

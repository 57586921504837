import { Modal as ModalComponent, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Modal = styled(ModalComponent)`
  .wcl-modal {
    max-width: ${theme.sizes.SIZE_504};
  }

  .wcl-modal__main {
    padding: 0 ${theme.sizes.SIZE_24};
  }

  header,
  .wcl-modal__header {
    margin: 0;
    padding: 0;
  }

  h1,
  .wcl-modal__header__title {
    font-size: ${theme.fontSizes.FONT_18};
    color: var(--SHADE_1_1);
    font-weight: 400;
    margin: ${theme.sizes.SIZE_24} 0 ${theme.sizes.SIZE_32};

    span {
      font-weight: 600;
    }
  }

  .textarea__container {
    width: 100%;

    &__textarea {
      min-width: 100%;
      min-height: ${theme.sizes.SIZE_104};

      &:hover {
        transition: unset;
      }
    }
  }

  footer,
  .wcl-modal__footer {
    justify-content: flex-end;

    button {
      &:last-child {
        margin-left: 48px;
      }
    }
  }

  .exclude-passenger-modal {
    &__last-passenger {
      ${flex('row', 'flex-start', 'flex-start')};

      font-size: ${theme.fontSizes.FONT_18};
      font-weight: 600;
      background-color: var(--LABEL_TEXT_1_7);
      padding: ${theme.sizes.SIZE_16};
      border-radius: ${theme.sizes.SIZE_8};
      margin-bottom: ${theme.sizes.SIZE_10};
      margin-top: ${theme.sizes.SIZE_16};

      svg {
        margin: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_8} 0 0;
      }

      p {
        margin: 0;
      }
    }
  }
`;

import { appUri } from 'config';
import { Route } from 'models';
import Activations from './Activations.page';
import CreateActivation from './pages/CreateActivation';

const activationsRoutes: Route[] = [
  {
    component: Activations,
    handle: 'Activations',
    title: 'Activations',
    exact: true,
    path: appUri.ACTIVATIONS,
  },
  {
    component: CreateActivation,
    handle: 'Create activations',
    title: 'Create activations',
    exact: true,
    path: appUri.ACTIVATIONS_CREATE,
  },
];

export default activationsRoutes;

import dayjs from 'dayjs';

const DATE_STRING_REGEX = /\d{4}-\d{2}-\d{2}$/;

export const formatChartData = (
  data: Record<string, number | Record<string, number>>,
  extractDeepProp?: string
) => {
  const activeUsers: Record<string, number> = {};

  Object.entries(data).forEach(([key, value]) => {
    if (!DATE_STRING_REGEX.test(key)) return;
    const date = new Date(key);

    const newKey = `${dayjs(date).format('DD')} ${`${dayjs(date)
      .format('MMM')
      .toLowerCase()}`} '${date.getFullYear().toString().slice(2, 4)}`;

    if (!extractDeepProp) {
      activeUsers[newKey] = value as number;
    } else {
      activeUsers[newKey] = (value as Record<string, number>)[extractDeepProp];
    }
  });

  return activeUsers;
};

import { SVGProps } from './props';

const PermissionsLock: React.FC<SVGProps> = (props: SVGProps) => {
  const { className, onClick } = props;

  return (
    <svg
      onClick={onClick}
      className={className}
      width="154"
      height="151"
      viewBox="0 0 154 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.2833 55.6145H26.7949C24.3105 55.6145 22.2966 57.6285 22.2966 60.1128V75.8571C22.2966 78.3414 24.3105 80.3554 26.7949 80.3554H58.2833C60.7677 80.3554 62.7817 78.3414 62.7817 75.8571V60.1128C62.7817 57.6285 60.7677 55.6145 58.2833 55.6145Z"
        stroke="#00708D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.2932 55.6145V46.6178C31.2932 43.6352 32.4781 40.7748 34.5871 38.6658C36.6961 36.5568 39.5565 35.3719 42.5391 35.3719C45.5217 35.3719 48.3821 36.5568 50.4911 38.6658C52.6001 40.7748 53.785 43.6352 53.785 46.6178V55.6145"
        stroke="#00708D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.231 27.8679V28.4793V45.3549C153.231 45.674 152.987 45.9363 152.676 45.9663L152.617 45.969H83.694C83.3549 45.969 83.0799 45.6939 83.0799 45.3549C83.0799 45.0354 83.3235 44.7732 83.635 44.7434L83.694 44.7405L152.002 44.7403V29.0934H83.694C83.3749 29.0934 83.1126 28.8498 83.0828 28.5384L83.0799 28.4793C83.0799 28.1599 83.3235 27.8976 83.635 27.8679L83.694 27.865L153.231 27.8679Z"
        fill="#00708D"
      />
      <path
        d="M152.616 44.7405C152.936 44.7405 153.198 44.9841 153.228 45.2955L153.231 45.3546V109.48C153.231 109.799 152.987 110.061 152.676 110.091L152.616 110.094H102.046C101.706 110.094 101.431 109.819 101.431 109.48C101.431 109.161 101.675 108.898 101.986 108.868L102.046 108.865H152.002L152.002 45.3546C152.002 45.0354 152.246 44.7729 152.557 44.7431L152.616 44.7405Z"
        fill="#00708D"
      />
      <path
        d="M140.309 33.6409C140.533 33.416 140.889 33.4019 141.131 33.5986L141.177 33.6409L146.409 38.8735C146.649 39.1135 146.649 39.5023 146.409 39.7422C146.185 39.9671 145.829 39.9813 145.588 39.7845L145.541 39.7422L140.309 34.5096C140.069 34.2696 140.069 33.8806 140.309 33.6409Z"
        fill="#00708D"
      />
      <path
        d="M145.541 33.6409C145.781 33.4009 146.17 33.4009 146.409 33.6409C146.634 33.8658 146.648 34.2217 146.452 34.4628L146.409 34.5096L141.177 39.7423C140.937 39.9823 140.548 39.9823 140.308 39.7423C140.084 39.5174 140.07 39.1615 140.266 38.9201L140.308 38.8736L145.541 33.6409Z"
        fill="#00708D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.702 63.0604C133.702 64.5512 132.493 65.7594 131.003 65.7594C129.512 65.7594 128.304 64.5512 128.304 63.0604C128.304 61.5695 129.512 60.361 131.003 60.361C132.493 60.361 133.702 61.5695 133.702 63.0604Z"
        fill="#00708D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.088 63.0604C120.088 64.5512 118.879 65.7594 117.389 65.7594C115.898 65.7594 114.69 64.5512 114.69 63.0604C114.69 61.5695 115.898 60.361 117.389 60.361C118.879 60.361 120.088 61.5695 120.088 63.0604Z"
        fill="#00708D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.474 63.0604C106.474 64.5512 105.265 65.7594 103.775 65.7594C102.284 65.7594 101.076 64.5512 101.076 63.0604C101.076 61.5695 102.284 60.361 103.775 60.361C105.265 60.361 106.474 61.5695 106.474 63.0604Z"
        fill="#00708D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.702 76.4594C133.702 77.9503 132.493 79.1584 131.003 79.1584C129.512 79.1584 128.304 77.9503 128.304 76.4594C128.304 74.9684 129.512 73.7604 131.003 73.7604C132.493 73.7604 133.702 74.9684 133.702 76.4594Z"
        fill="#00708D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.088 76.4594C120.088 77.9503 118.879 79.1584 117.389 79.1584C115.898 79.1584 114.69 77.9503 114.69 76.4594C114.69 74.9684 115.898 73.7604 117.389 73.7604C118.879 73.7604 120.088 74.9684 120.088 76.4594Z"
        fill="#00708D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.474 76.4594C106.474 77.9503 105.265 79.1584 103.775 79.1584C102.284 79.1584 101.076 77.9503 101.076 76.4594C101.076 74.9684 102.284 73.7604 103.775 73.7604C105.265 73.7604 106.474 74.9684 106.474 76.4594Z"
        fill="#00708D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.702 89.8584C133.702 91.3496 132.493 92.5579 131.003 92.5579C129.512 92.5579 128.304 91.3496 128.304 89.8584C128.304 88.3679 129.512 87.1594 131.003 87.1594C132.493 87.1594 133.702 88.3679 133.702 89.8584Z"
        fill="#00708D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.088 89.8584C120.088 91.3496 118.879 92.5579 117.389 92.5579C115.898 92.5579 114.69 91.3496 114.69 89.8584C114.69 88.3679 115.898 87.1594 117.389 87.1594C118.879 87.1594 120.088 88.3679 120.088 89.8584Z"
        fill="#00708D"
      />
      <path
        d="M102.897 135.134C104.009 135.134 104.929 135.462 105.755 136.093C106.346 136.545 106.745 136.986 107.539 137.993L107.786 138.306C109.261 140.153 110.093 140.745 111.709 140.745C111.934 140.745 112.117 140.933 112.117 141.166C112.117 141.398 111.934 141.586 111.709 141.586C110.597 141.586 109.676 141.258 108.85 140.627L108.734 140.537C108.204 140.114 107.807 139.667 107.067 138.728L106.819 138.415C105.345 136.567 104.512 135.975 102.897 135.975C101.281 135.975 100.448 136.567 98.9737 138.415L98.5727 138.922C97.8787 139.793 97.4945 140.206 96.9428 140.627C96.1168 141.258 95.1963 141.586 94.084 141.586C92.9717 141.586 92.0512 141.258 91.2252 140.627L91.1095 140.537C90.5794 140.114 90.1825 139.667 89.4419 138.728L89.3613 138.625C87.7808 136.612 86.9476 135.975 85.2723 135.975C83.6271 135.975 82.7939 136.59 81.2674 138.518L80.949 138.922C80.2549 139.793 79.871 140.206 79.3193 140.627C78.4933 141.258 77.573 141.586 76.4607 141.586C75.3484 141.586 74.4279 141.258 73.6021 140.627L73.4862 140.537C72.9561 140.114 72.5594 139.667 71.8186 138.728L71.654 138.518L71.4901 138.313C70.0658 136.546 69.2346 135.975 67.6495 135.975C67.4242 135.975 67.2416 135.787 67.2416 135.555C67.2416 135.322 67.4242 135.134 67.6495 135.134C68.7616 135.134 69.6819 135.462 70.5079 136.093L70.6238 136.183C71.1349 136.591 71.522 137.021 72.2131 137.894L72.3718 138.095C73.9522 140.108 74.7857 140.745 76.4607 140.745C78.076 140.745 78.9084 140.153 80.3829 138.306L80.8645 137.698C81.5083 136.895 81.8837 136.498 82.4135 136.093C83.2395 135.462 84.1601 135.134 85.2723 135.134C86.3846 135.134 87.3052 135.462 88.1312 136.093C88.7222 136.545 89.1208 136.986 89.9144 137.993L90.1616 138.306C91.6363 140.153 92.4687 140.745 94.084 140.745C95.6995 140.745 96.532 140.153 98.0066 138.306L98.4808 137.707C99.1292 136.898 99.5055 136.5 100.038 136.093C100.864 135.462 101.784 135.134 102.897 135.134Z"
        fill="#00708D"
      />
      <path
        d="M102.896 144.519C104.009 144.519 104.929 144.848 105.755 145.479L105.871 145.569C106.345 145.947 106.712 146.343 107.312 147.093L107.786 147.691C109.261 149.538 110.093 150.13 111.709 150.13C111.934 150.13 112.117 150.319 112.117 150.551C112.117 150.784 111.934 150.972 111.709 150.972C110.597 150.972 109.676 150.644 108.85 150.013L108.734 149.922C108.204 149.5 107.807 149.053 107.066 148.113L106.986 148.011C105.405 145.998 104.572 145.361 102.896 145.361C101.251 145.361 100.418 145.975 98.891 147.904L98.5725 148.307C97.8785 149.179 97.4943 149.591 96.9426 150.013C96.1166 150.644 95.1961 150.972 94.0838 150.972C92.9715 150.972 92.051 150.644 91.225 150.013L91.1093 149.922C90.5792 149.5 90.1823 149.053 89.4417 148.113L89.3611 148.011C87.7806 145.998 86.9474 145.361 85.2722 145.361C83.6269 145.361 82.7937 145.975 81.2672 147.904L80.9488 148.307C80.2548 149.179 79.8708 149.591 79.3191 150.013C78.4931 150.644 77.5728 150.972 76.4605 150.972C75.3482 150.972 74.4277 150.644 73.6019 150.013L73.486 149.922C72.9559 149.5 72.5592 149.053 71.8184 148.113L71.6538 147.904L71.4899 147.699C70.0657 145.931 69.2344 145.361 67.6494 145.361C67.424 145.361 67.2414 145.173 67.2414 144.94C67.2414 144.708 67.424 144.519 67.6494 144.519C68.7614 144.519 69.6817 144.848 70.5077 145.479L70.6236 145.569C71.1347 145.977 71.5218 146.407 72.213 147.279L72.3716 147.481C73.9521 149.494 74.7855 150.13 76.4605 150.13C78.0758 150.13 78.9083 149.538 80.3827 147.691L80.8643 147.083C81.5082 146.28 81.8835 145.884 82.4133 145.479C83.2393 144.848 84.1599 144.519 85.2722 144.519C86.3844 144.519 87.305 144.848 88.131 145.479L88.2467 145.569C88.7768 145.992 89.1734 146.439 89.9142 147.378L90.1614 147.691C91.6361 149.538 92.4685 150.13 94.0838 150.13C95.6993 150.13 96.5318 149.538 98.0064 147.691L98.4806 147.093C99.1291 146.283 99.5053 145.885 100.037 145.479C100.863 144.848 101.784 144.519 102.896 144.519Z"
        fill="#00708D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.3208 0.675768H6.04822C3.49033 0.675768 1.4167 2.74939 1.4167 5.30729V124.815C1.4167 127.373 3.49033 129.446 6.04822 129.446H77.3208C79.8787 129.446 81.9524 127.373 81.9524 124.815V5.30729C81.9524 2.74939 79.8787 0.675768 77.3208 0.675768ZM6.04822 0.027832C3.13252 0.027832 0.768768 2.39159 0.768768 5.30729V124.815C0.768768 127.731 3.13252 130.094 6.04822 130.094H77.3208C80.2365 130.094 82.6003 127.731 82.6003 124.815V5.30729C82.6003 2.39159 80.2365 0.027832 77.3208 0.027832H6.04822Z"
        fill="#00708D"
      />
      <path
        d="M46.5501 6.68884C46.7725 6.68884 46.9525 6.84555 46.9525 7.03849C46.9525 7.22039 46.7929 7.36966 46.589 7.3867L46.5501 7.38813H36.1457C35.9235 7.38813 35.7433 7.23167 35.7433 7.03849C35.7433 6.85683 35.9029 6.70757 36.1071 6.69053L36.1457 6.68884H46.5501Z"
        fill="#00708D"
      />
      <path
        d="M46.5501 123.211C46.7725 123.211 46.9525 123.368 46.9525 123.561C46.9525 123.743 46.7929 123.892 46.589 123.909L46.5501 123.911H36.1457C35.9235 123.911 35.7433 123.754 35.7433 123.561C35.7433 123.379 35.9029 123.23 36.1071 123.213L36.1457 123.211H46.5501Z"
        fill="#00708D"
      />
    </svg>
  );
};

export default PermissionsLock;

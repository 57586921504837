import { elevate_l, elevate_xl, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import { phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const MapActions = styled.div`
  ${flex('column', 'flex-start', 'flex-start')};
  padding: ${theme.sizes.SIZE_24};
  border-radius: ${theme.sizes.SIZE_8};

  .sa-journey-map-actions {
    &__title {
      ${fontSize('28px', '30px')};

      margin: 0 0 24px;
    }

    &__hr {
      width: 100%;
      border: 1px solid var(--BACKGROUND_3_1);
      outline: none;
      margin-top: ${theme.sizes.SIZE_24};
      margin: 24px 0;
    }

    &__container {
      ${size('100%', 'auto')};

      overflow-y: auto;
      max-height: ${theme.sizes.SIZE_400};

      > :not(:last-child) {
        margin-bottom: ${theme.sizes.SIZE_8};
      }
    }

    &__action {
      ${flex('row', 'flex-start')};
      ${size('100%', 'auto')};

      border-radius: ${theme.sizes.SIZE_8};
      padding: ${theme.sizes.SIZE_16};
      border: 2px solid var(--BACKGROUND_3_1);
      background-color: var(--BACKGROUND_1_1);

      .button--right {
        min-width: ${theme.sizes.SIZE_72};
        place-content: end;
      }

      &--active {
        border: 2px solid var(--ACCENT_1_1);
      }

      > span {
        ${size('1px', '25px')};

        background-color: var(--BACKGROUND_3_1);
        margin-right: ${theme.sizes.SIZE_16};
      }

      &__name {
        ${flex('row', 'flex-start', 'center')};
        ${fontSize(theme.sizes.SIZE_16, theme.sizes.SIZE_24)};

        margin: 0px;
        margin-right: auto;
        font-weight: 400;
        color: var(--SHADE_1_2);
        text-transform: capitalize;
        cursor: pointer;
      }
    }

    &__journey-details {
      ${elevate_l};
      ${size('100%', 'auto')};
      ${flex('row', 'flex-start', 'center')};
      cursor: pointer;

      padding: ${theme.sizes.SIZE_16};
      border-radius: ${theme.sizes.SIZE_8};
      background-color: var(--BACKGROUND_1_1);
      color: var(--ACCENT_1_1);

      > span {
        ${fontSize(theme.sizes.SIZE_16, theme.sizes.SIZE_24)};

        margin: 0 0 0 14px;
        color: var(--SHADE_1_2);
      }
    }
  }

  ${phablet(css`
    ${elevate_xl};

    top: ${theme.sizes.SIZE_80};
    left: 0;
    border: unset !important;
    border-radius: unset;
    width: 100%;

    .sa-journey-map-actions {
      &__title {
        ${flex('row', 'space-between')};

        margin: 0px;

        .button__icon {
          transform: rotate(180deg);
          transition: transform 150ms ease-in;
        }

        &--open {
          .button__icon {
            transform: rotate(0deg);
          }
        }
      }

      &__container {
        padding-top: ${theme.sizes.SIZE_24};
      }

      &__expander {
        header,
        main {
          width: 100%;
        }

        .expander__trigger {
          margin: 0;
        }
      }
    }
  `)}
`;

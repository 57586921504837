import { Fragment } from 'react';
import { renderRoutes } from 'react-router-config';

import { Route } from 'models';

const RoutesRenderer: React.FC<{ route: Route }> = (props) => {
  const { route } = props;

  return <Fragment>{route && renderRoutes(route.routes)}</Fragment>;
};

export default RoutesRenderer;

export const MAP_MARKER_TYPE = {
  DRIVER_END: 'driver-end',
  DRIVER_START: 'driver-start',
  PASSENGER_PICK_UP: 'passenger-pick-up',
  PASSENGER_DROP_OFF: 'passenger-drop-off',
  DRIVER_TRAX: 'driver-trax',
  PASSENGER_TRAX: 'passenger-trax',
} as const;

export type MapMarkerType =
  (typeof MAP_MARKER_TYPE)[keyof typeof MAP_MARKER_TYPE];

export type MapMarker = {
  userId?: string;
  lat: string;
  lng: string;
  type: MapMarkerType;
  index?: number;
  passengerIndex?: number;
};

import { useCallback, useContext, useRef } from 'react';
import classNames from 'classnames';
import {
  Button,
  getColor,
  Heading,
  Stepper,
  TableRef,
  useCallbackRef,
} from '@faxi/web-component-library';
import { Form, FormRef, useFormRefValues } from '@faxi/web-form';
import { useHistory } from 'react-router';
import { AxiosError } from 'axios';

import CommunityDetails from './components/CommunityDetails';
import CreateCommunityProvider from './components/CreateCommunityProvider';
import CreateCommunityProviderContext from './components/CreateCommunityProvider/CreateCommunityProvider.context';
import AddAdminRole from './components/AddAdminRole';

import { apiCommunities } from 'modules';
import { CreateCommunityData } from 'models';
import { Icon } from 'components';
import { appUri } from 'config';
import { snackBarSuccessMessage } from 'utils';

import * as Styled from './CreateCommunity.page.styles';

const initialData = { type: 'Group' };

const CreateCommunity = () => {
  const [form, formRef] = useCallbackRef<FormRef>();

  const [usersTable, usersTableRef] = useCallbackRef<TableRef>();

  const { step, setStep } = useContext(CreateCommunityProviderContext);

  // community names that are already checked, to avoid double checking for those one
  const checkedCommunityName = useRef<Set<string>>(new Set<string>());

  const history = useHistory();

  const communityName = useFormRefValues(form, 'name')?.name;

  const handleOnSubmit = useCallback(
    async (values: any) => {
      const communityData: CreateCommunityData = {
        ...values,
        ...values.address,
        address: values.address.formatted_address,
        admins: usersTable?.checkedRows,
      };

      try {
        const res = await apiCommunities.createCommunity(communityData);

        if (res) {
          history.push(appUri.COMMUNITIES);
          snackBarSuccessMessage(`${values.name} successfully created`);
          checkedCommunityName.current = new Set();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [history, usersTable?.checkedRows]
  );

  const checkCommunityName = useCallback(async () => {
    // to avoid checking already checked name
    if (checkedCommunityName.current.has(communityName)) {
      setStep(2);
      return;
    }

    try {
      const { data } = await apiCommunities.checkCommunityNameUnique(
        communityName
      );

      if (data) {
        checkedCommunityName.current.add(communityName);
        setStep(2);
      }
    } catch (e) {
      // be throw error 422 when community name is already taken
      if ((e as AxiosError)?.response?.status === 422) {
        form.setFieldError(
          'name',
          'The community name is already taken or is not valid.'
        );
      }
    }
  }, [communityName, form, setStep]);

  return (
    <Styled.CreateCommunityPageStyled>
      <div className="create-community__header">
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="create-community__header__title"
        >
          Create community
        </Heading>

        <Button
          icon={<Icon name="times" />}
          iconPosition="right"
          variant="ghost"
          onClick={() => {
            history.push(appUri.COMMUNITIES);
          }}
        >
          Close
        </Button>
      </div>

      <Stepper
        className={classNames('create-community__stepper', {
          'create-community__stepper__community-details': step === 1,
          'create-community__stepper__add-admin-role': step === 2,
        })}
        steps={[{ name: 'Community details' }, { name: 'Add an admin role' }]}
        currentStep={step}
      />

      <Form
        ref={formRef}
        onSubmit={handleOnSubmit}
        initialData={initialData}
        strictValidation={false}
        className="kinto-modal__form"
      >
        <div className={classNames({ 'create-community__hide': step === 2 })}>
          <CommunityDetails />
          <Button
            type="button"
            onClick={checkCommunityName}
            className="create-community__next-step-btn"
            disabled={!form?.syncFormValid || !form?.asyncFormValid}
          >
            Next step
          </Button>
        </div>

        <div className={classNames({ 'create-community__hide': step === 1 })}>
          <AddAdminRole tableRef={usersTableRef} />

          <Button type="submit" className="create-community__create-btn">
            Create
          </Button>
        </div>
      </Form>
    </Styled.CreateCommunityPageStyled>
  );
};

const CreateCommunityPage = () => {
  return (
    <CreateCommunityProvider>
      <CreateCommunity />
    </CreateCommunityProvider>
  );
};

export default CreateCommunityPage;

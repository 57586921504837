import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const AddAdminRoleStyled = styled.div`
  .add-admin-role {
    &__back-btn {
      margin-bottom: ${theme.sizes.SIZE_16};
      margin-left: ${theme.sizes.SIZE_12};
    }

    &__search {
      width: 240px;
      margin-bottom: ${theme.sizes.SIZE_20};
    }

    &__empty-state {
      height: 100%;
      color: var(--SHADE_1_3);
    }
  }
`;

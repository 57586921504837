import { JOURNEY_STATUS } from './Journey';

type ExpectedSizeGraphData = {
  id_organisation: number;
  organisation_count: number;
  organisation_expected_size: number;
};

export type Analytics = {
  active_communities: Record<string, number>;
  active_communities_test?: Record<string, number>;

  active_communities_total: number;
  active_communities_total_test?: number;

  active_users: Record<string, number>;
  active_users_test?: Record<string, number>;

  active_users_total: number;
  active_users_total_test?: number;

  expected_size: ExpectedSizeGraphData[];

  carparks: number;
  carpark_spaces: number;

  shifts: number;
  shift_users: number;

  shifts_test: number;
  shift_users_test: number;

  carparks_test: number;
  carpark_spaces_test: number;

  number_of_journeys: Record<string, number>;

  carpooling_journeys: Record<string, number>;
  carpooling_journeys_test: Record<string, number>;
  carpooling_journeys_total: number;
  carpooling_journeys_total_test: number;

  walking_journeys: Record<string, number>;
  walking_journeys_test: Record<string, number>;
  walking_journeys_total: number;
  walking_journeys_total_test: number;

  cycling_journeys: Record<string, number>;
  cycling_journeys_test: Record<string, number>;
  cycling_journeys_total: number;
  cycling_journeys_total_test: number;

  emission_stats: Record<
    string,
    {
      co2: number;
      co2_cumulative: number;
      nox: number;
      nox_cumulative: number;
      'co2_cumulative-test'?: number;
      'co2-test'?: number;
      'nox-test'?: number;
      'nox_cumulative-test'?: number;
    }
  >;

  booked_journeys: Record<
    string,
    {
      participants: number;
      total_bookings: number;
    }
  > & {
    total_bookings: number;
    total_participants: number;
  };

  number_of_journeys_test?: Record<string, number>;
  number_of_journeys_total: number;
  number_of_journeys_test_total?: number;

  registered_communities: Record<string, number>;
  registered_communities_test?: Record<string, number>;

  registered_communities_total: number;
  registered_communities_total_test?: number;

  registered_users: Record<string, number>;
  registered_users_test?: Record<string, number>;
  registered_users_total: number;
  registered_users_test_total?: number;

  rewards: Record<string, number>;
  rewards_total: number;

  campaigns: Record<string, number>;
  campaigns_total: number;

  total_communities: number;
  total_communities_test: number;

  total_users: number;
  total_users_test: number;
};

export const JOURNEY_STATUS_KEYS = {
  [JOURNEY_STATUS.FINISHED]: 'finished',
  [JOURNEY_STATUS.ABORTED]: 'aborted',
  [JOURNEY_STATUS.FINISHED_NO_PASSENGER]: 'finished_no_passenger',
} as const;

export type JourneyStatusKey =
  typeof JOURNEY_STATUS_KEYS[keyof typeof JOURNEY_STATUS_KEYS];

export type JourneyStatusAnalytics = Record<
  string,
  Record<JourneyStatusKey, number>
> & {
  aborted_total: number;
  finished_no_passenger_total: number;
  finished_total: number;
  aborted_total_test: number;
  finished_no_passenger_total_test: number;
  finished_total_test: number;
};

import { theme } from '@faxi/web-component-library';
import { flex, size, phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_64};
  ${flex('column', 'flex-start', 'stretch')};
  overflow: auto;

  .team-management {
    &__title {
      color: var(--PRIMARY_1_1);
      font-weight: 600;
      font-size: ${theme.fontSizes.FONT_34};
      margin: unset;
      margin-bottom: ${theme.sizes.SIZE_46};
    }

    &__actions {
      ${flex('row', 'space-between', 'center')};
      margin: 0 0 ${theme.sizes.SIZE_32};

      &__button {
        ${flex('row', 'flex-start', 'center')};

        > button {
          margin-right: ${theme.sizes.SIZE_20};
        }

        &__info {
          ${flex('row', 'flex-start', 'flex-start')};

          > .wcl-icon {
            margin-top: 3px;
            margin-right: ${theme.sizes.SIZE_10};
            font-size: 18px;
          }

          > div {
            max-width: ${theme.sizes.SIZE_320};
            color: var(--SHADE_1_2);
            font-size: ${theme.fontSizes.FONT_12};
          }
        }
      }

      &__search {
        width: ${theme.sizes.SIZE_240};
      }

      ${phablet(css`
        flex-direction: column;
        margin: 0px;

        &__search {
          margin-top: ${theme.sizes.SIZE_12};
        }

        > * {
          width: 100%;
        }
      `)};
    }

    &__no-admins {
      margin-top: ${theme.sizes.SIZE_64};
      color: var(--SHADE_1_3);
      font-size: ${theme.fontSizes.FONT_16};
    }

    &__admins-table {
      margin-bottom: ${theme.sizes.SIZE_24};

      &__title {
        font-size: ${theme.fontSizes.FONT_18};
        font-weight: 600;
        margin-bottom: ${theme.sizes.SIZE_12};
        color: var(--SECONDARY_1_1);
      }
    }

    &__progress {
      ${flex('row', 'space-between', 'center')};
      width: 100%;
      margin-bottom: ${theme.sizes.SIZE_32};

      .kinto-stepper {
        width: ${theme.sizes.SIZE_600};
      }
    }

    &__select-user {
      position: relative;

      &__empty-state {
        color: var(--SHADE_1_3);
      }

      &__search {
        width: ${theme.sizes.SIZE_240};
        margin: 0 0 ${theme.sizes.SIZE_24};
      }

      &__commands {
        ${flex('row', 'space-between', 'center')};

        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    &__add-admin-role {
      ${flex('column')};

      &__label {
        font-size: ${theme.fontSizes.FONT_14};
        color: var(--SHADE_1_3);

        span {
          color: var(--SHADE_1_1);
          font-weight: 600;
        }
      }

      &__selected-users {
        margin-top: ${theme.sizes.SIZE_20};

        &__user {
          ${flex('row', 'flex-start', 'center')};
          height: ${theme.sizes.SIZE_48};
          width: 100%;
          color: var(--SHADE_1_1);
          font-size: ${theme.fontSizes.FONT_14};
          background-color: var(--BACKGROUND_2_1);
          padding: 0 ${theme.sizes.SIZE_12};
          border-bottom: 1px solid var(--SHADE_1_7);

          > div {
            ${flex('row', 'flex-start', 'center')};
            flex: 0 0 33%;
          }

          > button {
            margin-left: auto;
          }

          img {
            ${size(theme.sizes.SIZE_24)};
            margin-right: ${theme.sizes.SIZE_8};
          }
        }
      }

      &__actions {
        ${flex('row', 'space-between', 'center')};
        margin-top: ${theme.sizes.SIZE_64};
      }
    }
  }

  ${phablet(css`
    padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_12};

    .team-management__admins-table {
      margin-bottom: unset;
    }
  `)};
`;

import {
  Fragment,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  SelectOption,
  SortOption,
  SORT_OPTIONS,
  Table,
  TableRef,
  useUtilities,
  useEffectSkipFirst,
  useCallbackRef,
  Heading,
  useQueryParams,
  Stepper,
  getColor,
} from '@faxi/web-component-library';
import { Prompt, useLocation } from 'react-router';
import dayjs from 'dayjs';
import { debounce, uniqBy } from 'lodash';
import { AxiosError } from 'axios';

import {
  Icon,
  InputField,
  TablePageLayout,
  PermissionsModal,
  NoPermissionsPlaceholder,
} from 'components';
import { useSearch } from 'hooks';
import apiUsers from 'modules/router/api/apiUsers';
import {
  findDiffBetweenQParamsObjects,
  snackBarErrorMessage,
  snackBarSuccessMessage,
} from 'utils';
import { Admin } from 'models/Admin';
import { Permission, TableAdmin, TableUser, User } from 'models';
import AuthContext from 'store/AuthProvider/Auth.context';
import { dateFormat } from 'config';
import { TableAction } from 'components/_layouts/PageLayout.styles';

import * as Styled from './TeamManagement.styles';

type TeamManagementStep = 'select-user' | 'add-admin-role' | undefined;

const whiltelistedQParams = ['search'];
const DEFAULT_LIMIT_PAGINATON = 10;

const TeamManagement: React.FC = (): JSX.Element => {
  const {
    params: { step, search },
    setQueryParam,
    removeQueryParam,
  } = useQueryParams<{ step: TeamManagementStep; search: string }>();

  const location = useLocation();
  const editBtnRef = useRef<HTMLButtonElement>(null);

  const { showOverlay, hideOverlay, prompt } = useUtilities();

  const { admin } = useContext(AuthContext);

  const [admins, setAdmins] = useState<Array<Admin>>([]);
  const [adminsNumberOfPages, setAdminsNumberOfPages] = useState<number>(0);
  const [adminsTotalCount, setAdminsTotalCount] = useState<number>(0);
  const [adminsCurrentPage, setAdminsCurrentPage] = useState(1);
  const [adminsItemPerPage, setAdminsItemPerPage] = useState<number>(10);
  const [debouncedAdminsSearch, setDebouncedAdminsSearch] = useState(
    search || ''
  );
  const [users, setUsers] = useState<User[]>();
  const usersCached = useRef<TableUser[]>([]);
  const [usersNumberOfPages, setUsersNumberOfPages] = useState<number>(0);
  const [usersTotalCount, setUsersTotalCount] = useState<number>(0);
  const [usersCurrentPage, setUsersCurrentPage] = useState(1);
  const [debouncedUsersSearch, setDebouncedUsersSearch] = useState(
    search || ''
  );
  const [deleteSearch, setDeleteSearch] = useState(false);

  const [activeColumnSortAdmins, setActiveColumnSortAdmins] = useState<
    SortOption<TableAdmin>
  >({ sortBy: 'id', sortDirection: SORT_OPTIONS.DSC });

  const [activeColumnSortUsers, setActiveColumnSortUsers] = useState<
    SortOption<TableUser>
  >({ sortBy: 'id', sortDirection: SORT_OPTIONS.DSC });

  const [requestError, setRequestError] = useState(false);

  const [selectedAdmin, setSelectedAdmin] =
    useState<Pick<TableAdmin, 'name' | 'permissions' | 'id'>>();
  const [roleModalOpen, setRoleModalOpen] = useState<boolean>(false);

  const [allPermissions, setAllPermissions] = useState<Permission[]>();

  const [futureAdminsPermissions, setFutureAdminsPermissions] =
    useState<Record<string, string[]>>();

  const checkedRows = useRef<number[]>();
  const checkedCheckedRows = useRef(false);

  const oldAdminsSearch = useRef(debouncedAdminsSearch);
  const oldUsersSearch = useRef(debouncedUsersSearch);

  const [adminsSearch, setAdminsSearch] = useSearch('search');
  const [usersSearch, setUsersSearch] = useSearch('search');

  const [adminsTable, adminsTableRef] = useCallbackRef<TableRef>();
  const [usersTable, usersTableRef] = useCallbackRef<TableRef>();

  // MAP PAGE: CHECKED ARRAY
  const [promoteUsersToAdmins, setPromoteUsersToAdmins] = useState(false);

  const canEdit = useMemo(
    () => !!admin?.permissions.find((p) => p.name === 'team_management_edit'),
    [admin]
  );

  const fetchAdmins = useCallback(async () => {
    showOverlay('body');

    try {
      const {
        data,
        meta: { last_page, total },
      } = await apiUsers.getAdmins(
        adminsCurrentPage,
        debouncedAdminsSearch,
        adminsItemPerPage,
        activeColumnSortAdmins.sortBy,
        activeColumnSortAdmins.sortDirection
      );
      setAdmins(data.users);
      setAdminsTotalCount(total);
      setAdminsNumberOfPages(last_page);
    } catch (e) {
      console.error(e);
      setRequestError(true);
    } finally {
      hideOverlay('body');
    }
  }, [
    adminsCurrentPage,
    adminsItemPerPage,
    debouncedAdminsSearch,
    activeColumnSortAdmins.sortBy,
    activeColumnSortAdmins.sortDirection,
    showOverlay,
    hideOverlay,
  ]);

  const handleRevokeAdmin = useCallback(
    async ({ id, name }: TableAdmin) => {
      if (!canEdit) return;

      if (
        await prompt({
          type: 'standard',
          submitBtnText: 'Revoke',
          submitBtnVariant: 'outline',
          cancelBtnText: 'Cancel',
          btnIcon: 'arrow-rotate-right',
          title: `Do you really want to revoke super admin role for ${name}?`,
        })
      ) {
        try {
          showOverlay('body');
          await apiUsers.promoteUsersToAdmins(
            [{ permissions: [], user_id: +id }],
            'user'
          );
          snackBarSuccessMessage('Admin role successfully revoked');
          adminsTable?.deselectAll();
          fetchAdmins();
        } catch (e) {
          console.error(e);
          snackBarErrorMessage(e as AxiosError);
        } finally {
          hideOverlay('body');
        }
      }
    },
    [adminsTable, canEdit, fetchAdmins, hideOverlay, prompt, showOverlay]
  );

  const adminTableActions = useMemo(
    () => (tableAdmin: TableAdmin) =>
      (
        <TablePageLayout.TableActions
          actions={(
            [
              // {
              //   name: 'View details',
              //   icon: 'chevron-right',
              //   iconPosition: 'right',
              //   onClick: () => {
              //     if (adminsTable && adminsTable.activeRow !== -1) {
              //       history.push(
              //         appUri.SETTINGS_TEAM_MANAGEMENT_ADMIN(`${tableAdmin.id}`)
              //       );
              //     }
              //   },
              // } as TableAction,
            ] as TableAction[]
          ).concat(
            admin?.id !== +tableAdmin.id && canEdit
              ? ([
                  {
                    name: 'Edit permissions',
                    icon: 'user-lock',
                    onClick: (el: HTMLButtonElement) => {
                      setSelectedAdmin(tableAdmin);
                      setRoleModalOpen(true);
                      (
                        editBtnRef as MutableRefObject<HTMLButtonElement>
                      ).current = el;
                    },
                  },
                  {
                    name: 'Revoke Super admin',
                    icon: 'arrow-rotate-right',
                    iconPosition: 'left',
                    onClick: async (el: HTMLButtonElement) => {
                      await handleRevokeAdmin(tableAdmin);
                      el.focus();
                    },
                  },
                ] as TableAction[])
              : []
          )}
        />
      ),
    [admin?.id, canEdit, handleRevokeAdmin]
  );

  const adminTranslationKeys = useMemo(
    () =>
      ({
        id: 'ID',
        name: 'Name',
        email: 'Email',
        platform: 'Market access',
        upgradedBy: 'Upgraded by',
      } as Record<keyof TableAdmin, string>),
    []
  );

  const usersTranslationKeys = useMemo(
    () =>
      ({
        id: 'ID',
        name: 'Name',
        email: 'Email',
        created_at: 'Joined',
        lastaccess_at: 'Last active',
      } as Record<keyof User | 'name' | 'statusData', string>),
    []
  );

  const tableAdmins = useMemo(
    () =>
      admins?.map(
        ({
          id,
          firstname,
          lastname,
          email,
          platform,
          upgraded_by,
          permissions,
        }) =>
          ({
            id,
            name: `${firstname} ${lastname}`.trim() || '-',
            email,
            platform: platform.name,
            upgradedBy: upgraded_by
              ? `${upgraded_by?.firstname} ${upgraded_by?.lastname}`.trim()
              : '-',
            permissions,
          } as TableAdmin)
      ) as Array<TableAdmin>,

    [admins]
  );

  const transformUserForTable = useCallback(
    ({ id, firstname, lastname, email, lastaccess_at, created_at }: User) =>
      ({
        id,
        name: `${firstname} ${lastname}`.trim() || '-',
        email,
        lastaccess_at: lastaccess_at
          ? dayjs(lastaccess_at).format(dateFormat)
          : '-',
        created_at: dayjs(created_at).format(dateFormat),
      } as TableUser),
    []
  );

  const tableUsers = useMemo(
    () => users?.map((u) => transformUserForTable(u)),
    [transformUserForTable, users]
  );

  const selectedUsers = useMemo(() => {
    if (!usersTable?.checkedRows) {
      return [];
    }

    return usersCached.current.filter(({ id }) =>
      usersTable.checkedRows.find((checkedRow) => checkedRow === id)
    );
  }, [usersTable?.checkedRows]);

  const initialData = useMemo(() => {
    const obj = {};

    if (allPermissions && selectedAdmin) {
      allPermissions
        .map((permission) => permission.name)
        .sort()
        .filter((_, index) => index % 2 === 0)
        .forEach((name) => {
          // every second permission is $_edit
          if (
            selectedAdmin.permissions.find((el: Permission) => el.name === name)
          ) {
            Object.assign(obj, { [name]: 'edit' });
          } else if (
            selectedAdmin.permissions.find(
              (el: Permission) => el.name === `${name.slice(0, -4)}view`
            )
          ) {
            Object.assign(obj, { [name]: 'view-only' });
          } else {
            Object.assign(obj, { [name]: 'disabled' });
          }
        });
    }

    return obj;
  }, [allPermissions, selectedAdmin]);

  const fetchUsers = useCallback(async () => {
    showOverlay('.team-management__select-user');

    try {
      const {
        data: {
          data,
          meta: { total, last_page },
        },
      } = await apiUsers.getUsers({
        per_page: 10,
        page: usersCurrentPage,
        search: debouncedUsersSearch,
        sort_by: activeColumnSortUsers.sortBy,
        sort_direction: activeColumnSortUsers.sortDirection,
      });

      setUsers(data.users);
      setUsersTotalCount(total);
      setUsersNumberOfPages(last_page);
    } catch (e) {
      console.error(e);
      setRequestError(true);
    } finally {
      hideOverlay('.team-management__select-user');
    }
  }, [
    activeColumnSortUsers,
    debouncedUsersSearch,
    hideOverlay,
    showOverlay,
    usersCurrentPage,
  ]);

  const fetchPermissions = useCallback(async () => {
    try {
      const {
        data: { permissions },
      } = await apiUsers.getPermissions();

      setAllPermissions(permissions);
    } catch (e) {
      console.error(e);
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAdminsSearchChange = useCallback(
    debounce((text: string) => {
      setDebouncedAdminsSearch((old) => {
        oldAdminsSearch.current = old;
        return text;
      });
      setAdminsCurrentPage(1);
    }, 250),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUsersSearchChange = useCallback(
    debounce((text: string) => {
      setDebouncedUsersSearch((old) => {
        oldUsersSearch.current = old;
        return text;
      });
      setUsersCurrentPage(1);
    }, 250),
    []
  );

  const handlePromoteUsersToAdmins = useCallback(async () => {
    try {
      showOverlay('.team_management');

      const userIds = (usersTable?.checkedRowsData as Array<User>).map(
        (user) => user.id
      );

      const users: { user_id: number; permissions: string[] }[] = [];

      userIds.forEach((id) =>
        users.push({
          user_id: id,
          permissions: futureAdminsPermissions
            ? futureAdminsPermissions[`${id}`]
            : [],
        })
      );

      await apiUsers.promoteUsersToAdmins(users, 'superadmin');

      snackBarSuccessMessage('Admin role successfully assigned');

      setPromoteUsersToAdmins(true);

      setFutureAdminsPermissions({});

      // timeout to avoid missing promoteUsersToAdmins state change so that prompt doesn't catch it
      setTimeout(() => {
        removeQueryParam('step');
      }, 0);
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.team_management');
    }
  }, [
    showOverlay,
    usersTable,
    removeQueryParam,
    futureAdminsPermissions,
    hideOverlay,
  ]);

  const handleOnColumnSortAdmins = useCallback(
    (sortOptions: SortOption<TableAdmin>) => {
      setActiveColumnSortAdmins(sortOptions);
      setAdminsCurrentPage(1);
      adminsTable?.deselectAll();
    },
    [adminsTable]
  );

  const handleOnColumnSortUsers = useCallback(
    (sortOptions: SortOption<TableUser>) => {
      setActiveColumnSortUsers(sortOptions);
      setUsersCurrentPage(1);
      usersTable?.deselectAll();
    },
    [usersTable]
  );

  const getAdminPermissions = useCallback(
    (adminId: number) => {
      if (
        !futureAdminsPermissions ||
        !allPermissions ||
        !futureAdminsPermissions[`${adminId}`]
      ) {
        return [];
      }

      return allPermissions.filter((permission) =>
        futureAdminsPermissions[`${adminId}`].find((p) => p === permission.name)
      );
    },
    [allPermissions, futureAdminsPermissions]
  );

  const submitPermissions = useCallback(
    async (values: Record<string, string>) => {
      if (!selectedAdmin) return;

      try {
        showOverlay('.wcl-modal');

        const newPermissions: string[] = [];

        Object.entries(values).forEach(([key, value]) => {
          if (value !== 'disabled') {
            if (value === 'edit') {
              newPermissions.push(key);
            } else {
              newPermissions.push(`${key.slice(0, -4)}view`);
            }
          }
        });

        if (!step) {
          const {
            data: { permissions },
          } = await apiUsers.setPermissions(newPermissions, +selectedAdmin.id);

          const admin = tableAdmins.find(
            (admin) => admin.id === selectedAdmin.id
          );
          if (admin && allPermissions) {
            admin.permissions = [];
            permissions.forEach((permission) => {
              const { id, name } = allPermissions.find(
                (p) => p.name === permission
              )!;

              admin.permissions.push({ id, name });
            });
          }
        } else {
          setFutureAdminsPermissions((old) => ({
            ...old,
            [selectedAdmin.id]: newPermissions,
          }));
        }
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.wcl-modal');

        snackBarSuccessMessage(`Permissions updated for ${selectedAdmin.name}`);
        setRoleModalOpen(false);
      }
    },
    [selectedAdmin, showOverlay, step, tableAdmins, allPermissions, hideOverlay]
  );

  useEffect(() => {
    if (step === 'select-user') {
      fetchUsers();
    }
  }, [fetchUsers, step]);

  useEffect(() => {
    if (
      step === 'select-user' &&
      !usersTable?.checkedRowsData?.length &&
      checkedRows.current &&
      !checkedCheckedRows.current
    ) {
      checkedCheckedRows.current = true;
      usersTable?.setCheckedRows(checkedRows.current);
    }
  }, [step, usersTable]);

  useEffect(() => {
    if (step === 'add-admin-role') {
      checkedRows.current = usersTable?.checkedRows;
    }
  }, [step, usersTable?.checkedRows]);

  useEffect(() => {
    if (step === 'add-admin-role') {
      const userPermissionsPrefill = {};

      usersTable?.checkedRowsData.forEach((user) => {
        Object.assign(userPermissionsPrefill, {
          [user.id]: allPermissions
            ?.filter((p) => p.name.includes('view'))
            .map((p) => p.name),
        });
      });

      setFutureAdminsPermissions(userPermissionsPrefill);

      checkedCheckedRows.current = false;
    }
  }, [allPermissions, step, usersTable?.checkedRowsData]);

  useEffect(() => {
    if (!step) {
      fetchAdmins();
    }
  }, [fetchAdmins, step, adminsItemPerPage]);

  useEffect(() => {
    onAdminsSearchChange(adminsSearch);
  }, [onAdminsSearchChange, adminsSearch]);

  useEffect(() => {
    onUsersSearchChange(usersSearch);
  }, [onUsersSearchChange, usersSearch]);

  useEffect(() => {
    if (step === 'add-admin-role' && !usersTable?.checkedRowsData?.length) {
      setQueryParam('step', 'select-user');
    }
  }, [removeQueryParam, setQueryParam, step, usersTable]);

  useEffect(() => {
    if (deleteSearch && search) {
      removeQueryParam('search');
      setDeleteSearch(false);
    }
  }, [deleteSearch, removeQueryParam, search]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  useEffect(() => {
    if (users) {
      usersCached.current = uniqBy(
        [...usersCached.current, ...users.map((u) => transformUserForTable(u))],
        (data) => data.id
      );
    }
  }, [transformUserForTable, users]);

  useEffectSkipFirst(() => {
    if (!roleModalOpen) {
      setTimeout(() => setSelectedAdmin(undefined), 201);
    }
  }, [roleModalOpen]);

  useEffectSkipFirst(() => usersTable?.deselectAll(), [debouncedUsersSearch]);

  useEffectSkipFirst(() => {
    if (!step) {
      setUsersCurrentPage(1);
    }
  }, [step]);

  return requestError ? (
    <NoPermissionsPlaceholder />
  ) : (
    <Styled.Container className="team-management">
      <Prompt
        when={!!step}
        message={(nextLocation) => {
          if (promoteUsersToAdmins) {
            setPromoteUsersToAdmins(false);
            return '';
          }

          const changedQParams = findDiffBetweenQParamsObjects(
            location as any,
            nextLocation as any
          );

          const updatedParams = Object.keys(changedQParams || {});

          if (changedQParams && !!changedQParams['step']) {
            return '';
          }

          // if only filters have changed in query params
          // then don't show confirmation window to the user
          const onlyWhitelistedParamsHaveChanged = !updatedParams
            ? null
            : updatedParams.every((el) => whiltelistedQParams.includes(el));

          if (onlyWhitelistedParamsHaveChanged) {
            return '';
          }

          return nextLocation.pathname;
        }}
      />

      {/* NO STEP ACTIVE */}
      {!step && (
        <Fragment>
          <h1 className="team-management__title">Team management</h1>
          <div className="team-management__actions">
            {canEdit && (
              <div className="team-management__actions__button">
                <Button
                  icon={<Icon name="plus" />}
                  onClick={() => {
                    if (search) {
                      removeQueryParam('search');
                    }
                    setQueryParam('step', 'select-user');
                  }}
                >
                  Add a Super Admin
                </Button>
                <div className="team-management__actions__button__info">
                  <Icon name="circle-info" color={getColor('--PRIMARY_1_1')} />
                  <div>
                    For a user to be promoted to SuperAdmin, they need to be an
                    existing user of the KINTO Join app
                  </div>
                </div>
              </div>
            )}
            <InputField
              className="team-management__actions__search"
              prefixIcon={<Icon name="search" />}
              placeholder="Search here..."
              value={adminsSearch}
              onChange={setAdminsSearch}
              {...(adminsSearch && {
                suffixIcon: (
                  <Button
                    variant="ghost"
                    aria-label="Delete input search value"
                    onClick={() => setAdminsSearch('')}
                    icon={<Icon name="xmark" />}
                  />
                ),
              })}
            />
          </div>
          <Heading level="2" className="team-management__admins-table__title">
            Super admin users
          </Heading>
          {admins.length === 0 && (
            <div className="team-management__no-admins">
              {search
                ? 'Sorry, there are no results that match your search.'
                : 'No admins added yet.'}
            </div>
          )}
          {tableAdmins.length > 0 && (
            <Table<TableAdmin>
              tableId="team-management-admins-table"
              className="team-management__admins-table"
              ref={adminsTableRef}
              expandable={canEdit}
              tableData={tableAdmins}
              initialSort={activeColumnSortAdmins}
              translationKeys={adminTranslationKeys}
              excludeColumns={['id', 'firstname', 'lastname', 'permissions']}
              headerActionLabel="member(s) selected"
              tableActions={adminTableActions}
              onColumnSortClicked={handleOnColumnSortAdmins}
              onPageChange={setAdminsCurrentPage}
              onLimitChange={(data: SelectOption) => {
                setAdminsCurrentPage(1);
                adminsTable?.deselectAll();
                setAdminsItemPerPage(+data.value);
              }}
              paginationData={{
                currentPage: adminsCurrentPage,
                totalPages: adminsNumberOfPages,
                totalCount: adminsTotalCount,
                limit: adminsItemPerPage,
              }}
              goToPageInputProps={{ placeholder: 'Go to page' }}
            />
          )}
        </Fragment>
      )}
      {/* STEP ACTIVE */}
      {step && (
        <Fragment>
          {/* PROGRESS BAR */}
          <div className="team-management__progress">
            <Stepper
              steps={[{ name: 'Select user' }, { name: 'Add an admin role' }]}
              currentStep={step === 'select-user' ? 1 : 2}
            />
            <Button
              icon={<Icon name="times" />}
              iconPosition="right"
              variant="ghost"
              onClick={() => {
                checkedRows.current = [];
                removeQueryParam('step');
              }}
            >
              Close
            </Button>
          </div>

          {/* STEP 1 */}
          {step === 'select-user' && (
            <div className="team-management__select-user">
              <InputField
                className="team-management__select-user__search"
                prefixIcon={<Icon name="search" />}
                placeholder="Search here..."
                value={usersSearch}
                onChange={setUsersSearch}
                {...(usersSearch && {
                  suffixIcon: (
                    <Button
                      variant="ghost"
                      aria-label="Delete input search value"
                      onClick={() => setUsersSearch('')}
                      icon={<Icon name="xmark" />}
                    />
                  ),
                })}
              />
              {users?.length ? (
                <Table<TableUser>
                  tableId="team-management-user-table"
                  className="team-management__select-user__table"
                  ref={usersTableRef}
                  hasCheckboxes
                  tableData={tableUsers!}
                  hasLimitPagination={false}
                  initialSort={activeColumnSortUsers}
                  translationKeys={usersTranslationKeys}
                  headerActionLabel="member(s) selected"
                  onPageChange={setUsersCurrentPage}
                  onColumnSortClicked={handleOnColumnSortUsers}
                  paginationData={{
                    currentPage: usersCurrentPage,
                    totalPages: usersNumberOfPages,
                    totalCount: usersTotalCount,
                    limit: DEFAULT_LIMIT_PAGINATON,
                  }}
                  goToPageInputProps={{ placeholder: 'Go to page' }}
                />
              ) : (
                <div className="team-management__select-user__empty-state">
                  Sorry, there are no results that match your search.
                </div>
              )}
              <div className="team-management__select-user__commands">
                <Button
                  onClick={() => setQueryParam('step', 'add-admin-role')}
                  disabled={!usersTable?.totalSelectedCount}
                >
                  Next step
                </Button>
              </div>
            </div>
          )}

          {/* STEP 2 */}
          {step === 'add-admin-role' && (
            <div className="team-management__add-admin-role">
              <div className="team-management__add-admin-role__label">
                New role: <span>Super admin</span>
              </div>
              <div className="team-management__add-admin-role__selected-users">
                {(selectedUsers as Array<TableUser>)?.map(
                  ({ email, name, id }, index) => (
                    <div
                      className="team-management__add-admin-role__selected-users__user"
                      key={`user__${index}`}
                    >
                      <div>{name}</div>
                      <div>{email}</div>
                      <Button
                        id={`${id}`}
                        icon={<Icon name="user-lock" />}
                        variant="ghost"
                        onClick={() => {
                          setSelectedAdmin({
                            name,
                            id,
                            permissions: getAdminPermissions(id),
                          });
                          setRoleModalOpen(true);
                        }}
                      >
                        Edit permissions
                      </Button>
                    </div>
                  )
                )}
              </div>
              <div className="team-management__add-admin-role__actions">
                <Button
                  variant="outline"
                  onClick={() => setQueryParam('step', 'select-user')}
                >
                  Previous step
                </Button>
                <Button
                  onClick={() => {
                    checkedRows.current = [];
                    handlePromoteUsersToAdmins();
                  }}
                >
                  Complete
                </Button>
              </div>
            </div>
          )}
        </Fragment>
      )}

      {roleModalOpen && (
        <PermissionsModal
          allPermissions={allPermissions}
          onClose={() => {
            setRoleModalOpen(false);
            editBtnRef.current?.focus();
            if (step === 'add-admin-role') {
              const editBtn = document.getElementById(`${selectedAdmin?.id}`);
              if (editBtn) editBtn?.focus();
            }
          }}
          onSubmit={submitPermissions}
          selectedAdmin={selectedAdmin}
          initialData={initialData}
        />
      )}
    </Styled.Container>
  );
};

export default TeamManagement;

import { outline, theme } from '@faxi/web-component-library';
import {
  laptop,
  phablet,
  flex,
  size,
  flexGap,
  fontSize,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const AnalyticsPageStyled = styled.div`
  ${flex('column')};
  padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_64};
  background-color: var(--BACKGROUND_2_1);
  overflow: auto;

  ${phablet(css`
    padding: ${theme.sizes.SIZE_20};
  `)};

  .wcl-date-picker {
    width: fit-content;
  }

  .analytics {
    &__header {
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__filters {
      display: flex;
      margin-bottom: ${theme.sizes.SIZE_32};

      &__data {
        ${flex('row', 'flex-start', 'center')};
        flex: 1;
        gap: ${theme.sizes.SIZE_20};
        margin-right: ${theme.sizes.SIZE_48};

        &__date-range-selector-container {
          position: relative;
          margin: auto 0;
        }

        &__select {
          width: ${theme.sizes.SIZE_248};
          .wcl-dropdown__content {
            z-index: 1;
          }
        }

        // TODO: consider expanding the Link component in WCL to match Button designs
        &__link {
          cursor: pointer;
          ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_16)};
          padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_24};
          border: unset;
          border-radius: ${theme.sizes.SIZE_8};
          width: fit-content;
          ${flex('row', 'center', 'center')};
          font-weight: 500;
          height: ${theme.sizes.SIZE_48};
          min-height: ${theme.sizes.SIZE_48};
          white-space: pre-wrap;
          background-color: var(--BACKGROUND_1_1);
          color: var(--PRIMARY_1_1);
          ${outline()};

          @media (hover: hover) {
            &:hover {
              background-color: var(--ACCENT_1_6);
            }
          }
        }
      }

      &__show-test-data {
        ${flex('row', 'center', 'center')};
        ${flexGap(theme.sizes.SIZE_10, 'row')};

        span {
          font-size: ${theme.fontSizes.FONT_12};
          color: var(--SHADE_1_3);
        }

        .wcl-toggle {
          margin-top: ${theme.sizes.SIZE_4};
        }

        ${phablet(css`
          justify-content: flex-start;
          align-items: center;
        `)};
      }

      ${phablet(css`
        flex-direction: column;

        &__calendar-dropdown {
          &,
          .wcl-dropdown__trigger {
            width: 100%;

            .date-range-selector__buttons__button {
              width: 100%;
            }
          }
        }

        &__data {
          flex-direction: column;
          margin: 0 0 ${theme.sizes.SIZE_12};

          &__select {
            &,
            .wcl-dropdown__trigger {
              width: 100%;
            }
          }

          > :not(:last-child) {
            margin-bottom: ${theme.sizes.SIZE_12};
          }
        }
      `)};
    }

    &__date-picker {
      margin-top: ${theme.sizes.SIZE_32};

      .wcl-dropdown__trigger {
        .button {
          min-height: 32px;
          height: fit-content;
        }
      }

      ${laptop(css`
        margin: ${theme.sizes.SIZE_32} 0;
      `)}
    }

    &__totals {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr 1fr;
      gap: ${theme.sizes.SIZE_10};

      width: 100%;
      grid-area: totals;

      div[class*='analytics__graph'],
      .mini-report-total {
        ${size('100%')};
      }

      ${laptop(css`
        grid-template-columns: repeat(2, 1fr);
      `)}

      ${phablet(css`
        ${flex('column')};
        ${flexGap(theme.sizes.SIZE_10, 'column')};
      `)}
    }

    &__graphs {
      display: grid;
      width: 100%;
      grid-gap: ${theme.sizes.SIZE_32};
      align-self: center;
      grid-template-columns: repeat(auto-fit, minmax(34%, 1fr));
      grid-template-rows: auto;
      grid-template-areas:
        'totals totals'
        'registered-communities active-communities'
        'registered-users active-users'
        'nox co2'
        'number-journeys number-journeys'
        'number-booked-journeys number-booked-journeys'
        'campaigns rewards'
        'journey-statuses journey-statuses';

      &--selected-community {
        grid-template-areas:
          'totals totals'
          'registered-users active-users'
          'nox co2'
          'number-journeys number-journeys'
          'number-booked-journeys number-booked-journeys'
          'campaigns rewards'
          'journey-statuses journey-statuses';
      }

      ${laptop(css`
        ${flex('column', 'center', 'flex-start')}
      `)}
    }

    &__graph {
      &--registered-communities {
        grid-area: registered-communities;
      }

      &--active-communities {
        grid-area: active-communities;
      }

      &--registered-users {
        grid-area: registered-users;
      }

      &--active-users {
        grid-area: active-users;
      }

      &--nox {
        grid-area: nox;
      }

      &--co2 {
        grid-area: co2;
      }

      &--number-journeys {
        grid-area: number-journeys;
      }

      &--journey-statuses {
        grid-area: journey-statuses;
      }

      &--rewards {
        grid-area: rewards;
      }

      &--campaigns {
        grid-area: campaigns;
      }

      &--number-booked-journeys {
        grid-area: number-booked-journeys;
      }
    }
  }
`;

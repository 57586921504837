import { Modal as ModalComponent, theme } from '@faxi/web-component-library';
import { fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Modal = styled(ModalComponent)`
  .wcl-table__expander {
    .button {
      min-height: ${theme.sizes.SIZE_32};
    }
  }

  .user-communities-modal {
    &__table {
      .expander__content {
        padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_48};
      }

      td {
        &:nth-child(2) {
          text-transform: capitalize;
        }
      }
    }

    &__no-actions {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};
      margin: ${theme.sizes.SIZE_12} 0;
    }
  }
`;

import { useCallback, useState } from 'react';
import { apiCommunities } from 'modules';
import { useParams } from 'react-router';
import CarParkContext, {
  CarParkInitialValues,
  CarParkNumbers,
} from './CarPark.context';

const CarParkProvider = (props: any) => {
  const { children } = props;

  const [carParkNumbers, setCarParkNumbers] = useState<CarParkNumbers>(
    CarParkInitialValues.carParkNumbers
  );

  const { communityId } = useParams<{
    communityId: string;
  }>();

  //function to get car park numbers if user dont come to CarParkSpaces throw CarPark page
  const fetchCarParkNumbers = useCallback(
    async (parkId: string) => {
      if (communityId) {
        try {
          const {
            data: {
              data: { carparks },
            },
          } = await apiCommunities.getCarParks(communityId);

          const selectedCarPark = carparks.find(
            (carpark) => carpark.id.toString() === parkId
          );

          if (selectedCarPark) {
            setCarParkNumbers({
              spaces_total: selectedCarPark.spaces_total || '',
              spaces_shared: selectedCarPark.spaces_shared || '',
              spaces_other: selectedCarPark.spaces_other || '',
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
    [communityId]
  );

  return (
    <CarParkContext.Provider
      value={{ carParkNumbers, setCarParkNumbers, fetchCarParkNumbers }}
    >
      {children}
    </CarParkContext.Provider>
  );
};

export default CarParkProvider;

import { Platform } from 'models';
import { PlatformEmission } from 'models/Platform';
import React from 'react';
import { noop } from 'utils';

export type MarketSettingsType = Pick<
  Platform,
  'support_phone' | 'support_email' | 'retention_period'
> &
  Pick<PlatformEmission, 'co2_per_km' | 'nox_per_km'>;

type MarketSettingsContextProps = {
  loading: boolean;
  platform: Platform;
  requestError: boolean;
  platformEmission: PlatformEmission;
  setPlatformEmission: React.Dispatch<React.SetStateAction<PlatformEmission>>;
  setPlatform: React.Dispatch<React.SetStateAction<Platform>>;
};

export default React.createContext<MarketSettingsContextProps>({
  loading: false,
  platform: {} as Platform,
  requestError: false,
  platformEmission: {} as PlatformEmission,
  setPlatform: noop,
  setPlatformEmission: noop,
});

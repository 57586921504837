import { useContext, useEffect, useMemo } from 'react';
import { GoogleMapsContext } from '@faxi/web-component-library';

import { MapMarker as MapMarkerModel, MapMarkerType, Trax } from 'models';
import { ExtendMarkerType } from '../MapElements/MapElements.component';
import MapInfoWindow from '../MapInfoWindow';
import MarkersContext, {
  OVERLAY_TYPE,
} from 'pages/Map/providers/Markers/Markers.context';
import { dateInUTCFormat } from 'utils';

type MapMarkerProps = MapMarkerModel & Partial<Trax>;

const mapMarkerTypeToSVGName: Record<MapMarkerType, string> = {
  'driver-end': 'end-pin',
  'driver-start': 'start-pin',
  'passenger-pick-up': 'passenger-pick-up-pin',
  'passenger-drop-off': 'passenger-drop-off-pin',
  'driver-trax': 'driver-trax-map-pin',
  'passenger-trax': 'passenger-trax-map-pin',
};

const MapMarker: React.FC<MapMarkerProps> = (props) => {
  const { lat, lng, type, index, userId, passengerIndex, ...traxRest } = props;

  const { map } = useContext(GoogleMapsContext);
  const { addPin } = useContext(MarkersContext);

  const svgName = useMemo(() => mapMarkerTypeToSVGName[type], [type]);

  useEffect(() => {
    if (!map) return;

    const marker = new window.google.maps.Marker({
      userId,
      type,
      position: { lat: +lat, lng: +lng },
      map,
      icon: `/assets/svg/${
        traxRest?.ble
          ? 'ble-'
          : traxRest?.ok !== undefined
          ? `ok-${traxRest.ok}-`
          : type === 'passenger-trax'
          ? 'error-'
          : ''
      }${svgName}${index !== undefined ? `-${index + 1}` : ''}.svg`,
      optimized: true,
    } as google.maps.MarkerOptions & MapMarkerModel);

    let mouseoverListenerTrax: google.maps.MapsEventListener;
    let mouseoutListenerTrax: google.maps.MapsEventListener;

    if (['driver-trax', 'passenger-trax'].includes(type)) {
      mouseoverListenerTrax = marker.addListener('mouseover', () => {
        MapInfoWindow.show({
          ID: traxRest.id,
          TS: dateInUTCFormat(traxRest.ts),
          DTS: traxRest.dts,
          ...(traxRest.accuracy !== undefined && {
            'Accuracy (68%)': `${traxRest.accuracy} m`,
          }),
          ...(traxRest.age !== undefined && { Age: traxRest.age }),
          ...(traxRest.speed !== undefined && {
            Speed: `${traxRest.speed} m/s`,
          }),
          ...(traxRest.altitude !== undefined && {
            Altitude: `${traxRest.altitude} m`,
          }),
          ...(traxRest.course !== undefined && { Course: traxRest.course }),
          'Activity Mode': traxRest.mode,
          ...(traxRest.phone_status !== undefined && {
            'Phone Status': traxRest.phone_status,
          }),
          BLE: Boolean(traxRest.ble),
        });
      });

      mouseoutListenerTrax = marker.addListener('mouseout', () => {
        MapInfoWindow.hide();
      });
    }

    let mouseoverListener: google.maps.MapsEventListener;
    let mouseoutListener: google.maps.MapsEventListener;

    if (
      ['organisation', 'driver', 'passenger'].some(
        (s) => type.includes(s) && !type.includes('trax')
      )
    ) {
      let infoWindowContent;

      switch (type) {
        case 'driver-end':
          infoWindowContent = 'Driver end location';
          break;
        case 'passenger-pick-up':
          infoWindowContent = `Passenger ${passengerIndex} pick up location`;
          break;
        case 'passenger-drop-off':
          infoWindowContent = `Passenger ${passengerIndex} drop off location`;
          break;
        case 'driver-start':
          infoWindowContent = 'Driver start location';
          break;
      }

      const infoWindow = new google.maps.InfoWindow({
        content: `<div>${infoWindowContent}
            ${
              type.includes('passenger')
                ? `<br />
            User ID: ${userId}`
                : ''
            }
          </div>`,
        headerDisabled: true,
      });

      mouseoverListener = marker.addListener('mouseover', () => {
        infoWindow.open({
          anchor: marker,
          map,
          shouldFocus: false,
        });
      });

      mouseoutListener = marker.addListener('mouseout', () => {
        infoWindow.close();
      });
    }

    addPin(marker as ExtendMarkerType, OVERLAY_TYPE.MARKER);

    return () => {
      if (mouseoverListenerTrax && mouseoutListenerTrax) {
        google.maps.event.removeListener(mouseoverListenerTrax);
        google.maps.event.removeListener(mouseoutListenerTrax);
      }

      if (mouseoverListener && mouseoutListener) {
        google.maps.event.removeListener(mouseoverListener);
        google.maps.event.removeListener(mouseoutListener);
      }
    };
  }, [
    lat,
    lng,
    map,
    svgName,
    index,
    traxRest,
    type,
    userId,
    passengerIndex,
    addPin,
  ]);

  return null;
};

export default MapMarker;

import { JourneyStatus } from 'models';
import {
  RadioGroupOption,
  StatusElementStatus,
} from '@faxi/web-component-library';
import { TableJourney } from './Journeys.page';

export const JOURNEY_SEARCH_OPTIONS = [
  { label: 'Community ID', value: 'organisation_id' },
  { label: 'Journey ID', value: 'journey_id' },
  { label: 'User ID', value: 'user_id' },
  { label: 'By day', value: 'date' },
] as RadioGroupOption[];

export const TRANSLATION_KEYS = {
  id: 'ID',
  organisation_id: 'Community ID',
  user_id: 'User ID',
  reported: 'Reported',
  email: 'Email',
  vehicle_registration: 'VRN',
  started: 'Started',
  ended: 'Ended',
  pocs_time: 'POCS',
  pocs_type: 'PoCS Type',
  booking_id: 'Type',
  number_of_passengers: 'Passengers',
  type: 'Travel mode',
  status: 'Status',
  direction: 'Direction',
  start_point: 'Start Point',
  end_point: 'End Point',
  ticket: 'Parking ticket',
} as Record<Partial<keyof TableJourney>, string>;

export const STATUS_MAP = {
  ABORTED: 'rejected',
  ACTIVE: 'approved',
  FINISHED: 'approved',
  ACTIVE_NO_PASSENGER: 'canceled',
  FINISHED_NO_PASSENGER: 'canceled',
} as Record<JourneyStatus, StatusElementStatus>;

export const STATUS_TRANSLATIONS = {
  ACTIVE: 'Active',
  ABORTED: 'Aborted',
  FINISHED: 'Finished',
  ACTIVE_NO_PASSENGER: 'Active',
  FINISHED_NO_PASSENGER: 'Finished',
} as Record<JourneyStatus, string>;

export const SEARCH_PARAMS = {
  organisation_id: 'organisation_id',
  journey_id: 'journey_id',
  user_id: 'user_id',
  date: 'date',
} as const;

export type JourneySearchParams =
  (typeof SEARCH_PARAMS)[keyof typeof SEARCH_PARAMS];

export const PARAMS_PLACEHOLDERS = {
  organisation_id: 'Community ID',
  journey_id: 'Journey ID',
  user_id: 'User ID',
  date: 'Pick a day',
} as Record<JourneySearchParams, string>;

import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  FormField,
  FormRef,
  useFormContextValues,
  validators,
} from '@faxi/web-form';
import { Button } from '@faxi/web-component-library';

import { FileInput, Icon, InputField } from 'components';
import { snackBarErrorMessage } from 'utils';

import * as Styled from './UploadCsvStep.styles';

type UloadCsvStepProps = {
  form?: FormRef;
  editMode?: boolean;
  initialCSV?: string[];
};

const UloadCsvStep = (props: UloadCsvStepProps) => {
  const { form, editMode, initialCSV } = props;

  const formValues = useFormContextValues('name', 'file');

  const [csvFiles, setCsvFiles] = useState<File[]>([]);

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required('This field is required'),
        validators.general.maxLength(100, 'Maximum length is 100'),
      ],
    }),
    []
  );

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept: { 'text/csv': ['.csv', '.xlsx'] },
    multiple: true,
    noClick: true,
    noKeyboard: true,
    // COMMENT: limit is 200kB
    maxSize: 200000,
    onDropAccepted: (files) => {
      const filteredFiles: File[] = formValues?.file || [];

      files.forEach(async (file) => {
        const reader = new FileReader();
        reader.readAsText(file);

        const loadFilePromise = new Promise(
          (resolve, reject) =>
            (reader.onload = async (e) => {
              if (!e || !e.target || !e.target.result) {
                reject();
              }

              if (csvFiles.find((csv) => csv.name === file.name)) {
                snackBarErrorMessage('Can not add same file twice');
              } else {
                setCsvFiles((old) => [...old, file]);
                resolve(file);
                filteredFiles.push(file);
              }
            })
        );

        await loadFilePromise;
        form?.updateValueField('file', filteredFiles);
      });
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((rejection) => {
        const error = rejection.errors[0].code;
        switch (error) {
          case 'file-invalid-type': {
            snackBarErrorMessage(
              'Please upload a valid file. Only .csv and .xlsx files are supported'
            );
            return;
          }
          case 'file-too-large': {
            snackBarErrorMessage('File is larger than 200kB');
            return;
          }
          default: {
            snackBarErrorMessage('An error occurred.');
          }
        }
      });
    },
  });

  return (
    <Styled.Container>
      <FormField
        component={InputField}
        name="name"
        placeholder="Reward name"
        requiredLabel="Required"
        className="upload-csv__reward_name"
        required
        validate={validations.name}
        disabled={editMode}
      />
      <div className="upload-csv__files">
        {initialCSV?.map((csvFile) => {
          return (
            <div key={csvFile} className="upload-csv__initial-file-uploaded">
              {csvFile}
              <Icon
                name="check"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--PRIMARY_1_1')}
              />
            </div>
          );
        })}
        {csvFiles.map((csvFile: File) => {
          return (
            <div key={csvFile.name} className="upload-csv__file-uploaded">
              {csvFile.name}

              <Button
                variant="ghost"
                icon={<Icon name="times" />}
                onClick={() => {
                  setCsvFiles((old) => old.filter((file) => file !== csvFile));

                  // this is important to clear input
                  // so same file can be upload again if it was exluded
                  ((inputRef as any)?.current as any)!.value = '';

                  form?.updateValueField(
                    'file',
                    csvFiles.filter((csv) => csv !== csvFile) || undefined
                  );
                }}
              />
            </div>
          );
        })}
      </div>
      <div {...getRootProps()}>
        <Button variant="outline" onClick={() => inputRef.current?.click()}>
          Upload csv
        </Button>

        <FormField
          inputProps={getInputProps()}
          component={FileInput}
          name="file"
        />
      </div>
    </Styled.Container>
  );
};

export default UloadCsvStep;

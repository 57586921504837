import { FC } from 'react';
import { Image } from '@faxi/web-component-library';

import PermissionsLock from '../../svg/PermissionsLock';

import * as Styled from './NoPermissionsPlaceholder.styles';

type NoPermissionsPlaceholderProps = {
  variant?: 'table' | 'panel';
};

const NoPermissionsPlaceholder: FC<NoPermissionsPlaceholderProps> = (
  props: NoPermissionsPlaceholderProps
): JSX.Element => {
  const { variant = 'table' } = props;

  return (
    <Styled.NoPermissionsPlaceholder className="no-permissions-placeholder">
      <Image
        src={`/assets/images/no-permissions-placeholder-${variant}.webp`}
        alt="No permissions placeholder"
      />

      <div className="no-permissions-placeholder__window">
        <PermissionsLock />
        <div>You don't have permission to view or edit this page</div>
        <b>Contact your manager and get the access.</b>
      </div>
    </Styled.NoPermissionsPlaceholder>
  );
};

export default NoPermissionsPlaceholder;

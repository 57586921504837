import { useMemo, useCallback, useContext } from 'react';
import {
  Form,
  validators,
  validationRegexes,
  FormField,
  FormRef,
} from '@faxi/web-form';
import {
  useUtilities,
  useCallbackRef,
  Heading,
  getColor,
} from '@faxi/web-component-library';
import { AxiosError } from 'axios';

import { useFormButtons } from 'hooks';
import {
  getStringError,
  snackBarErrorMessage,
  snackBarSuccessMessage,
} from 'utils';
import { Admin, BaseFormProps } from 'models';
import { apiUsers } from 'modules';

import { InputField, TablePageLayout } from 'components';
import AuthContext from 'store/AuthProvider/Auth.context';
import { credentialService } from 'services';

import { FormActions } from 'Global.styles';

type UserDetailsFormType = {
  screenName: string;
  firstName: string;
  lastName: string;
};

const PersonalDetailsForm: React.FC<BaseFormProps> = (props): JSX.Element => {
  const [FormButtons] = useFormButtons();
  const [form, formRef] = useCallbackRef<FormRef>();

  const { showOverlay, hideOverlay } = useUtilities();

  const { admin, setAdmin } = useContext(AuthContext);

  const handleSubmit = useCallback(
    async ({
      screenName: login,
      firstName: firstname,
      lastName: lastname,
    }: UserDetailsFormType) => {
      try {
        showOverlay('body');

        await apiUsers.updateUserDetails(login, firstname, lastname);

        setAdmin((oldAdmin) => {
          const updatedAdmin = {
            ...oldAdmin,
            login,
            firstname,
            lastname,
          } as Admin;

          credentialService.saveUser(updatedAdmin);
          return updatedAdmin;
        });
      } catch (e) {
        console.error(e);
        snackBarErrorMessage(getStringError(e as AxiosError));
      } finally {
        hideOverlay('body');
        snackBarSuccessMessage('Successfully updated Super Admin details!');
      }
    },
    [hideOverlay, setAdmin, showOverlay]
  );

  const validations = useMemo(
    () => ({
      screenName: [
        validators.general.required('This field is required'),
        validators.general.maxLength(60, 'Maximum length is 60'),
        validators.general.regex(
          validationRegexes.displayName,
          'Please enter a valid screen name'
        ),
      ],
      firstName: [
        validators.general.required('This field is required'),
        validators.general.maxLength(20, 'Maximum length is 20'),
        validators.general.regex(
          validationRegexes.name,
          'Please enter a valid first name'
        ),
      ],
      lastName: [
        validators.general.required('This field is required'),
        validators.general.maxLength(50, 'Maximum length is 50'),
        validators.general.regex(
          validationRegexes.name,
          'Please enter a valid last name'
        ),
      ],
    }),
    []
  );

  const initialData = useMemo(
    () => ({
      screenName: admin?.login,
      firstName: admin?.firstname,
      lastName: admin?.lastname,
    }),
    [admin]
  );

  return (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__header"
      >
        Personal details
      </Heading>

      <Form initialData={initialData} onSubmit={handleSubmit} ref={formRef}>
        <div className="form__fields">
          <FormField
            name="screenName"
            component={InputField}
            placeholder="Screen name"
            validate={validations.screenName}
          />
          <FormField
            name="firstName"
            component={InputField}
            placeholder="First name"
            validate={validations.firstName}
          />
          <FormField
            name="lastName"
            component={InputField}
            placeholder="Last name"
            validate={validations.lastName}
          />
        </div>
        <FormActions className="edit-form-buttons">
          <FormButtons.Submit
            disabled={!form?.isFormChanged() || !form?.syncFormValid}
          />
        </FormActions>
      </Form>
    </TablePageLayout.PageLayoutContainer>
  );
};

export default PersonalDetailsForm;

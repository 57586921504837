import {
  flex,
  fontSize,
  position,
  size,
  phablet,
} from '@faxi/web-css-utilities';
import { elevate_xxl, theme } from '@faxi/web-component-library';
import styled, { css } from 'styled-components';

export const MapUtilityButton = styled.button`
  ${fontSize(`${theme.fontSizes.FONT_20}`, `${theme.fontSizes.FONT_28}`)};
  ${size('auto', `${theme.sizes.SIZE_56}`)};

  background: white;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: inherit;
  color: var(--SECONDARY_1_1);
  min-width: ${theme.sizes.SIZE_56};

  &:focus {
    outline: none;
  }
`;

export const MapMode = styled.div`
  ${elevate_xxl};

  z-index: 1;
  display: flex;
  width: ${theme.sizes.SIZE_256};
  position: absolute;
  bottom: ${theme.sizes.SIZE_32};
  transform: translateX(-50%);
  left: 50%;

  button {
    flex: 1 1 100%;
    margin: 0;

    &:first-of-type {
      border-radius: 8px 0 0 8px;
    }

    &:last-of-type {
      border-radius: 0 8px 8px 0;
    }

    &.active {
      font-weight: 600;
    }

    + button {
      border-left: ${theme.sizes.SIZE_1} solid var(--SHADE_1_6);
    }

    ${phablet(css`
      ${fontSize(`${theme.fontSizes.FONT_12}`, `${theme.fontSizes.FONT_16}`)};

      height: ${theme.sizes.SIZE_32};
    `)}
  }

  ${phablet(css`
    width: ${theme.sizes.SIZE_160};
  `)}
`;

export const MapZoom = styled.div`
  ${flex('column')}
  ${elevate_xxl};

  position: absolute;
  top: ${theme.sizes.SIZE_40};
  right: ${theme.sizes.SIZE_32};
  height: ${theme.sizes.SIZE_96};
  border-radius: ${theme.sizes.SIZE_8};
  z-index: 1;

  button {
    ${flex('row', 'center', 'center')};

    svg[data-icon] {
      ${size(theme.sizes.SIZE_24)};
    }

    + button {
      position: relative;

      &:before {
        ${position(
          'absolute',
          'top -0.5px right -0.5px bottom -0.5px left -0.5px'
        )};
        content: '';
        background-color: var(--SHADE_1_6);
        display: block;
        height: ${theme.sizes.SIZE_1};
        width: 80%;
        margin: 0 auto;
      }
    }

    &:first-of-type {
      border-radius: 8px 8px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }

    ${phablet(css`
      width: ${theme.sizes.SIZE_32};
      min-width: ${theme.sizes.SIZE_32};

      svg[data-icon] {
        ${size(theme.sizes.SIZE_16)};
      }
    `)}
  }

  ${phablet(css`
    right: ${theme.sizes.SIZE_16};
    height: ${theme.sizes.SIZE_72};
  `)}
`;

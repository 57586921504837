import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import dayjs from 'dayjs';
import {
  Button,
  Heading,
  getColor,
  useUtilities,
} from '@faxi/web-component-library';

import { useCallbackAsync } from 'hooks';
import { apiPlatform } from 'modules';
import { snackBarSuccessMessage } from 'utils';

import Icon from 'components/Icon';
import { NoPermissionsPlaceholder, TablePageLayout } from 'components';

import { AuthContext } from 'store';

import * as Styled from './AppSettingsForm.styles';

const mapStaticPagesCopy = {
  terms_and_conditions: 'Terms & conditions',
  privacy_policy: 'Privacy policy',
};

const AppSettingsForm: FC = () => {
  const { showOverlay, hideOverlay, prompt } = useUtilities();

  const { admin } = useContext(AuthContext);

  const [requestError, setRequestError] = useState(false);
  const [lastResetDate, setLastResetDate] = useState<
    Record<keyof typeof mapStaticPagesCopy, string | undefined>
  >({
    terms_and_conditions: undefined,
    privacy_policy: undefined,
  });

  const [loadLastResetDate] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.app-settings',
    callback: async () => {
      try {
        const {
          data: { data },
        } = await apiPlatform.getLastResetDateOfStaticPages();

        if (data) {
          setLastResetDate(data);
        }
      } catch (e) {
        console.error(e);
        setRequestError(true);
      }
    },
  });

  const handleReset = useCallback(
    async (doc: keyof typeof mapStaticPagesCopy) => {
      if (
        await prompt({
          type: 'info-error',
          submitBtnText: 'Reset',
          submitBtnVariant: 'outline',
          btnIcon: 'arrow-rotate-left',
          title: `Are you sure you want to reset ${
            mapStaticPagesCopy[`${doc}`]
          }?  `,
          cancelBtnText: 'Cancel',
        })
      ) {
        try {
          showOverlay('.app-settings', 'fixed');
          const { data } = await apiPlatform.resetConsents(doc);

          if (data) {
            snackBarSuccessMessage(`${mapStaticPagesCopy[`${doc}`]} reset`);
            setLastResetDate((old) => ({ ...old, [`${doc}`]: new Date() }));
          }
        } catch (e) {
          console.error(e);
        } finally {
          hideOverlay('.app-settings');
        }
      }
    },
    [hideOverlay, prompt, showOverlay]
  );

  const canEdit = useMemo(
    () => !!admin?.permissions.find((p) => p.name === 'market_settings_edit'),
    [admin]
  );

  const settingsActions = useMemo(
    () => [
      {
        label: 'Terms & conditions',
        lastResetDate: lastResetDate.terms_and_conditions,
        onReset: () => handleReset('terms_and_conditions'),
      },
      {
        label: 'Privacy policy',
        lastResetDate: lastResetDate.privacy_policy,
        onReset: () => handleReset('privacy_policy'),
      },
    ],
    [
      handleReset,
      lastResetDate.privacy_policy,
      lastResetDate.terms_and_conditions,
    ]
  );

  useEffect(() => {
    loadLastResetDate();
  }, [loadLastResetDate]);

  return requestError ? (
    <NoPermissionsPlaceholder variant="panel" />
  ) : (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__header"
      >
        App settings
      </Heading>

      <Styled.Container className="app-settings">
        <div className="app-settings__description">
          When you reset T&C or Privacy Policy users will be asked to read and
          accept again in order to use the app
        </div>

        {settingsActions.map((settingsAction, index) => (
          <div className="app-settings__action-row" key={index}>
            <div className="app-settings__action-row__label">
              <div>{settingsAction.label}</div>
              {settingsAction.lastResetDate && (
                <div className="app-settings__last-rest-date">
                  {`Last reset - ${dayjs(settingsAction.lastResetDate).format(
                    'ddd D MMMM'
                  )}`}
                </div>
              )}
            </div>

            <Button
              variant="outline"
              icon={<Icon name="arrow-rotate-left" />}
              onClick={settingsAction.onReset}
              disabled={!canEdit}
            >
              Reset
            </Button>
          </div>
        ))}
      </Styled.Container>
    </TablePageLayout.PageLayoutContainer>
  );
};

export default AppSettingsForm;

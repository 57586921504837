import { createContext } from 'react';
import { noop } from 'utils';

export type CarParkNumbers = {
  spaces_total?: string;
  spaces_shared?: string;
  spaces_other?: string;
};

type CarParkContextProps = {
  carParkNumbers: CarParkNumbers;
  setCarParkNumbers: React.Dispatch<React.SetStateAction<CarParkNumbers>>;
  fetchCarParkNumbers: (parkId: string) => Promise<any>;
};

export const CarParkInitialValues = {
  carParkNumbers: {
    spaces_total: '',
    spaces_shared: '',
    spaces_other: '',
  },
  setCarParkNumbers: noop,
  fetchCarParkNumbers: () => new Promise(() => {}),
};
const CarParkContext = createContext<CarParkContextProps>(CarParkInitialValues);

export default CarParkContext;

import { Modal as ModalComponent, theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const Modal = styled(ModalComponent)`
  .edit-reward-modal {
    &__select_communities {
      font-size: ${theme.fontSizes.FONT_20};
      color: var(--SECONDARY_1_1);
      margin-bottom: ${theme.sizes.SIZE_16};
      margin-top: ${theme.sizes.SIZE_32};
    }
  }

  .kinto-modal__actions {
    margin-top: 0;
  }
`;

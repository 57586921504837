import { FC } from 'react';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { LinkProps } from 'react-router-dom';
import { Link } from '@faxi/web-component-library';

import * as Styled from './InfoCard.styles';

type InfoCardProps = {
  className?: string;
  text: string;
  linkText?: string;
} & Partial<LinkProps>;

const InfoCard: FC<InfoCardProps> = (props) => {
  const { className, text, linkText, ...rest } = props;

  return (
    <Styled.InfoCardContainer
      className={classNames('kinto-info-card', className)}
    >
      <Icon name="triangle-exclamation" />

      <div className="kinto-info-card__content">
        <p className="kinto-info-card__text">{text}</p>
        {linkText && (
          <Link className="kinto-info-card__link" {...(rest as LinkProps)}>
            {linkText}
          </Link>
        )}
      </div>
    </Styled.InfoCardContainer>
  );
};

export default InfoCard;

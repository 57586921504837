import {
  FC,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Form, FormField, FormRef, validators } from '@faxi/web-form';
import {
  useCallbackRef,
  ModalProps,
  Modal,
  ModalRef,
} from '@faxi/web-component-library';

import { snackBarErrorMessage, snackBarSuccessMessage } from 'utils';
import { useFormButtons, useCallbackAsync } from 'hooks';

import { FormActions } from 'Global.styles';
import { InputField } from 'components';
import CheckboxField from 'components/fields/CheckboxField';
import GoogleAutocompleteField from 'components/fields/GoogleAutocompleteField';
import TabsField from 'components/fields/TabsField';
import { apiCommunities } from 'modules';
import { APIResponse, CreateCommunityData } from 'models';
import specific from 'validation/validators/specific';

const createCommunityTypes = [
  { label: 'Workplace', value: 'Group' },
  { label: 'School', value: 'School' },
];

export type CreateCommunityModalProps = {
  community?: CreateCommunityData;
  onSubmit: () => void;
} & ModalProps;

const CreateCommunityModal: FC<CreateCommunityModalProps> = (props) => {
  const { onSubmit, community, ...rest } = props;

  const modalRef = useRef<ModalRef>(null);

  const [form, formRef] = useCallbackRef<FormRef>();
  const [FormButtons] = useFormButtons(community ? 'Save' : 'Create', 'Cancel');

  const validations = useMemo(
    () => ({
      name: [
        validators.general.required('Name is required'),
        validators.general.maxLength(
          150,
          'Maximum length exceeded, name can only have a maximum of 150 characters.'
        ),
      ],
      address: [specific.googleAutocompleteAddress('Address is required')],
    }),
    []
  );

  const initialData = useMemo(
    () =>
      community
        ? {
            name: community.name,
            test: community.test,
            type: community.type,
            address: {
              formatted_address: community.address,
              lat: community.lat,
              lng: community.lng,
              city: community.city,
              country: community.country,
            },
          }
        : { name: null, test: null, address: null, type: 'Group' },
    [community]
  );

  const [handleOnSubmit, loadingSubmit] = useCallbackAsync({
    showSpinner: false,
    callback: async (data) => {
      const communityData: CreateCommunityData = {
        ...data,
        ...data.address,
        address: data.address.formatted_address,
      };

      try {
        const res = await (community
          ? apiCommunities.updateCommunity(community.id, communityData)
          : apiCommunities.createCommunity(communityData));

        snackBarSuccessMessage(
          `${res.data.name} successfully ${community ? 'updated' : 'created'}`
        );
        onSubmit?.();
        modalRef.current?.close();
      } catch (e: unknown) {
        snackBarErrorMessage(
          (e as { response: APIResponse<{ message: string }> }).response.data
            .message
        );
      }
    },
  });

  const formWrapper = useCallback(
    ({ children }: PropsWithChildren) => (
      <Form
        ref={formRef}
        onSubmit={handleOnSubmit}
        initialData={initialData}
        strictValidation={false}
        children={children}
        className="kinto-modal__form"
      />
    ),
    [formRef, handleOnSubmit, initialData]
  );

  return (
    <Modal
      loading={loadingSubmit}
      className="kinto-create-community-modal"
      title="Create a community"
      childrenWrapper={formWrapper}
      ref={modalRef}
      footer={
        <FormActions className="kinto-modal__actions">
          <FormButtons.Submit
            disabled={
              !(
                form?.isFormChanged() &&
                form?.asyncFormValid &&
                form?.syncFormValid
              )
            }
          />
          <FormButtons.Cancel onClick={() => modalRef.current?.close()} />
        </FormActions>
      }
      {...rest}
    >
      <div className="kinto-modal__fields">
        <FormField
          component={InputField}
          name="name"
          required
          requiredLabel="Required"
          placeholder="Community name"
          validate={validations.name}
        />

        <FormField
          component={CheckboxField}
          name="test"
          label="Test community"
        />

        <FormField
          component={GoogleAutocompleteField}
          name="address"
          placeholder="Address"
          required
          requiredLabel="Required"
          validate={validations.address}
        />

        <fieldset>
          <legend>What type of community are you creating?</legend>

          <FormField
            component={TabsField}
            name="type"
            tabs={createCommunityTypes}
          />
        </fieldset>
      </div>
    </Modal>
  );
};

export default memo(CreateCommunityModal);

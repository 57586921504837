import { Heading, useUtilities, Table } from '@faxi/web-component-library';
import { useContext, useEffect, useMemo } from 'react';

import MapDataContext from 'pages/Map/providers/MapData/MapData.context';
import { Trax } from 'models';
import TraxTableTemplate from '../TraxTableTamplate';
import { dateInUTCFormat } from 'utils';

type TableData = Trax & { index: number; pin_arr: string };

const DriverTraxTable = () => {
  const { showOverlay, hideOverlay } = useUtilities();

  const { driverMap } = useContext(MapDataContext);

  const translationKeys = useMemo(
    () =>
      ({
        index: '#',
        id: 'ID',
        ts: 'ts',
        dts: 'dts',
        lat: 'lat',
        lng: 'lng',
        accuracy: 'Accuracy',
        age: 'Age',
        pin_arr: 'PIN',
        mode: 'Activity mode',
        phone_status: 'Status',
      } as Record<Partial<keyof Trax | 'index'>, string>),
    []
  );

  const trax = useMemo(
    () =>
      driverMap?.trax
        ? (driverMap.trax.map(
            (
              {
                id,
                ts,
                dts,
                lat,
                lng,
                accuracy,
                age,
                pin_arr,
                mode,
                phone_status,
              },
              index: number
            ) => {
              return {
                index,
                id,
                ts: dateInUTCFormat(ts),
                dts,
                lat: lat || '-',
                lng: lng || '-',
                accuracy: accuracy || '-',
                age: age || 0,
                pin_arr: pin_arr?.toString() || '-',
                mode: mode || '-',
                phone_status: phone_status || '-',
              };
            }
          ) as Array<TableData>)
        : [],
    [driverMap]
  );

  useEffect(() => {
    if (!trax) {
      showOverlay('.kinto-trax-table');
    } else {
      hideOverlay('.kinto-trax-table');
    }
  }, [hideOverlay, showOverlay, trax]);

  return (
    <TraxTableTemplate title="Driver Trax" className="kinto-trax-table">
      {trax &&
        (trax.length > 0 ? (
          <Table
            tableId="trax-table"
            tableData={trax}
            translationKeys={translationKeys}
            disableSort={true}
            breakAtMaxWidth={1400}
          />
        ) : (
          <Heading level="4" className="trax-table__empty-placeholder">
            Sorry, there is no trax data.
          </Heading>
        ))}
    </TraxTableTemplate>
  );
};

export default DriverTraxTable;

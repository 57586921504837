import { Modal, theme } from '@faxi/web-component-library';
import { phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const FiltersModal = styled(Modal)`
  fieldset {
    padding: unset;
    border: unset;

    legend {
      font-size: ${theme.fontSizes.FONT_16};
      color: var(--PRIMARY_1_1);
      font-weight: 600;
      margin-bottom: ${theme.sizes.SIZE_16};
    }
  }

  .kinto-filters-modal {
    &__form-actions {
      width: 100%;

      &__clear-all {
        margin-left: auto;
        grid-area: clear;
      }

      ${phablet(css`
        display: grid;
        gap: ${theme.sizes.SIZE_12};
        grid-template-columns: 1fr 1fr;
        margin-top: unset;
        grid-template-areas:
          'save save'
          'clear cancel';

        .button {
          margin: unset;
        }

        .cancel-btn {
          grid-area: cancel;
        }

        .button[type='submit'] {
          grid-column: span 2;
        }
      `)}
    }
  }
`;

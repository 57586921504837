import { useCallback, useMemo, FC } from 'react';
import { useHistory } from 'react-router';
import {
  SortOption,
  Table,
  Heading,
  useQueryParams,
  Button,
  StatusElement,
  useUtilities,
  getColor,
} from '@faxi/web-component-library';

import { useTablePagination } from 'hooks';
import { COLUMNS_SETTINGS_LABELS, appUri } from 'config';
import { apiPlans } from 'modules';
import { Plan, PremiumFeature, Price, TablePlan } from 'models';
import { useColumnSettings } from 'hooks';
import { Icon, TablePageLayout, NoPermissionsPlaceholder } from 'components';
import { snackBarSuccessMessage } from 'utils';

import { PREMIUM_FEATURES_TITLES } from 'pages/Communities/components/PremiumFeaturesModal/utils';

const Plans: FC = (): JSX.Element => {
  const history = useHistory();

  const {
    columnBtnRef,
    columnSettingsOpen,
    closeColumnSettings,
    ColumnSettingsButton,
  } = useColumnSettings();

  const { setQueryParam } = useQueryParams<{
    page: string;
  }>();

  const { showOverlay, hideOverlay, prompts } = useUtilities();

  const mapPlans = useCallback(
    (plans: Plan[]) =>
      plans.map(
        ({
          id,
          name,
          description,
          prices,
          premium_features,
          published,
          active_subscriptions_count,
        }) =>
          ({
            id,
            name,
            description,
            per_community:
              `${prices
                .filter((p: Price) => p.type === 'per_community')
                ?.map((p) => `${p.price} ${p.currency}`)}` || '-',
            per_user:
              `${prices
                .filter((p: Price) => p.type === 'per_user')
                ?.map((p) => `${p.price} ${p.currency}`)}` || '-',
            features: premium_features.reduce(
              (v: string, p: PremiumFeature) =>
                v +
                (v.length > 0 ? ', ' : '') +
                PREMIUM_FEATURES_TITLES[p.name],
              ''
            ),
            visibility: (
              <StatusElement status={published ? 'active' : 'pending'}>
                {published ? 'Published' : 'Not published'}
              </StatusElement>
            ),
            active_subscriptions_count,
          } as TablePlan)
      ),
    []
  );

  const {
    data,
    count,
    requestError,
    totalCount,
    totalPages,
    currentPage,
    activeColumnSort,
    setCount,
    setCurrentPage,
    setActiveColumnSort,
    setData,
  } = useTablePagination<TablePlan, 'plans'>({
    itemsKey: 'plans',
    spinnerParentClass: '.kinto-page',
    mappingFunction: (values: Plan[]) => mapPlans(values),
    apiRequest: ({ per_page, currentPage, sort_by, sort_direction }) =>
      apiPlans.getAll({
        page: currentPage,
        per_page,
        sort_by: sort_by as keyof Plan,
        sort_direction,
      }),
  });

  const handleOnColumnSort = useCallback(
    (sortOptions: SortOption<TablePlan>) => {
      setActiveColumnSort(sortOptions);
      setCurrentPage(1);
    },
    [setActiveColumnSort, setCurrentPage]
  );

  const handleDeletePlan = useCallback(
    async ({ id, name }: Pick<Plan, 'id' | 'name'>) => {
      showOverlay('.wcl-table');

      try {
        await apiPlans.remove({ id });

        setData((old) => old.filter(({ id: pId }) => pId !== id));
        snackBarSuccessMessage(`Successfully deleted plan ${name}`);
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.wcl-table');
      }
    },
    [hideOverlay, setData, showOverlay]
  );

  const translationKeys = useMemo(
    () =>
      ({
        name: 'Name',
        description: 'Description',
        per_community: 'Price per community',
        per_user: 'Price per user',
        visibility: 'Visibility',
        features: 'Features',
      } as Record<Partial<keyof TablePlan>, string>),
    []
  );

  const tableActions = useMemo(
    () =>
      ({
        id,
        name,
        active_subscriptions_count,
      }: Pick<Plan, 'id' | 'name' | 'active_subscriptions_count'>) =>
        active_subscriptions_count === 0 ? (
          <TablePageLayout.TableActions
            actions={[
              {
                name: 'Delete',
                icon: 'trash-can',
                variant: 'delete-ghost',
                onClick: async (btnElement?: HTMLButtonElement) => {
                  if (
                    await prompts.delete({
                      submitBtnText: 'Delete',
                      cancelBtnText: 'Cancel',
                      title: `Do you really want to delete plan ${name}?`,
                    })
                  ) {
                    handleDeletePlan({ id, name });
                  }
                  btnElement?.focus();
                },
              },
            ]}
          />
        ) : (
          <div className="kinto-page__actions__warning_info">
            <Icon name="triangle-exclamation" />
            Plan is included in an activation - it can't be deleted.
          </div>
        ),
    [handleDeletePlan, prompts]
  );

  return requestError ? (
    <NoPermissionsPlaceholder />
  ) : (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__header"
      >
        Plans
      </Heading>

      <div className="kinto-page__actions">
        <Button
          variant="outline"
          icon={<Icon name="plus" />}
          onClick={() => history.push(appUri.PLANS_CREATE)}
        >
          Create a plan
        </Button>

        {data.length !== 0 && <ColumnSettingsButton />}
      </div>

      {data.length === 0 ? (
        <div className="kinto-page__empty-state">There are no plans.</div>
      ) : (
        <Table<TablePlan>
          cacheColumns
          tableData={data}
          tableId="plans-table"
          translationKeys={translationKeys}
          columnSettingsOpen={columnSettingsOpen}
          columnsBtnElement={columnBtnRef.current!}
          excludeColumns={['id', 'active_subscriptions_count']}
          expandable
          tableActions={tableActions}
          columnsModalLabels={COLUMNS_SETTINGS_LABELS}
          initialSort={activeColumnSort}
          className="kinto-plans__table"
          paginationData={{
            currentPage,
            limit: count,
            totalCount,
            totalPages,
          }}
          goToPageInputProps={{ placeholder: 'Go to page' }}
          perPagePlaceholder="Per page"
          onPageChange={(value) => {
            setCurrentPage(value);
            setQueryParam('page', value);
          }}
          onLimitChange={(data) => {
            setCurrentPage(1);
            setCount(+data.value);
          }}
          onColumnsModalClose={closeColumnSettings}
          onColumnSortClicked={handleOnColumnSort}
        />
      )}
    </TablePageLayout.PageLayoutContainer>
  );
};

export default Plans;

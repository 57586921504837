import classNames from 'classnames';
import { Pin } from 'components';
import * as Styles from './EventTableLegend.styles';

type EventTableLegendProps = {
  className?: string;
  numberOfPassengers?: number;
  onSelectPassenger?: (index: number) => void;
};

const EventTableLegend = (props: EventTableLegendProps) => {
  const { className, numberOfPassengers, onSelectPassenger } = props;

  return (
    <Styles.TableDataContainer
      className={classNames('sa-event-table-legend', className)}
    >
      <div onClick={() => onSelectPassenger?.(0)}>
        <Pin
          type="driver"
          alt="Driver Trax"
          className="sa-journey__events-list__driver-pin"
        />
        Driver Traxes
      </div>
      {[...Array(numberOfPassengers).keys()].map((num) => (
        <div onClick={() => onSelectPassenger?.(num + 1)} key={num + 1}>
          <Pin
            index={num + 1}
            className="sa-journey__events-list__passenger-pin"
            alt={`Passengers ${num + 1} Traxes`}
          />
          Passenger {num + 1} Traxes
        </div>
      ))}
    </Styles.TableDataContainer>
  );
};

export default EventTableLegend;

import { FC, useCallback, useMemo } from 'react';
import { Select, SelectProps } from '@faxi/web-component-library';
import { useFieldError, FieldProps } from '@faxi/web-form';
import classNames from 'classnames';

export type SelectFieldProps = Omit<
  FieldProps<string, (event: string) => void> & {
    className?: string;
    description?: string;
    disabled?: boolean;
  },
  'value'
> &
  SelectProps<string>;

const SelectField: FC<SelectFieldProps> = (props: SelectFieldProps) => {
  const {
    className,
    description,
    dirty,
    error,
    touched,
    value,
    options,
    onChange,
    multiple,
    ...rest
  } = props;

  const showError = useFieldError(props);
  const showErrorMultiple = useMemo(() => error && dirty, [error, dirty]);

  const selectOnChange = useCallback(
    (value: any) => {
      onChange && onChange(value as string);
    },
    [onChange]
  );

  return (
    <div className={classNames('kinto-select-field', className)}>
      {multiple ? (
        <Select
          className={classNames('dropdown-field', {
            'wcl-select--error': showErrorMultiple,
          })}
          {...rest}
          multiple
          options={options}
          value={value}
          onChange={(ev) => selectOnChange(ev)}
        />
      ) : (
        <Select
          className={classNames('dropdown-field', {
            'wcl-select--error': showError,
          })}
          {...rest}
          multiple={false}
          options={options}
          value={value}
          onChange={(ev) => selectOnChange(ev.value)}
          error={showError && error}
          errorText={`${error}`}
        />
      )}
      {description && (
        <div className="wcl-select__description-message">{description}</div>
      )}
    </div>
  );
};

export default SelectField;

import {
  APIResponse,
  APIResponsePaginated,
  Admin,
  PaginatedResponse,
  Permission,
  TableAdmin,
  TableUser,
  User,
  UserRole,
  UserFeedback,
  UserOrganisation,
  UserStatus,
} from 'models';

import { SortDirections } from '@faxi/web-component-library';
import httpClient from '../httpClient';

type GetUsersProps = {
  per_page?: number;
  page?: number;
  search?: string;
  sort_by?: keyof TableUser;
  sort_direction?: SortDirections;
  hide_test?: boolean;
  all_users?: '1';
  organisation_id?: string;
  status?: string;
  exclude_admins_organisation_id?: string;
  include_superadmins?: number;
};

const getUsers = async ({
  per_page,
  page,
  search,
  sort_by,
  sort_direction,
  hide_test,
  all_users,
  organisation_id,
  status,
  exclude_admins_organisation_id,
  include_superadmins,
}: GetUsersProps) =>
  httpClient.get<PaginatedResponse<User, 'users'>>('/users', {
    params: {
      per_page,
      page,
      search,
      sort: `${sort_by}:${sort_direction}`,
      hide_test,
      all_users,
      organisation_id,
      status,
      exclude_admins_organisation_id,
      include_superadmins,
    },
  });

const getAdmins = async (
  page: number,
  search: string,
  page_limit: number,
  sort_by?: keyof TableAdmin,
  sort_direction?: SortDirections
) =>
  httpClient
    .get<APIResponsePaginated<Admin, 'users'>>('/admins', {
      params: {
        page,
        search,
        page_limit,
        sort: `${sort_by}:${sort_direction}`,
      },
    })
    .then((res) => res.data);

const getAdmin = async (id: string) =>
  httpClient.get<APIResponse<Admin[]>>(`/admins/${id}`).then((res) => res.data);

const promoteUsersToAdmins = async (
  values: { user_id: number; permissions: string[] }[],
  role: UserRole
) =>
  httpClient
    .post<APIResponse<APIResponsePaginated<Admin, 'user'>>>('/users/role', {
      users: values,
      role,
    })
    .then((res) => res.data);

const updateUserDetails = async (
  screenName: string,
  firstName: string,
  lastName: string
) => {
  const formData = new FormData();

  formData.append('login', screenName);
  formData.append('firstname', firstName);
  formData.append('lastname', lastName);

  return httpClient.put<APIResponse<User>>('/admins/update', formData);
};

const setPermissions = async (permissions: string[], user_id: number) =>
  httpClient
    .post<APIResponse<{ permissions: string[] }>>('/admins/permissions', {
      permissions,
      user_id,
    })
    .then((res) => res.data);

const getPermissions = async () =>
  httpClient
    .get<APIResponse<{ permissions: Permission[] }>>('/permissions')
    .then((res) => res.data);

const getUserCommunties = (uid: string, organisation_id?: string) =>
  httpClient.get<APIResponse<{ organisaitons: Array<UserOrganisation> }>>(
    `users/${uid}/organisations`,
    {
      params: {
        ...(organisation_id && { organisation_id }),
      },
    }
  );

const getDeactivateStatus = (uid: string) =>
  httpClient.get<APIResponse<{ deletable: boolean }>>(
    `users/${uid}/deactivate-check`
  );

type GetUsersFeedbackProps = {
  per_page?: number;
  page?: number;
  sort_by?: keyof UserFeedback;
  sort_direction?: SortDirections;
};
const getUsersFeedback = ({
  per_page,
  page,
  sort_by,
  sort_direction,
}: GetUsersFeedbackProps) =>
  httpClient.get<PaginatedResponse<UserFeedback, 'users'>>('users/feedback', {
    params: {
      per_page,
      page,
      sort: `${sort_by}:${sort_direction}`,
    },
  });

const updateUserStatus = (uid: number, oid: string, status: UserStatus) => {
  const formData = new FormData();

  formData.append('organisation_id', oid);
  formData.append('status', status);

  return httpClient.post(`users/${uid}/status`, formData);
};

const deactivateUser = (uid: string, reason: string) =>
  httpClient.post<APIResponse<any>>(
    `users/deactivate`,
    {},
    {
      params: {
        user_id: uid,
        reason,
      },
    }
  );

const updateUserPermission = (
  uid: number,
  oid: number,
  role: 'admin' | 'regular'
) =>
  httpClient.post<APIResponse<any>>(`users/${uid}/role`, {
    organisation_id: oid,
    role,
  });

export default {
  getUsers,
  getAdmins,
  getAdmin,
  getDeactivateStatus,
  promoteUsersToAdmins,
  updateUserDetails,
  setPermissions,
  getPermissions,
  getUserCommunties,
  getUsersFeedback,
  updateUserStatus,
  deactivateUser,
  updateUserPermission,
};

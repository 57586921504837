import { theme } from '@faxi/web-component-library';
import { flex, fontSize, tablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${flex('column')};
  border-radius: ${theme.sizes.SIZE_16};
  width: ${theme.sizes.SIZE_456};
  background-color: var(--BACKGROUND_1_1);
  padding: ${theme.sizes.SIZE_16};

  ${tablet(css`
    width: 100%;
  `)}

  .app-settings {
    &__description {
      ${fontSize(theme.fontSizes.FONT_14, '20px')};
      font-weight: 400;
      color: var(--SHADE_1_2);
      margin: 0;
    }

    &__action-row {
      ${flex('row', 'space-between', 'center')};
      padding: ${theme.sizes.SIZE_16};
      border-bottom: 1px solid var(--SHADE_1_6);

      &:last-of-type {
        border-bottom: unset;
      }

      &:first-of-type {
        border-bottom: 1px solid var(--SHADE_1_6);
      }

      &__label {
        ${flex('column')};
      }
    }

    &__last-rest-date {
      ${fontSize(theme.fontSizes.FONT_11, '17px')};

      font-weight: 400;
      color: var(--SHADE_1_2);
    }
  }
`;

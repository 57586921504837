import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Container = styled.div`
  .activate-per-community {
    &__search {
      max-width: ${theme.sizes.SIZE_216};
      margin-bottom: ${theme.sizes.SIZE_12};
    }

    &__selected {
      font-size: ${theme.fontSizes.FONT_14};
      font-weight: 600;
      color: var(--SHADE_1_1);
      margin-bottom: ${theme.sizes.SIZE_8};
    }

    &__communities {
      overflow: auto;
      height: fit-content;
      margin-top: ${theme.sizes.SIZE_12};

      &__infinitive-scroll {
        padding: 0 ${theme.sizes.SIZE_8};
        overflow: auto;
        height: ${theme.sizes.SIZE_304};
      }
    }

    &__switch {
      ${flex('row', 'space-between', 'center')};
      border-bottom: 1px solid var(--SHADE_1_8);
      width: 100%;
      padding: ${theme.sizes.SIZE_8} 0;
      cursor: pointer;

      font-weight: 600;
      margin: ${theme.sizes.SIZE_8} 0;

      .wcl-switch__label {
        font-size: ${theme.fontSizes.FONT_14};
        flex: 1;
      }

      &--disabled {
        cursor: not-allowed;

        .wcl-switch__label {
          color: var(--SHADE_1_4);
        }
      }
    }
  }
`;

import { Modal, theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const ManualVerificationModal = styled(Modal)`
  .textarea__container {
    width: 100%;
  }

  .kinto-manual-verification-modal {
    &__switch-container {
      .kinto-modal__error-message {
        margin-top: ${theme.sizes.SIZE_20};
      }
    }
  }
`;

import { useMemo } from 'react';
import classNames from 'classnames';
import { useFieldError, FieldProps } from '@faxi/web-form';
import { Icon, InputProps, Tooltip } from '@faxi/web-component-library';
import PreviewInput from 'components/_molecules/PreviewInput';

import * as Styles from './InputField.styles';

type ValueOnChange = (event: string) => void;

export type InputFieldProps = FieldProps<string, ValueOnChange> &
  InputProps & {
    tooltipContent?: string;
  };

const InputField: React.FC<InputFieldProps> = (
  props: InputFieldProps
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    dirty,
    error,
    touched,
    value,
    readOnly,
    tooltipContent,
    placeholder,
    className,
    label,
    ...rest
  } = props;

  const showError = useFieldError(props);
  const classes = classNames('input-field', className);

  const tooltip = useMemo(
    () =>
      !!tooltipContent && (
        <Tooltip
          placement="top"
          content={tooltipContent}
          disabled={!tooltipContent}
        >
          <span className="input-field__tooltip">
            <Icon name="circle-info" />
          </span>
        </Tooltip>
      ),
    [tooltipContent]
  );

  if (readOnly) {
    return <PreviewInput title={placeholder} content={value} />;
  }

  return (
    <Styles.InputContainer
      className={classes}
      errorText={String(error)}
      label={
        <>
          {label}
          {tooltip}
        </>
      }
      value={value}
      error={showError}
      readOnly={readOnly}
      placeholder={placeholder}
      //To prevent autocomplete modal closing
      onKeyDown={(e) => e.stopPropagation()}
      {...rest}
    />
  );
};

export default InputField;

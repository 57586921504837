import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const TableDataContainer = styled.section`
  ${flex('row', undefined, 'center')}

  > div {
    ${flex('row', undefined, 'center')}
    cursor: pointer;
    margin-right: ${theme.sizes.SIZE_16};

    img {
      margin-right: ${theme.sizes.SIZE_8};
    }
  }
`;

import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const PocsTimeTableData = styled.div`
  ${flex('row', undefined, 'center')}

  .pocs-time-table-data {
    &__icon {
      margin-right: ${theme.sizes.SIZE_10};
    }
  }
`;

import { useCallback, useContext, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import {
  Breadcrumbs,
  Button,
  getColor,
  Heading,
  Modal,
  SelectOption,
  SortOption,
  StatusElement,
  Table,
  TableRef,
  Tooltip,
  useCallbackRef,
  useModalUtilities,
  useQueryParams,
} from '@faxi/web-component-library';
import { uniqueId } from 'lodash';

import { Icon, NoPermissionsPlaceholder, TablePageLayout } from 'components';
import { INameExtended } from 'components/Icon';
import AddAdminRole from 'pages/CreateCommunity/components/AddAdminRole/AddAdminRole.component';
import UserPermissionModal from 'pages/Users/components/UserPermissionModal';

import { appUri, dateFormat } from 'config';
import { useFormButtons, useTablePagination } from 'hooks';
import { ConsentType } from 'models/TableData';
import { TableUser, User } from 'models';
import { apiCommunities } from 'modules';
import { getConsentsFormat } from 'utils/consents';
import { snackBarSuccessMessage } from 'utils';
import { AuthContext } from 'store';

import { FormActions, NoData } from 'Global.styles';
import * as Styled from './AdminsPage.styles';

const crumbs = [
  { text: 'Communities', href: appUri.COMMUNITIES },
  { text: 'Admins', href: '' },
];

const AdminsPage = () => {
  const { communityId } = useParams<{
    communityId: string;
  }>();

  const { admin } = useContext(AuthContext);

  const [usersTable, usersTableRef] = useCallbackRef<TableRef>();

  const selectedUser = useRef<any>(null);

  const {
    params: { community },
  } = useQueryParams<{ community: string }>();

  const {
    open: adminModal,
    openModal: openAdminModal,
    closeModal: closeAdminModal,
  } = useModalUtilities();

  const {
    open: permissionsModal,
    openModal: openPermissionsModal,
    closeModal: closePermissionsModal,
  } = useModalUtilities();

  const [FormButtonsAddAdmin] = useFormButtons('Add', 'Cancel');

  const translationKeys = useMemo(
    () =>
      ({
        id: 'ID',
        name: 'Name',
        email: 'Email',
        lastaccess_at: 'Last active',
        created_at: 'Joined',
        type: 'User type',
        organisations_count: 'Communities',
        consents: 'Accepted consents',
      } as Record<Partial<keyof TableUser>, string>),
    []
  );

  const canEdit = useMemo(
    () =>
      !!admin?.permissions.find((p) => p.name === 'community_management_edit'),
    [admin]
  );

  const renderNumOfCommunities = useCallback(
    (numOfCommunities: number) => (
      <Tooltip placement="top-start" content={'Number of communities'}>
        <div className="kinto-users__table__communities">
          <Button
            className="num-of-communities"
            icon={<Icon name="buildings" />}
            iconPosition="right"
            variant="outline"
          >
            <span>{numOfCommunities}</span>
          </Button>
        </div>
      </Tooltip>
    ),
    []
  );

  const renderConsents = useCallback(
    (consents: Array<ConsentType>) => (
      <div className="kinto-users__table__consents">
        {!consents.length ? (
          <small className="kinto-users__table__consents__title">
            There are no accepted consents.
          </small>
        ) : (
          consents.map(({ name, description }) => (
            <StatusElement<INameExtended>
              key={uniqueId(name)}
              status="canceled"
              icon="info-circle"
              enableTooltip
              tooltipText={description}
            >
              {name}
            </StatusElement>
          ))
        )}
      </div>
    ),
    []
  );

  const mapUserData = useCallback(
    (users: User[]) =>
      users.map(
        ({
          id,
          firstname,
          lastname,
          email,
          lastaccess_at,
          created_at,
          consents,
          organisations_count,
          type,
        }: User) =>
          ({
            id,
            name: `${firstname} ${lastname}`.trim() || '-',
            email,
            lastaccess_at: lastaccess_at
              ? `${dayjs(lastaccess_at).format(dateFormat)}`
              : '-',
            created_at: dayjs(created_at).format(dateFormat),
            type,
            organisations_count: renderNumOfCommunities(organisations_count),
            consents: renderConsents(getConsentsFormat(consents)),
          } as TableUser)
      ),
    [renderConsents, renderNumOfCommunities]
  );

  const {
    data,
    count,
    totalCount,
    requestError,
    totalPages,
    currentPage,
    activeColumnSort,
    setData,
    setCount,
    setCurrentPage,
    loadData,
    setActiveColumnSort,
  } = useTablePagination<TableUser, 'users'>({
    itemsKey: 'users',
    spinnerParentClass: '.admins-page',
    deps: [],
    mappingFunction: (values: Array<User>) => mapUserData(values),
    apiRequest: ({
      per_page,
      currentPage,
      searchString,
      sort_by,
      sort_direction,
    }) =>
      apiCommunities.getCommunityAdmins(communityId, {
        per_page,
        page: currentPage,
        search: searchString,
        sort_by,
        sort_direction,
      }),
  });

  const tableActions = useMemo(
    () => (user: TableUser) => {
      return canEdit ? (
        <TablePageLayout.TableActions
          actions={[
            {
              name: 'Permissions',
              icon: 'user-gear',
              onClick: () => {
                selectedUser.current = user;
                openPermissionsModal();
              },
            },
          ]}
        />
      ) : (
        <NoData>No actions</NoData>
      );
    },
    [canEdit, openPermissionsModal]
  );

  const handleAddAdmin = useCallback(async () => {
    try {
      const admins = usersTable?.checkedRows;

      const { data: updatedCommunity } = await apiCommunities.updateCommunity(
        +communityId,
        { admins }
      );

      if (updatedCommunity) {
        loadData();
        snackBarSuccessMessage('Admin/s successfully added');
        closeAdminModal();
      }
    } catch (e) {
      console.error(e);
    }
  }, [closeAdminModal, communityId, loadData, usersTable?.checkedRows]);

  const handleOnColumnSort = useCallback(
    (sortOptions: SortOption<TableUser>) => {
      setActiveColumnSort(sortOptions);
      setCurrentPage(1);
    },
    [setActiveColumnSort, setCurrentPage]
  );

  return requestError ? (
    <NoPermissionsPlaceholder />
  ) : (
    <TablePageLayout.PageLayoutContainer>
      <Styled.Container className="admins-page">
        <Breadcrumbs className="admins-page__breadcrumbs" crumbs={crumbs} />

        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="admins-page__header"
        >
          Admins
        </Heading>

        {canEdit && (
          <Button
            variant="outline"
            icon={<Icon name="plus" />}
            className="admins-page__add-admin-button"
            onClick={openAdminModal}
          >
            Add an admin
          </Button>
        )}

        {data.length === 0 ? (
          <div className="admins-page__empty-state">
            Sorry, there are no admins in this community.
          </div>
        ) : (
          <Table<TableUser>
            expandable
            tableId="admins-table"
            className="admins-page__table"
            translationKeys={translationKeys}
            excludeColumns={['statusData']}
            initialSort={activeColumnSort}
            breakAtMaxWidth={1200}
            tableData={data}
            tableActions={tableActions}
            excludeSortColumns={['consents', 'status']}
            paginationData={{
              currentPage,
              limit: count,
              totalCount,
              totalPages,
            }}
            goToPageInputProps={{ placeholder: 'Go to page' }}
            onPageChange={setCurrentPage}
            onLimitChange={(data: SelectOption) => {
              setCurrentPage(1);
              setCount(+data.value);
            }}
            onColumnSortClicked={handleOnColumnSort}
          />
        )}

        {adminModal && (
          <Modal
            title="Add admin"
            modalClassName="admins-page__add-admin-modal"
            footer={
              <FormActions className="kinto-modal__actions">
                <FormButtonsAddAdmin.Submit
                  disabled={usersTable?.checkedRows.length === 0}
                  onClick={() => {
                    handleAddAdmin();
                  }}
                />
                <FormButtonsAddAdmin.Cancel onClick={() => closeAdminModal()} />
              </FormActions>
            }
            onClose={closeAdminModal}
          >
            <AddAdminRole tableRef={usersTableRef} inModal />
          </Modal>
        )}

        {permissionsModal && (
          <UserPermissionModal
            userId={selectedUser.current?.id}
            username={selectedUser.current?.name}
            community={{
              id: +communityId,
              name: community,
              type: 'admin',
              status: 'Y',
            }}
            onApplyPermission={() => {
              setData((data) =>
                data.filter((admin) => admin.id !== selectedUser.current?.id)
              );
              closePermissionsModal();
            }}
            onClose={closePermissionsModal}
          />
        )}
      </Styled.Container>
    </TablePageLayout.PageLayoutContainer>
  );
};

export default AdminsPage;

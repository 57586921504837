import { FC, useCallback, useMemo } from 'react';
import {
  Button,
  Input,
  RadioGroup,
  Table,
  TablePagination,
  useQueryParams,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';

import {
  LogsSearchParam,
  LOGS_SEARCH_OPTIONS,
  SEARCH_PARAMS,
  TRANSLATION_KEYS,
  PARAMS_PLACEHOLDERS,
  EXCLUDED_COLUMNS,
} from './utils';
import { apiLogs } from 'modules';
import { Log } from 'models';
import { dateFormat, reportsConfig } from 'config';
import { useTablePagination } from 'hooks';
import {
  CalendarField,
  Icon,
  NoPermissionsPlaceholder,
  TablePageLayout,
} from 'components';
import { downloadLink } from 'utils';

import { NoData } from 'Global.styles';
import * as Styled from './Logs.styles';

const Logs: FC = () => {
  const {
    params: { searchParam = SEARCH_PARAMS.user_id },
    setQueryParam,
  } = useQueryParams<{
    page: string;
    searchParam: LogsSearchParam;
    search: string;
  }>();

  const {
    requestError,
    activeColumnSort,
    count,
    totalCount,
    totalPages,
    currentPage,
    search,
    data,
    setCount,
    setSearch,
    setCurrentPage,
    setActiveColumnSort,
  } = useTablePagination<Log, 'logs'>({
    itemsKey: 'logs',
    initialParams: { sortBy: 'device_time', sortDirection: 'desc' },
    spinnerParentClass: '.kinto-app-logs',
    mappingFunction: (values: Array<Log>) =>
      values.map(
        ({ device_time, email, id, name, type, user_id }) =>
          ({
            device_time: device_time
              ? dayjs(device_time).format(dateFormat)
              : '-',
            user_id: user_id || '-',
            email: email || '-',
            type: type || '-',
            name,
            id,
          } as Log)
      ),
    apiRequest: ({
      per_page,
      currentPage,
      searchString,
      sort_by,
      sort_direction,
    }) => {
      const search = searchString
        ? { param: searchParam, value: searchString }
        : undefined;

      return apiLogs.getLogs({
        per_page,
        page: currentPage,
        searchString: search,
        sort_by,
        sort_direction,
      });
    },
  });

  const paginationData = useMemo(
    () =>
      ({
        currentPage,
        totalCount,
        totalPages,
        limit: count,
      } as TablePagination),
    [count, currentPage, totalCount, totalPages]
  );

  const handleChangeSearchParam = useCallback(
    (value: string) => {
      setSearch('');
      setCurrentPage(1);
      setQueryParam('searchParam', SEARCH_PARAMS[value as LogsSearchParam]);
    },
    [setCurrentPage, setQueryParam, setSearch]
  );

  const handleChangeSearchInput = useCallback(
    (value: string, error?: boolean) => {
      setSearch(value, error);
      setQueryParam('page', 1);
    },
    [setQueryParam, setSearch]
  );

  const tableActions = useMemo(
    () =>
      ({ id, name }: Log) =>
        (
          <TablePageLayout.TableActions
            actions={[
              {
                name: 'Download',
                icon: 'chevron-right',
                iconPosition: 'right',
                onClick: async () => {
                  const { data: fileBlob } = await apiLogs.downloadLogs(id);
                  const url = URL.createObjectURL(fileBlob);
                  downloadLink(url, name);
                },
              },
            ]}
          />
        ),
    []
  );

  if (requestError) return <NoPermissionsPlaceholder />;

  return (
    <Styled.LogsContainer className="kinto-app-logs" title="Application Logs">
      <div className="kinto-app-logs__actions">
        <RadioGroup
          className="kinto-app-logs__search-param"
          name="searchParam"
          orientation="row"
          selected={searchParam}
          options={LOGS_SEARCH_OPTIONS}
          onChange={handleChangeSearchParam}
        />

        {searchParam !== 'date' ? (
          <Input
            value={search}
            prefixIcon={<Icon name="search" />}
            {...(search && {
              suffixIcon: (
                <Button
                  variant="ghost"
                  icon={<Icon name="xmark" />}
                  onClick={() => handleChangeSearchInput('')}
                />
              ),
            })}
            placeholder={PARAMS_PLACEHOLDERS[searchParam]}
            onChange={handleChangeSearchInput}
          />
        ) : (
          <CalendarField
            className="kinto-page__calendar"
            rightLimitDate={dayjs()}
            leftLimitDate={reportsConfig.range_start}
            {...(search && {
              suffixIcon: (
                <Button
                  variant="ghost"
                  icon={<Icon name="xmark" />}
                  onClick={() => handleChangeSearchInput('')}
                />
              ),
            })}
            placeholder={PARAMS_PLACEHOLDERS[searchParam]}
            onSetDate={(date) => {
              handleChangeSearchInput(date ? date.format('YYYY-MM-DD') : '');
            }}
            value={search}
            openAtToday
          />
        )}
      </div>

      {data.length === 0 ? (
        <NoData className="kinto-no-data">
          Sorry, there are no results that match your search.
        </NoData>
      ) : (
        <Table<Log>
          tableId="logs-table"
          className="kinto-app-logs__table"
          expandable
          tableActions={tableActions}
          tableData={data}
          initialSort={activeColumnSort}
          paginationData={paginationData}
          goToPageInputProps={{ placeholder: 'Go to page' }}
          excludeColumns={EXCLUDED_COLUMNS}
          translationKeys={TRANSLATION_KEYS}
          onPageChange={setCurrentPage}
          onLimitChange={(limit) => {
            setCurrentPage(1);
            setCount(+limit.value);
          }}
          onColumnSortClicked={(sort) => {
            setActiveColumnSort(sort);
            setCurrentPage(1);
          }}
        />
      )}
    </Styled.LogsContainer>
  );
};

export default Logs;

import { useMemo } from 'react';
import classNames from 'classnames';

import * as Styles from './Legend.styles';

type LegendInfoType = {
  iconUrl: string;
  title: string;
};

type LegendProps = {
  className?: string;
};

const Legend: React.FC<LegendProps> = (props) => {
  const { className } = props;

  const classes = classNames('map-legend', className);
  const pinInfos = useMemo(
    () =>
      [
        {
          title: 'Matched by time (after journey finished)',
          iconUrl: '/assets/svg/matched-by-time-after-empty.svg',
        },
        {
          title: 'Matched by time',
          iconUrl: '/assets/svg/matched-by-time-empty.svg',
        },
        {
          title: 'Not matched by time',
          iconUrl: '/assets/svg/not-matched-by-time-empty.svg',
        },
        {
          title: 'BLE',
          iconUrl: '/assets/svg/BLE-empty.svg',
        },
        {
          title: 'Aborted',
          iconUrl: '/assets/svg/error-passenger-trax-map-pin-empty.svg',
        },
        {
          title: 'GPS Trax',
          iconUrl: '/assets/svg/ble-driver-trax-map-pin.svg',
        },
        {
          title: 'GPS & BLE Trax',
          iconUrl: '/assets/svg/driver-trax-map-pin.svg',
        },
        {
          title: 'Trax matched',
          iconUrl: '/assets/svg/trax-matched.svg',
        },
        {
          title: 'Trax not matched',
          iconUrl: '/assets/svg/trax-not-matched.svg',
        },
      ] as Array<LegendInfoType>,
    []
  );

  return (
    <Styles.LegendContainer className={classes}>
      {pinInfos.map(({ iconUrl, title }, index) => (
        <div className="map-legend__pin-info" key={`${title}_${index}`}>
          <img
            className="map-legend__pin-info__pin"
            alt="pin-info"
            src={iconUrl}
          />
          <p className="map-legend__pin-info__title">{title}</p>
        </div>
      ))}
    </Styles.LegendContainer>
  );
};

export default Legend;

import { ColumnsModalLabels } from '@faxi/web-component-library/build/components/_organisms/Table/components/ColumnsModal/ColumnsModal.component';
import dayjs from 'dayjs';

export { default as appUri } from './appUri';
export { default as routes } from './routes';
export { default as RoutesRenderer } from './RoutesRenderer';

export const reportsConfig = {
  range_start: dayjs('01/01/2015'),
};

export const dateFormat = 'DD.MM.YYYY';
export const DATE_STRING_REGEX = /\d{4}-\d{2}-\d{2}$/;

export const DATE_PICKER_LABELS = {
  last3Days: 'Last 3 days',
  last7Days: 'Last 7 days',
  last30Days: 'Last 30 days',
  customDate: 'Custom date',
};

export const DATE_PICKER_ACTIONS_LABELS = {
  submitLabel: 'Apply',
  cancelLabel: 'Cancel',
};

export const COLUMNS_SETTINGS_LABELS = {
  cancel: 'Cancel',
  save: 'Save Changes',
  title: 'Column settings',
  unselected: 'Unselected',
  subtitle: 'You can hide, show and reorder columns in the table',
  warning: 'You must select at least one visible column',
} as ColumnsModalLabels;

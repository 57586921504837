import * as Styles from './JourneyMapActions.styles';

import {
  Button,
  Expander,
  Heading,
  useCallbackRef,
  useResize,
  ExpanderRef,
  getColor,
} from '@faxi/web-component-library';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router';

import { Icon, Pin } from 'components';
import { MAP_MARKER_TYPE } from 'models/MapMarker';
import MapDataContext from 'pages/Map/providers/MapData/MapData.context';
import MarkersContext from 'pages/Map/providers/Markers/Markers.context';
import { NavLink } from 'react-router-dom';
import { ParticipantType } from 'models/Participant';
import { PassengerMap } from 'models';
import { appUri } from 'config';
import classNames from 'classnames';
import { responsiveSm } from '@faxi/web-css-utilities';

type JourneyMapActinonsProps = {
  className?: string;
  journeyId: string;
  passengersTraxes: PassengerMap;
};

type MapActionsProps = {
  mapActionType: ParticipantType;
  passengerIndex?: number;
  visible: boolean;
  onVisible?: () => void;
  onSelect?: () => void;
};

const MapAction: FC<MapActionsProps> = (props) => {
  const { visible, passengerIndex, mapActionType, onVisible, onSelect } = props;

  const actionTitle = useMemo(
    () =>
      mapActionType === 'passenger'
        ? `Passenger ${passengerIndex} Traxes`
        : `${mapActionType} Traxes`,
    [mapActionType, passengerIndex]
  );

  return (
    <div
      className={classNames('sa-journey-map-actions__action', {
        'sa-journey-map-actions__action--active': visible,
      })}
    >
      <div className="sa-journey-map-actions__action__name" onClick={onSelect}>
        <Pin
          type={mapActionType === 'driver' ? 'driver' : 'passenger'}
          index={passengerIndex}
        />

        {actionTitle}
      </div>
      <span />
      <Button
        onClick={onVisible}
        variant="ghost"
        iconPosition="right"
        icon={<Icon name={visible ? 'eye' : 'eye-slash'} />}
      >
        {visible ? 'Hide' : 'Show'}
      </Button>
    </div>
  );
};

const JourneyMapActions: FC<JourneyMapActinonsProps> = (
  props: JourneyMapActinonsProps
) => {
  const { className, journeyId, passengersTraxes } = props;

  const belowPhablet = useResize(responsiveSm);
  const [expander, expanderRef] = useCallbackRef<ExpanderRef>();
  const history = useHistory();

  const { mapMarkers } = useContext(MarkersContext);
  const { driverMap } = useContext(MapDataContext);

  const [driverVisible, setDriverVisible] = useState<boolean>(true);
  const [passengersVisible, setPassengersVisible] = useState<
    Array<{
      passengerId: string;
      visible: boolean;
    }>
  >([]);

  const { communityId, journeyId: journeyID } = useParams<{
    communityId: string;
    journeyId: string;
  }>();

  const handleDriverVisible = useCallback(() => {
    setDriverVisible((oldVisible) => {
      mapMarkers.forEach(
        (m) =>
          m.type === MAP_MARKER_TYPE.DRIVER_TRAX && m.setVisible(!oldVisible)
      );

      return !oldVisible;
    });
  }, [mapMarkers]);

  const handlePassengerVisible = useCallback(
    (passengerId: string) => {
      setPassengersVisible((oldVisible) =>
        oldVisible?.map((data) => {
          if (data.passengerId === passengerId) {
            const cbData = { passengerId, visible: !data.visible };

            mapMarkers.forEach((m) => {
              if (
                m.userId === passengerId &&
                m.type === MAP_MARKER_TYPE.PASSENGER_TRAX
              )
                return m.setVisible(!data?.visible);
              else return m;
            });

            return cbData;
          } else return data;
        })
      );
    },
    [mapMarkers]
  );

  const mapActions = useMemo(
    () => (
      <>
        <div className="sa-journey-map-actions__container">
          <MapAction
            mapActionType={driverMap.participant_type!}
            visible={driverVisible}
            onVisible={handleDriverVisible}
            onSelect={() =>
              communityId
                ? history.push(
                    appUri.COMMUNITIES_JOURNEY_DRIVER_TRAXES(
                      communityId,
                      journeyID
                    )
                  )
                : history.push(appUri.JOURNEYS_DRIVER_TRAXES(journeyId))
            }
          />
          {passengersVisible?.map(({ visible, passengerId }, index) => (
            <MapAction
              key={passengerId}
              passengerIndex={index + 1}
              mapActionType="passenger"
              visible={visible}
              onVisible={() => handlePassengerVisible(passengerId!)}
              onSelect={() =>
                communityId
                  ? history.push(
                      appUri.COMMUNITIES_JOURNEY_PASSENGER_TRAXES(
                        communityId,
                        journeyID,
                        passengerId
                      )
                    )
                  : history.push(
                      appUri.JOURNEYS_PASSENGER_TRAXES(journeyId, passengerId)
                    )
              }
            />
          ))}
        </div>

        <hr className="sa-journey-map-actions__hr" />

        <NavLink
          className="sa-journey-map-actions__journey-details"
          to={
            communityId
              ? `/communities/${communityId}/journey-list/journey/${journeyID}`
              : `/journeys/${journeyId}`
          }
        >
          <Icon name="route" />
          <span>Journey Details</span>
        </NavLink>
      </>
    ),
    [
      communityId,
      driverMap,
      driverVisible,
      handleDriverVisible,
      handlePassengerVisible,
      history,
      journeyID,
      journeyId,
      passengersVisible,
    ]
  );

  const mapHeader = useMemo(
    () => (
      <Heading
        level="2"
        color={getColor('--PRIMARY_1_1')}
        className={classNames('sa-journey-map-actions__title', {
          'sa-journey-map-actions__title--open': expander?.open,
        })}
      >
        {`Journey ${journeyId}`}
        {belowPhablet && (
          <Button variant="ghost" icon={<Icon name="arrow-up" />}>
            {expander?.open ? 'Close' : 'Open'}
          </Button>
        )}
      </Heading>
    ),
    [expander, belowPhablet, journeyId]
  );

  useEffect(() => {
    if (!passengersTraxes) return;

    setPassengersVisible(
      Object.entries(passengersTraxes)?.map(([key, value]) => ({
        ...value,
        passengerId: key,
        visible: true,
      }))
    );
  }, [passengersTraxes, driverMap]);

  return (
    <Styles.MapActions
      className={classNames('sa-journey-map-actions', className)}
    >
      {belowPhablet ? (
        <Expander
          ref={expanderRef}
          className="sa-journey-map-actions__expander"
          header={mapHeader}
          body={mapActions}
          bodyAs="div"
        />
      ) : (
        <>
          {mapHeader}
          {mapActions}
        </>
      )}
    </Styles.MapActions>
  );
};

export default JourneyMapActions;

import { useState, useMemo, Fragment } from 'react';
import { Button, ButtonProps } from '@faxi/web-component-library';
import { Spinner } from 'components';

const useFormButtons = (
  submitLabel = 'Save',
  cancelLabel = 'Cancel'
): [
  {
    Submit: React.FC<ButtonProps>;
    Cancel: React.FC<ButtonProps>;
  },
  React.Dispatch<React.SetStateAction<boolean>>,
  boolean
] => {
  const [loading, setLoading] = useState(false);

  const submitButton = useMemo(
    () => (props: ButtonProps) =>
      (
        <Button type="submit" {...props}>
          {loading ? (
            <Spinner type="in-button" />
          ) : (
            <Fragment>{submitLabel}</Fragment>
          )}
        </Button>
      ),
    [loading, submitLabel]
  );

  const cancelButton = useMemo(
    () => (props: ButtonProps) =>
      (
        <Button variant="ghost" {...props}>
          {cancelLabel}
        </Button>
      ),
    [cancelLabel]
  );

  return [
    {
      Submit: submitButton,
      Cancel: cancelButton,
    },
    setLoading,
    loading,
  ];
};

export default useFormButtons;

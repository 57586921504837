import { PropsWithChildren, useCallback, useEffect } from 'react';
import { SnackBarProps, useUtilities } from '@faxi/web-component-library';
import { Router as ReactRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import env from 'env';
import Icon from '../../components/Icon';
import { routes } from 'config';
import AuthProvider from 'store/AuthProvider';
import authBus, { AUTH_BUS_EVENTS } from 'modules/authBus';

export const history = createBrowserHistory({
  basename: env.VITE_APP_PUBLIC_URL,
  getUserConfirmation: async (message, cb) => {
    // if message is undefined
    // don't show confirmation window
    if (!message) return cb(true);
    // this is the default behavior
    // const allowTransition = window.confirm(message);

    authBus.broadcastEvent(AUTH_BUS_EVENTS.UNSAVED_CHANGES, cb);
  },
});

const ScrollToTop: React.FC<any> = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const ProvidersWrapper = ({ children }: PropsWithChildren) => {
  const { prompts, showSnackBar } = useUtilities();

  const unsavedChanges = useCallback(
    async (cb: (result: boolean) => void) => {
      if (!cb) return;

      let skipClosing: Boolean = false;

      const viewChanges = await prompts.unsaved({
        cancelBtnText: 'Continue',
        submitBtnText: 'View changes',
        title: 'You have unsaved changes',
        titleIcon: <Icon name="triangle-exclamation" />,
        onOverlayClick: () => (skipClosing = true),
      });

      if (viewChanges || skipClosing) {
        cb(false);
        return;
      }

      cb(true);
    },
    [prompts]
  );

  const handleShowSnackBar = useCallback(
    (payload: {
      props: SnackBarProps;
      config?: {
        constant?: boolean;
        disappearAfter?: number;
      };
    }) => {
      showSnackBar(payload.props, payload.config);
    },
    [showSnackBar]
  );

  useEffect(() => {
    authBus.addEventListener(AUTH_BUS_EVENTS.UNSAVED_CHANGES, unsavedChanges);
    authBus.addEventListener(AUTH_BUS_EVENTS.SHOW_SNACKBAR, handleShowSnackBar);

    return () => {
      authBus.removeEventListener(
        AUTH_BUS_EVENTS.UNSAVED_CHANGES,
        unsavedChanges
      );

      authBus.removeEventListener(
        AUTH_BUS_EVENTS.SHOW_SNACKBAR,
        handleShowSnackBar
      );
    };
  }, [handleShowSnackBar, unsavedChanges]);

  return <AuthProvider>{children}</AuthProvider>;
};

const Router: React.FC = (): JSX.Element => {
  return (
    <ReactRouter history={history}>
      <ScrollToTop />
      <ProvidersWrapper>{renderRoutes(routes)}</ProvidersWrapper>
    </ReactRouter>
  );
};

export default Router;

import { Modal as ModalComponent, theme } from '@faxi/web-component-library';
import { flexGap, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Modal = styled(ModalComponent)`
  .textarea__container {
    width: 100%;

    &__textarea {
      min-width: 100%;
      min-height: ${theme.sizes.SIZE_104};

      &:hover {
        transition: unset;
      }
    }
  }

  .wcl-modal {
    max-width: ${theme.sizes.SIZE_504};

    &__header,
    &__footer,
    &__main {
      margin: unset;
      padding: ${theme.sizes.SIZE_24};
    }

    &__header {
      padding-bottom: unset;

      [class*='title'] {
        ${fontSize(theme.fontSizes.FONT_18, theme.sizes.SIZE_24)};

        font-weight: 400;
        color: var(--SHADE_1_1);
        margin: 0 0 ${theme.sizes.SIZE_8};
        padding-right: ${theme.sizes.SIZE_20};
      }
    }

    &__main: {
      padding: 0 ${theme.sizes.SIZE_24};
    }

    &__footer {
      ${flexGap(theme.sizes.SIZE_64, 'row')};

      width: 100%;
      justify-content: flex-end;
    }
  }
`;

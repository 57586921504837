import { FC } from 'react';
import { useElementClass } from '@faxi/web-component-library';

import Icon from 'components/Icon';

import * as Styled from './ViewOnlyBanner.styles';

const ViewOnlyBanner: FC = (): JSX.Element => {
  useElementClass('.root', 'root--extra-element', true);

  return (
    <Styled.Container className="kinto-view-only-banner">
      <Icon name="eye" />
      <div>
        <span>View only</span>: You have the permission to view this page only
      </div>
    </Styled.Container>
  );
};

export default ViewOnlyBanner;

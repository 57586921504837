import { FC, memo, useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import {
  ModalProps,
  ModalRef,
  useCallbackRef,
} from '@faxi/web-component-library';
import {
  FieldArray,
  Form,
  FormField,
  FormRef,
  validators,
  FormFieldArray,
} from '@faxi/web-form';
import { useCallbackAsync, useFormButtons } from 'hooks';
import { snackBarSuccessMessage } from 'utils';
import { apiJourneys } from 'modules';
import { TableJourney } from 'pages/Journeys/Journeys.page';
import PocsTimeTableData from 'pages/Journeys/components/PocsTimeTableData';
import { composeValidators } from 'validation/validators/specific';
import {
  JourneyParticipant,
  JourneyParticipantType,
  JOURNEY_PARTICIPANT_TYPE,
} from 'models/Journey';
import { Icon, TextareaField } from 'components';
import {
  getParticipantsFields,
  mapOppositeVerifications,
  mapVerifications,
} from './utils';
import VerificationSwitch from './components/VerificationSwitch';

import { FormActions } from 'Global.styles';
import * as Styled from './ManualVerificationModal.styles';

export type ManualVerificationModalProps = {
  className?: string;
  journey?: TableJourney;
  initialData?: string;
  onVerify: React.Dispatch<React.SetStateAction<TableJourney[]>>;
} & ModalProps;

export type VerificationParticipantForm = JourneyParticipant & {
  verified: boolean;
  type: JourneyParticipantType;
};

export type ManualVerificationFormData = {
  reason: string;
  participants: Array<VerificationParticipantForm>;
};

const ManualVerificationModal: FC<ManualVerificationModalProps> = (
  props: ManualVerificationModalProps
) => {
  const { className, journey, onVerify, ...rest } = props;

  const modalRef = useRef<ModalRef>(null);

  const [FormButtons] = useFormButtons('Verify', 'Cancel');

  const [form, formRef] = useCallbackRef<FormRef>();

  const initialData = useMemo<Partial<ManualVerificationFormData>>(() => {
    if (!journey) return {};

    const participants = mapVerifications(
      journey.participants
    ) as Array<VerificationParticipantForm>;

    return {
      reason: journey?.pocs_reason,
      participants,
    };
  }, [journey]);

  const validations = useMemo(
    () => ({
      reason: composeValidators(
        validators.general.required('This field is required'),
        validators.general.maxLength(1000, 'Maximum length is 1000 characters.')
      ),
      participants: (
        values: Array<VerificationParticipantForm>,
        fields: any
      ) => {
        const participants = getParticipantsFields(
          fields.participants as FormFieldArray
        );

        if (!participants?.length) return undefined;
        const driverHasVerification = participants[0]?.value;

        const hasVerificationSwitched = participants.some(
          (p, index) => p?.value && !values?.[index].pocs_time
        );

        if (driverHasVerification && hasVerificationSwitched) {
          return undefined;
        } else {
          return 'To verify passengers driver needs to verified as well';
        }
      },
    }),
    []
  );

  const [handleManualVerification, loading] = useCallbackAsync({
    showSpinner: false,
    callback: async (values: {
      reason: string;
      participants: Array<VerificationParticipantForm>;
    }) => {
      const switchValues = getParticipantsFields(
        form.fields.participants as FormFieldArray
      );

      const verificationData = {
        reason: values.reason,
        users: values.participants
          .filter((_, index) => switchValues[index].value)
          .map((p) => p.id)
          .join(','),
      };

      if (!journey) return;

      const { data } = await apiJourneys.addJourneyPocs(
        journey?.id_clean,
        verificationData
      );

      if (data.status === 'success') {
        onVerify((old) =>
          old.map((journeyData) => {
            if (journeyData.id_clean === journey.id_clean) {
              return {
                ...journeyData,
                participants: mapOppositeVerifications(
                  values,
                  switchValues,
                  data.pocs
                ),
                pocs_reason: verificationData.reason,
                pocs_time: (
                  <PocsTimeTableData
                    pocsTime={data.pocs}
                    pocsReason={verificationData.reason}
                  />
                ),
                pocs_type: 'M',
              };
            } else return journeyData;
          })
        );

        snackBarSuccessMessage('Successfully added journey verification');

        modalRef.current?.close();
      }
    },
  });

  const formWrapper = useCallback(
    ({ children }: any) => (
      <Form
        ref={formRef}
        onSubmit={handleManualVerification}
        strictValidation={false}
        initialData={initialData}
        children={children}
        className="kinto-modal__form"
      />
    ),
    [initialData, formRef, handleManualVerification]
  );

  return (
    <Styled.ManualVerificationModal
      className={classNames('kinto-manual-verification-modal', className)}
      loading={loading}
      title={`Manual verification (${journey?.id_clean})`}
      childrenWrapper={formWrapper}
      ref={modalRef}
      footer={
        <FormActions className="kinto-modal__actions">
          <FormButtons.Submit disabled={!form?.syncFormValid || loading} />
          <FormButtons.Cancel onClick={() => modalRef.current?.close()} />
        </FormActions>
      }
      {...rest}
    >
      <div className="form__fields">
        <FieldArray name="participants" validate={validations.participants}>
          {({ fields, error }) => (
            <div className="kinto-manual-verification-modal__switch-container">
              {fields?.map(({ name, value }) => (
                <VerificationSwitch
                  key={name}
                  fieldName={name}
                  disabled={
                    fields.length === 1 &&
                    value.type === JOURNEY_PARTICIPANT_TYPE.DRIVER
                  }
                  {...value}
                />
              ))}
              {error &&
                form?.fields['participants']?.dirty &&
                !initialData?.participants?.[0].pocs_time &&
                fields.length > 1 && (
                  <div className="kinto-modal__error-message">
                    <Icon name="ban" />
                    <p className="error-text">{error}</p>
                  </div>
                )}
            </div>
          )}
        </FieldArray>

        <FormField
          name="reason"
          component={TextareaField}
          placeholder="Type here..."
          label="Reason"
          required
          requiredLabel="Required"
          validate={validations.reason}
          noresize
          disabled={!!initialData?.reason}
          rows={6}
        />
      </div>
    </Styled.ManualVerificationModal>
  );
};

export default memo(ManualVerificationModal);

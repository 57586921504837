import styled from 'styled-components';
import { fontSize } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const Container = styled.div`
  width: 100%;
  position: relative;

  > label {
    color: var(--SHADE_1_2);
    display: flex;
    height: ${theme.sizes.SIZE_24};
    align-items: flex-end;
    margin-bottom: ${theme.sizes.SIZE_4};
    ${fontSize(theme.fontSizes.FONT_16)}
  }

  .kinto-password-field {
    &__eye {
      cursor: pointer;
    }

    &__caps-lock-active {
      position: absolute;
      color: var(--ALERT_WARNING_1_1);
      ${fontSize(theme.fontSizes.FONT_12)};
      margin-top: ${theme.sizes.SIZE_2};
      opacity: 0;

      &--exists {
        animation: fadeOutUp 250ms ease-in-out;
      }

      &--visible {
        animation: fadeInDown 250ms ease-in-out;
        opacity: 1;
      }
    }
  }
`;

import { APIResponse, PaginatedResponse, ServiceAccount } from 'models';

import httpClient from '../httpClient';
import { ServiceAccountPermission } from 'pages/ServiceAccounts/ServiceAccounts.component';

export type ServiceAccountApiKey = {
  id: number;
  hash: string;
};

const getKeys = async () =>
  httpClient
    .get<PaginatedResponse<ServiceAccountApiKey, 'api_keys'>>('/keys', {
      params: {
        sort: `id:desc`,
      },
    })
    .then((res) => res.data);

const addKey = () =>
  httpClient
    .post<{ data: ServiceAccountApiKey }>('/keys')
    .then((res) => res.data);

const deleteKey = (id: number) =>
  httpClient.delete(`/keys/${id}`).then((res) => res.data);

const getServiceAccounts = (hash: string) =>
  httpClient.get<PaginatedResponse<ServiceAccount, 'service_accounts'>>(
    `/service-accounts/hash/${hash}`
  );

const getServiceAccountsPermissions = () =>
  httpClient.get<PaginatedResponse<ServiceAccountPermission, 'permissions'>>(
    '/permissions/route'
  );

const addServiceAccount = ({
  api_key_id,
  organisation_id,
  organisation,
  permissions,
}: Pick<ServiceAccount, 'api_key_id' | 'organisation_id' | 'organisation'> & {
  permissions: string[];
}) =>
  httpClient.post<APIResponse<ServiceAccount>>('/service-accounts', {
    api_key_id,
    organisation_id,
    organisation,
    permissions,
  });

const deleteServiceAccount = (id: number) =>
  httpClient.delete(`/service-accounts/${id}`);

const updateServiceAccount = (
  serviceAccount: Pick<
    ServiceAccount,
    'id' | 'organisation_id' | 'organisation'
  > & { permissions: string[] }
) =>
  httpClient.put<APIResponse<ServiceAccount>>(
    `/service-accounts`,
    serviceAccount
  );

export default {
  getKeys,
  addKey,
  deleteKey,
  getServiceAccounts,
  getServiceAccountsPermissions,
  addServiceAccount,
  deleteServiceAccount,
  updateServiceAccount,
};

import {
  extendPropToFaNameMap,
  Icon,
  IconProps,
  IName,
  propToFaNameMap,
} from '@faxi/web-component-library';

export type INameExtended =
  | (
      | 'arrow-up'
      | 'badge-check'
      | 'browser'
      | 'browser-solid'
      | 'buildings'
      | 'buildings-solid'
      | 'calendar-clock'
      | 'calendar-day'
      | 'calendar-days'
      | 'cars'
      | 'chart-simple'
      | 'chart-simple-solid'
      | 'check-circle'
      | 'check-to-slot'
      | 'check-to-slot-solid'
      | 'circle-check-fas'
      | 'circle-check'
      | 'circle-parking'
      | 'clipboard-list-check'
      | 'circle'
      | 'clock'
      | 'cog'
      | 'cog-solid'
      | 'comment'
      | 'copy'
      | 'envelope'
      | 'eye-slash'
      | 'eye'
      | 'file-certificate'
      | 'gamepad-modern'
      | 'gear'
      | 'id-card'
      | 'image'
      | 'info-circle'
      | 'location-crosshairs'
      | 'lock-alt'
      | 'map-location-dot'
      | 'minus'
      | 'money-check-dollar-pen'
      | 'money-check-dollar-pen-solid'
      | 'pen'
      | 'pen'
      | 'person-biking'
      | 'person-walking'
      | 'play'
      | 'regular-users'
      | 'route'
      | 'route-solid'
      | 'search'
      | 'sign-out'
      | 'sliders-up'
      | 'sort-up'
      | 'sort'
      | 'stars'
      | 'sterling-sign'
      | 'times'
      | 'user-circle'
      | 'user-circle-solid'
      | 'user-clock'
      | 'user-gear'
      | 'user-group'
      | 'user-lock'
      | 'user-pen'
      | 'user-pen-solid'
      | 'user'
      | 'users-gear'
      | 'users-gear-solid'
      | 'users'
      | 'users-solid'
      | 'gift'
      | 'gift-solid'
      | 'magnifying-glass-chart'
      | 'circle-exclamation-solid'
      | 'message-lines'
      | 'filter'
    )
  | IName;

const customMapNameToFa: Record<INameExtended, string[]> = {
  'arrow-up': ['fa-light', 'fa-arrow-up'],
  'badge-check': ['fa-light', 'fa-badge-check'],
  browser: ['fa-light', 'fa-browser'],
  'browser-solid': ['fa-solid', 'fa-browser'],
  buildings: ['fa-light', 'fa-buildings'],
  'buildings-solid': ['fa-solid', 'fa-buildings'],
  'calendar-clock': ['fa-light', 'fa-calendar-clock'],
  'calendar-day': ['fa-light', 'fa-calendar-day'],
  'calendar-days': ['fa-light', 'fa-calendar-days'],
  cars: ['fa-light', 'fa-cars'],
  'chart-simple': ['fa-light', 'fa-chart-simple'],
  'chart-simple-solid': ['fa-solid', 'fa-chart-simple'],
  'check-circle': ['fa-light', 'fa-check-circle'],
  'check-to-slot': ['fa-light', 'fa-check-to-slot'],
  'check-to-slot-solid': ['fa-solid', 'fa-check-to-slot'],
  'circle-check-fas': ['fa-solid', 'fa-circle-check'],
  'circle-parking': ['fa-light', 'fa-circle-parking'],
  'clipboard-list-check': ['fa-light', 'fa-clipboard-list-check'],
  clock: ['fa-light', 'fa-clock'],
  cog: ['fa-light', 'fa-cog'],
  comment: ['fa-light', 'fa-comment'],
  'cog-solid': ['fa-solid', 'fa-cog'],
  copy: ['fa-light', 'fa-copy'],
  envelope: ['fa-light', 'fa-envelope'],
  'eye-slash': ['fa-light', 'fa-eye-slash'],
  eye: ['fa-light', 'fa-eye'],
  'file-certificate': ['fa-light', 'fa-file-certificate'],
  'gamepad-modern': ['fa-light', 'fa-gamepad-modern'],
  gear: ['fa-light', 'fa-gear'],
  'id-card': ['fa-light', 'fa-id-card'],
  image: ['fa-light', 'fa-image'],
  'info-circle': ['fa-light', 'fa-info-circle'],
  'location-crosshairs': ['fa-light', 'fa-location-crosshairs'],
  'lock-alt': ['fa-light', 'fa-lock-alt'],
  'map-location-dot': ['fa-light', 'fa-map-location-dot'],
  minus: ['fa-light', 'fa-minus'],
  'money-check-dollar-pen': ['fa-light', 'fa-money-check-dollar-pen'],
  'money-check-dollar-pen-solid': ['fa-solid', 'fa-money-check-dollar-pen'],
  'person-biking': ['fa-light', 'fa-person-biking'],
  'person-walking': ['fa-light', 'fa-person-walking'],
  play: ['fa-light', 'fa-play'],
  'regular-users': ['fa-light', 'fa-users'],
  route: ['fa-light', 'fa-route'],
  'route-solid': ['fa-solid', 'fa-route'],
  search: ['fa-light', 'fa-search'],
  'sign-out': ['fa-light', 'fa-sign-out'],
  'sliders-up': ['fa-light', 'fa-sliders-up'],
  'sort-up': ['fa-light', 'fa-sort-up'],
  stars: ['fa-light', 'fa-stars'],
  'sterling-sign': ['fa-light', 'fa-sterling-sign'],
  times: ['fa-light', 'fa-times'],
  'magnifying-glass-chart': ['fa-light', 'fa-magnifying-glass-chart'],
  'user-circle': ['fa-light', 'fa-user-circle'],
  'user-circle-solid': ['fa-solid', 'fa-user-circle'],
  'user-clock': ['fa-light', 'fa-user-clock'],
  'user-gear': ['fa-light', 'fa-user-gear'],
  'user-group': ['fa-light', 'fa-user-group'],
  'user-lock': ['fa-light', 'fa-user-lock'],
  'user-pen': ['fa-light', 'fa-user-pen'],
  'user-pen-solid': ['fa-solid', 'fa-user-pen'],
  user: ['fa-light', 'fa-user'],
  'users-gear': ['fa-light', 'fa-users-gear'],
  'users-gear-solid': ['fa-solid', 'fa-users-gear'],
  users: ['fa-light', 'fa-users'],
  'users-solid': ['fa-solid', 'fa-users'],
  gift: ['fa-light', 'fa-gift'],
  'gift-solid': ['fa-solid', 'fa-gift'],
  'circle-exclamation-solid': ['fa-solid', 'fa-circle-exclamation'],
  'message-lines': ['fa-light', 'fa-message-lines'],
  filter: ['fa-light', 'fa-filter'],
  ...propToFaNameMap,
};

export const solidIcon = (icon: INameExtended) => [
  'fa-solid',
  'fa-user',
  // customMapNameToFa[icon][1],
];

extendPropToFaNameMap(customMapNameToFa);

export default Icon as React.FC<IconProps<INameExtended>>;

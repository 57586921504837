import { ReportContainer, theme } from '@faxi/web-component-library';
import { position } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const JourneyStatusContainer = styled(ReportContainer)`
  height: unset;
  position: relative;

  .journey-status-loading {
    ${position('absolute', '0')};
    z-index: 1;
    background-color: var(--BACKGROUND_1_4);
  }

  .wcl-journey-status-chart {
    &__tabs {
      margin: ${theme.sizes.SIZE_32} 0;
    }
  }
`;

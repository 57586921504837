import { theme } from '@faxi/web-component-library';
import { flex, marginChildren, position } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Container = styled.div`
  ${position('sticky', 'top 0 left 0 right 0')}
  width: 100%;
  background-color: var(--LABEL_TEXT_1_8);
  color: var(--LABEL_TEXT_DARK_1_1);
  padding: ${theme.sizes.SIZE_10};
  ${flex('row', 'center', 'center')};
  ${marginChildren(`0 ${theme.sizes.SIZE_12} 0 0`)};
  grid-area: view-only-banner;

  > div {
    > span {
      font-weight: 500;
    }
  }
`;

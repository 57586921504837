import { appUri } from "config";
import { Route } from "models";
import CarPark from "./CarPark";
import CarParkSpaces from "./CarParkSpaces";


const carParkRoutes: Route[] = [
    {
        component: CarPark,
        handle: 'Car Parks',
        title: 'Car Parks',
        exact: true,
        path: appUri.COMMUNITIES_CARPARK(),
      },
      {
        component: CarParkSpaces,
        handle: 'Car Parks',
        title: 'Car Parks',
        exact: true,
        path: appUri.COMMUNITIES_CARPARK_SPACES(),
      },
]


export default carParkRoutes;

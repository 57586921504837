import { APIResponse, Platform, Consent, ConsentReset } from 'models';
import httpClient from '../httpClient';

const updatePlatform = ({
  platformId,
  support_email,
  support_phone,
  retention_period,
}: {
  platformId: string;
  support_email?: string;
  support_phone?: string;
  retention_period: string;
}) =>
  httpClient.put<APIResponse<Platform>>(`/platforms/${platformId}`, {
    support_email,
    support_phone,
    retention_period,
  });

const getPlatformbyId = (platformId: string) =>
  httpClient.get<APIResponse<Array<Platform>>>(`/platforms/${platformId}`);

const updatePlatformEmission = (
  platformId: string,
  co2_per_km: string,
  nox_per_km: string
) =>
  httpClient.post<APIResponse<Platform>>(`/platforms/${platformId}/emission`, {
    co2_per_km: +co2_per_km,
    nox_per_km: +nox_per_km,
  });

const getConsents = (platformId: number) =>
  httpClient.get<APIResponse<{ consents: Consent[] }>>(
    `/platforms/${platformId}/consents`
  );

const resetConsents = (type: 'terms_and_conditions' | 'privacy_policy') =>
  httpClient.post(`/platforms/reset-consent`, { type });

const getLastResetDateOfStaticPages = () =>
  httpClient.get<APIResponse<ConsentReset>>(`/platforms/reset-consent`);

export default {
  updatePlatform,
  updatePlatformEmission,
  getPlatformbyId,
  getConsents,
  resetConsents,
  getLastResetDateOfStaticPages,
};

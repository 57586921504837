import { ValidationFn } from '@faxi/web-form';

const PHONE_NUMBER_REGEX = /^([+]?[0-9]{4,32})?$/;
const NUMBERS_ONLY = /[0-9][0-9]{0,2}(,?[0-9]{3})*(\.[0-9]+)?$/i;
const DECIMALS_ALLOWED = /^\d*\.?\d*$/;
const NEGATIVE_DECIMALS_ALLOWED = /^-?\d*\.?\d*$/;
const INTEGERS_ONLY = /^$|^[0-9]+$/;
const STRING_START_WITH_ZEROS = /^0+/;
const STRING_START_WITH_ONE_ZERO = /^00+/;

const VALID: any = undefined;

const validateJSON = (msg: string) => (value: any) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return msg;
  }
};

const phoneNumberValidation =
  (errorMessage: string): ValidationFn<any> =>
  (value: string) =>
    PHONE_NUMBER_REGEX.test(value) ? VALID : errorMessage;

const numbersOnlyValidation =
  (errorMessage: string): ValidationFn<any> =>
  (value: string) => {
    return NUMBERS_ONLY.test(value) ? VALID : errorMessage;
  };

const decimalsAllowed =
  (errorMessage: string): ValidationFn<any> =>
  (value: string) => {
    return DECIMALS_ALLOWED.test(value) ? VALID : errorMessage;
  };

const negativeDecimalsAllowed =
  (errorMessage: string): ValidationFn<any> =>
  (value: string) => {
    return NEGATIVE_DECIMALS_ALLOWED.test(value) ? VALID : errorMessage;
  };

const integerOnly =
  (errorMessage: string): ValidationFn<any> =>
  (value: string) => {
    return INTEGERS_ONLY.test(value) ? VALID : errorMessage;
  };

export const minNumber =
  (errorMessage: string, minNum: number) => (value: any) => {
    if (!value) return;
    return parseFloat(value) >= minNum ? VALID : errorMessage;
  };

export const maxNumber =
  (errorMessage: string, maxNum: number) => (value: any) => {
    if (!value) return;
    return parseFloat(value) <= maxNum ? VALID : errorMessage;
  };

export const canNotStartWithZeros = (errorMessage: string) => (value: any) => {
  if (!value) return;
  return !STRING_START_WITH_ZEROS.test(value) ? VALID : errorMessage;
};

export const canNotStartWithMoreThenOneZero =
  (errorMessage: string) => (value: any) => {
    if (!value) return;
    return !STRING_START_WITH_ONE_ZERO.test(value) ? VALID : errorMessage;
  };

export const composeValidators =
  <T = string>(...validators: ValidationFn<T>[]) =>
  (value: T, fields: Record<string, any>) =>
    validators.reduce(
      (errorMessage, validator) =>
        (errorMessage as any) || validator?.(value, fields),
      VALID
    );

type AddressPlace = {
  lat: number;
  lng: number;
  formatted_address?: string;
  address?: string;
  country?: string;
  locality?: string;
  administrative_area_level_1?: string;
  postal_code?: string;
  city?: string;
};

const googleAutocompleteAddress =
  (errorMessage: string) => (value: AddressPlace) =>
    !value?.formatted_address ? errorMessage : undefined;

export default {
  maxNumber,
  minNumber,
  validateJSON,
  integerOnly,
  decimalsAllowed,
  negativeDecimalsAllowed,
  phoneNumberValidation,
  numbersOnlyValidation,
  canNotStartWithZeros,
  canNotStartWithMoreThenOneZero,
  googleAutocompleteAddress,
};

import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const CarParkSpacesContainer = styled.div`
  .kinto-carpark-spaces {
    &__table-wrapper {
      padding: ${theme.sizes.SIZE_20};
      max-width: ${theme.sizes.SIZE_744};
      border-radius: ${theme.sizes.SIZE_8};
      background-color: var(--BACKGROUND_1_1);
    }

    &__table {
      &--empty {
        .wcl-table-container__table-container {
          box-shadow: none;
          border: none;
        }
      }
    }

    &__total-numbers {
      ${flex('row')}

      max-width: ${theme.sizes.SIZE_744};
      margin-bottom: ${theme.sizes.SIZE_16};

      &__container {
        ${flex('column')};

        padding: ${`${theme.sizes.SIZE_10} ${theme.sizes.SIZE_20}
          ${theme.sizes.SIZE_12}`};
        height: fit-content;
        width: ${theme.sizes.SIZE_248};
        border-radius: ${theme.sizes.SIZE_8};
        background-color: var(--BACKGROUND_1_1);

        &:not(:last-child) {
          margin-right: ${theme.sizes.SIZE_8};
        }
      }

      &__total {
        ${fontSize(theme.fontSizes.FONT_24, theme.fontSizes.FONT_34)};

        font-weight: 600;
        color: var(--PRIMARY_1_1);
      }

      &__label {
        ${fontSize(theme.fontSizes.FONT_12, theme.fontSizes.FONT_18)};

        width: 70%;
      }
    }
  }
`;

import { flex, flexGap, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

import { phablet } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

export const JourneyContainer = styled.div`
  ${flex('column', 'flex-start', 'flex-start')};
  ${size('100%')};
  overflow: auto;
  padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_176};

  ${phablet(
    css`
      padding: ${theme.sizes.SIZE_20};
    `
  )};

  .sa-journey {
    &__journey-header {
      margin-right: ${theme.sizes.SIZE_24};
    }

    &__breadcrumbs,
    &__actions {
      margin: 0 0 ${theme.sizes.SIZE_32};
    }

    &__actions {
      ${flex('row', 'flex-start')};
      width: 100%;
    }

    ${phablet(css`
      &__actions {
        flex-direction: column;

        > :not(:first-child) {
          margin-top: ${theme.sizes.SIZE_24};
        }
      }
    `)}

    &__passenger-list {
      &__table__tooltip {
        ${flex('row')};
        ${flexGap(theme.sizes.SIZE_8, 'row')};

        .wcl-icon {
          ${size(theme.sizes.SIZE_16)};

          margin: auto 0;
          color: var(--ALERT_ERROR_1_1);

          > svg {
            ${size('100%')};
          }
        }
      }

      &__excluded-label {
        color: var(--ALERT_ERROR_1_1);
      }
    }
  }
`;

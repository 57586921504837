import {
  ModalProps,
  ModalRef,
  useCallbackRef,
} from '@faxi/web-component-library';
import { Form, FormField, FormRef } from '@faxi/web-form';
import { FC, ReactNode, useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { SwitchField } from 'components';
import { apiUsers } from 'modules';
import { UserOrganisation } from 'models';
import { snackBarSuccessMessage } from 'utils';
import { useCallbackAsync, useFormButtons } from 'hooks';

import * as Styled from './UserPermissionModal.styles';

type UserPermissionFormType = {
  admin: boolean;
};

const PermissionField = (props: {
  label: string;
  value: string;
  className?: string;
  children?: ReactNode;
}) => {
  const { children, className, label, value } = props;
  return (
    <fieldset className={classNames('permission-field', className)}>
      <legend>{label}</legend>
      <p className="permission-field__value">{value}</p>
      {children}
    </fieldset>
  );
};

type UserPermissionModalProps = Partial<ModalProps> & {
  userId: number;
  username: string;
  community: UserOrganisation;
  onApplyPermission: (communityId: number, role: 'admin' | 'regular') => void;
};

export const UserPermissionModal: FC<UserPermissionModalProps> = (props) => {
  const { userId, username, community, onApplyPermission, ...rest } = props;

  const modalRef = useRef<ModalRef>(null);
  const [form, formRef] = useCallbackRef<FormRef>();
  const [FormButtons] = useFormButtons('Save changes', 'Cancel');

  const initialData = useMemo(
    () => ({ admin: community.type === 'admin' } as UserPermissionFormType),
    [community]
  );

  const [handleSubmit, loading] = useCallbackAsync({
    showSpinner: false,
    callback: async (values: UserPermissionFormType) => {
      const role = values.admin ? 'admin' : 'regular';
      await apiUsers.updateUserPermission(userId, community.id, role);

      modalRef.current?.close();
      onApplyPermission(community.id, role);
      snackBarSuccessMessage(`Successfully updated user role`);
    },
  });

  const modalForm = useCallback(
    ({ children, className }: any) => (
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        strictValidation={false}
        children={children}
        className={className}
        initialData={initialData}
      />
    ),
    [initialData, formRef, handleSubmit]
  );

  return (
    <Styled.Modal
      ref={modalRef}
      loading={loading}
      className="kinto-user-permissions-modal"
      title={`Edit ${username} permissions`}
      childrenWrapper={modalForm}
      footer={
        <>
          <FormButtons.Submit
            disabled={!form?.isFormChanged() || !form?.syncFormValid}
            variant="primary"
          />
          <FormButtons.Cancel onClick={() => modalRef.current?.close()} />
        </>
      }
      {...rest}
    >
      <PermissionField label="Community" value={community.name} />
      <PermissionField label="Current role" value={community.type} />

      <hr />

      <PermissionField
        label="Admin"
        className="user-permission-field"
        value="Controls who has access to your organisation's account"
      >
        <FormField name="admin" component={SwitchField} />
      </PermissionField>
    </Styled.Modal>
  );
};

export default UserPermissionModal;

import { useMemo } from 'react';
import { Spinner as WCLSpinner, getColor } from '@faxi/web-component-library';
import * as SpinnerStyled from './Spinner.styles';

type SpinnerProps = {
  type?: 'standard' | 'in-button';
  className?: string;
  label?: string;
};

const Spinner: React.FC<SpinnerProps> = (props: SpinnerProps) => {
  const { type = 'standard', label, className } = props;

  const size = useMemo((): number => {
    switch (type) {
      case 'standard':
        return 60;
      case 'in-button':
        return 18;
      default:
        return 60;
    }
  }, [type]);

  const color = useMemo((): string => {
    switch (type) {
      case 'standard':
        return getColor('--PRIMARY_1_1');
      case 'in-button':
        return getColor('--BACKGROUND_1_1');
      default:
        return getColor('--PRIMARY_1_1');
    }
  }, [type]);

  return (
    <SpinnerStyled.SpinnerContainer className={className}>
      <WCLSpinner
        size={size}
        color={color}
        className="spinner-container__spinner"
      />
      {label && <span>{label}</span>}
    </SpinnerStyled.SpinnerContainer>
  );
};

export default Spinner;

export const STORAGE_KEYS = {
  AUTH: 'KINTO_SUPER_ADMIN__AUTH',
  TOKEN: 'KINTO_SUPER_ADMIN__TOKEN',
  REFRESH_TOKEN: 'KINTO_SUPER_ADMIN__REFRESH_TOKEN',
  USER: 'KINTO_SUPER_ADMIN__USER',
  USER_ID: 'KINTO_SUPER_ADMIN__USER_ID',
  ACTIVE_COMMUNITY: 'KINTO_SUPER_ADMIN__ACTIVE_COMMUNITY',
  SELECTED_COMMUNITY: 'KINTO_SUPER_ADMIN__SELECTED_COMMUNITY',
  SELECTED_CAR_PARK: 'KINTO_SUPER_ADMIN__SELECTED_CAR_PARK',
  SELECTED_HASH_KEY: 'KINTO_SUPER_ADMIN__SELECTED_HASH_KEY',
};

export type StorageServiceType = {
  ram: Record<string, string | undefined>;
  setItem: (key: string, value: string | Record<string, any>) => void;
  removeItem: (key: string) => void;
  getItem: <T>(key: string) => T;
};

export default {
  ram: {} as Record<string, string | undefined>,
  setItem(key: string, value: Record<string, unknown> | string): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn(e);
      this.ram[key] = JSON.stringify(value);
    }
  },
  removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.warn(e);
      this.ram[key] = undefined;
    }
  },
  getItem<T>(key: string): T {
    try {
      return JSON.parse(localStorage.getItem(key) as string);
    } catch (e) {
      console.warn(e);
      return JSON.parse(this.ram[key] as string);
    }
  },
} as StorageServiceType;

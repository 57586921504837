import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import {
  Button,
  Input,
  SelectOption,
  SortOption,
  StatusElement,
  Table,
  TableRef,
  useUtilities,
} from '@faxi/web-component-library';
import { Icon } from 'components';
import CreateCommunityProviderContext from '../CreateCommunityProvider/CreateCommunityProvider.context';
import { STATUS_MAP, STATUS_TRANSLATIONS } from 'pages/Users/utils';
import { TableUser, User } from 'models';
import { apiUsers } from 'modules';
import dayjs from 'dayjs';
import { dateFormat } from 'config';

import * as Styled from './AddAdminRole.styles';

type AddAdminRoleProps = {
  tableRef: (element: TableRef<Record<string, any>>) => void;
  inModal?: boolean;
};

const AddAdminRole = (props: AddAdminRoleProps) => {
  const { tableRef, inModal } = props;

  const { setStep } = useContext(CreateCommunityProviderContext);

  // TODO: use useTablePagination here when new version of wcl can be implement in SA
  // after routerv6 implement in SA
  const [adminsData, setAdminsData] = useState<User[]>([]);

  const [search, setSearch] = useState<string>();

  const [currentPage, setCurrentPage] = useState(1);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [count, setCount] = useState(10);

  const [activeColumnSort, setActiveColumnSort] = useState<
    SortOption<TableUser>
  >({
    sortBy: 'id',
    sortDirection: 'desc',
  });

  const { communityId } = useParams<{
    communityId: string;
  }>();

  const { showOverlay, hideOverlay } = useUtilities();

  const translationKeys = useMemo(
    () =>
      ({
        id: 'ID',
        name: 'Name',
        email: 'Email',
        lastaccess_at: 'Last active',
        created_at: 'Joined',
        consents: 'Accepted consents',
        organisations_count: 'Communities',
        type: 'User type',
        status: 'Status',
      } as Record<Partial<keyof TableUser>, string>),
    []
  );

  const mapUserData = useCallback(
    (users: User[]) =>
      users.map(
        ({
          id,
          firstname,
          lastname,
          email,
          lastaccess_at,
          created_at,
          status,
        }: User) =>
          ({
            id,
            name: `${firstname} ${lastname}`.trim() || '-',
            email,
            lastaccess_at: lastaccess_at
              ? `${dayjs(lastaccess_at).format(dateFormat)}`
              : '-',
            created_at: dayjs(created_at).format(dateFormat),

            status: (
              <StatusElement status={STATUS_MAP[status]}>
                {STATUS_TRANSLATIONS[status]}
              </StatusElement>
            ),
            statusData: status,
          } as TableUser)
      ),
    []
  );

  const getAdminsData = useCallback(async () => {
    try {
      showOverlay(inModal ? '.wcl-modal' : '.add-admin-role__table');
      const { data } = await apiUsers.getUsers({
        per_page: count,
        page: currentPage,
        search,
        sort_by: activeColumnSort.sortBy,
        sort_direction: activeColumnSort.sortDirection,
        status: 'active',
        exclude_admins_organisation_id: communityId || undefined,
        include_superadmins: 1,
      });

      if (data) {
        setAdminsData(data?.data.users);

        setTotalCount(data.meta.total);

        setTotalPages(Math.ceil(data.meta.total / count));
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay(inModal ? '.wcl-modal' : '.add-admin-role__table');
    }
  }, [
    activeColumnSort.sortBy,
    activeColumnSort.sortDirection,
    communityId,
    count,
    currentPage,
    hideOverlay,
    inModal,
    search,
    showOverlay,
  ]);

  useEffect(() => {
    getAdminsData();
  }, [getAdminsData]);

  const admins = useMemo(() => {
    return mapUserData(adminsData);
  }, [adminsData, mapUserData]);

  const handleOnColumnSort = useCallback(
    (sortOptions: SortOption<TableUser>) => {
      setActiveColumnSort(sortOptions);
      setCurrentPage(1);
    },
    [setActiveColumnSort, setCurrentPage]
  );

  return (
    <Styled.AddAdminRoleStyled>
      {!inModal && (
        <Button
          variant="ghost"
          icon={<Icon name="chevron-left" />}
          onClick={() => {
            setStep(1);
          }}
          className="add-admin-role__back-btn"
        >
          Back
        </Button>
      )}

      <Input
        placeholder={'Search here...'}
        value={search}
        className="add-admin-role__search"
        prefixIcon={<Icon name="search" />}
        suffixIcon={
          search && (
            <Button
              variant="ghost"
              aria-label="Delete input search value"
              onClick={() => setSearch('')}
              icon={<Icon name="xmark" />}
            />
          )
        }
        onChange={(value, error) => {
          setSearch(value);
        }}
      />

      {admins.length === 0 ? (
        <div className="add-admin-role__empty-state">
          Sorry, there are no results that match your search.
        </div>
      ) : (
        <Table<TableUser>
          tableId="users-table"
          className="add-admin-role__table"
          breakAtMaxWidth={1200}
          tableData={admins}
          translationKeys={translationKeys}
          initialSort={activeColumnSort}
          headerActionLabel="user(s) selected"
          ref={tableRef}
          excludeColumns={['statusData']}
          excludeSortColumns={['status']}
          paginationData={{
            currentPage,
            limit: count,
            totalCount,
            totalPages,
          }}
          goToPageInputProps={{ placeholder: 'Go to page' }}
          onPageChange={setCurrentPage}
          onLimitChange={(data: SelectOption) => {
            setCurrentPage(1);
            setCount(+data.value);
          }}
          onColumnSortClicked={handleOnColumnSort}
          hasCheckboxes
        />
      )}
    </Styled.AddAdminRoleStyled>
  );
};

export default AddAdminRole;

import {
  UtilitiesProvider,
  GlobalStyle as WCLGlobalStyle,
} from '@faxi/web-component-library';
import env from 'env';
import { Router } from 'modules';
import { Wrapper } from '@googlemaps/react-wrapper';
import { GlobalStyle } from 'Global.styles';

export const GOOGLE_MAPS_PLACES_SCRIPT_ID = 'google_maps_places_script';

const App = (): JSX.Element => {
  return (
    <>
      <WCLGlobalStyle />
      <GlobalStyle />

      <Wrapper
        apiKey={env.VITE_APP_GMAPKEY!}
        id={GOOGLE_MAPS_PLACES_SCRIPT_ID}
        libraries={['places']}
      >
        <UtilitiesProvider>
          <Router />
        </UtilitiesProvider>
      </Wrapper>
    </>
  );
};

export default App;

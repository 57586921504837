import { OnlyPublicRoute, PrivateRoute } from 'modules';
import { Redirect } from 'react-router';

import { appUri } from 'config';
import { LoginForm, PersonalDetailsForm } from 'components';

import {
  Analytics,
  Dashboard,
  Login,
  Root,
  LoggingOut,
  Logs,
  TeamManagement,
  ChangeLogs,
  Rewards,
} from 'pages';
import { RoutesRenderer } from './';

import journeysRoutes from 'pages/Journeys/routes';
import communitiesRoutes from 'pages/Communities/routes';
import { Route } from 'models';
import usersRoutes from 'pages/Users/routes';
import serviceAccountsRoutes from 'pages/ServiceAccounts/routes';
import plansRoutes from 'pages/Plans/routes';
import activationsRoutes from 'pages/Activations/routes';
import PlatformDetailsForm from 'components/_forms/PlatformDetailsForm';
import AppSettingsForm from 'components/_forms/AppSettingsForm';
import MarketSettingsProvider from 'pages/MarketSettings/providers/MarketSettingsProvider';
import CommunityEKGPage from 'pages/Analytics/pages/CommunityEKGPage';

const routes: Route[] = [
  {
    handle: 'Base',
    path: appUri.BASE,
    component: (props: any) => <Root {...props} />,
    routes: [
      {
        handle: 'Login',
        path: appUri.LOGIN,
        component: (props: any) => (
          <OnlyPublicRoute component={<Login {...props} />} />
        ),
        routes: [
          {
            handle: 'login-form',
            path: appUri.LOGIN,
            exact: true,
            component: LoginForm,
          },
        ],
      },
      {
        handle: 'Logout',
        path: appUri.LOGGING_OUT,
        exact: true,
        component: LoggingOut,
      },
      {
        handle: 'Dashboard',
        path: appUri.DASHBOARD,
        exact: true,
        component: () => <PrivateRoute component={Dashboard} />,
      },
      {
        handle: 'Communities',
        path: appUri.COMMUNITIES,
        exact: false,
        component: (props: any) => (
          <PrivateRoute component={<RoutesRenderer {...props} />} />
        ),
        routes: communitiesRoutes,
      },
      {
        handle: 'Journeys',
        path: appUri.JOURNEYS,
        exact: false,
        component: (props: any) => (
          <PrivateRoute component={<RoutesRenderer {...props} />} />
        ),
        routes: journeysRoutes,
      },
      {
        handle: 'Users',
        path: appUri.USERS,
        exact: false,
        component: (props: any) => (
          <PrivateRoute component={<RoutesRenderer {...props} />} />
        ),
        routes: usersRoutes,
      },
      {
        handle: 'Plans',
        path: appUri.PLANS,
        exact: false,
        component: (props: any) => (
          <PrivateRoute component={<RoutesRenderer {...props} />} />
        ),
        routes: plansRoutes,
      },
      {
        handle: 'Activations',
        path: appUri.ACTIVATIONS,
        exact: false,
        component: (props: any) => (
          <PrivateRoute component={<RoutesRenderer {...props} />} />
        ),
        routes: activationsRoutes,
      },
      {
        handle: 'Rewards',
        path: appUri.REWARDS,
        exact: true,
        component: () => <PrivateRoute component={Rewards} />,
      },
      {
        handle: 'Logs',
        path: appUri.LOGS,
        exact: false,
        component: (props: any) => <PrivateRoute component={Logs} {...props} />,
      },
      {
        handle: 'Analytics',
        path: appUri.ANALYTICS,
        exact: true,
        component: () => <PrivateRoute component={Analytics} />,
      },
      {
        handle: 'Account settings - Personal details',
        path: appUri.ACCOUNT_SETTINGS_PERSONAL_DETAILS,
        exact: true,
        component: PersonalDetailsForm,
      },
      {
        handle: 'Community EKG',
        path: appUri.ANALYTICS_EKG,
        exact: true,
        component: () => <PrivateRoute component={CommunityEKGPage} />,
      },
      {
        handle: 'Team Management',
        path: appUri.TEAM_MANAGEMENT,
        exact: true,
        component: TeamManagement,
        title: 'Team Management',
      },
      {
        handle: 'Change logs',
        path: appUri.SETTINGS_CHANGE_LOGS,
        exact: true,
        component: ChangeLogs,
        title: 'Change logs',
      },

      {
        handle: 'Market Details',
        path: appUri.MARKET_SETTINGS_PLATFORM_DETAILS,
        component: () => (
          <MarketSettingsProvider>
            <PlatformDetailsForm />
          </MarketSettingsProvider>
        ),
        exact: true,
        title: 'Market details',
      },
      {
        component: AppSettingsForm,
        handle: 'Market App Settings',
        title: 'App settings',
        path: appUri.MARKET_SETTINGS_APP_SETTINGS,
        exact: true,
      },

      {
        handle: 'Service accounts keys',
        path: appUri.SETTINGS_SERVICE_ACCOUNTS_KEYS,
        exact: false,
        component: (props: any) => (
          <PrivateRoute component={<RoutesRenderer {...props} />} />
        ),
        routes: serviceAccountsRoutes,
      },
      {
        handle: 'any',
        path: appUri.ANY,
        component: () => <Redirect to={appUri.LOGIN} />,
      },
    ],
  },
  {
    handle: 'any',
    path: appUri.ANY,
    component: () => <Redirect to={appUri.BASE} />,
  },
];

export default routes;

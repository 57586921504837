import { Modal, theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const ActivityModal = styled(Modal)`
  .wcl-modal {
    &__header__sub {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};
    }

    &__main[class] {
      ${flex('column', 'flex-start', 'flex-start')};
      ${flexGap(theme.sizes.SIZE_8, 'column')};

      width: 100%;

      .wcl-icon {
        color: var(--PRIMARY_1_1);
      }
    }
  }

  .activity-details-modal {
    &__subtitle {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      font-weight: 400;
      color: var(--SHADE_1_2);
      margin: 0 0 ${theme.sizes.SIZE_32};
    }

    &__container {
      width: 100%;
      padding: ${theme.sizes.SIZE_8};
      border-radius: ${theme.sizes.SIZE_8};

      &.old {
        background-color: var(--SHADE_1_9);
      }

      &.new {
        background-color: var(--ACCENT_1_7);

        .details-value {
          font-weight: 600;
        }
      }
    }

    &__details {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      font-weight: 600;
      color: var(--SHADE_1_1);
      margin: 0 0 ${theme.sizes.SIZE_10};
    }

    &__details-container {
      ${flexGap(theme.sizes.SIZE_8, 'row')};
      width: 100%;

      .details-title,
      .details-value {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

        font-weight: 400;
        word-break: break-word;
        color: var(--SHADE_1_1);
      }
    }
  }
`;

import { Device } from 'models/Device';
import { Event } from 'models/Event';
import { JourneyDetails } from 'models/Journey';
import { PassengerTable } from 'models/Participant';
import { Traxes } from 'models/Traxes';

export const journeyTranslationKeys = {
  id: 'ID',
  user_id: 'User ID',
  email: 'Email',
  vehicle_registration: 'VRN',
  created_at: 'Created at',
  start_time: 'Started',
  estimated_distance: 'Distance',
  end_time: 'Ended',
  direction: 'Direction',
  participants_count: 'Passengers',
  coordinates: 'Home Location',
  points: 'Points'
} as Record<Partial<keyof JourneyDetails>, string>;

export const passengerTranslationKeys = {
  id: 'TID',
  passenger_id: 'PID',
  email: 'Email',
  screenName: 'Screenname',
  created_at: 'Created',
  pocs_time: 'POCS',
  pocs_type: 'PoCS Type',
  amount: 'Amount',
  paid: 'Paid',
  estimated_distance: 'Distance',
  handshake_time: 'Driver HS',
  destination_time: 'Passenger dest.',
  start_point: 'Start point',
  end_point: 'End point',
  points: 'Points',
} as Partial<Record<keyof PassengerTable, string>>;

export const traxTranslationKeys = {
  id: 'ID Trax',
  user_id: 'Passenger Id',
  count: 'Trax Count',
  created_at: 'Trax TS',
} as Record<Partial<keyof Traxes>, string>;

export const eventTranslationKeys = {
  id: 'ID',
  created_at: 'TS',
  user_id: 'UID',
  journey_id: 'IDJ',
  organisation_id: 'CID',
  text: 'Text',
  transaction_id: 'IDT',
} as Record<Partial<keyof Event>, string>;

export const deviceTranslationKeys = {
  device_id: 'Device ID',
  created_at: 'TS',
  user_id: 'TD User',
  type: 'Type',
  reg_id: 'Reg ID',
  app_release_number: 'App. rel. num.',
  app_build_number: 'App. build num.',
} as Record<Partial<keyof Device>, string>;

import React from 'react';
import {
  ExtendMarkerType,
  ExtendPolygonType,
  ExtendPolylineType,
} from 'pages/Map/components/MapElements/MapElements.component';
import { noop } from 'utils';

export const OVERLAY_TYPE = {
  MARKER: 'marker',
  POLYGON: 'polygon',
  POLYLINE: 'polyline',
} as const;

export type PossibleOverlayType =
  typeof OVERLAY_TYPE[keyof typeof OVERLAY_TYPE];

type MarkersContextProps = {
  mapMarkers: Array<ExtendMarkerType>;
  mapPolygons: Array<google.maps.Polygon>;
  addPin: (
    marker: ExtendMarkerType | ExtendPolygonType | ExtendPolylineType,
    type: PossibleOverlayType
  ) => void;
};

export default React.createContext<MarkersContextProps>({
  mapMarkers: [],
  mapPolygons: [],
  addPin: noop,
});

import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const PreviewInput = styled.div`
  ${flex('column')};
  ${flexGap(theme.sizes.SIZE_8, 'column')};

  .preview-input {
    &--title {
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

      font-weight: 400;
      color: var(--SHADE_1_2);
    }

    &--content {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      font-weight: 400;
      color: var(--SHADE_1_1);
    }
  }
`;

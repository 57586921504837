import { theme } from '@faxi/web-component-library';
import { size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const PassengerPin = styled.div`
  ${size(theme.sizes.SIZE_24)};
  position: relative;

  .passenger-pin {
    &__img {
      ${size(theme.sizes.SIZE_24)};

      &--driver {
        height: ${theme.sizes.SIZE_12};
      }
    }

    &__index {
      color: var(--BACKGROUND_1_1);
      position: absolute;
      top: 5px;
      left: 50%;
      transform: translateX(-50%);
      font-size: ${theme.sizes.SIZE_8};
      line-height: 1;
    }
  }
`;

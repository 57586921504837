import { useCallback, useRef, useState } from 'react';

import {
  Button,
  ModalRef,
  Stepper,
  useCallbackRef,
} from '@faxi/web-component-library';
import { Form, FormRef, useFormRefValues } from '@faxi/web-form';

import { Icon } from 'components';
import classNames from 'classnames';
import { dateFormat } from 'config';
import { useCallbackAsync } from 'hooks';
import { apiRewards } from 'modules';
import { Reward } from 'models';
import { snackBarSuccessMessage } from 'utils';
import dayjs from 'dayjs';

import UploadCsvStep from '../UploadCsvStep';
import ActivatePerCommunityStep from '../ActivatePerCommunityStep';

import * as Styled from './CreateRewardModal.styles';

type CreateRewardModalProps = {
  onClose: () => void;
  onCreate: (reward: Reward) => void;
};

const CreateRewardModal = (props: CreateRewardModalProps) => {
  const { onClose, onCreate } = props;

  const [step, setStep] = useState(1);

  const [communityToggles, setCommunityToggles] = useState<
    Record<number, string>
  >({});

  const modalRef = useRef<ModalRef>(null);
  const [form, formRef] = useCallbackRef<FormRef>();

  const formValues = useFormRefValues(form, 'name', 'file');

  const [handleSubmit] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.wcl-modal__children-wrapper',
    callback: async (values: { name: string; file: any }) => {
      const { name, file } = values;

      const communities = Object.keys(communityToggles).filter(
        (communityToggleKey: string) =>
          (communityToggles as any)?.[`${communityToggleKey}`]
      );

      try {
        const {
          data: { data },
        } = await apiRewards.createReward(name, file, communities);

        if (data) {
          onClose();
          snackBarSuccessMessage(`Sodexo reward activated`);
          onCreate({
            ...data,
            created_at: dayjs(data.created_at).format(dateFormat),
          } as Reward);
        }
      } catch (e) {
        console.error(e);
      }
    },
  });

  const modalForm = useCallback(
    ({ children, className }: any) => (
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        strictValidation={false}
        children={children}
        className={className}
      />
    ),
    [formRef, handleSubmit]
  );

  return (
    <Styled.Modal
      ref={modalRef}
      onClose={onClose}
      childrenWrapper={modalForm}
      title="Create a reward"
      footer={
        <>
          {step === 1 ? (
            <Button
              type="button"
              onClick={(ev) => {
                ev.preventDefault();
                setStep(2);
              }}
              disabled={!formValues?.name || formValues?.file?.length === 0}
            >
              Next
            </Button>
          ) : (
            <Button type="submit">Done</Button>
          )}
        </>
      }
    >
      <Stepper
        steps={[{ name: 'Upload csv' }, { name: 'Activate per community' }]}
        currentStep={step}
      />

      <div className={classNames({ 'create-reward-modal__hide': step === 2 })}>
        <UploadCsvStep form={form} />
      </div>

      <div className={classNames({ 'create-reward-modal__hide': step === 1 })}>
        <Button
          variant="ghost"
          icon={<Icon name="chevron-left" />}
          onClick={() => {
            setStep(1);
          }}
        >
          Back
        </Button>

        <p className="create-reward-modal__desc">
          Community where the reward is enabled will be displayed in the list of
          rewards in Client admin and can be added into the gamification
          campaign
        </p>

        <ActivatePerCommunityStep
          communityToggles={communityToggles}
          setCommunityToggles={setCommunityToggles}
        />
      </div>
    </Styled.Modal>
  );
};

export default CreateRewardModal;

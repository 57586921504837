import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Chart,
  TabOption,
  Tabs,
  DatePickerValue,
  getColor,
} from '@faxi/web-component-library';
import classNames from 'classnames';

import { useCallbackAsync } from 'hooks';
import { formatChartData } from 'utils';
import { apiAnalytics } from 'modules';
import { Spinner } from 'components';
import {
  JourneyStatusAnalytics,
  JourneyStatusKey,
  JOURNEY_STATUS_KEYS,
} from 'models/Analytics';

import * as Styled from './JourneyStatusChart.styles';

const TRANSPORTATION_TYPE = {
  ALL: 'ALL',
  WALKING: 'W',
  CYCLING: 'B',
  CARPOOLING: 'C',
} as const;

type TransportationType =
  (typeof TRANSPORTATION_TYPE)[keyof typeof TRANSPORTATION_TYPE];

const TRANSPORTATION_TABS = [
  {
    label: 'All',
    value: TRANSPORTATION_TYPE.ALL,
  },
  {
    label: 'Carpooling',
    value: TRANSPORTATION_TYPE.CARPOOLING,
    iconName: 'car',
  },
  {
    label: 'Walking',
    value: TRANSPORTATION_TYPE.WALKING,
    iconName: 'person-walking',
  },
  {
    label: 'Cycling',
    value: TRANSPORTATION_TYPE.CYCLING,
    iconName: 'person-biking',
  },
] as Array<TabOption<TransportationType>>;

type JourneyStatusChartProps = {
  className?: string;
  range?: DatePickerValue;
  showTestUsers?: boolean;
  communityId?: string;
};

const JourneyStatusChart: React.FC<JourneyStatusChartProps> = (props) => {
  const { className, range, showTestUsers, communityId } = props;

  const classes = classNames('wcl-journey-status-chart', className);
  const [chartData, setChartData] = useState<JourneyStatusAnalytics>();

  const [activeTransport, setActiveTransport] = useState<TransportationType>(
    TRANSPORTATION_TYPE.ALL
  );

  const [loadJourneyAnalytics, loading] = useCallbackAsync({
    showSpinner: false,
    callback: async () => {
      if (!range) return;

      const { data: analytics } = await apiAnalytics.getJourneyAnalytics(
        range?.from.format('YYYY-MM-DD'),
        range?.to.format('YYYY-MM-DD'),
        showTestUsers ? 1 : undefined,
        communityId,
        activeTransport !== TRANSPORTATION_TYPE.ALL
          ? activeTransport
          : undefined
      );

      setChartData(analytics.data);
    },
  });

  const seriesData = useCallback(
    (status: JourneyStatusKey, isTest = false) => {
      if (!chartData) return {};

      return isTest
        ? formatChartData(chartData!, `${status}_test`)
        : formatChartData(chartData!, status);
    },
    [chartData]
  );

  const headerActions = useMemo(
    () => (
      <Tabs<TransportationType>
        className="wcl-journey-status-chart__tabs"
        disabled={loading}
        value={activeTransport}
        tabs={TRANSPORTATION_TABS}
        onChange={setActiveTransport}
      />
    ),
    [activeTransport, loading]
  );

  const total = useMemo(
    () =>
      chartData
        ? chartData?.aborted_total +
          chartData?.finished_no_passenger_total +
          chartData?.finished_total
        : 0,
    [chartData]
  );

  const testTotal = useMemo(
    () =>
      chartData
        ? chartData?.aborted_total_test +
          chartData?.finished_no_passenger_total_test +
          chartData?.finished_total_test
        : 0,
    [chartData]
  );

  const totals = useMemo(
    () =>
      chartData
        ? [
            {
              number: total,
              testNumber: testTotal,
              hasTestData: showTestUsers,
            },
            {
              number: chartData?.aborted_total,
              testNumber: chartData?.aborted_total_test,
              hasTestData: showTestUsers,
            },
            {
              number: chartData?.finished_total,
              testNumber: chartData.finished_total_test,
              hasTestData: showTestUsers,
            },
            {
              number: chartData?.finished_no_passenger_total,
              testNumber: chartData.finished_no_passenger_total_test,
              hasTestData: showTestUsers,
            },
          ]
        : [],
    [showTestUsers, chartData, total, testTotal]
  );

  useEffect(() => {
    if (!range) return;

    loadJourneyAnalytics();
  }, [
    range,
    communityId,
    showTestUsers,
    activeTransport,
    loadJourneyAnalytics,
  ]);

  return (
    <Styled.JourneyStatusContainer
      totals={totals}
      className={classes}
      headerActions={headerActions}
      title="Journey status"
      info="The graph shows the number of journeys per status (finished, finished (no passengers), aborted)"
      tooltipContent="The graph shows the number of journeys per status (finished, finished (no passengers), aborted)"
      totalsTooltipContent="Total all/aborted/finished/finished without passengers for the chosen period"
    >
      {loading && <Spinner className="journey-status-loading" />}
      <Chart
        series={[
          {
            name: 'Finished',
            color: getColor('--ACCENT_1_1'),
            backgroundColor: 'transparent',
            data: seriesData(JOURNEY_STATUS_KEYS.FINISHED),
          },
          {
            name: 'Finished (no passengers)',
            color: '#F6BB7A',
            backgroundColor: 'transparent',
            data: seriesData(JOURNEY_STATUS_KEYS.FINISHED_NO_PASSENGER),
          },
          {
            name: 'Aborted',
            color: getColor('--PRIMARY_2_1'),
            backgroundColor: 'transparent',
            data: seriesData(JOURNEY_STATUS_KEYS.ABORTED),
          },
        ].concat(
          showTestUsers
            ? [
                {
                  name: 'Finished (test)',
                  color: getColor('--NOTIFICATIONS_1_1'),
                  backgroundColor: 'transparent',
                  data: seriesData(JOURNEY_STATUS_KEYS.FINISHED, true),
                },
                {
                  name: 'Finished (no passengers) (test)',
                  color: getColor('--SECONDARY_2_1'),
                  backgroundColor: 'transparent',
                  data: seriesData(
                    JOURNEY_STATUS_KEYS.FINISHED_NO_PASSENGER,
                    true
                  ),
                },
                {
                  name: 'Aborted (test)',
                  color: getColor('--ALERT_ERROR_1_1'),
                  backgroundColor: 'transparent',
                  data: seriesData(JOURNEY_STATUS_KEYS.ABORTED, true),
                },
              ]
            : []
        )}
      />
    </Styled.JourneyStatusContainer>
  );
};

export default JourneyStatusChart;

import { AxiosResponse } from 'axios';
import httpClient from '../httpClient';
import { APIResponse } from 'models/APIResponse';

// TODO: jocacode to set proper types
const login = (
  email: string,
  password: string
): Promise<AxiosResponse<APIResponse<any>>> => {
  const formData = new FormData();

  formData.append('email', email);
  formData.append('password', password);

  return httpClient.post<APIResponse<any>>('auth/login', formData);
};

const refreshToken = (
  refresh_token: string
): Promise<AxiosResponse<APIResponse<any>>> =>
  httpClient.post<APIResponse<any>>('auth/refresh', { refresh_token }, {
    isRefresh: true,
  } as any);

const logout = () => httpClient.post('auth/logout');

export default {
  login,
  refreshToken,
  logout,
};

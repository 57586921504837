import { Heading } from '@faxi/web-component-library';

import { DashboardWelcome } from 'components';

import * as Styled from './Dashboard.page.styles';

const Dashboard: React.FC = (): JSX.Element => {
  return (
    <Styled.DashboardPageStyled className="dashboard-page">
      <Heading className="dashboard-page__heading" level="2">
        Welcome to Super Admin (Power user) dashboard
      </Heading>
      <div className="dashboard-page__text">
        From here you can have insights of KINTO Join data and control over the
        most important functions
      </div>
      <DashboardWelcome className="dashboard-page__graphics" />
    </Styled.DashboardPageStyled>
  );
};

export default Dashboard;

import { ButtonProps, Button } from '@faxi/web-component-library';
import { Icon } from 'components';
import { useCallback, useMemo, useRef, useState } from 'react';

export default function useColumnSettings() {
  const columnBtnRef = useRef<HTMLButtonElement>(null);
  const [columnSettingsOpen, setColumnSettingsOpen] = useState<boolean>(false);

  const openColumnSettings = useCallback(() => {
    setColumnSettingsOpen(true);
  }, []);

  const closeColumnSettings = useCallback(() => {
    setColumnSettingsOpen(false);
  }, []);

  const columnSettingsBtn = useMemo(
    () => (props: ButtonProps) =>
      (
        <Button
          variant="ghost"
          iconPosition="left"
          ref={columnBtnRef}
          icon={<Icon name="sliders-up" />}
          className="kinto-page__col-settings"
          onClick={openColumnSettings}
          {...props}
        >
          Column settings
        </Button>
      ),
    [openColumnSettings]
  );

  return {
    columnSettingsOpen,
    columnBtnRef,
    openColumnSettings,
    closeColumnSettings,
    ColumnSettingsButton: columnSettingsBtn,
  };
}

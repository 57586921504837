import { Icon, Tooltip, getColor } from '@faxi/web-component-library';
import { dateInUTCFormat } from 'utils';

import * as Styles from './PocsTimeTableData.styles';

export type PocsTimeTableDataProps = {
  pocsTime?: string | Date;
  pocsReason?: string;
};
const PocsTimeTableData = (props: PocsTimeTableDataProps) => {
  const { pocsTime, pocsReason } = props;

  return (
    <Styles.PocsTimeTableData>
      {pocsReason && (
        <Tooltip
          // beacause this component is used in table this will be preferred placement for tooltip
          placement="right"
          content={
            <div>
              {`${dateInUTCFormat(pocsTime)}`} <br /> <br /> {`${pocsReason}`}
            </div>
          }
        >
          <span className="pocs-time-table-data__icon">
            <Icon name="badge-check" color={getColor('--PRIMARY_1_1')} />
          </span>
        </Tooltip>
      )}
      {dateInUTCFormat(pocsTime)}
    </Styles.PocsTimeTableData>
  );
};

export default PocsTimeTableData;

import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Form, FormField, FormRef, validators } from '@faxi/web-form';
import { validationRegexes } from '@faxi/web-form';
import { AxiosError } from 'axios';

import {
  InputField,
  NoPermissionsPlaceholder,
  TablePageLayout,
} from 'components';
import { useFormButtons } from 'hooks';
import {
  getStringError,
  snackBarErrorMessage,
  snackBarSuccessMessage,
} from 'utils';

import { apiPlatform } from 'modules';
import specific, { composeValidators } from 'validation/validators/specific';
import { credentialService } from 'services';
import MarketSettingsContext, {
  MarketSettingsType,
} from 'pages/MarketSettings/providers/MarketSettingsProvider/MarketSettings.context';
import {
  useUtilities,
  useCallbackRef,
  Heading,
  getColor,
} from '@faxi/web-component-library';
import { AuthContext } from 'store';

import { FormActions } from 'Global.styles';

type MarketDetailsFormProps = {
  className?: string;
};

const PlatformDetailsForm: React.FC<MarketDetailsFormProps> = (props) => {
  const { admin } = useContext(AuthContext);

  const {
    setPlatform,
    setPlatformEmission,
    platformEmission: { co2_per_km, nox_per_km },
    platform: { support_phone, support_email, retention_period },
    loading: loadingPlatform,
    requestError,
  } = useContext(MarketSettingsContext);

  const initialData = useMemo(
    () => ({
      support_phone: support_phone || '',
      support_email,
      retention_period: `${retention_period || '-'}`,
      co2_per_km: `${co2_per_km}`,
      nox_per_km: `${nox_per_km}`,
    }),
    [co2_per_km, nox_per_km, retention_period, support_email, support_phone]
  );

  const { showOverlay, hideOverlay } = useUtilities();

  const [FormButtons] = useFormButtons();
  const [form, formRef] = useCallbackRef<FormRef>();

  const canEdit = useMemo(
    () => !!admin?.permissions.find((p) => p.name === 'market_settings_edit'),
    [admin]
  );

  const {
    platform: { id: platformId },
  } = credentialService.user;

  const validations = useMemo(
    () => ({
      contactPhone: composeValidators(
        validators.general.minLength(4, 'Minimum length is 4'),
        validators.general.maxLength(32, 'Maximum length is 32'),
        specific.phoneNumberValidation('Must be a valid phone number')
      ),
      email: composeValidators(
        validators.general.regex(
          validationRegexes.workEmail,
          'Please enter a valid email'
        )
      ),
      retention: composeValidators(
        validators.general.required('This field is required'),
        specific.integerOnly('Please enter a valid positive number'),
        specific.canNotStartWithZeros('Please enter a valid positive number'),
        specific.minNumber(
          'Minimum value for retention period is 365 days (1 year)',
          365
        ),
        specific.maxNumber(
          'Maximum value for retention period is 5479 days (15 years)',
          5479
        )
      ),
      co2: composeValidators(
        specific.decimalsAllowed('Please enter a valid positive number'),
        specific.canNotStartWithMoreThenOneZero(
          'Please enter a valid positive number'
        ),
        specific.minNumber('Minimum value is 0.0001', 0.0001),
        specific.maxNumber('Maximum value is 99', 99),
        validators.general.maxLength(13, 'Maximum length is 13')
      ),
      nox: composeValidators(
        specific.decimalsAllowed('Please enter a valid positive number'),
        specific.canNotStartWithMoreThenOneZero(
          'Please enter a valid positive number'
        ),
        specific.minNumber('Minimum value is 0.0001', 0.0001),
        specific.maxNumber('Maximum value is 99', 99),
        validators.general.maxLength(13, 'Maximum length is 13')
      ),
    }),
    []
  );

  const handleSubmit = useCallback(
    async (values: MarketSettingsType) => {
      try {
        showOverlay('body');

        const {
          support_phone,
          support_email,
          retention_period,
          co2_per_km,
          nox_per_km,
        } = values;

        const {
          support_phone: support_phone_init,
          support_email: support_email_init,
          retention_period: retention_period_init,
          co2_per_km: co2_per_km_init,
          nox_per_km: nox_per_km_init,
        } = initialData;

        if (
          support_phone !== support_phone_init ||
          support_email !== support_email_init ||
          retention_period !== retention_period_init
        ) {
          const {
            data: { data: platformData },
          } = await apiPlatform.updatePlatform({
            platformId: `${platformId}`,
            support_email,
            support_phone,
            retention_period: `${retention_period}`,
          });

          setPlatform((oldPlatform) => ({
            ...oldPlatform,
            ...platformData,
          }));
        }

        if (
          Number(co2_per_km) !== Number(co2_per_km_init) ||
          Number(nox_per_km) !== Number(nox_per_km_init)
        ) {
          const {
            data: { data: platformEmissionData },
          } = await apiPlatform.updatePlatformEmission(
            `${platformId}`,
            co2_per_km,
            nox_per_km
          );

          setPlatformEmission((oldEmission) => ({
            ...oldEmission,
            ...platformEmissionData,
          }));
        }

        snackBarSuccessMessage('Platform settings updated successfully!');
      } catch (error) {
        console.error(error);
        snackBarErrorMessage(getStringError(error as AxiosError));
      } finally {
        hideOverlay('body');
      }
    },
    [
      hideOverlay,
      initialData,
      platformId,
      setPlatform,
      setPlatformEmission,
      showOverlay,
    ]
  );

  useEffect(() => {
    if (loadingPlatform) {
      showOverlay('.market-settings');
    } else {
      hideOverlay('.market-settings');
    }
  }, [hideOverlay, loadingPlatform, showOverlay]);

  return requestError ? (
    <NoPermissionsPlaceholder variant="panel" />
  ) : (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__header"
      >
        Platform details
      </Heading>

      <Form
        initialData={initialData}
        className="market-settings"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <div className="form__fields">
          <FormField
            name="support_phone"
            component={InputField}
            placeholder="Contact support number"
            readOnly={!canEdit}
            validate={validations.contactPhone}
          />
          <FormField
            name="support_email"
            component={InputField}
            placeholder="Contact support email"
            readOnly={!canEdit}
            validate={validations.email}
          />
          <FormField
            name="retention_period"
            autoComplete="off"
            component={InputField}
            inputMode="numeric"
            placeholder="Retention period (days)"
            readOnly={!canEdit}
            validate={validations.retention}
            required
            requiredLabel="Required"
          />
          <FormField
            name="co2_per_km"
            autoComplete="off"
            component={InputField}
            inputMode="numeric"
            placeholder="CO₂ coefficient"
            readOnly={!canEdit}
            validate={validations.co2}
          />
          <FormField
            name="nox_per_km"
            autoComplete="off"
            inputMode="numeric"
            component={InputField}
            placeholder="NOₓ coefficient"
            readOnly={!canEdit}
            validate={validations.nox}
          />
        </div>
        <FormActions className="edit-form-buttons">
          {canEdit && (
            <FormButtons.Submit
              disabled={!form?.isFormChanged() || !form?.syncFormValid}
            />
          )}
        </FormActions>
      </Form>
    </TablePageLayout.PageLayoutContainer>
  );
};

export default PlatformDetailsForm;

import { FieldProps } from '@faxi/web-form';

export type FileInputProps = FieldProps & {
  inputProps?: React.HTMLAttributes<HTMLInputElement>;
  className?: string;
};

const FileInput: React.FC<FileInputProps> = (props: FileInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { inputProps, value, touched, dirty, onChange, ...rest } = props;

  return (
    <input
      type="file"
      {...inputProps}
      onChange={(event) => {
        onChange?.(event);
        inputProps?.onChange?.(event);
      }}
      {...rest}
    />
  );
};

export default FileInput;

import {
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  MutableRefObject,
} from 'react';
import {
  Breadcrumbs,
  Button,
  Heading,
  Icon,
  useUtilities,
  Table,
  getColor,
} from '@faxi/web-component-library';
import { useHistory, useParams } from 'react-router';
import { AxiosError } from 'axios';

import {
  getStringError,
  snackBarErrorMessage,
  snackBarSuccessMessage,
} from 'utils';
import { appUri } from 'config';
import { TablePageLayout } from 'components';
import { useTablePagination } from 'hooks';
import { CarParkType } from 'models/CarPark';
import { apiCommunities } from 'modules';
import CarParkModal from './components/CarParkModal';
import CarParkContext from 'store/CarParkProvider/CarPark.context';
import AuthContext from 'store/AuthProvider/Auth.context';
import { TableAction } from 'components/_layouts/PageLayout.styles';

import { NoData } from 'Global.styles';
import * as Styled from './CarPark.styles';
import { storageService } from 'services';
import { STORAGE_KEYS } from 'services/storageService';

const excludeColumns = [
  'action_type',
  'passphrase',
  'radius',
  'type',
  'latitude',
  'longitude',
  'json',
  'id_organisation',
] as Array<keyof CarParkType>;

export const CAR_PARK_TRANSLATIONS = {
  id: 'ID',
  name: 'Name',
  spaces_total: 'Number of car spaces',
  spaces_shared: 'Number of car spaces for car sharers',
  spaces_other: 'Number of car spaces for others',
} as Record<keyof CarParkType, string>;

const CarPark: FC = () => {
  const history = useHistory();

  const { admin } = useContext(AuthContext);
  const { setCarParkNumbers } = useContext(CarParkContext);

  const { showOverlay, hideOverlay, prompts } = useUtilities();

  const { communityId } = useParams<{
    communityId: string;
  }>();

  const editBtnRef = useRef<HTMLButtonElement>(null);
  const addParkBtnRef = useRef<HTMLButtonElement>(null);

  const selectedCarPark = useRef<CarParkType>();

  const [carParkModalOpened, setCarParkModalOpened] = useState(false);

  const communityName = useCallback(
    () => storageService.getItem(STORAGE_KEYS.SELECTED_COMMUNITY),
    []
  );

  const canEdit = useMemo(
    () =>
      !!admin?.permissions.find((p) => p.name === 'community_management_edit'),
    [admin]
  );

  const mapCarPark = useCallback(
    ({ point, ...restPark }: CarParkType) =>
      ({
        ...restPark,
        id: restPark.id,
        longitude: point?.lng,
        latitude: point?.lat,
        json: JSON.stringify(restPark.json),
        spaces_total: `${restPark.spaces_total || '-'}`,
        spaces_shared: `${restPark.spaces_shared || '-'}`,
        spaces_other: `${restPark.spaces_other || '-'}`,
        passphrase: `${restPark.passphrase || ''}`,
      } as CarParkType),
    []
  );

  const {
    data: carparks,
    setData: setCarParks,
    loading: loadingCarparks,
  } = useTablePagination<CarParkType, 'carparks'>({
    count: 50,
    itemsKey: 'carparks',
    spinnerParentClass: 'body',
    mappingFunction: (parks: Array<CarParkType>) =>
      parks.map((park) => mapCarPark(park)),
    apiRequest: ({ per_page, currentPage }) => {
      return apiCommunities.getCarParks(communityId, currentPage, per_page);
    },
  });

  const updateCarPark = useCallback(
    async (carpark: CarParkType) => {
      setCarParks((oldParks) => {
        if (!oldParks.some((park) => park.id === carpark.id))
          return [...oldParks, carpark];
        else
          return oldParks.map((park) => {
            if (park.id === carpark.id) return carpark;
            else return park;
          });
      });
    },
    [setCarParks]
  );

  const deleteCarPark = useCallback(
    async (data: CarParkType) => {
      try {
        showOverlay('body');

        await apiCommunities.deleteCarPark(communityId, data.id);
        snackBarSuccessMessage('Successfully deleted car park');

        setCarParks((oldParks) =>
          oldParks.filter((park) => park.id !== data.id)
        );
      } catch (e) {
        console.error(e);
        snackBarErrorMessage(getStringError(e as AxiosError));
      } finally {
        hideOverlay('body');
      }
    },
    [communityId, hideOverlay, setCarParks, showOverlay]
  );

  const tableActions = useMemo(
    () => (carpark: CarParkType) =>
      (
        <TablePageLayout.TableActions
          actions={(
            (canEdit
              ? [
                  {
                    name: 'Edit',
                    icon: 'pen',
                    onClick: (btnElement: HTMLButtonElement) => {
                      selectedCarPark.current = carpark;
                      setCarParkModalOpened(true);
                      (
                        editBtnRef as MutableRefObject<HTMLButtonElement>
                      ).current = btnElement;
                    },
                  },
                ]
              : []) as TableAction[]
          ).concat(
            (
              [
                {
                  name: 'Parking spaces',
                  icon: 'circle-parking',
                  onClick: () => {
                    setCarParkNumbers({
                      spaces_total: carpark.spaces_total,
                      spaces_shared: carpark.spaces_shared,
                      spaces_other: carpark.spaces_other,
                    });
                    storageService.setItem(
                      STORAGE_KEYS.SELECTED_CAR_PARK,
                      carpark.name
                    );
                    history.push(
                      appUri.COMMUNITIES_CARPARK_SPACES(
                        communityId,
                        `${carpark.id}`
                      )
                    );
                  },
                },
              ] as TableAction[]
            ).concat(
              canEdit
                ? [
                    {
                      name: 'Delete',
                      icon: 'trash-can',
                      variant: 'delete-ghost',
                      onClick: async (btnElemtn: HTMLButtonElement) => {
                        if (
                          await prompts.delete({
                            submitBtnText: 'Delete',
                            cancelBtnText: 'Cancel',
                            title:
                              'Do you really want to delete this car park?',
                          })
                        ) {
                          deleteCarPark(carpark);
                        }
                        btnElemtn.focus();
                      },
                    } as TableAction,
                  ]
                : []
            )
          )}
        />
      ),
    [canEdit, communityId, deleteCarPark, history, prompts, setCarParkNumbers]
  );

  const crumbs = useMemo(
    () => [
      { text: 'Communities', href: appUri.COMMUNITIES },
      { text: `Car parks (${communityName()})`, href: '' },
    ],
    [communityName]
  );

  return (
    <TablePageLayout.PageLayoutContainer>
      {carParkModalOpened && (
        <CarParkModal
          carPark={selectedCarPark.current}
          communityId={communityId}
          onSave={(p) => updateCarPark(mapCarPark(p))}
          onClose={() => {
            setCarParkModalOpened(false);
            if (selectedCarPark.current) editBtnRef.current?.focus();
            else addParkBtnRef.current?.focus();
          }}
        />
      )}

      {communityId && (
        <Breadcrumbs className="kinto-page__breadcrumbs" crumbs={crumbs} />
      )}

      <Styled.CarParkContainer className="kinto-carpark">
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="kinto-page__header"
        >
          Car parks ({communityName() as any})
        </Heading>

        {canEdit && (
          <Button
            ref={addParkBtnRef}
            variant="primary"
            icon={<Icon name="plus" />}
            className="panel__edit-button"
            onClick={() => {
              selectedCarPark.current = undefined;
              setCarParkModalOpened(true);
            }}
          >
            Add car park
          </Button>
        )}

        {!carparks.length && !loadingCarparks && <NoData>No car parks.</NoData>}

        <Table<CarParkType>
          expandable
          disableSort
          tableId="carpark-table"
          className="kinto-carpark__table"
          translationKeys={CAR_PARK_TRANSLATIONS}
          excludeColumns={excludeColumns}
          breakAtMaxWidth={1200}
          tableData={carparks}
          tableActions={tableActions}
        />
      </Styled.CarParkContainer>
    </TablePageLayout.PageLayoutContainer>
  );
};

export default CarPark;

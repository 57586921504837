import { Modal } from '@faxi/web-component-library';
import { flex, position } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const PremiumFeaturesModal = styled(Modal)`
  &.kinto-premium-features-modal {
    .expander__trigger {
      ${flex('row', 'space-between', 'center')};
      width: 100%;

      &__title {
        ${position('absolute', 'right 0')};
      }
    }

    .expander__container {
      width: 100%;
    }
  }
`;

import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Platform } from 'models';
import apiPlatform from 'modules/router/api/apiPlatform';
import AuthContext from 'store/AuthProvider/Auth.context';
import MarketSettingsContext from './MarketSettings.context';
import { PlatformEmission } from 'models/Platform';

type MarketSettingsProviderProps = {
  children: ReactNode;
};

const MarketSettingsProvider: FC<MarketSettingsProviderProps> = (props) => {
  const { children } = props;
  const { admin } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [platform, setPlatform] = useState<Platform>({} as Platform);
  const [platformEmission, setPlatformEmission] = useState<PlatformEmission>(
    {} as PlatformEmission
  );

  useEffect(() => {
    if (!admin) return;

    const loadPlatform = async () => {
      try {
        setLoading(true);

        const {
          data: { data: platformData },
        } = await apiPlatform.getPlatformbyId(admin?.platform.id.toString());

        setPlatform(platformData[0]);
        setPlatformEmission(platformData[0].platform_emission[0]);
      } catch (e) {
        console.error(e);
        setRequestError(true);
      } finally {
        setLoading(false);
      }
    };

    loadPlatform();
  }, [admin]);

  return (
    <MarketSettingsContext.Provider
      value={{
        loading,
        platform,
        requestError,
        platformEmission,
        setPlatformEmission,
        setPlatform,
      }}
    >
      {children}
    </MarketSettingsContext.Provider>
  );
};

export default MarketSettingsProvider;

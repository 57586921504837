import { FC } from 'react';
import { Checkbox, CheckboxProps } from '@faxi/web-component-library';
import { FieldProps } from '@faxi/web-form';

export type CheckboxFieldProps = FieldProps<boolean> &
  Omit<CheckboxProps, 'ref'>;

const CheckboxField: FC<CheckboxFieldProps> = (
  props: CheckboxFieldProps
): JSX.Element => {
  const { value, label, touched, dirty, ...rest } = props;

  return (
    <Checkbox
      {...rest}
      label={label}
      aria-label={label}
      checked={Boolean(value)}
    />
  );
};

export default CheckboxField;

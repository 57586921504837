import { useMemo, useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { AxiosError } from 'axios';
import { Button, useUtilities, Heading } from '@faxi/web-component-library';
import { FormField, Form, validators, validationRegexes } from '@faxi/web-form';

import { appUri } from 'config';
import { Icon } from 'components';
import { snackBarErrorMessage } from 'utils';

import { AuthBody } from 'models';
import { apiAuth } from 'modules';
import { AuthContext } from 'store';
import { credentialService } from 'services';

import InputField from '../../fields/InputField';
import PasswordField from '../../fields/PasswordField';

import * as Styled from './LoginForm.styles';

const LoginForm: React.FC = (): JSX.Element => {
  const history = useHistory();

  const { setIsAuthenticated } = useContext(AuthContext);

  const { showOverlay, hideOverlay } = useUtilities();

  const handleLoginUser = useCallback(
    async (values: any) => {
      try {
        showOverlay('body');

        const { email, password } = values;
        const { data: authData } = await apiAuth.login(email, password);

        const authBody = {
          token: authData.data.token,
          refreshToken: authData.data.refresh_token,
          userId: authData.data.user_id.toString(),
        } as AuthBody;

        document.cookie = `KintoSID=${authData.data.token}; Path=/; HttpOnly; Secure;`;

        credentialService.saveAuthBody(authBody);
        setIsAuthenticated(true);

        history.push(appUri.DASHBOARD);
      } catch (e) {
        snackBarErrorMessage(e as AxiosError);
      } finally {
        hideOverlay('body');
      }
    },
    [hideOverlay, history, setIsAuthenticated, showOverlay]
  );

  const validations = useMemo(
    () => ({
      email: [
        validators.general.required('This field is required'),
        validators.general.regex(
          validationRegexes.workEmail,
          'Please enter a valid email'
        ),
      ],
      password: [validators.general.required('This field is required')],
    }),
    []
  );

  return (
    <Styled.LoginFormStyled className="login-form">
      <Heading className="login-form__heading" level="2">
        Login
      </Heading>
      <Form
        onSubmit={handleLoginUser}
        strictValidation={false}
        className="login-form__form"
      >
        <Heading className="login-form__form__heading" level="3">
          Email
        </Heading>
        <FormField
          className="login-form__form__field"
          component={InputField}
          name="email"
          prefixIcon={<Icon name="envelope" />}
          placeholder="Your email"
          validate={validations.email}
          autoFocus
        />
        <Heading className="login-form__form__heading" level="3">
          Password
        </Heading>
        <FormField
          className="login-form__form__field"
          component={PasswordField}
          name="password"
          prefixIcon={<Icon name="lock-alt" />}
          placeholder="Your password"
          validate={validations.password}
        />
        <div className="login-form__actions">
          <Button className="login-form__actions__login" type="submit">
            Login
          </Button>
        </div>
      </Form>
    </Styled.LoginFormStyled>
  );
};
export default LoginForm;

import { VerificationType } from 'models/Community';
import { CommunitySettingsType } from '../CommunitySettingsModal.component';

const SETTINGS_DEFAULT_VALUES = {
  verifafter: '0.5',
  gps_nbseg: '0',
  gps_percseg: '0.5',
  gps_percdist: '0.5',
  ble_threshold: '0.5',
  radius: '250',
  cycle_min: '0',
  walking_min: '0',
  caw_threshold: '0.5',
} as Partial<CommunitySettingsType>;

const PERCANTAGE_FIELDS = [
  'verifafter',
  'gps_nbseg',
  'gps_percseg',
  'gps_percdist',
  'ble_threshold',
  'caw_threshold',
] as Array<keyof CommunitySettingsType>;

const TOOLTIP_TITLES = {
  radius:
    'The area around the community location. Ideally 200-300m. For bigger communities such as airports, large factories... please contact support.',
  gps_nbseg: 'Number of segments that will be recorded during the journey',
  gps_percseg:
    'Minimum percentage of recorded GPS segments for which journey will be verified',
  gps_percdist:
    'Minimum percentage of recorded distance for which journey will be verified',
  ble_threshold: 'Bluetooth in percentage',
  verifafter:
    'The required percentage of the journey completed for journey to be verified. Suggested value: 50%. Note that a higher value will strongly affect the number of verified journeys.',
  cycle_min:
    'Minimal distance required for a Cycling journey to be verified. Suggested value: 0.',
  walking_min:
    'Minimal distance required for a Walking journey to be verified. Suggested value: 0.',
  caw_threshold:
    'Percentage of the total journey distance required to be in the Walking or Cycling mode for the journey to be verified. Suggested value: 50.',
} as Record<keyof CommunitySettingsType, any>;

// convert numbers to percentage and vice versa
const convertNumbers = <T extends Record<string, any>>(
  object: T,
  keys: Array<keyof T>,
  type: 'mul' | 'div' | 'string'
) => {
  return Object.entries(object).reduce((prevValue, [key, value]) => {
    if (!keys.includes(key)) return { ...prevValue };
    else
      return {
        ...prevValue,
        [key]:
          value !== undefined
            ? type === 'mul'
              ? `${Number(value === 0 ? 0 : (value * 100).toFixed(8))}`
              : type === 'div'
              ? parseFloat(`${value / 100}`)
              : type === 'string'
              ? `${value}`
              : ''
            : '',
      };
  }, object);
};

const JOURNEY_VERIFICATION_EASY = {
  verifafter: '0.2',
  gps_nbseg: '0',
  gps_percseg: '0.1',
  gps_percdist: '0.1',
  ble_threshold: '0.2',
  cycle_min: '0',
  walking_min: '0',
  caw_threshold: '0.01',
};

const JOURNEY_VERIFICATION_MEDIUM = {
  verifafter: '0.4',
  gps_nbseg: '0',
  gps_percseg: '0.35',
  gps_percdist: '0.35',
  ble_threshold: '0.4',
  cycle_min: '0',
  walking_min: '0',
  caw_threshold: '0.25',
};

const JOURNEY_VERIFICATION_HARD = {
  verifafter: '0.6',
  gps_nbseg: '0',
  gps_percseg: '0.6',
  gps_percdist: '0.6',
  ble_threshold: '0.6',
  cycle_min: '0',
  walking_min: '0',
  caw_threshold: '0.5',
};

const VERIF_GRADE: Record<VerificationType, Partial<CommunitySettingsType>> = {
  easy: JOURNEY_VERIFICATION_EASY,
  medium: JOURNEY_VERIFICATION_MEDIUM,
  hard: JOURNEY_VERIFICATION_HARD,
  custom: SETTINGS_DEFAULT_VALUES,
};

export {
  TOOLTIP_TITLES,
  PERCANTAGE_FIELDS,
  SETTINGS_DEFAULT_VALUES,
  convertNumbers,
  JOURNEY_VERIFICATION_EASY,
  JOURNEY_VERIFICATION_MEDIUM,
  JOURNEY_VERIFICATION_HARD,
  VERIF_GRADE,
};

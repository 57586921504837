import DriverTraxTable from 'components/tables/DriverTraxTable';
import PassengerTraxTable from 'components/tables/PassengerTraxTable';
import { appUri, RoutesRenderer } from 'config';
import { Route } from 'models';
import { PrivateRoute } from 'modules';
import { AdminsPage, Map } from 'pages';
import Journeys from 'pages/Journeys';
import MapDataProvider from 'pages/Map/providers/MapData';
import Communities from './Communities.page';
import carParkRoutes from './pages/routes';
import CarParkProvider from 'store/CarParkProvider';
import Journey from 'pages/Journeys/pages/components/Journey';
import CreateCommunity from 'pages/CreateCommunity';

const mapRoutes: Route[] = [
  {
    component: Map,
    handle: 'Journey map',
    title: 'Journey map',
    exact: true,
    path: appUri.COMMUNITIES_JOURNEY_MAP(),
  },
  {
    component: DriverTraxTable,
    handle: 'Driver Trax',
    title: 'Driver Trax',
    exact: true,
    path: appUri.COMMUNITIES_JOURNEY_DRIVER_TRAXES(),
  },
  {
    component: PassengerTraxTable,
    handle: 'Passenger Trax',
    title: 'Passenger Trax',
    exact: true,
    path: appUri.COMMUNITIES_JOURNEY_PASSENGER_TRAXES(),
  },
];

const communitiesJourneyRoutes: Route[] = [
  {
    component: Journeys,
    handle: 'Journeys',
    title: 'Journeys',
    exact: true,
    path: appUri.COMMUNITIES_JOURNEYS(),
  },

  {
    component: Journey,
    handle: 'Journey',
    title: 'Journey',
    exact: true,
    path: appUri.COMMUNITIES_JOURNEY(),
  },
  {
    component: (props: any) => (
      <PrivateRoute
        component={
          <MapDataProvider>
            <RoutesRenderer {...props} />
          </MapDataProvider>
        }
      />
    ),
    handle: 'Journey map',
    title: 'Journey map',
    exact: false,
    path: appUri.COMMUNITIES_JOURNEY_MAP(),
    routes: mapRoutes,
  },
];

const communitiesRoutes: Route[] = [
  {
    component: Communities,
    handle: 'Communities Table',
    title: 'Communities Table',
    exact: true,
    path: appUri.COMMUNITIES,
  },
  {
    component: (props: any) => (
      <PrivateRoute
        component={
          <CarParkProvider>
            <RoutesRenderer {...props} />
          </CarParkProvider>
        }
      />
    ),
    handle: 'Car parks',
    title: 'Car parks',
    exact: false,
    path: appUri.COMMUNITIES_CARPARK(),
    routes: carParkRoutes,
  },
  {
    component: AdminsPage,
    handle: 'Admins',
    title: 'Admins',
    exact: true,
    path: appUri.COMMUNITIES_ADMINS(),
  },
  {
    component: (props: any) => (
      <PrivateRoute component={<RoutesRenderer {...props} />} />
    ),
    handle: 'Communities Table Journeys',
    title: 'Communities Table Journeys',
    exact: false,
    path: appUri.COMMUNITIES_JOURNEYS(),
    routes: communitiesJourneyRoutes,
  },
  {
    component: CreateCommunity,
    handle: 'Create Community',
    title: 'Create Community',
    exact: false,
    path: appUri.CREATE_COMMUNITIY,
  },
];

export default communitiesRoutes;

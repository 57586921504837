import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  InfiniteScroll,
  Input,
  Switch,
  Tooltip,
  useCallbackRef,
  useGlowScroll,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { Icon } from 'components';
import { useInfinitePagination } from 'hooks';
import { apiCommunities } from 'modules';

import * as Styled from './ActivatePerCommunityStep.styles';

type DropdownCommunityOption = { id: number; name: string };
const COMMUNITIES_PER_PAGE = 10;

const SwitchMemoized = memo(Switch);

type ActivatePerCommunityStepProps = {
  communityToggles: Record<number, string>;
  setCommunityToggles: React.Dispatch<
    React.SetStateAction<Record<number, string>>
  >;
  disabledCommunities?: number[];
};
const ActivatePerCommunityStep = (props: ActivatePerCommunityStepProps) => {
  const { communityToggles, setCommunityToggles, disabledCommunities } = props;
  const [totalState, setTotalState] = useState<number>();

  const [modalTableContainer, modalTableContainerRef] = useCallbackRef(null);
  const [modalTableWrapper, modalTableWrapperRef] = useCallbackRef(null);

  useGlowScroll(
    modalTableContainer,
    modalTableWrapper,
    1,
    0,
    'gray',
    'vertical'
  );

  const handleSwitch = useCallback(
    (communityId: number) => {
      setCommunityToggles({
        ...communityToggles,
        [`${communityId}`]: !communityToggles?.[communityId],
      });
    },
    [communityToggles, setCommunityToggles]
  );

  const {
    items: communites,
    loading: loadingCommunities,
    total,
    search,
    onSearchChange,
    handleContainerScroll,
  } = useInfinitePagination<DropdownCommunityOption, 'organisations'>({
    itemsKey: 'organisations',
    perPage: COMMUNITIES_PER_PAGE,
    spinnerParent: '.activate-per-community__communities',
    useQueryParams: false,
    apiRequest: (page: string, search: string) => {
      return apiCommunities.getSearchCommunities(
        page,
        search,
        `${COMMUNITIES_PER_PAGE}`
      );
    },
  });

  const selectedCommunitiesCount = useMemo(() => {
    return Object.keys(communityToggles).reduce((acc: number, curr: string) => {
      if ((communityToggles as any)?.[`${curr}`]) {
        ++acc;
      }
      return acc;
    }, 0);
  }, [communityToggles]);

  useEffect(() => {
    if (!totalState) {
      setTotalState(total);
    }
  }, [total, totalState]);

  return (
    <Styled.Container>
      <Input
        prefixIcon={<Icon name={'search'} />}
        placeholder="Community..."
        className="activate-per-community__search"
        value={search}
        onChange={onSearchChange}
      />

      <div className="activate-per-community__selected">
        Selected: {selectedCommunitiesCount} of {totalState}
      </div>

      <div
        className="activate-per-community__communities"
        ref={modalTableContainerRef}
      >
        {communites.length > 0 ? (
          <InfiniteScroll
            loading={loadingCommunities}
            className="activate-per-community__communities__infinitive-scroll"
            readyForScrolling={communites.length > 0}
            onScroll={handleContainerScroll}
            ref={modalTableWrapperRef}
          >
            {communites?.map(({ id, name }) => {
              return (
                name && (
                  <Tooltip
                    placement="top-start"
                    content={
                      'You can’t disable reward for the community which has this reward included in the active campaign'
                    }
                    disabled={!disabledCommunities?.includes(id)}
                    key={id}
                    className="activate-per-community__tooltip"
                  >
                    <div>
                      <SwitchMemoized
                        name={name}
                        label={name}
                        className={classNames(
                          'activate-per-community__switch',
                          {
                            'activate-per-community__switch--disabled':
                              disabledCommunities?.includes(id),
                          }
                        )}
                        value={Boolean(communityToggles?.[id])}
                        onChange={() => {
                          handleSwitch(id);
                        }}
                        disabled={disabledCommunities?.includes(id)}
                      />
                    </div>
                  </Tooltip>
                )
              );
            })}
          </InfiniteScroll>
        ) : (
          <div>Sorry, there are no results that match your search.</div>
        )}
      </div>
    </Styled.Container>
  );
};

export default ActivatePerCommunityStep;

import { ActivityTableType } from '../ChangeLogs.page';

const EXCLUDED_COLUMNS = ['id', 'properties'] as Array<keyof ActivityTableType>;

const TRANSLATION_KEYS = {
  property: 'Property',
  date: 'When',
  new_value: 'New value',
  old_value: 'Old value',
  user_name: 'User',
  event: 'Event',
} as Record<keyof ActivityTableType, string>;

export { TRANSLATION_KEYS, EXCLUDED_COLUMNS };

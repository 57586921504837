import {
  useMemo,
  useCallback,
  useState,
  useContext,
  useEffect,
  FC,
} from 'react';
import {
  Button,
  Heading,
  Icon,
  useUtilities,
  Table,
  Tooltip,
  getColor,
} from '@faxi/web-component-library';
import { useHistory } from 'react-router';

import apiServiceAccounts, {
  ServiceAccountApiKey,
} from 'modules/router/api/apiServiceAccounts';

import { AuthContext } from 'store';
import { appUri } from 'config';
import { snackBarErrorMessage, snackBarSuccessMessage } from 'utils';
import { NoPermissionsPlaceholder } from 'components';
import { Route } from 'models';
import { storageService } from 'services';
import { STORAGE_KEYS } from 'services/storageService';
import { TableAction } from 'components/_layouts/PageLayout.styles';
import * as TablePageLayout from '../../components/_layouts/PageLayout.styles';

type ServiceAccountApiKeyTable = Pick<ServiceAccountApiKey, 'id'> & {
  hash: JSX.Element;
};

const ServiceAccountsKeys: FC<{ route: Route }> = () => {
  const { showOverlay, hideOverlay, prompts } = useUtilities();

  const [hashKeys, setHashKeys] = useState<ServiceAccountApiKeyTable[]>([]);

  const [requestError, setRequestError] = useState(false);

  const renderHashElement = useCallback(
    (hash: string) => (
      <div className="kinto-page__copy-to-clipboard">
        <span>{hash}</span>
        <Tooltip content="Copy to clipboard">
          <button
            onClick={async (e) => {
              e.stopPropagation();

              try {
                await navigator.clipboard.writeText(hash);

                snackBarSuccessMessage('API key copied to your clipboard.');
              } catch (e) {
                console.error(e);
                snackBarErrorMessage('An error occurred.');
              }
            }}
          >
            <Icon name="copy" color={getColor('--PRIMARY_1_1')} />
          </button>
        </Tooltip>
      </div>
    ),
    []
  );

  const getKeys = useCallback(async () => {
    showOverlay('.kinto-page', 'fixed');

    try {
      const {
        data: { api_keys },
      } = await apiServiceAccounts.getKeys();

      if (api_keys.length !== 0) {
        const tmp = api_keys.map(({ id, hash }: ServiceAccountApiKey) => ({
          id,
          hash: renderHashElement(hash),
        }));
        setHashKeys(tmp as any);
      }
    } catch (e) {
      console.error(e);
      setRequestError(true);
    } finally {
      hideOverlay('.kinto-page');
    }
  }, [hideOverlay, renderHashElement, showOverlay]);

  useEffect(() => {
    getKeys();
  }, [getKeys]);

  const addKey = useCallback(async () => {
    showOverlay('.kinto-page');

    try {
      const {
        data: { id, hash },
      } = await apiServiceAccounts.addKey();

      if (id) {
        snackBarSuccessMessage(`Successfully created a new API key: ${hash}`);
        setHashKeys((old) => [...old, { id, hash: renderHashElement(hash) }]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      hideOverlay('.kinto-page');
    }
  }, [hideOverlay, renderHashElement, showOverlay]);

  const { admin } = useContext(AuthContext);

  const canEdit = useMemo(
    () => !!admin?.permissions.find((p) => p.name === 'service_account_edit'),
    [admin]
  );

  const history = useHistory();

  const translationKeys = useMemo(
    () =>
      ({
        id: 'ID',
        hash: 'Key',
      } as Record<Partial<keyof ServiceAccountApiKey>, string>),
    []
  );

  const handleDeleteHash = useCallback(
    async (hash: ServiceAccountApiKeyTable) => {
      try {
        const data = await apiServiceAccounts.deleteKey(hash.id);

        if (data.status === 'Success') {
          snackBarSuccessMessage('Successfully deleted the API key');
          getKeys();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [getKeys]
  );

  const tableActions = useMemo(
    () => (data: ServiceAccountApiKeyTable) => {
      const hash = data.hash.props.children[0].props.children;

      return (
        <TablePageLayout.TableActions
          actions={(
            [
              {
                name: 'Service accounts',
                icon: 'users-gear',
                onClick: () => {
                  storageService.setItem(STORAGE_KEYS.SELECTED_HASH_KEY, hash);
                  history.push(
                    appUri.SETTINGS_SERVICE_ACCOUNTS_KEYS_KEY(`${data.id}`)
                  );
                },
              },
            ] as TableAction[]
          ).concat(
            canEdit
              ? ([
                  {
                    name: 'Delete',
                    icon: 'trash-can',
                    variant: 'delete-ghost',
                    onClick: async (btnElement: HTMLButtonElement) => {
                      if (
                        await prompts.delete({
                          submitBtnText: 'Delete',
                          cancelBtnText: 'Cancel',
                          title: `Do you really want to delete hash ${hash}?`,
                        })
                      ) {
                        handleDeleteHash(data);
                      }
                      btnElement.focus();
                    },
                  },
                ] as TableAction[])
              : []
          )}
        />
      );
    },
    [canEdit, handleDeleteHash, history, prompts]
  );

  return requestError ? (
    <NoPermissionsPlaceholder />
  ) : (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__header"
      >
        Service accounts API keys
      </Heading>

      {canEdit && (
        <div className="kinto-page__actions">
          <Button icon={<Icon name="plus" />} onClick={addKey}>
            Create API key
          </Button>
        </div>
      )}

      <Table<ServiceAccountApiKeyTable>
        expandable
        tableId="service-account-keys-table"
        className="service-accounts-keys__table"
        tableData={hashKeys}
        translationKeys={translationKeys}
        tableActions={tableActions}
        disableSort
      />
    </TablePageLayout.PageLayoutContainer>
  );
};

export default ServiceAccountsKeys;

import { renderRoutes } from 'react-router-config';

import * as Styled from './Login.page.styles';

const Login: React.FC<{
  route?: any;
}> = (props): JSX.Element => {
  const { route } = props;

  return (
    <Styled.LoginStyled className="login-page">
      {route && renderRoutes(route.routes)}
    </Styled.LoginStyled>
  );
};

export default Login;

import classNames from 'classnames';
import {
  Table,
  TableProps,
  Heading,
  Spinner,
  getColor,
} from '@faxi/web-component-library';

import * as Styles from './TableData.styles';

type TableDataProps<T extends Record<string, any>> = {
  className?: string;
  data: Array<T>;
  label: string;
  emptyPlaceholder?: string;
  loading?: boolean;
  tableActions?: (data: T) => JSX.Element;
} & Pick<
  TableProps,
  | 'excludeColumns'
  | 'translationKeys'
  | 'expandable'
  | 'breakAtMaxWidth'
  | 'legend'
  | 'tableId'
>;

function TableData<T extends Record<string, any> & { id: number }>(
  props: TableDataProps<T>
) {
  const {
    className,
    data,
    label,
    excludeColumns,
    translationKeys,
    expandable,
    emptyPlaceholder,
    loading,
    breakAtMaxWidth,
    tableId,
    legend,
    tableActions,
  } = props;

  const classes = classNames('sa-table-data', className);

  return (
    <Styles.TableDataContainer className={classes}>
      <Heading
        level="4"
        color={getColor('--PRIMARY_1_1')}
        className="sa-table-data__title"
      >
        {label}
      </Heading>
      {data.length === 0 && !loading ? (
        <Heading level="4" className="sa-table-data__empty-placeholder">
          {emptyPlaceholder}
        </Heading>
      ) : loading ? (
        <Spinner
          className="sa-table-data__spinner"
          color={getColor('--PRIMARY_1_1')}
          size={32}
        />
      ) : (
        <Table<T>
          className="sa-table-data__table"
          tableData={data}
          tableId={tableId}
          expandable={expandable}
          excludeColumns={excludeColumns}
          translationKeys={translationKeys as Record<keyof T, string>}
          disableSort
          breakAtMaxWidth={breakAtMaxWidth}
          legend={legend}
          tableActions={tableActions}
        />
      )}
    </Styles.TableDataContainer>
  );
}

export default TableData;

import { FC } from 'react';

import {
  Breadcrumbs,
  Chart,
  Heading,
  InfiniteScroll,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { TablePageLayout } from 'components';
import { appUri, dateFormat } from 'config';
import { useInfinitePagination } from 'hooks';
import { apiCommunities } from 'modules';
import { formatChartData } from 'utils';
import { CommunityEKG } from 'models';

const CommunityEKGPage: FC = () => {
  const {
    loading,
    items: communitiesEKGData,
    handleContainerScroll,
  } = useInfinitePagination<CommunityEKG, 'organisations'>({
    spinnerParent: '.community-ekg-page',
    itemsKey: 'organisations',
    perPage: 10,
    useQueryParams: false,

    apiRequest: (page: string, search: string, perPage: string) =>
      apiCommunities.getEKG(page, search, perPage),
  });

  return (
    <TablePageLayout.PageLayoutContainer
      className={classNames('kinto-page', 'community-ekg-page')}
    >
      <Breadcrumbs
        className="kinto-page__breadcrumbs"
        crumbs={[
          { text: 'Analytics', href: appUri.ANALYTICS },
          {
            text: 'Analytics EKG',
            href: '',
          },
        ]}
      />
      <Heading
        level="1"
        color={getComputedStyle(document.documentElement).getPropertyValue(
          '--PRIMARY_1_1'
        )}
        className="kinto-page__header"
      >
        Community EKG
      </Heading>

      <InfiniteScroll
        onScroll={handleContainerScroll}
        className="community-ekg-page__charts-container"
        loading={loading}
        readyForScrolling={communitiesEKGData.length > 0}
      >
        {communitiesEKGData.map(
          ({ 'date-range': dateRange, name, creation_date }) => (
            <div
              className="community-ekg-page__charts-container__chart"
              key={name}
            >
              <div className="community-ekg-page__charts-container__chart__name">
                {name}{' '}
                <span>
                  (Created on {dayjs(creation_date).format(dateFormat)})
                </span>
              </div>
              <Chart
                series={[
                  {
                    name: 'Journeys',
                    data: formatChartData(dateRange, 'journeys'),
                  },
                  {
                    name: 'Earned rewards',
                    data: formatChartData(dateRange, 'rewards'),
                  },
                ]}
              />
            </div>
          )
        )}
      </InfiniteScroll>
    </TablePageLayout.PageLayoutContainer>
  );
};

export default CommunityEKGPage;

import { theme } from '@faxi/web-component-library';
import {
  flex,
  flexGap,
  fontSize,
  phablet,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const InfoCardContainer = styled.div`
  ${flex('row')};
  ${flexGap(theme.sizes.SIZE_10, 'row')};

  padding: ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};
  color: var(--LABEL_TEXT_DARK_1_1);
  background-color: var(--LABEL_TEXT_1_7);

  .wcl-icon {
    ${size(theme.sizes.SIZE_24)};
  }

  .kinto-info-card {
    &__content {
      ${flex('row', 'flex-start', 'center')};
      width: 100%;

      ${phablet(css`
        ${flex('column', 'center', 'flex-start')};
        gap: ${theme.sizes.SIZE_12};
      `)}
    }

    &__text {
      ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

      font-weight: 600;
      color: var(--SHADE_1_1);
      max-width: ${theme.sizes.SIZE_664};
      margin: 0 ${theme.sizes.SIZE_10} 0 0;
    }

    &__link {
      font-weight: 500;
      text-align: left;
      width: fit-content;
      white-space: nowrap;
      color: var(--LABEL_TEXT_DARK_1_1);
    }
  }
`;

import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const CreateCommunityPageStyled = styled.div`
  padding: ${theme.sizes.SIZE_40} ${theme.sizes.SIZE_72};
  overflow: auto;

  .create-community {
    &__hide {
      display: none;
    }

    &__header {
      ${flex('row', 'space-between')};

      &__title {
        font-size: ${theme.fontSizes.FONT_24};
        font-weight: 400;
        margin-bottom: ${theme.sizes.SIZE_72};
      }
    }

    &__stepper {
      width: ${theme.sizes.SIZE_712};

      &__community-details {
        margin-bottom: ${theme.sizes.SIZE_56};
      }

      &__add-admin-role {
        margin-bottom: ${theme.sizes.SIZE_16};
      }

      .kinto-stepper__step__title {
        margin-left: ${theme.sizes.SIZE_4};
      }
    }

    &__next-step-btn {
      margin-top: ${theme.sizes.SIZE_80};
    }

    &__create-btn {
      margin-top: ${theme.sizes.SIZE_46};
    }
  }
`;

import { Modal as ModalComponent, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Modal = styled(ModalComponent)`
  .wcl-modal {
    background-color: var(--BACKGROUND_2_1);
  }

  .kinto-modal__actions {
    margin-top: 0;
  }

  .rewards-modal {
    &__description {
      font-size: ${theme.fontSizes.FONT_14};
      color: var(--SHADE_1_2);
      font-weight: 400;
      margin-bottom: ${theme.sizes.SIZE_32};
      margin-top: 0;
    }

    &__rewards {
      ${flex('column')};
      max-height: ${theme.sizes.SIZE_568};
      overflow: auto;
      padding-right: ${theme.sizes.SIZE_8};
    }

    &__switch {
      ${flex('row', 'space-between', 'center')};
      cursor: pointer;
      border-bottom: 1px solid var(--SHADE_1_8);
      width: 100%;
      background-color: var(--BACKGROUND_1_1);
      border-radius: ${theme.sizes.SIZE_8};
      padding: ${theme.sizes.SIZE_8};
      margin-bottom: ${theme.sizes.SIZE_8};

      .wcl-switch__label {
        font-weight: 600;
        color: var(--SHADE_1_1);
        flex: 1;
      }

      &--disabled {
        cursor: not-allowed;

        .wcl-switch__label {
          color: var(--SHADE_1_4);
        }
      }
    }
  }
`;

import { Modal as ModalComponent, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Modal = styled(ModalComponent)`
  .reports-modal {
    &__report {
      margin: ${theme.sizes.SIZE_16} 0;

      &:first-of-type {
        margin-top: 0;
      }
      &__name {
        font-weight: 600;
        margin-bottom: ${theme.sizes.SIZE_8};
        color: var(--SHADE_1_1);
      }

      &__desc {
        ${flex('row', undefined, 'flex-start')}

        > div {
          word-break: break-all;
        }

        svg {
          margin-right: ${theme.sizes.SIZE_8};
        }

        &--answer {
          svg {
            margin-top: ${theme.sizes.SIZE_4};
          }
        }
      }
    }

    &__comment {
      margin-top: ${theme.sizes.SIZE_32};

      .textarea__container {
        width: 100%;
      }
    }
  }

  .kinto-modal__actions {
    margin-top: 0;
  }
`;

import { FC } from 'react';
import { Tabs, TabOption, TabsProps } from '@faxi/web-component-library';
import { FieldProps } from '@faxi/web-form';

export type TabsFieldProps = FieldProps<TabOption> & TabsProps<string>;

const TabsField: FC<TabsFieldProps> = (props: TabsFieldProps): JSX.Element => {
  const { label, touched, dirty, ...rest } = props;

  return <Tabs {...rest} label={label} aria-label={label} />;
};

export default TabsField;

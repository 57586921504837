import { useRef, useEffect, useMemo } from 'react';
import { Redirect, useLocation } from 'react-router';
import { appUri } from 'config';
import credentialService from 'services/credentialService';

const PrivateRoute: React.FC<{
  component: React.FC<any> | JSX.Element;
}> = (props) => {
  const { component: Component } = props;
  const isAuthenticated = useMemo(() => credentialService.token, []);

  const fromRef = useRef('');
  const location = useLocation<{
    from: string;
  }>();

  useEffect(() => {
    if (!fromRef.current && location.state) {
      fromRef.current = location.state.from;
    }
  }, [location]);

  return !isAuthenticated ? (
    <Redirect to={appUri.LOGIN} />
  ) : typeof Component === 'object' ? (
    Component
  ) : (
    <Component from={fromRef.current} />
  );
};

export default PrivateRoute;

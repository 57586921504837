import { AxiosError } from 'axios';
import errorCodes from 'services/errorCodes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import authBus from 'modules/authBus';

dayjs.extend(utc);

function noop() {}

function getStringError(error: any): string {
  if (!error?.response?.data) return "Couldn't parse error";

  let {
    response: {
      data: { message },
    },
  } = error;

  if (!message) return "Couldn't parse error";

  message =
    typeof message === 'string'
      ? message
      : typeof message.errors[0].message === 'string'
      ? message.errors[0].message
      : 'Invalid data';
  return message;
}

function getErrorCodeMessage(error: any): string {
  if (typeof error === 'string') return error;

  if (!error.response?.data?.data?.code && !error.response?.data?.data?.errc) {
    return "Couldn't parse error";
  }

  const {
    response: {
      data: { data: errcCodeData },
    },
  } = error as any;

  return errorCodes[errcCodeData.code]
    ? errorCodes[errcCodeData.code]
    : errorCodes[errcCodeData.errc] || "Couldn't parse error";
}

function snackBarSuccessMessage(message: string): void {
  authBus.broadcastEvent('show_snackbar', {
    props: {
      variant: 'success',
      actionButtonText: 'Dismiss',
      text: message,
    },
    config: {
      disappearAfter: 5000,
    },
  });
}

function snackBarErrorMessage(error: AxiosError | string): void {
  authBus.broadcastEvent('show_snackbar', {
    props: {
      variant: 'error',
      actionButtonText: 'Dismiss',
      text:
        typeof error === 'string'
          ? error
          : getErrorCodeMessage(error) || getStringError(error),
    },
    config: {
      disappearAfter: 5000,
    },
  });
}

function getQueryParamsObject(search: string) {
  if (!search || search === '?') return {};
  return search
    .replace(/^\?/, '')
    .split('&')
    .map((pairStr) => pairStr.split('='))
    .reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value }),
      {} as Record<string, string | undefined>
    );
}

function findDiffBetweenQParamsObjects(
  lastLocation: Location,
  currentLocation: Location
): Record<string, string | undefined> | undefined {
  const { search: lastSearch } = lastLocation;
  const { search } = currentLocation;

  if (search === lastSearch) return {};

  const lastQParams = getQueryParamsObject(lastSearch);
  const qParams = getQueryParamsObject(search);

  for (const prop in lastQParams) {
    if (!qParams[prop]) {
      qParams[prop] = undefined;
      continue;
    }
    if (lastQParams[prop] === qParams[prop]) {
      delete qParams[prop];
      continue;
    }
  }

  return qParams;
}

function isNonEmptyString(value: any) {
  return value.trim().length > 0;
}

const dateInUTCFormat = (
  date?: string | Date,
  dateFormat = 'DD.MM.YYYY h:mm A'
) => (date ? dayjs.utc(date).local().format(dateFormat) : '-');

const removeURLPlaceholderRegex = new RegExp(':[a-zA-Z]+', 'g');

const downloadLink = (dataUrl: string, filename: string) => {
  const link = document.createElement('a');
  link.href = dataUrl;
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export {
  noop,
  getStringError,
  getErrorCodeMessage,
  snackBarErrorMessage,
  snackBarSuccessMessage,
  findDiffBetweenQParamsObjects,
  dateInUTCFormat,
  isNonEmptyString,
  downloadLink,
  removeURLPlaceholderRegex,
};

import { Modal as ModalComponent, theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const Modal = styled(ModalComponent)`
  .permission-field {
    border: unset;
    margin: unset;
    padding: unset;

    legend,
    &__value {
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

      margin: 0;
      font-weight: 400;
      text-transform: capitalize;
      color: var(--SHADE_1_1);
    }

    &__value {
      font-weight: 600;
      margin-top: ${theme.sizes.SIZE_8};
    }

    &.user-permission-field {
      ${flex('row')};

      width: 100%;
      position: relative;

      .permission-field__value {
        font-weight: 400;
        text-transform: unset;
        color: var(--SHADE_1_2);
      }

      .wcl-switch {
        margin-left: auto;
      }
    }
  }

  hr {
    ${size('100%', theme.sizes.SIZE_1)};

    border: none;
    background-color: var(--SHADE_1_7);
  }

  .wcl-modal {
    &__main {
      ${flex('column')};
      ${flexGap(theme.sizes.SIZE_24, 'column')};
    }

    &__footer {
      ${flexGap(theme.sizes.SIZE_48, 'row')};
      width: 100%;
    }
  }
`;

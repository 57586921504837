import { Switch, SwitchProps } from '@faxi/web-component-library';
import { FieldProps } from '@faxi/web-form';

type ValueOnChange = (event: string) => void;

export type SwitchFieldProps = FieldProps<string, ValueOnChange> &
  SwitchProps & {
    tooltipContent?: string;
  };

// the purpose of this component is to exclute props which FormField adds
const SwitchField: React.FC<SwitchFieldProps> = (
  props: SwitchFieldProps
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { dirty, error, touched, tooltipContent, className, label, ...rest } =
    props;

  return <Switch className={className} label={label} {...rest} />;
};

export default SwitchField;

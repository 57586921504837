import { Switch, Tooltip } from '@faxi/web-component-library';
import classNames from 'classnames';
import { FormActions } from 'Global.styles';
import { useCallbackAsync, useFormButtons } from 'hooks';
import { appUri } from 'config';
import { InfoCard, Spinner } from 'components';
import { Reward } from 'models';
import { apiRewards } from 'modules';
import { memo, useEffect, useMemo, useState } from 'react';
import { snackBarSuccessMessage } from 'utils';

import * as Styled from './RewardsModal.styles';

const SwitchMemoized = memo(Switch);

type RewardsModalProps = {
  selectedCommunityId: number;
  onClose: () => void;
};

const RewardsModal = (props: RewardsModalProps) => {
  const { selectedCommunityId, onClose } = props;

  const [communityRewards, setCommunityRewards] = useState<number[]>([]);

  const [rewards, setRewards] = useState<Reward[]>([]);
  const [loadingRewards, setLoadingRewards] = useState(false);

  const [FormButtons] = useFormButtons('Save changes', 'Cancel');

  /*
    if reward is active in some campaign 
    we disable deactivate it from that community in which campaign is created
  **/
  const disabledRewardsPerCommunity = useMemo(
    () =>
      rewards
        .filter((reward) => {
          return reward.communities?.find(
            (comm) =>
              comm.community_id === selectedCommunityId && comm.activated_at
          );
        })
        .map((reward) => reward.id),
    [rewards, selectedCommunityId]
  );

  const disableSaveButton = useMemo(() => {
    const initialValues = rewards
      .filter((reward) => {
        return reward.communities?.find(
          (comm: any) => comm.community_id === selectedCommunityId
        );
      })
      .map((reward) => reward.id);

    return (
      initialValues?.sort().toString() === communityRewards.sort().toString()
    );
  }, [communityRewards, rewards, selectedCommunityId]);

  const [getRewards] = useCallbackAsync({
    showSpinner: false,
    callback: async () => {
      try {
        setLoadingRewards(true);

        const {
          data: {
            data: { vouchers },
          },
        } = await apiRewards.getRewards();

        setRewards(vouchers);
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingRewards(false);
      }
    },
  });

  const [handleSubmit] = useCallbackAsync({
    showSpinner: true,
    spinnerParent: '.wcl-modal',
    callback: async () => {
      try {
        const {
          data: { data },
        } = await apiRewards.updateRewardPerCommunity(
          selectedCommunityId!,
          communityRewards
        );

        if (data) {
          snackBarSuccessMessage(`Changes has been successfully saved`);
          onClose();
        }
      } catch (e) {
        console.error(e);
      }
    },
  });

  useEffect(() => {
    setCommunityRewards(
      rewards
        .filter((reward) => {
          return reward.communities?.find(
            (comm: any) => comm.community_id === selectedCommunityId
          );
        })
        .map((reward) => reward.id)
    );
  }, [rewards, selectedCommunityId]);

  useEffect(() => {
    getRewards();
  }, [getRewards]);

  return (
    <Styled.Modal
      title="Rewards"
      onClose={onClose}
      footer={
        rewards.length !== 0 && (
          <FormActions className="kinto-modal__actions">
            <FormButtons.Submit
              disabled={disableSaveButton}
              onClick={handleSubmit}
            />
            <FormButtons.Cancel onClick={() => onClose()} />
          </FormActions>
        )
      }
    >
      <>
        <p className="rewards-modal__description">
          When reward is activated each market can activate the reward per
          community.
        </p>

        {loadingRewards ? (
          <Spinner />
        ) : (
          <div className="rewards-modal__rewards">
            {rewards.length !== 0 ? (
              rewards?.map((reward) => {
                return (
                  <Tooltip
                    placement="top-start"
                    content={
                      'You can’t disable reward for the community which has this reward included in the active campaign'
                    }
                    disabled={!disabledRewardsPerCommunity.includes(reward.id)}
                    key={reward.id}
                    className="activate-per-community__tooltip"
                  >
                    <div>
                      <SwitchMemoized
                        name={reward.name}
                        label={reward.name}
                        className={classNames('rewards-modal__switch', {
                          'rewards-modal__switch--disabled':
                            disabledRewardsPerCommunity.includes(reward.id),
                        })}
                        value={communityRewards.includes(reward.id)}
                        onChange={(ev) => {
                          setCommunityRewards((old) =>
                            ev.target.value === 'true'
                              ? old.filter((el) => el !== reward.id)
                              : [...old, reward.id]
                          );
                        }}
                        disabled={disabledRewardsPerCommunity.includes(
                          reward.id
                        )}
                      />
                    </div>
                  </Tooltip>
                );
              })
            ) : (
              <div>
                <InfoCard
                  text={
                    'You need to add at least one reward in order to activate it some community'
                  }
                  linkText={'Go to Rewards page'}
                  to={appUri.REWARDS}
                />
              </div>
            )}
          </div>
        )}
      </>
    </Styled.Modal>
  );
};

export default RewardsModal;

import { FC } from 'react';
import classNames from 'classnames';
import { FormField, FormFieldProps } from '@faxi/web-form';
import { Image, StatusElement } from '@faxi/web-component-library';

import {
  JourneyParticipantType,
  JOURNEY_PARTICIPANT_TYPE,
} from 'models/Journey';
import { SwitchField } from 'components';
import { SwitchFieldProps } from 'components/fields/SwitchField/SwitchField.component';
import { VerificationParticipantForm } from '../../ManualVerificationModal.component';

import * as Styled from './VerificationSwitch.styles';

type VerificationSwitchProps = {
  fieldName: string;
  className?: string;
} & VerificationParticipantForm &
  Omit<FormFieldProps<SwitchFieldProps>, 'type'>;

const TYPE_TRANSLATIONS = {
  [JOURNEY_PARTICIPANT_TYPE.CYCLER]: 'Cyclist',
  [JOURNEY_PARTICIPANT_TYPE.DRIVER]: 'Driver',
  [JOURNEY_PARTICIPANT_TYPE.WALKER]: 'Walker',
  [JOURNEY_PARTICIPANT_TYPE.PASSENGERS]: 'Passenger',
} as Record<JourneyParticipantType, string>;

const VerificationSwitch: FC<VerificationSwitchProps> = (props) => {
  const {
    user_email,
    user_id,
    verified,
    className,
    fieldName,
    username,
    pocs_time,
    type,
    ...rest
  } = props;

  return (
    <Styled.VerificationSwitch
      className={classNames('kinto-verification-switch', className)}
    >
      <Styled.VerificationColum className="kinto-verification-switch__name">
        <Image
          alt=""
          src="/assets/svg/user_circle_placeholder.svg"
          className="profile-img"
          onError={(e) => {
            (e.target as HTMLImageElement).src =
              '/assets/svg/user_circle_placeholder.svg';
          }}
        />
        <span>{username}</span>
      </Styled.VerificationColum>
      <Styled.VerificationColum className="kinto-verification-switch__type">
        {TYPE_TRANSLATIONS[type]}
      </Styled.VerificationColum>

      {pocs_time && (
        <StatusElement
          className="kinto-verification-switch__status"
          status="approved"
          icon="circle-check"
        >
          Verified
        </StatusElement>
      )}

      <FormField
        {...rest}
        className="kinto-verification-switch__field"
        disabled={!!pocs_time}
        name={`${fieldName}.verified`}
        component={SwitchField}
      />
    </Styled.VerificationSwitch>
  );
};

export default VerificationSwitch;

import {
  APIResponse,
  Journey,
  JourneyResponse,
  JournyeMapResponse,
  PaginatedResponse,
} from 'models';

import { SortDirections } from '@faxi/web-component-library';
import { TableJourney } from 'pages/Journeys/Journeys.page';
import httpClient from '../httpClient';

const getJourneys = async ({
  per_page,
  page,
  searchString,
  sort_by,
  sort_direction,
  hide_test,
}: {
  per_page?: number;
  page?: number;
  searchString?: { param: string; value: string };
  sort_by?: keyof TableJourney;
  sort_direction?: SortDirections;
  hide_test?: number;
}) =>
  httpClient.get<PaginatedResponse<Journey, 'journeys'>>('journeys', {
    params: {
      per_page,
      page,
      [`${searchString?.param}`]: searchString?.value,
      sort: (sort_by || sort_direction) && `${sort_by}:${sort_direction}`,
      hide_test,
    },
  });

const getJourneyById = async (journeyId: string) =>
  httpClient.get<{ data: JourneyResponse }>(`journeys/${journeyId}`);

const getJourneyInspect = async (journeyId: string) =>
  httpClient.get<APIResponse<{ data: JournyeMapResponse }>>(
    `journeys/${journeyId}/inspect`
  );

const addJourneyPocs = (
  journey_id: number,
  data: { reason: string; users: string }
) =>
  httpClient.put<{ pocs: string; status: string }>(
    `journeys/${journey_id}/users/pocs`,
    data
  );

const markAsUncertain = (
  journey_id: number,
  reason: string,
  only_passenger?: string
) =>
  httpClient.put(`journeys/${journey_id}/mark-as-uncertain`, {
    reason,
    only_passengers: `[${only_passenger}]`,
  });

const addReportAnswer = (journey_id: number, answer: string) =>
  httpClient.put(`journeys/${journey_id}/report-answer`, { answer });

export default {
  getJourneys,
  getJourneyById,
  getJourneyInspect,
  addJourneyPocs,
  markAsUncertain,
  addReportAnswer,
};

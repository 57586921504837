import { FC, useCallback, useRef } from 'react';
import { Button, ModalProps, ModalRef } from '@faxi/web-component-library';
import classNames from 'classnames';

import { Icon } from 'components';
import { ActivityTableType } from 'pages/ChangeLogs/ChangeLogs.page';

import * as Styled from './ActivityDetailsModal.styles';

type ActivityDetailsModalProps = Partial<ModalProps> & ActivityTableType;

function formatString(value: string) {
  return value.replace(/_/g, ' ').replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}

const ActivityDetailsModal: FC<ActivityDetailsModalProps> = (
  props: ActivityDetailsModalProps
) => {
  const { id, user_name, properties, ...rest } = props;

  const modalRef = useRef<ModalRef>(null);

  const renderActivityDetails = useCallback(
    (title: string, data: Record<string, any>, className?: string) => {
      return (
        <div
          className={classNames('activity-details-modal__container', className)}
        >
          <p className="activity-details-modal__details">{title}</p>
          {!data
            ? 'No data'
            : Object.entries(data || {}).map(([key, value]) => {
                const isValueObject = typeof value === 'object';

                return (
                  value && (
                    <div className="activity-details-modal__details-container">
                      {!isValueObject && (
                        <>
                          <span className="details-title">
                            {formatString(key)}
                          </span>
                          <span>-</span>
                        </>
                      )}
                      <span className="details-value">
                        {isValueObject
                          ? renderActivityDetails(formatString(key), value)
                          : value}
                      </span>
                    </div>
                  )
                );
              })}
        </div>
      );
    },
    []
  );

  return (
    <Styled.ActivityModal
      ref={modalRef}
      className="activity-details-modal"
      title={`Activity Details`}
      subtitle={`Caused by ${user_name}`}
      renderAsPortal
      footer={
        <Button
          variant="outline"
          onClick={() => {
            modalRef.current?.close();
          }}
        >
          OK
        </Button>
      }
      {...rest}
    >
      {renderActivityDetails('New values', properties.attributes, 'new')}
      {properties?.old && (
        <>
          <Icon name="arrow-up" />
          {renderActivityDetails('Old values', properties.old, 'old')}
        </>
      )}
    </Styled.ActivityModal>
  );
};

export default ActivityDetailsModal;

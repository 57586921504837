import { forwardRef, useCallback, useContext, useRef } from 'react';
import {
  Breadcrumbs,
  GoogleMapsProvider,
  useCallbackRef,
} from '@faxi/web-component-library';
import { useParams } from 'react-router';
import { appUri } from 'config';
import Legend from './components/Legend';
import MapElements from './components/MapElements';
import MapUtilities from './components/MapUtilities';
import JourneyMapActions from '../Journeys/components/JourneyMapActions';
import MapDataContext from './providers/MapData/MapData.context';
import MarkersProvider from './providers/Markers';
import { storageService } from 'services';
import { STORAGE_KEYS } from 'services/storageService';

import * as Styled from './Map.page.styles';

const MapCanvas = forwardRef<any, any>(({ children }, ref) => (
  <Styled.Map>
    <div className="kinto-map" id="kinto-map" ref={ref} />
    {children}
  </Styled.Map>
));

const defaultCenterLondon: google.maps.LatLngLiteral = {
  lat: 51.5254842,
  lng: -0.1057376,
};

const Map: React.FC = () => {
  const [mapContainer, mapRef] = useCallbackRef<HTMLDivElement>();
  const center = useRef<google.maps.LatLngLiteral>(defaultCenterLondon);

  const { passengersMap } = useContext(MapDataContext);

  const { communityId, journeyId } = useParams<{
    communityId: string;
    journeyId: string;
  }>();

  const communityName = useCallback(
    () => storageService.getItem(STORAGE_KEYS.SELECTED_COMMUNITY),
    []
  );

  return (
    <Styled.MapPageContainer className="kinto-map-page-container">
      <Breadcrumbs
        className="kinto-map-page-container__breadcrumbs"
        crumbs={
          communityId
            ? [
                { text: 'Communities', href: appUri.COMMUNITIES },
                {
                  text: `Journeys ${communityName()}`,
                  href: appUri.COMMUNITIES_JOURNEYS(communityId),
                },
                { text: `Map view (Journey ${journeyId})`, href: '' },
              ]
            : [
                { text: 'Journeys', href: appUri.JOURNEYS },
                {
                  text: `Map view (Journey ${journeyId})`,
                  href: appUri.COMMUNITIES,
                },
              ]
        }
      />
      <GoogleMapsProvider
        mapContainer={mapContainer}
        mapOptions={{
          zoom: 15,
          center: center.current,
          disableDefaultUI: true,
          scaleControl: true,
        }}
      >
        <MarkersProvider>
          <aside className="kinto-map-page-container__aside">
            <JourneyMapActions
              journeyId={journeyId}
              passengersTraxes={passengersMap}
            />
            <Legend />
          </aside>

          <Styled.MapPage>
            <MapCanvas ref={mapRef}>
              <MapUtilities />
            </MapCanvas>
            <MapElements />
          </Styled.MapPage>
        </MarkersProvider>
      </GoogleMapsProvider>
    </Styled.MapPageContainer>
  );
};

export default Map;

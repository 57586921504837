import styled from 'styled-components';
import { theme } from '@faxi/web-component-library';

export const MapInfoWindow = styled.ul`
  font-size: ${theme.fontSizes.FONT_14};
  list-style-type: none;
  margin: unset;
  padding: unset;

  word-break: break-word;
`;

import { TabOption } from "@faxi/web-component-library";

export const MAP_PERMISSIONS_LABEL = {
  community_management_edit: 'Community management',
  journeys_edit: 'Journeys',
  market_settings_edit: 'Market settings',
  service_account_edit: 'Service account',
  team_management_edit: 'Team management',
  users_edit: 'Users',
};

export const permissionsTabs: TabOption<string>[] = [
  { label: 'View only', value: 'view-only' },
  { label: 'Edit', value: 'edit' },
  { label: 'Disabled', value: 'disabled' },
];

import { theme } from '@faxi/web-component-library';
import { flex, marginChildren } from '@faxi/web-css-utilities';
import { phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

export const LoginFormStyled = styled.div`
  background-color: var(--BACKGROUND_1_1);
  padding: ${theme.sizes.SIZE_40};
  width: ${theme.sizes.SIZE_680};

  ${phablet(
    css`
      width: unset;
      margin: ${theme.sizes.SIZE_16};
      padding: ${theme.sizes.SIZE_16};
    `
  )};

  .login-form {
    &__heading {
      color: var(--PRIMARY_1_1);
      margin-bottom: ${theme.sizes.SIZE_32};

      ${phablet(
        css`
          color: var(--SECONDARY_1_1);
          font-size: ${theme.fontSizes.FONT_18};
        `
      )};
    }

    &__form {
      &__heading {
        margin-bottom: ${theme.sizes.SIZE_16};
        color: var(--PRIMARY_1_1);
        ${phablet(css`
          display: none;
        `)}
      }

      &__field {
        margin-bottom: ${theme.sizes.SIZE_32};
      }
    }

    &__actions {
      ${flex('row', 'space-between', 'center')};

      ${phablet(css`
        ${flex('column')};
        ${marginChildren(`0 0 ${theme.sizes.SIZE_46} 0`)};
      `)};

      &__login {
        ${phablet(css`
          width: 100%;
        `)};
      }
    }
  }
`;

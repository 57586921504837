import { elevate_xxl, theme } from '@faxi/web-component-library';
import {
  flex,
  fontSize,
  marginChildren,
  position,
  size,
} from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const NoPermissionsPlaceholder = styled.div`
  position: relative;
  ${size('100%')};
  overflow: auto;

  > img {
    ${size('100%')};
  }

  .no-permissions-placeholder {
    &__window {
      ${position('absolute', `top ${theme.sizes.SIZE_56} left 50%`)};
      transform: translateX(-50%);
      width: ${theme.sizes.SIZE_424};
      ${elevate_xxl};
      background-color: var(--BACKGROUND_1_1);
      ${flex('column', 'center', 'center')};
      padding: ${theme.sizes.SIZE_32};
      ${marginChildren(`0 0 ${theme.sizes.SIZE_48} 0`)};
      text-align: center;
      color: var(--SHADE_1_1);
      ${fontSize(theme.fontSizes.FONT_18, '25px')};
      border-radius: ${theme.sizes.SIZE_8};

      > b {
        font-weight: 600;
      }
    }
  }
`;

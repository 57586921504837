import { useContext, useEffect } from 'react';
import { GoogleMapsContext, getColor } from '@faxi/web-component-library';
import { MapPolygon as MapPolygonModel } from 'models';

type MapPolygonProps = MapPolygonModel & {
  onPolygonAdd(polygon: google.maps.Polygon): void;
};

const MapPolygon: React.FC<MapPolygonProps> = (props) => {
  const { A, B, C, D, ok, onPolygonAdd } = props;

  const { map } = useContext(GoogleMapsContext);

  useEffect(() => {
    if (!map) return;

    onPolygonAdd(
      new window.google.maps.Polygon({
        paths: [A, B, C, D],
        map,
        strokeColor: ok
          ? getColor('--ALERT_SUCCESS_1_4')
          : getColor('--ALERT_ERROR_1_4'),
        strokeWeight: 2,
        fillColor: ok
          ? getColor('--ALERT_SUCCESS_1_4')
          : getColor('--ALERT_ERROR_1_4'),
      })
    );
  }, [A, B, C, D, map, ok, onPolygonAdd]);

  return null;
};

export default MapPolygon;

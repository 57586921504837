import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const TableDataContainer = styled.div`
  ${flex('column', 'center')}
  width: 100%;
  margin: 0 0 ${theme.sizes.SIZE_40};

  .sa-table-data {
    &__title {
      color: var(--SECONDARY_1_1);
      font-weight: 600;
      margin: 0 0 ${theme.sizes.SIZE_24};
    }

    &__table {
      user-select: auto;
    }

    &__empty-placeholder {
      ${fontSize(theme.sizes.SIZE_16, theme.sizes.SIZE_20)};
      color: var(--SECONDARY_1_1);
    }

    &__spinner {
      margin: auto;
    }
  }
`;

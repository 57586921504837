import { Admin } from 'models/Admin';
import { createContext } from 'react';
import { noop } from 'utils';

type AuthContextProps = {
  admin?: Admin;
  adminFullName: string;
  globalLoading: boolean;
  isAuthenticated: boolean;
  setGlobalLoading: (value: boolean) => void;
  simpleLogout: () => void;
  globalLogout: (showToast: boolean) => void;
  setAdmin: React.Dispatch<React.SetStateAction<Admin>>;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
};

const AuthContext = createContext<AuthContextProps>({
  admin: null!,
  adminFullName: '',
  globalLoading: false,
  isAuthenticated: false,
  simpleLogout: noop,
  setAdmin: noop,
  globalLogout: noop,
  setIsAuthenticated: noop,
  setGlobalLoading: noop,
});

export default AuthContext;

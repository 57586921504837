import { theme } from '@faxi/web-component-library';
import { flex, flexGap } from '@faxi/web-css-utilities';
import { PageLayoutContainer } from 'components/_layouts/PageLayout.styles';
import styled from 'styled-components';

export const LogsContainer = styled(PageLayoutContainer)`
  gap: ${theme.sizes.SIZE_32};

  .kinto-app-logs {
    &__actions {
      ${flex('column')};
      ${flexGap(theme.sizes.SIZE_20, 'column')};

      width: fit-content;

      .input {
        max-width: ${theme.sizes.SIZE_240};
      }
    }
  }
`;

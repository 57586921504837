import { ReactNode } from 'react';
import { Button } from '@faxi/web-component-library';
import { useHistory } from 'react-router';
import classNames from 'classnames';

import Icon from '../Icon';

import * as Styled from './TableStyles.styles';

type TraxTableTemplateProp = {
  title: string;
  children: ReactNode;
  className?: string;
};

const TraxTableTemplate = (props: TraxTableTemplateProp) => {
  const { title, children, className } = props;

  const history = useHistory();

  return (
    <Styled.TraxTable className={classNames('trax-table', className)}>
      <Button
        type="button"
        variant="ghost"
        onClick={() => {
          history.goBack();
        }}
        icon={<Icon name="chevron-left" />}
        iconPosition="left"
        className="trax-table__back-button"
      >
        Back to map view
      </Button>
      <Styled.TableHeader level="4">{title}</Styled.TableHeader>
      {children}
    </Styled.TraxTable>
  );
};

export default TraxTableTemplate;

import { useMemo } from 'react';
import { FormField, validators } from '@faxi/web-form';
import { InputField } from 'components';
import GoogleAutocompleteField from 'components/fields/GoogleAutocompleteField';
import TabsField from 'components/fields/TabsField';
import CheckboxField from 'components/fields/CheckboxField';
import specific from 'validation/validators/specific';

import * as Styled from './CommunityDetails.styles';

const createCommunityTypes = [
  { label: 'Workplace', value: 'Group' },
  { label: 'School', value: 'School' },
];

const CommunityDetails = () => {
  const validations = useMemo(
    () => ({
      name: [
        validators.general.required('Name is required'),
        validators.general.maxLength(
          60,
          'Maximum length exceeded, name can only have a maximum of 60 characters.'
        ),
      ],
      address: [specific.googleAutocompleteAddress('Address is required')],
    }),
    []
  );

  return (
    <Styled.CommunityDetailsStyled className="kinto-modal__fields">
      <FormField
        component={InputField}
        name="name"
        required
        requiredLabel="Required"
        placeholder="Community name"
        validate={validations.name}
      />

      <FormField component={CheckboxField} name="test" label="Test community" />

      <FormField
        component={GoogleAutocompleteField}
        name="address"
        placeholder="Address"
        required
        requiredLabel="Required"
        validate={validations.address}
      />

      <fieldset>
        <legend>What type of community are you creating?</legend>

        <FormField
          component={TabsField}
          name="type"
          tabs={createCommunityTypes}
        />
      </fieldset>
    </Styled.CommunityDetailsStyled>
  );
};

export default CommunityDetails;

import {
  Breadcrumbs,
  Button,
  Icon,
  Modal,
  ModalRef,
  useUtilities,
  SelectOption,
  Table,
  useCallbackRef,
  Heading,
  StatusElement,
  getColor,
} from '@faxi/web-component-library';
import {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
  MutableRefObject,
} from 'react';
import { useParams } from 'react-router';
import { useFormButtons } from 'hooks';
import { Form, FormField, FormRef, validators } from '@faxi/web-form';
import { TablePageLayout, SelectField } from 'components';
import { FormActions, NoData } from 'Global.styles';
import { debounce, uniqueId } from 'lodash';
import { appUri } from 'config';
import { snackBarSuccessMessage } from 'utils';
import { AuthContext } from 'store';
import { apiCommunities, apiServiceAccounts } from 'modules';
import { composeValidators } from 'validation/validators/specific';
import { Permission, ServiceAccount } from 'models';
import { PERMISSIONS_SHORTHAND, STANDARD_PERMISSIONS } from 'utils/permissions';
import { SwitchField } from 'components';
import { TableAction } from 'components/_layouts/PageLayout.styles';
import { storageService } from 'services';
import { STORAGE_KEYS } from 'services/storageService';
import { INameExtended } from 'components/Icon';

export type ServiceAccountPermission = {
  id: number;
  name: string;
  route?: string;
};

type TableServiceAccount = Pick<
  ServiceAccount,
  'id' | 'organisation_id' | 'organisation' | 'permissions'
> & {
  permissionsJSX: JSX.Element;
};

const ServiceAccounts: React.FC = () => {
  const { admin } = useContext(AuthContext);

  const { showOverlay, hideOverlay, prompts } = useUtilities();

  const [FormButtons] = useFormButtons('Save changes');

  const { hashId } = useParams<{
    hashId: string;
  }>();

  const modalRef = useRef<ModalRef>(null);
  const editBtnRef = useRef<HTMLButtonElement>(null);
  const createBtnRef = useRef<HTMLButtonElement>(null);

  const [loadingServiceAccounts, setLoadingServiceAccounts] = useState(false);
  const [showModal, setShowModal] = useState<'add' | 'edit' | 'none'>('none');

  const [serviceAccounts, setServiceAccounts] = useState<ServiceAccount[]>([]);

  const [serviceAccountPermissions, setServiceAccountPermissions] = useState<
    ServiceAccountPermission[]
  >([]);

  const [communitiesOptions, setCommunitiesOptions] = useState<SelectOption[]>(
    []
  );
  const communitiesOptionsRef = useRef<SelectOption[]>([]);

  const [selectedServiceAccount, setSelectedServiceAccount] =
    useState<
      Pick<
        ServiceAccount,
        'id' | 'organisation_id' | 'organisation' | 'permissions'
      >
    >();

  const [loadingCommunities, setLoadingCommunities] = useState<boolean>(false);
  const [communitiesSearch, setCommunitiesSearch] = useState<string>();
  const [communitiesCurrentPage, setCommunitiesCurrentPage] =
    useState<number>(1);
  const [debouncedCommunitiesSearch, setDebouncedCommunitiesSearch] =
    useState<string>();
  const oldCommunitiesSearch = useRef(debouncedCommunitiesSearch);
  const [communitiesNumberOfPages, setCommunitiesNumberOfPages] =
    useState<number>(1);

  const translationKeys = useMemo(
    () =>
      ({
        id: 'ID',
        organisation_id: 'Community ID',
        organisation: 'Community name',
        permissionsJSX: 'Permissions',
      } as Record<
        Partial<keyof (ServiceAccount & { permissionsJSX: JSX.Element })>,
        string
      >),
    []
  );

  const canEdit = useMemo(
    () => !!admin?.permissions.find((p) => p.name === 'service_account_edit'),
    [admin]
  );

  const initialData = useMemo(
    () => ({
      organisation_id: selectedServiceAccount
        ? {
            value: `${selectedServiceAccount.organisation_id}`,
            label: `${selectedServiceAccount.organisation}`,
          }
        : undefined,
      organisation_journey_access: selectedServiceAccount
        ? !!selectedServiceAccount.permissions.find(
            (p) => p.name === 'organisation_journey_access'
          )
        : true,
    }),
    [selectedServiceAccount]
  );

  const validations = useMemo(
    () => ({
      organisation_id: composeValidators(
        validators.general.required('This field is required')
      ),
    }),
    []
  );

  const hashKey = useCallback(() => {
    return storageService.getItem(STORAGE_KEYS.SELECTED_HASH_KEY);
  }, []);

  const renderPermissions = useCallback(
    (permissions: Permission[]) => (
      <div className="kinto-users__table__consents">
        {permissions.length === 0 && '-'}
        {permissions.map(({ name }) => (
          <StatusElement<INameExtended>
            key={uniqueId(name)}
            status="canceled"
            icon="info-circle"
            tooltipText={STANDARD_PERMISSIONS[name]}
          >
            {PERMISSIONS_SHORTHAND[name]}
          </StatusElement>
        ))}
      </div>
    ),
    []
  );

  const tableServiceAccounts = useMemo<TableServiceAccount[]>(
    () =>
      serviceAccounts.map(
        ({ id, organisation_id, organisation, permissions }) => ({
          id,
          organisation_id,
          organisation,
          permissionsJSX: renderPermissions(permissions),
          permissions,
        })
      ),
    [renderPermissions, serviceAccounts]
  );

  const [form, formRef] = useCallbackRef<FormRef>();

  const crumbs = useMemo(
    () => [
      {
        text: 'Service accounts API keys',
        href: appUri.SETTINGS_SERVICE_ACCOUNTS_KEYS,
      },
      {
        text: `Service accounts for API key ${hashKey()}`,
        href: '',
      },
    ],
    [hashKey]
  );

  const getServiceAccounts = useCallback(async () => {
    setLoadingServiceAccounts(true);
    try {
      showOverlay('.kinto-page', 'fixed');

      const {
        data: {
          data: { service_accounts },
        },
      } = await apiServiceAccounts.getServiceAccounts(hashKey() as any);

      setServiceAccounts(service_accounts);
      // if (service_accounts) {
      //   setServiceAccounts(
      //     service_accounts.map((serviceAccount: ServiceAccount) => {
      //       const permissions = serviceAccount?.permissions?.map(
      //         (permission: { id: number; name: string }) => permission.name
      //       );

      //       return {
      //         id: serviceAccount.id,
      //         organisation: serviceAccount.organisation,
      //         organisation_id: serviceAccount.organisation_id,
      //         permissionsJSX: renderPermissions(
      //           permissionsList.filter((permission) =>
      //             permissions?.includes(permission.key)
      //           )
      //         ),
      //         permissions,
      //       };
      //     })
      //   );
      // }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingServiceAccounts(false);
      hideOverlay('.kinto-page');
    }
  }, [hashKey, hideOverlay, showOverlay]);

  const getServiceAccountsPermissions = useCallback(async () => {
    try {
      const {
        data: {
          data: { permissions },
        },
      } = await apiServiceAccounts.getServiceAccountsPermissions();

      if (permissions) {
        setServiceAccountPermissions(permissions);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleAddServiceAccount = useCallback(
    async (data: any) => {
      showOverlay('.wcl-modal');

      const { organisation_id, ...rest } = data;

      const permissions = Object.keys(rest);

      const organisation = communitiesOptionsRef.current.find(
        (option: SelectOption) => option.value === organisation_id
      )!.label;

      try {
        const { data } = await apiServiceAccounts.addServiceAccount({
          api_key_id: +hashId,
          organisation_id,
          organisation,
          permissions,
        });

        if (data) {
          snackBarSuccessMessage(`Successfully created a service account`);
          setServiceAccounts((old) => [...old, data.data]);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setShowModal('none');
        hideOverlay('.wcl-modal');
      }
    },
    [hashId, hideOverlay, showOverlay]
  );

  const handleDeleteServiceAccount = useCallback(
    async ({
      id,
      organisation,
    }: Pick<TableServiceAccount, 'id' | 'organisation'>) => {
      showOverlay('.wcl-modal');

      try {
        await apiServiceAccounts.deleteServiceAccount(id);

        setServiceAccounts((old) =>
          old.filter((serviceAccount) => serviceAccount.id !== id)
        );
        snackBarSuccessMessage(
          `Successfully deleted service account for community ${organisation}`
        );
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('.wcl-modal');
      }
    },
    [hideOverlay, showOverlay]
  );

  const tableActions = useMemo(
    () =>
      ({
        id,
        organisation,
        organisation_id,
        permissions,
      }: TableServiceAccount) =>
        (
          <TablePageLayout.TableActions
            actions={
              [
                {
                  name: 'Edit',
                  icon: 'pen',
                  onClick: async (btnElement: HTMLButtonElement) => {
                    setSelectedServiceAccount({
                      id,
                      organisation_id,
                      organisation,
                      permissions,
                    });
                    (
                      editBtnRef as MutableRefObject<HTMLButtonElement>
                    ).current = btnElement;
                    setShowModal('edit');
                  },
                },
                {
                  name: 'Delete',
                  icon: 'trash-can',
                  variant: 'delete-ghost',
                  onClick: async (btnElement: HTMLButtonElement) => {
                    if (
                      await prompts.delete({
                        submitBtnText: 'Delete',
                        cancelBtnText: 'Cancel',
                        title:
                          'Do you really want to delete this service account?',
                      })
                    ) {
                      handleDeleteServiceAccount({ id, organisation });
                    }
                    btnElement.focus();
                  },
                },
              ] as TableAction[]
            }
          />
        ),
    [handleDeleteServiceAccount, prompts]
  );

  const renderSwitches = useMemo(
    () =>
      serviceAccountPermissions?.map(
        ({ name, id }: ServiceAccountPermission) => (
          <FormField
            name={name}
            label={STANDARD_PERMISSIONS[name]}
            disabled={showModal === 'add'}
            component={SwitchField}
            key={id}
          />
        )
      ),
    [serviceAccountPermissions, showModal]
  );

  const handleEditServiceAccount = useCallback(
    async (data: any) => {
      showOverlay('.wcl-modal');

      const { organisation_id, organisation, ...rest } = data;

      const permissions = Object.entries(rest)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);

      try {
        const {
          data: { data },
        } = await apiServiceAccounts.updateServiceAccount({
          id: +selectedServiceAccount!.id,
          // in case of edit, we prefill the Select field with SelectOption which possibly does not exist in options list
          // this is why the `organisation_id` is an object in this case
          organisation_id: organisation_id.id,
          organisation,
          permissions,
        });

        setServiceAccounts((old) => {
          const index = old.findIndex((el) => el.id === data.id);

          old[index] = { ...data };

          return [...old];
        });

        snackBarSuccessMessage(
          `Successfully updated service account ${selectedServiceAccount?.id}`
        );
      } catch (e) {
        console.error(e);
      } finally {
        setShowModal('none');
        hideOverlay('.wcl-modal');
      }
    },
    [hideOverlay, selectedServiceAccount, showOverlay]
  );

  const loadMoreItems = useCallback(() => {
    if (communitiesCurrentPage < communitiesNumberOfPages) {
      setCommunitiesCurrentPage((old) => old + 1);
    }
  }, [communitiesCurrentPage, communitiesNumberOfPages]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCommunitiesSearchChange = useCallback(
    debounce((text: string) => {
      setDebouncedCommunitiesSearch((old) => {
        oldCommunitiesSearch.current = old;
        return text;
      });
      setCommunitiesCurrentPage(1);
    }, 250),
    []
  );

  const getCommunitiesOptions = useCallback(async () => {
    if (
      oldCommunitiesSearch.current !== communitiesSearch &&
      communitiesCurrentPage > 1
    ) {
      return;
    }

    try {
      setLoadingCommunities(true);

      const {
        data: {
          data,
          meta: { last_page },
        },
      } = await apiCommunities.getAllCommunities({
        page: communitiesCurrentPage,
        search: debouncedCommunitiesSearch,
        per_page: 10,
      });

      setCommunitiesNumberOfPages(last_page);

      if (oldCommunitiesSearch.current === debouncedCommunitiesSearch) {
        setCommunitiesOptions((old) => [
          ...old,
          ...data.organisations.map(({ name, id }) => ({
            label: name,
            value: `${id}`,
          })),
        ]);
      } else {
        oldCommunitiesSearch.current = debouncedCommunitiesSearch;
        setCommunitiesOptions(
          data.organisations.map(({ name, id }) => ({
            label: name,
            value: `${id}`,
          }))
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingCommunities(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communitiesCurrentPage, debouncedCommunitiesSearch]);

  const modalForm = useCallback(
    ({ children, className }: any) => (
      <Form
        onSubmit={
          showModal === 'edit'
            ? handleEditServiceAccount
            : handleAddServiceAccount
        }
        initialData={initialData}
        ref={formRef}
        children={children}
        className={className}
      />
    ),
    [
      formRef,
      handleAddServiceAccount,
      handleEditServiceAccount,
      initialData,
      showModal,
    ]
  );

  useEffect(() => {
    communitiesOptionsRef.current = communitiesOptions;
  }, [communitiesOptions]);

  useEffect(() => {
    getServiceAccounts();
  }, [getServiceAccounts]);

  useEffect(() => {
    getServiceAccountsPermissions();
  }, [getServiceAccountsPermissions]);

  // GET COMMUNITIES OPTIONS
  useEffect(() => {
    getCommunitiesOptions();
  }, [getCommunitiesOptions]);

  return (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Breadcrumbs className="kinto-page__breadcrumbs" crumbs={crumbs} />

      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__header"
      >
        Service accounts for API key {hashKey() as any}
      </Heading>

      {canEdit && (
        <div className="kinto-page__actions">
          <Button
            ref={createBtnRef}
            icon={<Icon name="plus" />}
            onClick={() => {
              setSelectedServiceAccount(undefined);
              setShowModal('add');
            }}
          >
            Create a service account
          </Button>
        </div>
      )}

      {!loadingServiceAccounts && !tableServiceAccounts.length ? (
        <NoData>There are no service accounts for this key</NoData>
      ) : (
        <Table<TableServiceAccount>
          tableId="service-accounts-table"
          className="service-accounts__table"
          expandable={canEdit}
          tableData={tableServiceAccounts}
          translationKeys={translationKeys}
          tableActions={tableActions}
          disableSort
          excludeColumns={['permissions']}
        />
      )}

      {/* SERVICE ACCOUNT MODAL */}
      {showModal !== 'none' && (
        <Modal
          onClose={() => {
            setShowModal('none');
            if (showModal === 'edit') editBtnRef.current?.focus();
            else if (showModal === 'add') createBtnRef.current?.focus();
          }}
          title={`${
            showModal === 'edit' ? 'Edit' : 'Create a'
          } service account`}
          className="kinto-modal"
          childrenWrapper={modalForm}
          footer={
            <FormActions className="kinto-modal__actions">
              <FormButtons.Submit
                disabled={!form?.isFormChanged() || !form?.syncFormValid}
              />
              <FormButtons.Cancel onClick={() => modalRef.current?.close()} />
            </FormActions>
          }
          ref={modalRef}
        >
          <div className="kinto-modal__fields">
            <FormField
              name="organisation_id"
              renderAsPortal
              component={SelectField}
              disabled={showModal === 'edit'}
              placeholder="Select community"
              hasClearAction
              clearTitle="Clear selection"
              validate={validations.organisation_id}
              async
              searchable
              errorText="This field can only contain lowercase (a-z) and uppercase (A-Z) letters, numbers (0-9), the following special characters (@ _ & $ -) and single spaces between."
              regex={/^([A-Za-z0-9_@&$-]+(\s?[A-Za-z0-9_@&$-]+)*)*$/}
              repositionDropdown={false}
              loading={loadingCommunities}
              options={communitiesOptions}
              onContainerScroll={loadMoreItems}
              onSearchChange={(e: string) => {
                setCommunitiesSearch(e);
                onCommunitiesSearchChange(e);
              }}
              onClear={() => setCommunitiesSearch('')}
            />

            <label>
              <b>Permissions</b>
            </label>

            {renderSwitches}
          </div>
        </Modal>
      )}
    </TablePageLayout.PageLayoutContainer>
  );
};

export default ServiceAccounts;

import { Consents, ConsentType } from 'models/TableData';
import credentialService from 'services/credentialService';

export const getConsentsFormat = (
  consents: Array<ConsentType>,
  translations: Record<string, any> = getPlatformConsents(
    credentialService.platformId
  )
) => {
  return (
    Object.entries(consents)
      //we need to remove terms and conditions and
      //privacy and policy from list of consetns for now
      .filter(([key, value]) => value && key !== 't&c' && key !== 'prp')
      .map(
        ([key, _]) =>
          ({
            name: CONSENTS_SHORTHAND[key as Consents] || key.toUpperCase(),
            description: translations[key as Consents],
          } as ConsentType)
      )
  );
};

export const getPlatformConsents = (platformID: string) =>
  CONSENTS(+platformID);

export const CONSENTS_SHORTHAND = {
  dk_evn: 'EVN',
  dk_mrk: 'MRK',
  dk_rmd: 'RMD',
  dk_off: 'OFF',
  dpr: 'DPR',
  geo: 'GEO',
  inc: 'INC',
  ins: 'INS',
  msg: 'MSG',
  per: 'PER',
  prp: 'PRP',
  sds: 'SDS',
} as Record<Consents, string>;

export const STANDARD_CONSENTS = {
  dpr: 'I allow KINTO Join to process my data',
  inc: 'I agree to participate in carpooling incentive programmes',
  msg: 'Sign up to hear about the latest offers from KINTO Join and selected third parties.',
} as Record<string, string>;

export const UK_CONSENTS = {
  ...STANDARD_CONSENTS,
  ins: 'Car Insurance Offers',
};

export const ITALIAN_CONSENTS = {
  ...STANDARD_CONSENTS,
  geo: 'Geolocation',
  dpr: 'Data processing',
  msg: 'Marketing Communication',
  survey: 'Survey',
  profile: 'Profiling',
} as Record<Partial<Consents>, string>;

export const DENMARK_CONSENTS = {
  ...STANDARD_CONSENTS,
  dk_rmd: 'Reminders',
  dk_off: 'Product offers and news',
  dk_mrk: 'Marketing-related surveys',
  dk_evn: 'Events and competitions',
} as Record<string, string>;

export const CONSENTS = (platformId: number) => {
  switch (platformId) {
    case 1:
    case 5:
      return UK_CONSENTS;
    case 6:
      return ITALIAN_CONSENTS;
    case 12:
      return DENMARK_CONSENTS;
    default:
      return STANDARD_CONSENTS;
  }
};

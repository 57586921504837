import { useCallbackAsync } from 'hooks';
import { apiAuth } from 'modules';
import { useContext, useEffect } from 'react';
import AuthContext from 'store/AuthProvider/Auth.context';

/*
    This component is used as intermediary page for logging out
    It is created to complement Promp functionality,
     now we clear localStorage only if redirect to this page
*/
const LoggingOut = () => {
  const { simpleLogout } = useContext(AuthContext);

  const [logout] = useCallbackAsync({
    callback: async () => {
      await apiAuth.logout();
    },
    showSpinner: true,
    spinnerParent: '.root',
    onFinish: () => {
      simpleLogout();
    },
  });

  useEffect(() => {
    logout();
  }, [logout]);

  return null;
};

export default LoggingOut;

import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

export const Container = styled.div`
  .admins-page {
    &__breadcrumbs {
      margin-bottom: ${theme.sizes.SIZE_4};
    }

    &__header {
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__add-admin-button {
      margin-bottom: ${theme.sizes.SIZE_20};
    }

    &__empty-state {
      color: var(--SHADE_1_3);
    }

    &__add-admin-modal,
    .wcl-modal {
      width: 100%;
      max-width: fit-content;

      .kinto-modal__actions {
        margin-top: 0;
      }
    }

    &__table {
      height: fit-content;
    }
  }
`;

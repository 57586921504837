import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import {
  Breadcrumbs,
  Heading,
  SelectOption,
  Table,
  getColor,
  useQueryParams,
} from '@faxi/web-component-library';
import { useHistory, useParams } from 'react-router';

import { appUri } from 'config';
import classNames from 'classnames';
import { TablePageLayout } from 'components';
import { apiCommunities } from 'modules';
import { CarParkType } from 'models/CarPark';
import { storageService } from 'services';
import { STORAGE_KEYS } from 'services/storageService';
import { useTablePagination } from 'hooks';
import { CAR_PARK_TRANSLATIONS } from '../CarPark/CarPark.page';
import CarParkContext from 'store/CarParkProvider/CarPark.context';

import * as Styled from './CarParkSpaces.styles';
import { NoData } from 'Global.styles';

const CarParkSpaces: FC = () => {
  const { communityId, parkId } = useParams<{
    communityId: string;
    parkId: string;
    page: string;
  }>();

  const { setQueryParam } = useQueryParams<{
    page: string;
  }>();

  const {
    carParkNumbers: { spaces_total, spaces_other, spaces_shared },
    fetchCarParkNumbers,
  } = useContext(CarParkContext);

  const history = useHistory();

  const carParkName = useCallback(() => {
    return storageService.getItem(STORAGE_KEYS.SELECTED_CAR_PARK);
  }, []);

  useEffect(() => {
    //This is call only on reload app or on outside redirecting to this page
    if (history.action === 'POP' && parkId) {
      fetchCarParkNumbers(parkId);
    }
  }, [fetchCarParkNumbers, parkId, history]);

  const {
    data: parkSpaces,
    loading,
    totalCount,
    totalPages,
    count,
    setCount,
    currentPage,
    setCurrentPage,
  } = useTablePagination<CarParkType, 'spaces'>({
    itemsKey: 'spaces',
    spinnerParentClass: 'body',
    mappingFunction: (parks: Array<CarParkType>) => parks,
    apiRequest: ({ per_page, currentPage }) => {
      return apiCommunities.getCarParkSpaces(
        communityId,
        parkId,
        currentPage,
        per_page
      );
    },
  });

  const crumbs = useMemo(
    () => [
      { text: 'Communities', href: appUri.COMMUNITIES },
      {
        text: `Car parks`,
        href: appUri.COMMUNITIES_CARPARK(communityId),
      },
      {
        text: `Parking spaces (${carParkName()})`,
        href: '',
      },
    ],
    [communityId, carParkName]
  );

  return (
    <TablePageLayout.PageLayoutContainer className="kinto-carpark-spaces-container">
      {communityId && (
        <Breadcrumbs className="kinto-page__breadcrumbs" crumbs={crumbs} />
      )}

      <Styled.CarParkSpacesContainer className="kinto-carpark-spaces">
        <Heading
          level="1"
          color={getColor('--PRIMARY_1_1')}
          className="kinto-page__header"
        >
          Parking spaces ({carParkName() as any})
        </Heading>

        {
          <div className="kinto-carpark-spaces__total-numbers">
            <div className="kinto-carpark-spaces__total-numbers__container">
              <div className="kinto-carpark-spaces__total-numbers__total">
                {spaces_total || '-'}
              </div>
              <div className="kinto-carpark-spaces__total-numbers__label">
                Total number of car spaces
              </div>
            </div>
            <div className="kinto-carpark-spaces__total-numbers__container">
              <div className="kinto-carpark-spaces__total-numbers__total">
                {spaces_shared || '-'}
              </div>
              <div className="kinto-carpark-spaces__total-numbers__label">
                Total number of car spaces for car sharers
              </div>
            </div>
            <div className="kinto-carpark-spaces__total-numbers__container">
              <div className="kinto-carpark-spaces__total-numbers__total">
                {spaces_other || '-'}
              </div>
              <div className="kinto-carpark-spaces__total-numbers__label">
                Total number of car spaces for others
              </div>
            </div>
          </div>
        }

        <div className="kinto-carpark-spaces__table-wrapper">
          <Table<CarParkType>
            tableId="carpark-spaces-table"
            className={classNames([
              'kinto-carpark-spaces__table',
              { 'kinto-carpark-spaces__table--empty': !totalCount },
            ])}
            tableData={parkSpaces}
            disableSort
            breakAtMaxWidth={900}
            translationKeys={CAR_PARK_TRANSLATIONS}
            {...(!loading && {
              noDataPlaceholder: <NoData>No car park spaces.</NoData>,
            })}
            paginationData={{
              currentPage,
              limit: count,
              totalCount,
              totalPages,
            }}
            goToPageInputProps={{ placeholder: 'Go to page' }}
            onPageChange={(value) => {
              setCurrentPage(value);
              setQueryParam('page', value);
            }}
            onLimitChange={(data: SelectOption<string>) => {
              setCurrentPage(1);
              setCount(+data.value);
            }}
          />
        </div>
      </Styled.CarParkSpacesContainer>
    </TablePageLayout.PageLayoutContainer>
  );
};

export default CarParkSpaces;

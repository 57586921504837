import { Heading, useUtilities, Table } from '@faxi/web-component-library';
import { useContext, useEffect, useMemo } from 'react';

import MapDataContext from 'pages/Map/providers/MapData/MapData.context';
import { Trax } from 'models';
import TraxTableTemplate from '../TraxTableTamplate';
import { dateInUTCFormat } from 'utils';
import { useParams } from 'react-router';

export type PassengersApiType = Record<
  string,
  {
    trax: Trax[];
    start_lat: string;
    start_lng: string;
    end_lat: string;
    end_lng: string;
  }
>;

type TableData = Trax & { index: number; pin_arr: string };

const PassengerTraxTable = () => {
  const { showOverlay, hideOverlay } = useUtilities();

  const { passengerId } = useParams<{ passengerId: string }>();
  const { passengersMap } = useContext(MapDataContext);

  const translationKeys = useMemo(
    () =>
      ({
        index: '#',
        id: 'ID',
        ts: 'ts',
        dts: 'dts',
        lat: 'lat',
        lng: 'lng',
        accuracy: 'Accuracy',
        age: 'Age',
        pin_arr: 'PIN',
        mode: 'Activity mode',
        phone_status: 'Status',
      } as Record<Partial<keyof Trax | 'index'>, string>),
    []
  );

  const trax = useMemo(
    () =>
      passengersMap[passengerId]?.trax
        ? (passengersMap[passengerId].trax.map(
            (
              {
                id,
                ts,
                dts,
                lat,
                lng,
                accuracy,
                age,
                pin_arr,
                mode,
                phone_status,
              },
              index: number
            ) =>
              ({
                index,
                id,
                ts: dateInUTCFormat(ts),
                dts,
                lat: lat || '-',
                lng: lng || '-',
                accuracy: accuracy || '-',
                age: age || 0,
                pin_arr: pin_arr?.toString() || '-',
                mode: mode || '-',
                phone_status: phone_status || '-',
              } as TableData)
          ) as Array<TableData>)
        : [],
    [passengerId, passengersMap]
  );

  useEffect(() => {
    if (!trax) {
      showOverlay('.kinto-passenger-trax-table');
    } else {
      hideOverlay('.kinto-passenger-trax-table');
    }
  }, [hideOverlay, showOverlay, trax]);

  return (
    <TraxTableTemplate
      title={`Passenger ${passengerId} trax`}
      className="kinto-passenger-trax-table"
    >
      {trax &&
        (trax.length > 0 ? (
          <Table
            tableId="passenger-trax-table"
            tableData={trax}
            excludeColumns={['altitude', 'course', 'speed']}
            translationKeys={translationKeys}
            disableSort={true}
            breakAtMaxWidth={1400}
          />
        ) : (
          <Heading level="4" className="trax-table__empty-placeholder">
            Sorry, there is no trax data for passenger {passengerId}.
          </Heading>
        ))}
    </TraxTableTemplate>
  );
};

export default PassengerTraxTable;
